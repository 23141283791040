export default {
  nil: "",
  or: "or",
  and: "and",
  yes: "Yes",
  no: "No",
  all: "All",
  others: "others",
  men: "Men",
  women: "Women",
  recommended: "Recommended",
  recommended_for_you: "Recommended for You",
  new_in: "New In",
  designers: "Designers",
  designer: "Designer",
  category: "Category",
  condition: "Condition",
  conditions: "Conditions",
  gender: "Gender",
  size: "Size",
  sizes: "Sizes",
  price: "Price",
  accept_offers: "Accept Offers",
  refine: "Refine",
  marketplace: "Marketplace",
  show_sold_only: "Show Sold Only",
  sold_only: "Sold Only",
  only_in_marketplace_instr:
    "This listing will appear only under Marketplace view",
  shipping: "Shipping",
  loading: "Loading...",
  no_available_options: "No Available Options",
  search: "Search...",
  searching: "Searching",
  search_products: "Search Products",
  search_designers: "Search Designers",
  description: "Description",
  please_enter_description: "Please enter description",
  failed_to_load: "Failed to Load",
  lowest_ask: "Lowest Ask",
  highest_bid: "Highest Bid",
  images: "Images",
  filter_name: "Filter {{name}}",
  count_items: "{{count}} Items",
  previous: "Previous",
  next: "Next",
  username: "Username",
  username_or_email: "Username or Email",
  password: "Password",
  password_again: "Password Again",
  log_in: "Log in",
  sign_up: "Sign up",
  profile: "Profile",
  my_offers: "My Offers",
  my_orders: "My Orders",
  browsing_history: "Browsing History",
  feedbacks: "Feedbacks",
  preferences: "Preferences",
  message: "Message",
  messages: "Messages",
  offer_list: "Offer List",
  compositions: "Compositions",
  brand_new: "Brand New",
  like_new: "Like New",
  very_good: "Very Good",
  good: "Good",
  acceptable: "Acceptable",
  ask: "Ask",
  bid: "Bid",
  create_ask: "Create Ask",
  create_bid: "Create Bid",
  edit_ask: "Edit Ask",
  edit_bid: "Edit Bid",
  create_offer: "Create Offer",
  buy: "Buy",
  shop: "Shop",
  buy_now: "Buy Now",
  sell: "Sell",
  sell_now: "Sell Now",
  no_matched_offers: "No Matched Offers",
  not_found: "Not Found",
  loading_paypal: "Loading PayPal",
  size_placeholder: "Please select size.",
  condition_placeholder: "Please select condition.",
  expires_in: "Expires in",
  expired: "Expired",
  never: "Never",
  refunded: "Refunded",
  refunding: "Refunding",
  one_day: "24 Hours",
  two_days: "48 Hours",
  seven_days: "7 Days",
  fourteen_days: "14 Days",
  thirty_days: "30 Days",
  debug_10_min: "Debug 10 Min",
  six_hours: "6 Hours",
  clear_all: "Clear All",
  sort_by: "Sort by",
  default: "Default",
  low_price: "Price Low to High",
  high_price: "Price High to Low",
  featured: "Featured",
  latest: "Latest",
  new_lowest_asks: "New Lowest Asks",
  new_highest_bids: "New Highest Bids",
  new: "New",
  most_popular: "Most Popular",
  click_to_start_measuring: "Click to Start Measuring",
  cant_find_product: `Can't find the product you're looking for? Create one.`,
  please_enter_product_name: "Please enter product name.",
  select_category: "Select Category",
  offer_information: "Offer Information",
  match_product: "Match Product",
  matching: "Matching",
  category_alert: "Please select category.",
  designer_alert: "Please select designer.",
  select_size: "Please select size",
  size_unit: "Size Unit",
  size_unit_alert: "Please select size unit.",
  product_code: "Product Code",
  product_images_alert: "Please upload product images.",
  images_alert: "Please upload images.",
  favorites: "Favorites",
  no_bio: "No Bio",
  public_profile: "Public Profile",
  account_security: "Account Security",
  log_out: "Log out",
  region: "Region",
  language: "Language",
  currency: "Currency",
  switch_region_language_currency: "Switch Region / Language / Currency",
  change_password: "Change Password",
  old_password: "Old Password",
  cancel: "Cancel",
  save_profile: "Save Profile",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  change_avatar: "Change Avatar",
  bio: "Bio",
  date_of_birth: "Date of Birth",
  email: "Email",
  join_date: "Join Date",
  new_messages: "New messages",
  new_private_offer_received: "New private offer received in message",
  new_highest_bid_on_ask: "New highest bid on my public ask",
  ask_expiring: "Expiring on my public ask",
  bid_expiring: "Expiring on my public bid",
  buying: "Buying",
  selling: "Selling",
  update_on_my_watchlist: "Update on my watchlist item",
  no_code_required: "NO CODE REQUIRED",
  new_coupon_available: "New coupon available",
  new_lowest_ask_on_bid: "New lowest ask on my public bid",
  posts_and_comments: "Posts and Comments",
  likes_on_my_post: "Likes on my post",
  comments_on_my_post: "Comments on my post",
  mention_of_me: "Mention of me",
  new_coupon_available_on_my_bid: "New coupon available on my public bid",
  watchlist_items: "Watchlist Items",
  following_and_followers: "Following and Followers",
  new_followers: "New Followers",
  loading_preferences: "Loading Preferences",
  size_chart: "Size Chart",
  loading_size_chart: "Loading Size Chart",
  page_loading: "Page Loading",
  please_enter_username_or_email: "Please enter username or email.",
  edit_public_profile: "Edit Public Profile",
  unknown_error: "Unknown Error",
  please_enter_password: "Please enter password.",
  new_address: "New Address",
  edit_address: "Edit Address",
  first_name: "First Name",
  please_enter_first_name: "Please enter first name.",
  last_name: "Last Name",
  please_enter_last_name: "Please enter last name.",
  please_enter_zip_code: "Please enter zip code.",
  please_select_country: "Please select country / region.",
  please_enter_state: "Please enter state.",
  please_enter_city: "Please select city.",
  ok: "Ok",
  set_as_default: "Set as Default",
  address: "Address",
  address2: "Apartment, Suite, etc. (optional)",
  please_enter_your_address: "Please enter your address.",
  zip_code: "Zip Code",
  country: "Country",
  country_region: "Country / Region",
  phone: "Phone",
  phone_code: "Phone Code",
  please_select_phone_code: "Please select phone code.",
  please_enter_phone_number: "Please enter phone number.",
  phone_number_should_contain_numbers_only:
    "Phone number should contain numbers only",
  phone_number_too_long: "Enter a phone number under 14 numbers.",
  delete_this_address: "Delete this address?",
  followers: "Followers",
  following: "Following",
  followers_count: "{{count}} followers",
  loading_user_info: "Loading User Info",
  follow: "Follow",
  unfollow: "Unfollow",
  are_you_sure_to_delete_address:
    "Are you sure you want to delete this address?",
  loading_browsing_history: "Loading Browsing History",
  empty: "Empty",
  loading_offers: "Loading Offers",
  loading_orders: "Loading Orders",
  no_results_found: "No Results Found",
  please_enter_old_password: "Please enter the old password.",
  please_enter_new_password: "Please enter the new password.",
  new_password_must_be_different:
    "Create a new password that isn't your current password.",
  password_changed: "Password Changed",
  my_favorites: "My Favorites",
  loading_conversation: "Loading Conversation",
  conversation_not_found: "Conversation Not Found",
  offer_created: "Offer Created",
  please_enter_the_price_of_your_offer: "Please enter the price of your offer.",
  no_image: "No Image",
  private: "Private",
  offer: "Offer",
  offer_unavailable: "Offer Unavailable",
  offer_no_longer_accept: "Offers are no longer accepted",
  payment: "Payment",
  your_paypal_account: "Your PayPal Account",
  no_paypal_account:
    "Please click the button below to connect your PayPal account.",
  connect_with_paypal: "Connect with PayPal",
  yesterday: "Yesterday",
  name: "Name",
  offer_name_placeholder: "Please enter the name of your offer.",
  shipping_placeholder: "Please add shipping cost for at least one region.",
  offer_desc_placeholder: "Describe Your Product",
  image: "Image",
  send_ask_offer: "Send Ask Offer",
  send_bid_offer: "Send Bid Offer",
  reject: "Reject",
  you: "You",
  accept: "Accept",
  loading_address: "Loading Address",
  no_address: `You don't have an address yet`,
  create_now: "Create Now",
  manage_address: "Manage Address",
  create_address: "Create Address",
  delete: "Delete",
  edit: "Edit",
  expires_soon: "Expires soon",
  expires_in_minutes: "Expires in {{minute}} minutes",
  expires_in_hours: "Expires in {{hour}} hours",
  expires_in_days: "Expires in {{day}} days",
  never_expires: "Never expires",
  create_a: "Create a",
  create_an: "Create an",
  exit_measurable: "Exit Measurable™",
  ship_from: "Ship from {{place}}",
  ship_to: "Ship to {{place}}",
  privacy: "Privacy Policy",
  terms: "Terms & Conditions",
  faqs: "FAQs",
  disclosure: "Disclosure",
  back: "Back",
  more_listings: "More Listings",
  offer_not_found: "Offer Not Found",
  image_credit: "Image Credit",
  product_details_source: "Product details provided by",
  multiple_sizes: "Multiple Sizes",
  varied_conditions: "Varied Conditions",
  available_in: "Available in",
  checking_login_state: "Checking Login State",
  failed_to_check_login_state: "Failed to Check Login State",
  please_enter_username: "Please enter your username.",
  please_enter_address: "Please enter address.",
  username_syntax_alert:
    "Usernames can only use letter, numbers, underscores and periods.",
  username_too_long: "Enter a name under 20 characters.",
  username_period_start_alert: `You can't start your username with a period.`,
  username_period_end_alert: `You can't end your username with a period.`,
  username_period_more_alert: `Your can't have more than one period in a row.`,
  username_already_in_use: "This username is already in use.",
  email_invalid_alert: "Please enter a valid email address.",
  password_confirmation_alert: "Please make sure both passwords match.",
  please_confirm_new_password: "Please confirm the new password.",
  please_enter_your_email: "Please enter your email address.",
  please_enter_password_again: "Please enter password again.",
  please_select_your_region: "Please select your region.",
  please_select_a_gender_for_the_product:
    "Please select a gender for the product.",
  password_syntax_rule:
    "Use 8 or more characters with a mix of letters, numbers & symbols.",
  email_already_in_use: "This email is already in use.",
  check_your_inbox: "Check Your Inbox",
  reset_token_sent_to_email: `We've sent an email to the provided address.`,
  continue_shopping: "Continue Shopping",
  enter_email_for_reset: `Enter your email address and we'll send you a link to reset your password.`,
  reset_password: "Reset Password",
  back_to_login: "Back to Log in",
  submit: "Submit",
  forgot_your_password: "Forgot your password?",
  password_reset_success: `We've successfully reset your password!`,
  continue_with_google: "Continue with Google",
  continue_with_apple: "Continue with Apple",
  city: "City",
  state: "State",
  inventory: "Inventory",
  delete_this_offer: "Delete this offer?",
  offer_deleted: "Offer Deleted",
  offer_not_available: "Offer Not available",
  offer_available: "Offer Available",
  offer_rejected: "Offer Rejected",
  offer_sold: "Offer Sold",
  offer_expired: "Offer Expired",
  deleted: "Deleted",
  invalid: "Invalid",
  create_payment: "Create Payment",
  creating_payment: "Creating Payment",
  confirm_payment: "Confirm Payment",
  payment_completed: "Payment Completed",
  pay_now: "Pay Now",
  buyer: "Buyer",
  seller: "Seller",
  go_to_my_orders: "Go to My Orders",
  payment_error: "Payment Error",
  paid: "Paid",
  completed: "Completed",
  add_tracking: "Add Tracking",
  edit_tracking: "Edit Tracking",
  feedback: "Feedback",
  my_feedback: "My Feedback",
  carrier: "Carrier",
  tracking_no: "Tracking No.",
  please_enter_carrier: "Please enter carrier.",
  please_enter_tracking_no: "Please enter tracking No.",
  rating: "Rating",
  please_enter_rating: "Please enter rating.",
  shipped: "Shipped",
  continue_without_tracking: "Continue without Tracking",
  ship_without_tracking_info: `This order will be shipped without tracking info.`,
  select_a_conversation_to_continue: "Select a conversation to continue",
  confirm: "Confirm",
  confirm_order: "Confirm Order",
  order_confirmed: "Order Confirmed",
  tracking: "Tracking",
  sending: "Sending",
  no_tracking_info_provided: "No Tracking Info Provided",
  previous_step: "Previous Step",
  product_name: "Product Name",
  create_more: "Create More",
  go_to_offer: "Go to Offer",
  offer_type: "Offer Type",
  are_you_selling: "Are You Selling?",
  are_you_buying: "Are You Buying?",
  review_offer: "Review Offer",
  add_new_product: "Add New Product",
  marketplace_view: "Marketplace View",
  print: "Print",
  about_measurable: "About Measurable™",
  cm: "cm",
  inch: "inch",
  bio_too_long_alert: "Enter a bio under 50 characters.",
  i_want_to: "I want to ...",
  i_want_to_sell: "I want to sell",
  i_want_to_buy: "I want to buy",
  unclassified: "Unclassified",
  please_select_product: "Please select product.",
  no_description: "No Description",
  product: "Product",
  product_information: "Product Information",
  product_not_exist: "Sorry, this product doesn’t exist",
  offer_not_exist: "Sorry, this offer doesn’t exist",
  product_not_found_instr:
    "This may be because the link has expired or the website is experiencing a few issues behind the scenes.",
  we_will_review_product_info: "We will review product information shortly",
  includes_shipping: "includes shipping",
  approx: "approx.",
  pending_products: "Pending Products",
  try_measurable: "Try Measurable™",
  view_details: "View Details",
  unavailable: "Unavailable",
  available: "Available",
  pending: "Pending",
  details: "Details",
  offer_sent: "Offer Sent",
  offer_received: "Offer Received",
  sending_offer: "Sending Offer...",
  failed_to_send_offer: "Failed to send offer, please try again.",
  offer_accepted: "Offer Accepted",
  purchased: "Purchased",
  loading_order: "Loading Order",
  i_bought: "I bought {{condition}}",
  by_brand_in_size: "by {{brand}} in size {{size}}.",
  here_is_my_address: "Here is my shipping address:",
  purchased_for_price: "Purchased for {{symbol}}{{price}} {{currency}}",
  no_messages: "No Messages",
  you_dont_have_any_messages: `You don't have any messages`,
  loading_messages: "Loading Messages",
  start_conversation: "Start conversation with a photo, message or an offer.",
  status: "Status",
  date: "Date",
  date_completed: "Date Completed",
  back_to_my_orders: "Back to My Orders",
  offer_unavailable_below: "Offers below are unavailable in current region",
  current_region_settings:
    "You are currently shipping to {{region}} and price will be shown in {{currency}} {{currencySymbol}}.",
  designer_style_id: "Designer Style ID",
  connect_paypal_to_continue: `Please connect your PayPal account to continue`,
  address_unavailable: "Address Unavailable",
  preparing_payment: "Preparing Payment",
  fetching_latest_offer_info: "Fetching Latest Offer Info",
  offer_details: "Offer Details",
  start_exploring: "Start Exploring!",
  seller_only_ships_to_regions_below: "Seller only ships to regions below",
  continue: "Continue",
  uploading: "Uploading",
  not_available: "Not Available",
  rejected: "Rejected",
  sold: "Sold",
  no_feedback: `You haven't received any feedback yet`,
  user_not_found: "User Not Found",
  characters: "Characters",
  logging_with_google: "Logging in with Google",
  logging_with_apple: "Logging in with Apple ID",
  no_designer_style_id: "No Designer Style ID",
  you_re_going_to_visit_the_following_link:
    "You're going to visit the following link",
  signing_up_instr: "By signing up, you agree to our ",
  most_relevant: "Most Relevant",
  code: "Code",
  code_copied: "Code copied to Clipboard!",
  click_to_copy: "Click to Copy",
  please_select_a_gender_to_shop: "Please select a gender to shop",
  follower_list_instr: `You'll see all the people who follow you here.`,
  following_list_instr: `Once you follow people, you'll see them here.`,
  add_to_watchlist: "Add to Watchlist",
  watchlist: "Watchlist",
  watchlist_remove_instr: "Remove this item from Watchlist?",
  remove: "Remove",
  removed: "Removed",
  item_removed: "Item Removed",
  item_added: "Item Added",
  add_to_watchlist_instr:
    "Get notified about new {{offerTypeDesc}} for selected condition and size.",
  notification: "Notification",
  email_settings: "Email Settings",
  no_notifications: `You haven't received any notifications yet`,
  open_app: "Open App",
  open_app_title: "Explore the full features on our APP",
  open_app_instr:
    "If you have not been automatically redirected, click on the following button",
  find_us_on_social: "Find Us on Social",
  download_our_app: "Download Our App",
  page_of_total: "{{page}} of {{total}}",
  filter_offer_instr: "Please select condition and size to continue.",
  switch_to_marketplace_view_for_more:
    "No results found, switch to Marketplace View to see {{count}} items.",
  switch_to_marketplace_view: "Switch to Marketplace View",

  // only for metas
  shopping_page_title:
    "Fashion for {{gender}} - Shop and Sell Designer, Streetwear and Luxury Brands at REVERSIBLE",
  genders_fashion: `{{gender}}'s fashion`,
  latest_fashion_for_gender: "latest fashion for {{gender}}",
  marketplace_title: "Marketplace for {{gender}}",
  new_in_title: "New In for {{gender}}",
  recommended_title: "Recommended for you",
  shop_online: "shop online",
  shopping_desc_for_women:
    "Discover and shop your favourite designer and luxury brands in one place on REVERSIBLE. ",
  shopping_desc_for_men:
    "Designer, streetwear and luxury brands in one place on REVERSIBLE.",
  designer_category_for_gender: "Designer {{category}} for {{gender}}",
  designer_category: "designer {{category}}",
  genders_category: `{{gender}}'s {{category}}`,
  category_desc:
    "Shop designer {{category}} today. Discover over 100,000 styles at REVERSIBLE, new styles added daily.",
  brand_for_gender: "{{brand}} for {{gender}} - Designer Fashion",
  designer_desc:
    "Shop {{brand}} today. Discover over 100,000 styles at REVERSIBLE, new styles added daily.",
  linking_to_paypal: "Linking to PayPal",
  logging_in_with_google: "Logging in with Google",
  order_for_item: "Order for {{name}}",
  message_with_someone: "Message with {{username}}",
  register_log_in: "Register & Log in",
  disclosure_desc: "Learn more about our affiliate disclosure at REVERSIBLE.",
  faq_full: "frequently asked questions",
  faq_title: "Frequently Asked Questions - FAQs",
  faq_desc:
    "Discover frequently asked questions about buying, selling, and buyer/seller protection at REVERSIBLE.",
  measurable_desc:
    "Discover our patent pending technology to measure your garment effortlessly, only at REVERSIBLE.",
  privacy_desc: "Learn more about our privacy policy at REVERSIBLE.",
  terms_desc:
    "Discover terms of service in our terms & conditions page at REVERSIBLE.",
  show_more: "Show More",
  show_less: "Show Less",
  show_all: "Show All",
  results_for: "Results for",
  setup_paypal: "Setup PayPal",
  setup_now: "Setup Now",
  payment_setup_instr:
    "Thanks for joining REVERSIBLE, please setup PayPal to validate your listings.",
  retry: "Retry",
  reload_page: "Reload Page",
  discover: "Discover",
  suggested: "Suggested",
  likes: "likes",
  view_all: "View All",
  view_all_x_comments: "View all {{totalCount}} comments",
  add_a_comment: "Add a comment",
  add_a_reply: "Add a reply",
  reply: "Reply",
  view_more: "view more",
  topic_not_found: "Topic Not Found",
  posts: "Posts",
  posts_count: "{{count}} posts",
  delete_this_comment: "Delete this comment?",
  replying_to: "Replying to",
  failed_to_delete_comment: "Failed to delete comment",
  lookbook: "Lookbook",
  lookbook_empty_instr: "Be the first to share your looks",
  no_posts_yet: "No posts yet",
  post_not_found: "Post not found",
  settings: "Settings",
  edit_profile: "Edit Profile",
  profile_saved: "Profile Saved",
  listings: "Listings",
  post: "Post",
  just_now: "Just now",
  minutes_ago: "{{minutes}} minutes ago",
  hours_ago: "{{hours}} hours ago",
  days_ago: "{{days}} days ago",
  comments: "Comments",
  view_post: "View post",
  more: "more",
  less: "less",
  post_deleted: "Post deleted",
  no_comments: "No comments",
  no_listings_yet: "No listings yet",
  started_following_you: "started following you",
  commented: "commented",
  mentioned_you_in_comment: "mentioned you in a comment",
  liked_your_post: "liked your post",
  mentioned_you_in_post: "mentioned you in a post",
  tagged_you_in_post: "tagged you in a post",
  accounts: "Accounts",
  topics: "Topics",
  please_enter_valid_price_value: "Please enter a valid min and max value",
  report: "Report",
  not_interested: "Not interested",
  not_interested_success: "We'll show fewer posts like this.",
  report_instr: "Please give more details on why you are reporting this post.",
  dismiss: "Dismiss",
  report_finished: "Thanks for letting us know",
  block: "Block",
  unblock: "Unblock",
  block_username: "Block {{username}}?",
  unblock_username: "Unblock {{username}}?",
  block_instr:
    "You won’t see posts from this account. They won’t be notified that you blocked them",
  block_this_user: "Block this user",
  unblock_this_user: "Unblock this user",
  updated: "Updated",
  posted: "Posted",
  on: "on",
  click_to_see_all_offers: "Click to see all offers",
  subcategories: "Subcategories",
  sold_for: "Sold for",
  filter_by_keyword: "Filter by Keyword",
  keyword: "Keyword",
  seller_region: "Seller Region",
  products_for_gender: "Products for {{gender}}",
  my_filters: "My Filters",
  rename: "Rename",
  clear: "Clear",
  apply: "Apply",
  delete_this_filter: "Delete this filter?",
  rename_filter: "Rename Filter",
  name_this_filter: "Name this Filter",
  new_filter: "New Filter",
  save_current_filter: "Save Current Filter",
  manage_my_filters: "Manage My Filters",
  bump: "Bump",
  bumped: "Bumped",
  bump_in_days: "You can bump this listing again in {{day}} days.",
  you_may_add_at_most_n_filter: "You may add at most {{n}} filters.",
  create_first_filter: "Create a filter for customized feed",
  sign_in_to_create_filter:
    "Sign up or log in to create filters for customized feed",
  please_enter_the_name_of_this_filter: "Please enter the name of this filter",
  listing_view: "Listing View",
  price_history: "Price History",
  "1m": "1M",
  "3m": "3M",
  "6m": "6M",
  "1y": "1Y",
  today: "Today",
  price_history_instr:
    "All data and information is provided for informational purposes only.",
};
