import React, { FC, memo, HTMLAttributes } from "react";
import classnames from "classnames";
import { PUBLIC_URL } from "@/env";
import styles from "./icon.module.less";
import { IconType } from "./interface";

export * from "./interface";

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  type: IconType;
  spin?: boolean;
}

export const Icon: FC<IconProps> = memo(
  ({ type, className = "", spin = false, ...rest }) => {
    return (
      <span
        className={classnames(
          "iconfont",
          `icon-${type}`,
          styles.icon,
          {
            [styles.icon_spinning]: spin,
          },
          className
        )}
        data-name="icon-type"
        aria-label={`${type} icon`}
        {...rest}
      >
        <svg
          width="1em"
          height="1em"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <g fillRule="evenodd" fill="currentColor">
            <use href={`${PUBLIC_URL}/icon.svg#${type}`} />
          </g>
        </svg>
      </span>
    );
  }
);
