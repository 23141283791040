import React, { FC } from "react";
import classNames from "classnames";
import { StyledComponentProps } from "@/interface/base";
import { Img, ImgProps, Source } from "@/ui";
import avatarFallbackSrc from "./avatar-fallback.png";
import styles from "./avatar.module.less";

export interface AvatarProps
  extends StyledComponentProps,
    Pick<ImgProps, "easeIn"> {
  avatar: string;
  alt?: string;
}

export const Avatar: FC<AvatarProps> = ({
  avatar,
  alt,
  className,
  ...rest
}) => {
  return (
    <Img
      src={avatar}
      alt={alt || "avatar"}
      type="cover"
      className={classNames(styles.avatar, className)}
      imgClassName={styles.avatar_img}
      fallback={<Img src={avatarFallbackSrc} />}
      {...rest}
    >
      <Source size={200} />
    </Img>
  );
};
