import { noop } from "@reversible/common";
import {
  ColorMode,
  createPriceHistoryChart,
  PriceHistoryChart,
} from "@/component/price-history-chart";
import type { PriceHistoryData } from "@/interface/product";
import { url } from "@/url";
import { Scheme } from "@/util/scheme";

type Message =
  | {
      type: "set-price-history-data";
      payload: PriceHistoryData;
    }
  | {
      type: "set-color-mode";
      payload: "light" | "dark";
    }
  | {
      type: "blur-price-history" | "ready";
      payload: void;
    }
  | {
      type: "redirect";
      payload: string;
    };

function setFullSize(el: HTMLElement) {
  el.style.height = "100%";
  el.style.width = "100%";
  el.style.overflow = "hidden";
  el.style.background = "transparent";
}

const ID = "chart";

const debugToast: (...params: any[]) => void = noop;

function main() {
  let colorMode: ColorMode = "light";
  let container: HTMLElement = document.getElementById(ID);

  if (!container) {
    container = document.createElement("div");
    container.id = ID;
    document.body.append(container);
    setFullSize(container);
  }

  setFullSize(document.documentElement);
  setFullSize(document.body);

  let chartInstance: PriceHistoryChart;

  let sendIOSMessage: (message: Message) => void;

  function sendMessage(message: Message) {
    debugToast("sending message", message);
    if (sendIOSMessage) {
      sendIOSMessage(message);
    }
    if (window.android) {
      window.android.postBridgeMessage(JSON.stringify(message));
    }
  }

  const listeners = [
    [
      "set-price-history-data",
      (data: PriceHistoryData) => {
        if (chartInstance) {
          chartInstance.destroy();
        }
        chartInstance = createPriceHistoryChart({
          container,
          data,
          colorMode,
          onGotoOffer: ({ offerId }) => {
            const payload = url.redirect(Scheme.offerDetails(offerId, 0));
            sendMessage({
              type: "redirect",
              payload,
            });
          },
        });
      },
    ],

    [
      "blur-price-history",
      () => {
        if (chartInstance) {
          chartInstance.blur();
        }
      },
    ],

    [
      "set-color-mode",
      (mode: "light" | "dark") => {
        colorMode = mode;
        if (chartInstance) {
          chartInstance.setMode(mode);
        }
      },
    ],
  ] as const;

  // IOS setup
  function setupWKWebViewJavascriptBridge(callback: (bridge: any) => void) {
    if (window.WKWebViewJavascriptBridge) {
      callback(window.WKWebViewJavascriptBridge);
      return;
    }
    if (window.WKWVJBCallbacks) {
      window.WKWVJBCallbacks.push(callback);
      return;
    }
    window.WKWVJBCallbacks = [callback];
    if (window.webkit) {
      window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(
        null
      );
    }
  }

  setupWKWebViewJavascriptBridge((bridge) => {
    for (const [type, callback] of listeners) {
      bridge.registerHandler(type, (payload) => {
        debugToast("WKWebView message received", {
          type,
          payload,
        });
        callback(payload);
      });
    }

    sendIOSMessage = (message: Message) => {
      debugToast(
        `sending message to IOS with type=${message.type}, payload=${message.payload})`
      );
      bridge.callHandler(message.type, message);
    };
  });

  // setup Android listeners
  window.postBridgeMessage = (message: Message) => {
    for (const [type, callback] of listeners) {
      if (message.type === type) {
        debugToast("Global message received", message);
        callback(message.payload as any);
      }
    }
  };

  sendMessage({
    type: "ready",
    payload: null,
  });
}

main();
