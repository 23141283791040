import React, { CSSProperties, FC, useCallback, useState } from "react";
import { ScheduleUtils } from "@reversible/common";
import { NestableItem } from "@/interface/base";
import {
  ControlledFormProvider,
  Form,
  FormField,
  FormItem,
  WithFormField,
  Select,
  Option,
  FormRules,
  Button,
  Input,
  Cascader,
  Toggle,
  Checkbox,
  Radio,
  Toast,
  FormState,
  ImageSelect,
  MultiSelect,
  AutoComplete,
  Stars,
  Textarea,
  IntegerInput,
} from "@/ui";

const CASCADER_OPTIONS: NestableItem<number>[] = [
  {
    value: 1,
    name: "A",
    children: [
      {
        value: 1,
        name: "A1",
      },
      {
        value: 2,
        name: "A2",
      },
    ],
  },
  {
    value: 2,
    name: "B",
  },
];

const AUTO_COMPLETE_LIST: string[] = [
  "absiyajshd",
  "ubujre",
  "aliahie",
  "asdkjbaui",
  "oihiouftr",
  "hcbrkwugap",
  "crliwtehjoi",
  "iruehoub",
  "sdjfkbal",
  "itvnio",
  "auerul",
  "qeuohfu",
  "qweripuo",
  "qedpijp",
];

const formStyle: CSSProperties = { width: "80%", margin: "auto" };
const h1Style: CSSProperties = { fontSize: "20px", marginBottom: "20px" };

const FormView: FC = () => {
  interface ControlledFormValues {
    input: string;
    select: number;
    select2: number;
    multiSelect: number[];
    multiSelect2: number[];
  }

  const [state, setState] = useState<FormState<ControlledFormValues>>({
    values: {
      input: "initial input text",
      select: 1,
      select2: undefined,
      multiSelect: [],
      multiSelect2: [],
    },
    alerts: {},
    loading: false,
  });

  const checkDuplication = useCallback(async (name: string) => {
    await ScheduleUtils.timeout(2000);
    return ["dup", "duplica"].includes(name) ? "name duplicated" : "";
  }, []);

  const [value, setValue] = useState<string[]>([]);

  return (
    <div
      style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}
    >
      <div>
        <Form
          style={formStyle}
          onSubmit={(values) => {
            Toast.success(
              <span>
                Uncontrolled Form Submitted{" "}
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </span>,
              8
            );
          }}
        >
          <h1 style={h1Style}>Uncontrolled Form</h1>
          <select
            multiple
            value={value}
            onChange={(e) => {
              const selected = Array.from(e.target.selectedOptions).map(
                (option) => option.value
              );
              setValue(selected);
            }}
          >
            <option value="1">AAA</option>
            <option value="2">BBB</option>
            <option value="3">CCC</option>
          </select>
          <FormItem
            label="name"
            field="input1"
            initialValue=""
            rules={[FormRules.required("Please input name"), checkDuplication]}
          >
            <Input placeholder="name" />
          </FormItem>
          <FormItem
            label="password"
            field="password"
            initialValue=""
            rules={[
              FormRules.required("Please input password"),
              checkDuplication,
            ]}
          >
            <Input placeholder="password" password />
          </FormItem>
          <FormItem
            label="price"
            field="number"
            initialValue={undefined}
            rules={[FormRules.required("Please input price")]}
          >
            <IntegerInput prefix="$" placeholder="price" />
          </FormItem>
          <FormItem
            label="shippingPrice"
            field="shipping"
            initialValue={0}
            rules={[FormRules.required("Please input shipping price")]}
          >
            <IntegerInput
              autoFillZero
              prefix="$"
              placeholder="Shipping Price"
            />
          </FormItem>
          <FormItem label="description" field="textarea" initialValue="">
            <Textarea placeholder="name" maxLength={150} />
          </FormItem>
          <FormItem
            label="select"
            field="select"
            initialValue={undefined}
            rules={[FormRules.required("Please select")]}
          >
            <Select placeholder="No initial value" allowClear filterable>
              <Option value={1}>A</Option>
              <Option value={2}>B</Option>
              <Option value={3}>C</Option>
            </Select>
          </FormItem>
          <FormItem
            label="auto-complete"
            field="autoComplete"
            initialValue=""
            rules={[FormRules.required("Please input")]}
          >
            <AutoComplete
              onRequestOptions={(input: string) =>
                AUTO_COMPLETE_LIST.filter((item) =>
                  item.includes(input.trim())
                ).map((value) => ({
                  value,
                  name: value,
                }))
              }
            />
          </FormItem>
          <FormItem
            label="list"
            initialValue={[{}]}
            field="list"
            rules={[FormRules.required("Should be at least one item")]}
          >
            <WithFormField<any[]>>
              {({ value }) => (
                <>
                  {value.map((_, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <span style={{ display: "inline-block", width: "20px" }}>
                        {index + 1}.
                      </span>
                      <FormField field={index} initialValue={{}}>
                        <FormItem
                          field="value"
                          initialValue=""
                          rules={[FormRules.required("Please input")]}
                        >
                          <Input placeholder="please input value" />
                        </FormItem>
                      </FormField>
                      <WithFormField<any[]>>
                        {({ onChange }) => (
                          <Button
                            color="danger"
                            size="small"
                            style={{ marginLeft: "10px" }}
                            inline
                            onClick={() =>
                              onChange((prev) =>
                                prev.filter((_, i) => i !== index)
                              )
                            }
                          >
                            -
                          </Button>
                        )}
                      </WithFormField>
                    </div>
                  ))}
                </>
              )}
            </WithFormField>
            <WithFormField>
              {({ onChange }) => (
                <Button
                  size="small"
                  onClick={() => onChange((prev: any[]) => [...prev, {}])}
                  inline
                >
                  + Add Item
                </Button>
              )}
            </WithFormField>
          </FormItem>
          <FormItem label="cascader" field="cascader" initialValue={undefined}>
            <Cascader
              placeholder="please select cascader"
              options={CASCADER_OPTIONS}
              allowClear
            />
          </FormItem>
          <FormItem label="switch" field="switch" initialValue={false}>
            <Toggle />
            <Toggle size="large" />
            <Toggle size="small" />
          </FormItem>
          <FormItem label="radio" field="radio" initialValue={false}>
            <Radio />
          </FormItem>
          <FormItem label="check" field="check" initialValue={false}>
            <Checkbox />
          </FormItem>
          <FormItem label="stars" field="star" initialValue={undefined}>
            <Stars />
          </FormItem>
          <FormItem label="images" field="images" initialValue={[]}>
            <ImageSelect max={8} />
          </FormItem>
          <FormItem
            label="multi-filter-select"
            field="multiSelect2"
            initialValue={[]}
            rules={[FormRules.required("Please select")]}
          >
            <MultiSelect filterable>
              <Option value={11}>A1</Option>
              <Option value={12}>B1</Option>
              <Option value={13}>C1</Option>
              <Option value={21}>A2</Option>
              <Option value={22}>B2</Option>
              <Option value={23}>C2</Option>
              <Option value={31}>A3</Option>
              <Option value={32}>B3</Option>
              <Option value={33}>C3</Option>
            </MultiSelect>
          </FormItem>
          <Button htmlType="submit" type="fill">
            Submit
          </Button>
        </Form>
      </div>
      <div>
        <ControlledFormProvider value={state} onChange={setState}>
          <Form
            style={formStyle}
            onChange={(state) => console.log("Controlled value change", state)}
            onSubmit={(values) => {
              Toast.success(
                <span>
                  Controlled Form Submitted{" "}
                  <pre>{JSON.stringify(values, null, 2)}</pre>
                </span>,
                8
              );
            }}
          >
            <h1 style={h1Style}>Controlled Form</h1>
            <FormItem
              label="input"
              field="input"
              rules={[FormRules.required("Please niput")]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="select"
              field="select"
              rules={[FormRules.required("Please select")]}
            >
              <Select>
                <Option value={1}>A</Option>
                <Option value={2}>B</Option>
                <Option value={3}>C</Option>
              </Select>
            </FormItem>
            <FormItem
              label="multi-select"
              field="multiSelect"
              rules={[FormRules.required("Please select")]}
            >
              <MultiSelect>
                <Option value={1}>A</Option>
                <Option value={2}>B</Option>
                <Option value={3}>C</Option>
              </MultiSelect>
            </FormItem>
            <FormItem
              label="filter-select"
              field="select2"
              rules={[FormRules.required("Please select")]}
            >
              <Select filterable>
                <Option value={1}>A</Option>
                <Option value={2}>B</Option>
                <Option value={3}>C</Option>
              </Select>
            </FormItem>
            <FormItem
              label="multi-filter-select"
              field="multiSelect2"
              rules={[FormRules.required("Please select")]}
            >
              <MultiSelect filterable>
                <Option value={11}>A1</Option>
                <Option value={12}>B1</Option>
                <Option value={13}>C1</Option>
                <Option value={21}>A2</Option>
                <Option value={22}>B2</Option>
                <Option value={23}>C2</Option>
                <Option value={31}>A3</Option>
                <Option value={32}>B3</Option>
                <Option value={33}>C3</Option>
              </MultiSelect>
            </FormItem>
            <Button htmlType="submit" type="fill">
              Submit
            </Button>
          </Form>
        </ControlledFormProvider>
      </div>
    </div>
  );
};

export default FormView;
