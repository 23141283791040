import { useCallback, useEffect, useRef } from "react";
import { CallNext, useFlow } from "@reversible/common";
import { AuthStatus } from "@/enum";
import { MessageUnreadCountState } from "@/interface/message";
import * as apiService from "@/service";
import { GetUnreadMessageCount } from "@/service/interface";
import { startPolling } from "@/util/polling-scheduler";
import { useAccount } from "./use-account";
import { createModel } from ".";

const useUnreadMessageCountModel = createModel(() => {
  const [
    {
      data: { status },
    },
  ] = useAccount();

  const [{ data: unreadCount }, dispatch] = useFlow<
    MessageUnreadCountState,
    void
  >(
    null, // means the count is not synced yet
    function* ({ put, cancel, call }) {
      yield cancel();

      if (status !== AuthStatus.LoggedIn) {
        yield put(null);
        return;
      }

      const { data }: CallNext<GetUnreadMessageCount> = yield call(
        apiService.getUnreadMessageCount
      );
      yield put({
        ...data,
        total: data.buy + data.sell + data.system,
      });
    }
  );

  const pollingCanceller = useRef<() => void>(null);

  const cancel = useCallback(() => {
    if (pollingCanceller.current) {
      pollingCanceller.current();
    }
  }, []);

  const start = useCallback(() => {
    cancel();
    pollingCanceller.current = startPolling({
      intervalInSeconds: 10,
      callback: () => {
        dispatch();
      },
      keepRunningWhenTabInactive: false,
      callImmediatelyWhenStarted: true,
    });
  }, []);

  // when account changes
  useEffect(() => {
    if (status === AuthStatus.LoggedIn) {
      start();
    } else {
      cancel();
    }
  }, [status]);

  return [unreadCount, start] as const;
});

/**
 * provide onChange method for easier subscription to changes
 * @param onChange
 * @returns
 */
export const useOnNewMessage = (
  field?: keyof MessageUnreadCountState,
  onNew?: () => void
) => {
  const [state, update] = useUnreadMessageCountModel();

  const prevValueRef = useRef<number>(undefined);

  const currentValue = state?.[field];

  useEffect(() => {
    const prevValue = prevValueRef.current;
    prevValueRef.current = currentValue; // sync value
    if (field && currentValue > prevValue) {
      // this onchange won't trigger the first round
      onNew();
    }
  }, [currentValue]);

  return update;
};

const INITIAL_STATE: MessageUnreadCountState = {
  total: 0,
  buy: 0,
  sell: 0,
  system: 0,
};
export const useUnreadMessageCount = () => {
  const [state] = useUnreadMessageCountModel();

  return state || INITIAL_STATE;
};

export const useUpdateMessageCount = () => {
  return useUnreadMessageCountModel()[1];
};
