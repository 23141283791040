import { ArrayUtils } from "@reversible/common";
import { OS_UNIT } from "@/const";
import { SizeCategoryOptions, SizeOptions } from "@/interface/data";

export const toArr = <T>(input: T): T extends any[] ? T : T[] =>
  Array.isArray(input) ? input : input == null ? [] : ([input] as any);

export const toSingle = <T>(input: T): T extends (infer U)[] ? U : T =>
  Array.isArray(input) ? input[0] : input;

const c1CompareDict: Record<string, number> = Object.fromEntries(
  ["c", "s", "b", "a"].map((ch, i) => [ch, i]) // HACK: c -> s -> b -> a
);
export const compareCategory1 = ([a]: string, [b]: string): number =>
  c1CompareDict[a] - c1CompareDict[b];

/**
 * join sizeUnit and size to form a ambiguousless string of size
 * which is shared cross end for product query
 * @param sizeUnit
 * @param size
 * @returns {string}
 */
export const joinSize = (sizeUnit: string, size: string) =>
  `${sizeUnit}-${size}`;

export function mergeSizeOptions(...sizeOptionsList: SizeOptions[]) {
  const order = ArrayUtils.unique(
    sizeOptionsList.flatMap(({ order }) => order)
  ).sort((a) => (a === OS_UNIT ? -1 : 0));
  const sizeOptions: Record<string, string[]> = {};
  for (const sizeUnit of order) {
    const availableSizeLists = sizeOptionsList
      .map(({ sizeOptions }) => sizeOptions[sizeUnit])
      .filter(Boolean);
    if (availableSizeLists.length === 1) {
      sizeOptions[sizeUnit] = availableSizeLists[0];
    } else {
      const orderDicts = availableSizeLists.map((sizes) =>
        Object.fromEntries(sizes.map((size, i) => [size, i + 1]))
      );
      const compare = (sizeA: string, sizeB: string) => {
        for (const orderDict of orderDicts) {
          const indexA = orderDict[sizeA];
          const indexB = orderDict[sizeB];
          if (indexA && indexB) return indexA - indexB;
        }
        return 0;
      };
      sizeOptions[sizeUnit] = ArrayUtils.unique(availableSizeLists.flat()).sort(
        compare
      );
    }
  }
  return {
    order,
    sizeOptions,
  };
}

export function mergeSizeCategoryOptions(
  a: SizeCategoryOptions,
  b: SizeCategoryOptions
) {
  const allKeys = new Set([...Object.keys(a), ...Object.keys(b)]);
  const output: SizeCategoryOptions = {};
  for (const key of allKeys) {
    const valueA = a[key];
    const valueB = b[key];
    if (!valueA || !valueB) {
      output[key] = valueA ?? valueB;
    } else {
      output[key] = mergeSizeOptions(valueA, valueB);
    }
  }
  return output;
}
