/**
 * cachers here differs from
 * - service caches where data is cached in middleware context
 * - store where data is cached in react provider state
 *
 * cachers here are globally accessible data cached either globally, sessionally or locally
 */
import { createCacher, Resolvable, uniqueId } from "@reversible/common";
import { Gender, MeasuringUnit } from "@/enum";
import { AccountPreferences } from "@/interface/account";
import { Shipping } from "@/interface/address";
import { CategorySource, DesignerSource, SizeSource } from "@/interface/data";
import { UnionedScheme } from "./scheme";

export const authTokenCacher = createCacher<string>("token", "local");

export const shippingCacher = createCacher<Record<string, Shipping>>(
  "latest-shipping",
  "local"
);

export const acceptOfferCacher = createCacher<boolean>(
  "latest-accept-offer",
  "local"
);

export const measuringUnitCacher = createCacher<MeasuringUnit>(
  "measuring-unit",
  "local"
);

export const preferenceCacher = createCacher<AccountPreferences>(
  "preferences",
  "local"
);

export const shoppingGenderCacher = createCacher<Gender>("gender", "local");

export const messageTextInputsCacher = createCacher<Record<number, string>>(
  "message-text-inputs",
  "session"
);

export const marketplaceCacher = createCacher<boolean>("marketplace", "local");

const forwardCacher = createCacher<{
  [key: string]: string;
}>("forward", "session");

export const forwardCacherIO = {
  get(key: string) {
    const cache = forwardCacher.get();
    forwardCacher.clear();
    return cache?.[key] || "";
  },
  set(forward: string) {
    const key = uniqueId();
    forwardCacher.set({
      [key]: forward,
    });
    return key;
  },
};

export const ensembleVisitedCacher = createCacher<boolean>(
  "ensemble-visibled", // misspelling
  "local"
);

export const myFiltersVisitedCacher = createCacher<boolean>(
  "my-filters-visited",
  "local"
);

export const authFormModalResolvableCacher = createCacher<Resolvable<boolean>>(
  "auth-form-modal-resolvable",
  "global"
);

export const schemeCacher = createCacher<UnionedScheme>("scheme", "global");

export const ensembleTabKeyCacher = createCacher<string>(
  "ensemble-tab-key",
  "session"
);

export const categoryCacher = createCacher<CategorySource>(
  "category-data",
  "local"
);

export const designerCacher = createCacher<DesignerSource>(
  "designer-data",
  "local"
);

export const sizeOptionsCacher = createCacher<SizeSource>("size-data", "local");

/**
 * temporarily deprecated
 */
export const lowestAskCardClicked = createCacher<boolean>(
  "lowest-ask-card-clicked",
  "local"
);
