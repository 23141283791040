import { createContext } from "react";
import { noop } from "@reversible/common";

export interface ZoomingState {
  list: string[];
  index: number;
}

export const imgZoomController = {
  zoom: noop as (zoomingState: ZoomingState) => void,
};

export const imgGroupContext =
  createContext<
    (src: string) => {
      zoom: () => void;
      unregister: () => void;
    }
  >(null);

export const imgSourceContext = createContext<string>("");
