export const urls = {
  form: () => "/form",
  ui: () => "/ui",
  menu: () => "/menu",
  message: () => "/message",
  address: () => "/address",
  screenRuler: () => "/screen-ruler",
  lottie: () => "/lottie",
  colorModes: () => "/color-modes",
  issue: () => "/issue",
  issueViewportHeight: () => `${urls.issue()}/vh`,
  issueInsta: () => `${urls.issue()}/instagram`,
  imageCompress: () => `${urls.issue()}/image-compress`,
  chart: () => "/chart",
};
