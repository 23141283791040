// create structures from schema.org
import { ArrayUtils } from "@reversible/common";
import { Condition, UserStatus } from "@/enum";
import { UserInfoBrief } from "@/interface/account";
import { LinkData } from "@/interface/display";
import {
  OfferData,
  OfferDataBrief,
  SpuData,
  SpuMetaData,
} from "@/interface/product";
import { url } from "@/url";

// context definitions
export interface Schema {
  "@type": string;
}

export const createSchemaLink = (suffix = "") => `https://schema.org/${suffix}`;

export const createSchemaStructure = <T extends Schema>(schema: T) => ({
  "@context": createSchemaLink(),
  ...schema,
});

export interface ThingProperties {
  name: string;
  identifier?: string;
  image?: string;
  description?: string;
  url?: string;
}

export interface ThingSchema extends ThingProperties {
  "@type": "Thing";
}

// QuantitativeValue
export interface QuantitativeValueSchema<T extends number | string>
  extends Schema {
  "@type": "QuantitativeValue";
  minValue: T;
  maxValue: T;
  value?: T;
  unitText: string;
}

export function createQuantitativeValueSchemaForSize(
  size: string,
  sizeUnit: string,
  sizes: string[] = []
): QuantitativeValueSchema<string> {
  return {
    "@type": "QuantitativeValue",
    minValue: sizes[0],
    maxValue: ArrayUtils.last(sizes),
    value: size,
    unitText: sizeUnit,
  };
}

export function createQuantitativeValueSchemaForSizes(
  sizeUnit: string,
  sizes: string[]
): QuantitativeValueSchema<string> {
  return {
    "@type": "QuantitativeValue",
    minValue: sizes ? sizes[0] : "",
    maxValue: sizes ? ArrayUtils.last(sizes) : "",
    unitText: sizeUnit,
  };
}

export interface ContactPointSchema extends Schema {
  "@type": "ContactPoint";
  contactType: string;
  email: string;
}

// Person or Organization
export interface PersonOrOrganizationSchema extends Schema, ThingProperties {
  "@type": "Person" | "Organization";
  logo?: string;
  sameAs?: string[];
  contactPoint?: ContactPointSchema;
}

export function createPersonOrOrganizationSchema({
  userStatus,
  id,
  username,
  avatarUrl,
}: UserInfoBrief): PersonOrOrganizationSchema {
  return {
    "@type": userStatus === UserStatus.Official ? "Organization" : "Person",
    identifier: String(id),
    name: username,
    image: avatarUrl,
    url: url.user(username),
  };
}

export function createReversibleOrganizationSchema(
  links: string[]
): PersonOrOrganizationSchema {
  return {
    "@type": "Organization",
    url: "https://www.reversible.com",
    logo: "https://reversible-images-prod.s3.us-west-2.amazonaws.com/logo/logo.png",
    name: "Reversible",
    sameAs: links,
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "Customer Service",
      email: "support@reversible.com",
    },
  };
}

// Brand
export interface BrandSchema extends Schema, ThingProperties {
  "@type": "Brand";
}

export function createBrandSchema(
  brandValue: string,
  brandName = brandValue
): BrandSchema {
  return {
    "@type": "Brand",
    name: brandName,
    identifier: brandValue,
  };
}

// OfferItemCondition
export function createOfferItemConditionSchema(condition: Condition): string {
  return createSchemaLink(
    condition === Condition.BrandNew ? "NewCondition" : "UsedCondition"
  );
}

// Offer
export interface OfferSchema extends ThingProperties, Schema {
  "@type": "Offer";
  acceptedPaymentMethod?: string;
  availability: string; // this is actually a schema enum
  offeredBy?: PersonOrOrganizationSchema;
  itemCondition: string;
  price: number;
  priceCurrency: string;
  hasMeasurement: QuantitativeValueSchema<string>;
  priceValidUntil?: string; // ISO
}

export function createOfferSchema(
  offerData: OfferData | OfferDataBrief,
  product: SpuData | SpuMetaData
): OfferSchema {
  const { condition, size, offerName } = offerData;

  const { sizeUnit, sizes, id } =
    "id" in product
      ? product
      : {
          sizeUnit: "",
          sizes: [],
          id: product.spuId,
        };

  const common = {
    "@type": "Offer" as const,
    availability: createSchemaLink("OnlineOnly"),
    itemCondition: createOfferItemConditionSchema(condition),
    hasMeasurement: createQuantitativeValueSchemaForSize(size, sizeUnit, sizes),
    name: offerName,
  };

  if ("offerId" in offerData) {
    const { imgUrl, offerId, offerPrice, offerCurrency } = offerData;
    return {
      ...common,
      price: offerPrice,
      priceCurrency: offerCurrency,
      image: imgUrl,
      identifier: String(offerId),
      url: url.productDetail(
        {
          id,
          ...product,
        },
        { offerId }
      ),
      priceValidUntil: "",
    };
  }

  const {
    price,
    currency,
    offerDescription,
    offerUrl,
    userId,
    offerTime,
    offerValidTime,
    ...rest
  } = offerData;

  const offeredBy: PersonOrOrganizationSchema =
    createPersonOrOrganizationSchema({
      id: userId,
      ...rest,
    });

  const THIRTY_DAYS_LATER = Date.now() + 1000 * 60 * 60 * 24 * 30; // if never, give 30 days from now
  const priceValidUntil = new Date(
    offerValidTime
      ? offerTime.valueOf() + offerValidTime * 1000
      : THIRTY_DAYS_LATER
  ).toISOString();

  return {
    ...common,
    price,
    priceCurrency: currency,
    name: offerName,
    description: offerDescription,
    url:
      offerUrl ||
      url.productDetail(
        {
          id,
          ...product,
        },
        { offerId: offerData.id }
      ),
    identifier: String(id),
    offeredBy,
    priceValidUntil,
  };
}

export interface AggregateOfferSchema extends Schema {
  "@type": "AggregateOffer";
  priceCurrency: string;
  highPrice: string | number;
  lowPrice: string | number;
  offerCount: number;
  offers: OfferSchema[];
}

export function createAggregateOfferSchema({
  currency,
  highPrice,
  lowPrice,
  offerCount,
}: {
  currency: string;
  highPrice: number;
  lowPrice: number;
  offerCount: number;
}): AggregateOfferSchema {
  return {
    "@type": "AggregateOffer",
    priceCurrency: currency,
    highPrice,
    lowPrice,
    offerCount: offerCount || 1,
    offers: [],
  };
}

export function createAggregateOfferSchemaByProduct(
  product: SpuData | SpuMetaData
): AggregateOfferSchema {
  const offerList =
    ("spuId" in product
      ? product.minAsks
      : product.offers?.length
      ? product.offers
      : product.minAsks) || [];

  const price = "spuId" in product ? "" : product.price;

  const base = {
    "@type": "AggregateOffer" as const,
    priceCurrency: product.currency,
    highPrice: price || "",
    lowPrice: price || "",
    offerCount: offerList.length || 1, // offerCount should always be positive
  };
  if (!offerList.length) {
    return {
      ...base,
      offers: [],
    };
  }
  let lowPrice = Number.MAX_SAFE_INTEGER;
  let highPrice = 0;
  const offers: OfferSchema[] = [];
  for (const offer of offerList) {
    const offerSchema = createOfferSchema(offer, product);
    offers.push(offerSchema);
    const { price } = offerSchema;
    if (price < lowPrice) {
      lowPrice = price;
    }
    if (price > highPrice) {
      highPrice = price;
    }
  }
  return {
    ...base,
    lowPrice,
    highPrice,
    offers,
  };
}

// Product
export interface ProductSchema extends ThingProperties, Schema {
  "@type": "Product";
  brand: BrandSchema;
  category?: string;
  color?: string;
  hasMeasurement?: QuantitativeValueSchema<string>;
  offers?: AggregateOfferSchema;
  sku?: string;
}

export function createProductSchema(
  spuData: SpuData | SpuMetaData
): ProductSchema {
  const { brand, name } = spuData;

  const common = {
    "@type": "Product" as const,
    brand: createBrandSchema(brand),
    name,
  };

  if ("id" in spuData) {
    const {
      thumbnail,
      description,
      category1,
      category2,
      color,
      styleId,
      id,
      sizes,
      sizeUnit,
    } = spuData;

    return {
      ...common,
      category: [category1, category2].join(", "),
      color,
      identifier: String(id),
      hasMeasurement: createQuantitativeValueSchemaForSizes(sizeUnit, sizes),
      image: thumbnail,
      description,
      offers: createAggregateOfferSchemaByProduct(spuData),
      sku: styleId,
    };
  }

  const { spuId, thumbnail } = spuData;
  return {
    ...common,
    image: thumbnail,
    offers: createAggregateOfferSchemaByProduct(spuData),
    identifier: String(spuId),
  };
}

export interface ListItemSchema extends Schema {
  "@type": "ListItem";
  position: number;
  id: string; // link href
  item: ThingSchema;
}

export interface BreadcrumbListSchema extends Schema {
  "@type": "BreadcrumbList";
  itemListElement: ListItemSchema[];
}

export const createBreadcrumbListSchema = (
  breadcrumbList: LinkData[]
): BreadcrumbListSchema => ({
  "@type": "BreadcrumbList",
  itemListElement: breadcrumbList.map(({ name, link }, index) => ({
    "@type": "ListItem",
    position: index + 1,
    id: link,
    item: {
      "@type": "Thing",
      name,
    },
  })),
});
