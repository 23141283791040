/**
 * scheduler manage global polling tasks
 */

import { ScheduleUtils } from "@reversible/common";

export interface PollingConfig {
  intervalInSeconds?: number;
  intervalInMinutes?: number;
  keepRunningWhenTabInactive: boolean;
  callImmediatelyWhenStarted: boolean;
  callback(): void | (() => void); // callback can return a cleanup function, which would be callback on next tick, much like React useEffect
}

export const startPolling = (config: PollingConfig) => {
  const {
    keepRunningWhenTabInactive,
    callImmediatelyWhenStarted,
    intervalInSeconds,
    intervalInMinutes,
    callback,
  } = config;
  const time = intervalInSeconds
    ? 1000 * intervalInSeconds
    : 60000 * intervalInMinutes;
  if (!time) {
    throw new Error("interval time must be provided");
  }
  let cleanup: void | (() => void);
  const wrappedCallback = () => {
    if (cleanup) {
      cleanup();
    }
    cleanup = callback();
  };

  if (callImmediatelyWhenStarted) {
    wrappedCallback();
  }

  const cancelPolling = (
    keepRunningWhenTabInactive
      ? () => {
          const intervalId = setInterval(wrappedCallback, time);
          return () => clearInterval(intervalId);
        }
      : () => ScheduleUtils.setInterval(wrappedCallback, time)
  )();
  return () => {
    if (cleanup) {
      cleanup();
    }
    cancelPolling();
  };
};
