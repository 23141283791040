import React from "react";
import { Route } from "react-router";
import { ColorModes } from "./color-modes";
import { urls } from "../url";

export const colorModesRoute = () => (
  <Route exact path={urls.colorModes()}>
    <ColorModes />
  </Route>
);
