import { useCallback, useMemo } from "react";
import { Shipping, ShippingPriceConfig } from "@/interface/address";
import { useSettingsInfo } from "@/store/use-settings-info";

export const useCalcShippingPrice = () => {
  const {
    data: { mapping },
  } = useSettingsInfo();

  return useCallback(
    (region: string, shipping: Shipping): ShippingPriceConfig => {
      if (!shipping || !region) return null;
      const matches: string[] = [region];
      if (mapping[region]) {
        matches.push(mapping[region].shippingZoneName);
      }
      const dict = Object.fromEntries(
        shipping.map((item) => [item.region, item])
      );
      for (const match of matches) {
        if (dict[match]) {
          return dict[match];
        }
      }
      return null;
    },
    [mapping]
  );
};

export const useShippingPrice = (
  region: string,
  shipping: Shipping
): ShippingPriceConfig => {
  const calcShippingInfo = useCalcShippingPrice();

  return useMemo(
    () => calcShippingInfo(region, shipping),
    [calcShippingInfo, region, shipping]
  );
};
