/**
 * this implemetation is copied from
 * the source codes of react-redux
 */
export interface Listener<T> {
  callback: (payload: T) => void;
  next: Listener<T> | null;
  prev: Listener<T> | null;
}

export interface ListenerCollection<T> {
  clear(): void;
  notify(payload: T): void;
  subscribe(callback: (payload: T) => void): () => void;
}

const batch = (callback: () => void) => callback();

export function createListenerCollection<T>(): ListenerCollection<T> {
  let first: Listener<T> | null = null;
  let last: Listener<T> | null = null;

  return {
    clear() {
      first = null;
      last = null;
    },

    notify(payload: T) {
      batch(() => {
        let listener = first;
        while (listener) {
          listener.callback(payload);
          listener = listener.next;
        }
      });
    },

    subscribe(callback: (payload: T) => void) {
      let isSubscribed = true;

      const listener: Listener<T> = {
        callback,
        next: null,
        prev: last,
      };
      last = listener;

      if (listener.prev) {
        listener.prev.next = listener;
      } else {
        first = listener;
      }

      return function unsubscribe() {
        if (!isSubscribed || first === null) return;
        isSubscribed = false;

        if (listener.next) {
          listener.next.prev = listener.prev;
        } else {
          last = listener.prev;
        }
        if (listener.prev) {
          listener.prev.next = listener.next;
        } else {
          first = listener.next;
        }
      };
    },
  };
}
