import React from "react";
import { Route } from "react-router";
import { Chart } from "./chart";
import { urls } from "../url";

export const chartRoute = () => (
  <Route exact path={urls.chart()}>
    <Chart />
  </Route>
);
