import { useCallback } from "react";
import { connectFlowEffects, initFlowState } from "@reversible/common";
import { createListenerCollection } from "@/util/listener-collection";
import { Data, DataAction, dataFlow, initialData } from "./model";
import { useSyncExternalStore } from "./use-sync-external-store";

const listenerCollection = createListenerCollection<void>();

let data = initFlowState<Data, DataAction>(initialData);

const { dispatchAction } = connectFlowEffects<Data, DataAction>(
  {
    set: (map) => {
      data = map(data);
      listenerCollection.notify();
    },
    get: () => data,
    call: (callee, ...params) => callee(...params),
  },
  dataFlow
);

export const getDataStoreValue = () => data.data;

/**
 * this is in fact not a real hooks
 * as it doesn't depend on any primitive react hooks
 */
export const useDataStoreDispatch = () => {
  return dispatchAction;
};

export const useDataStoreSelector = <T>(selector: (data: Data) => T): T => {
  const getSnapshot = useCallback((): T => {
    const selected = selector(data.data);
    return selected;
  }, [selector]);

  return useSyncExternalStore(listenerCollection.subscribe, getSnapshot);
};
