import { useEffect } from "react";
import { useSuperState , ObjectUtils } from "@reversible/common";


export interface PositionData {
  left: number;
  top: number;
  width: number;
  height: number;
}

export const usePosition = <T extends HTMLElement>(
  getElement: () => T,
  on: boolean
) => {
  const [position, setPosition] = useSuperState<PositionData>({
    left: 0,
    top: 0,
    height: 0,
    width: 0,
  });

  useEffect(() => {
    if (on) {
      // follow scroll
      const callback = () => {
        const el = getElement();
        if (!el) return;
        setPosition(
          ObjectUtils.pick(el.getBoundingClientRect(), [
            "left",
            "top",
            "height",
            "width",
          ])
        );
      };

      callback(); // immediately call position update

      document.addEventListener("scroll", callback, {
        capture: true,
        passive: true,
      });

      window.addEventListener("resize", callback);

      return () => {
        document.removeEventListener("scroll", callback, { capture: true });
        window.removeEventListener("resize", callback);
      };
    }
    return undefined;
  }, [on]);

  return position;
};
