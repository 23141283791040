/**
 * get account and login status
 */

import { useCallback, useEffect } from "react";
import {
  CallNext,
  useFlow,
  Mutator,
  applyMutator,
  ObjectUtils,
} from "@reversible/common";
import { ACCOUNT_STORE_KEY } from "@/const";
import { ApiResponseCode, AuthStatus } from "@/enum";
import { AccountInfo, AccountPreferences } from "@/interface/account";
import * as httpService from "@/service";
import {
  Login,
  LoginWithApple,
  LoginWithGoogle,
  Register,
} from "@/service/interface";
import { tracker } from "@/system";
import { Toast } from "@/ui";
import { url } from "@/url";
import { startPolling } from "@/util/polling-scheduler";
import { useDisplayStates } from "./use-display-states";
import { createModel } from ".";

// LATER: part of the preference fields acts more like account fields, should combine

export type AccountAction =
  | {
      type: "info";
    }
  | {
      type: "logout";
      reload?: boolean;
    }
  | {
      type: "login";
      username: string;
      password: string;
    }
  | {
      type: "login-with-google" | "login-with-apple";
      code: string;
    }
  | ({
      type: "register";
      username: string;
      email: string;
      password: string;
    } & AccountPreferences)
  | {
      type: "update";
      data: Mutator<AccountInfo>;
    };

export interface AccountState {
  status: AuthStatus;
  account: AccountInfo;
}

export const useAccount = createModel(ACCOUNT_STORE_KEY, () => {
  const [, setDisplayStates] = useDisplayStates();

  const openPaymentSetupModalIfNeeded = useCallback(
    ({ forcePaypalConnect }: AccountInfo) => {
      if (forcePaypalConnect) {
        setDisplayStates({
          paymentModalVisible: true,
        });
      }
    },
    []
  );

  const [accountState, dispatchAccountAction] = useFlow<
    AccountState,
    AccountAction
  >(
    {
      status: AuthStatus.Unknown,
      account: null,
    },
    function* ({ get, put, call, block, cancel }, accountAction) {
      yield block(({ type }) => type !== "logout");

      // action direct effects
      switch (accountAction.type) {
        case "info": {
          const prevState: AccountState = yield get();
          try {
            const { data } = yield call(httpService.getUserInfo);
            // lazy update
            if (
              prevState.status === AuthStatus.LoggedIn &&
              ObjectUtils.shallowEqual(prevState.account, data)
            )
              return;
            yield put({
              status: AuthStatus.LoggedIn,
              account: data,
            });
            tracker.identify(data);
          } catch (e) {
            if (prevState.status === AuthStatus.NotLoggedIn) return;
            tracker.identify(null);
            if (e.code === ApiResponseCode.Forbidden) {
              yield put({
                status: AuthStatus.NotLoggedIn,
                account: null,
              }); // not authorized
            } else {
              throw e;
            }
          }
          break;
        }
        case "login": {
          try {
            tracker.track("login", {
              method: "email",
            });
            const { username, password } = accountAction;
            const {
              data: { user },
            }: CallNext<Login> = yield call(httpService.login, {
              username,
              password,
            });
            tracker.track("login_result", {
              method: "email",
              is_success: true,
            });
            tracker.identify(user);
            openPaymentSetupModalIfNeeded(user);
            yield put({
              status: AuthStatus.LoggedIn,
              account: user,
            });
          } catch (e) {
            tracker.track("login_result", {
              method: "email",
              is_success: false,
              fail_reason: e?.msg,
            });
            if (
              [ApiResponseCode.Forbidden, ApiResponseCode.BadRequest].includes(
                e?.code
              )
            ) {
              Toast.warn(e?.msg);
              yield put({
                status: AuthStatus.NotLoggedIn,
                account: null,
              });
            } else {
              Toast.error(e?.msg);
              throw e;
            }
          }
          break;
        }
        case "login-with-google": {
          try {
            const { code } = accountAction;
            const {
              data: { user },
            }: CallNext<LoginWithGoogle> = yield call(
              httpService.loginWithGoogle,
              {
                code,
              }
            );
            tracker.track("login_result", {
              method: "google",
              is_success: true,
            });
            tracker.identify(user);
            openPaymentSetupModalIfNeeded(user);
            yield put({
              status: AuthStatus.LoggedIn,
              account: user,
            });
          } catch (e) {
            tracker.track("login_result", {
              method: "google",
              is_success: false,
              fail_reason: e?.msg,
            });
            Toast.error(e?.msg, 4);
          }
          break;
        }
        case "login-with-apple": {
          try {
            const { code } = accountAction;
            const {
              data: { user },
            }: CallNext<LoginWithApple> = yield call(
              httpService.loginWithApple,
              {
                code,
              }
            );
            tracker.track("login_result", {
              method: "apple",
              is_success: true,
            });
            tracker.identify(user);
            openPaymentSetupModalIfNeeded(user);
            yield put({
              status: AuthStatus.LoggedIn,
              account: user,
            });
          } catch (e) {
            tracker.track("login_result", {
              method: "apple",
              is_success: false,
              fail_reason: e?.msg,
            });
            Toast.error(e?.msg, 4);
          }
          break;
        }
        case "register": {
          const { username, email, password, productRegion } = accountAction;
          try {
            tracker.track("register", {
              method: "email",
              email,
              user_name: username,
              region: productRegion,
            });
            const {
              data: { user },
            }: CallNext<Register> = yield call(httpService.register, {
              username: username.trim().toLowerCase(),
              email: email.trim(),
              password,
              confirmPassword: password,
              region: productRegion,
            });
            tracker.track("register_result", {
              method: "email",
              email,
              is_success: true,
            });
            tracker.track("login", {
              method: "email",
            });
            tracker.track("login_result", {
              method: "email",
              is_success: true,
            });
            tracker.identify(user);
            openPaymentSetupModalIfNeeded(user);
            yield put({
              status: AuthStatus.LoggedIn,
              account: user,
            });
          } catch (e) {
            tracker.track("register_result", {
              method: "email",
              email,
              is_success: false,
              fail_reason: e?.msg,
            });
            Toast.error(e?.msg, 4);
          }
          break;
        }
        case "logout": {
          tracker.track("logout");
          tracker.identify(null);
          yield cancel();
          httpService.logout();
          if (accountAction.reload) {
            const { location } = window;
            const { pathname, search, hash } = location;
            location.href = url.login({
              forward: `${pathname}${search}${hash}`,
            });
          } else {
            yield put({
              status: AuthStatus.NotLoggedIn,
              account: null,
            });
          }
          break;
        }
        case "update":
          yield put((prev) =>
            prev.status === AuthStatus.LoggedIn
              ? {
                  status: AuthStatus.LoggedIn,
                  account: applyMutator(accountAction.data, prev.account),
                }
              : prev
          );
          break;
        default:
          break;
      }
    }
  );

  useEffect(() => {
    startPolling({
      intervalInMinutes: 20,
      callback: () => {
        dispatchAccountAction({ type: "info" });
      },
      callImmediatelyWhenStarted: true,
      keepRunningWhenTabInactive: true,
    });
  }, []);

  return [accountState, dispatchAccountAction] as const;
});
