import { useEffect } from "react";
import type {
  AnimationConfig,
  AnimationItem,
  LottiePlayer,
  RendererType,
} from "lottie-web";
import { useFlowEffect , isFunction } from "@reversible/common";


type DefaultPromiseTupleBase = [...Promise<{ default: any }>[]];

type UnDefault<Tuple extends DefaultPromiseTupleBase> = {
  [Index in keyof Tuple]: Tuple[Index] extends Promise<{ default: infer T }>
    ? T
    : never;
};

function loadAssetsWithDefaultExports<Tuple extends DefaultPromiseTupleBase>(
  ...promises: Tuple
) {
  return Promise.all(
    promises.map((promise) => promise.then((res) => res.default))
  ) as Promise<UnDefault<Tuple>>;
}

export type AnimationName = "story_ring" | "lowest_ask";

const assets: Record<AnimationName, () => Promise<{ default: any }>> = {
  story_ring: () => import("@reversible/lottie/story_ring.json"),
  lowest_ask: () => import("@reversible/lottie/lowest_ask.json"),
};

export const useLottie = <T extends RendererType>(
  animationName: AnimationName,
  animationConfig: AnimationConfig<T> | (() => AnimationConfig<T>),
  callback?: (animationItem: AnimationItem) => void
) => {
  const animationItemSource = useFlowEffect<AnimationItem>(
    null,
    function* ({ call, put }) {
      const [lottie, animationData]: [LottiePlayer, any] = yield call(() =>
        loadAssetsWithDefaultExports(
          import("lottie-web"),
          assets[animationName]()
        )
      );

      const animationItem = lottie.loadAnimation({
        ...(isFunction(animationConfig) ? animationConfig() : animationConfig),
        animationData,
      });

      if (callback) {
        callback(animationItem);
      }

      yield put(animationItem);
    },
    []
  );

  useEffect(
    () => () => {
      if (animationItemSource.data) {
        animationItemSource.data.destroy();
      }
    },
    []
  );

  return animationItemSource;
};
