import React, { FC } from "react";
import { PayPalOrderInfo, SpuData } from "@/interface/product";
import { FormItem } from "@/ui";
import { AddressCard } from "@/component/address-management/address-card";
import { translate } from "@/i18n";
import { UserShoppingInfo } from "@/component/user-info";
import { conditionNamer } from "@/enum";
import { useAccount } from "@/store/use-account";
import { ShippingPriceConfig } from "@/interface/address";
import { OriginalPriceDisplay } from "../price";
import styles from "./paypal-order-display.module.less";
import { SizeUnit } from "../size-unit";

export interface PaypalOrderDisplayProps {
  orderInfo: PayPalOrderInfo;
  spuData: SpuData;
  shippingPrice?: ShippingPriceConfig;
}

export const PaypalOrderDisplay: FC<PaypalOrderDisplayProps> = ({
  orderInfo,
  spuData,
  shippingPrice,
}) => {
  const [
    {
      data: { account },
    },
  ] = useAccount();

  const {
    sellerInfo,
    buyerInfo,
    offerInfo,
    paypalPrice,
    paypalCurrency,
    shippingInfo,
  } = orderInfo;

  const isBuyer = account.id === buyerInfo.id;

  return (
    <>
      <FormItem label={translate(isBuyer ? "seller" : "buyer")}>
        <UserShoppingInfo
          size="mid"
          data={isBuyer ? sellerInfo : buyerInfo}
          display
        />
      </FormItem>
      <FormItem label={translate("condition")}>
        {conditionNamer(offerInfo.offerCondition)}
      </FormItem>
      <FormItem
        label={
          <>
            {translate("size")}
            <SizeUnit>{spuData.sizeUnit}</SizeUnit>
          </>
        }
      >
        {offerInfo.size}
      </FormItem>
      <FormItem label={translate("price")}>
        <OriginalPriceDisplay
          className={styles.price}
          originalCurrency={paypalCurrency}
          originalPrice={Number(paypalPrice)}
          currency={offerInfo?.offerCurrency}
          price={offerInfo.offerPrice + (shippingPrice?.price || 0)}
          includesShipping
        />
      </FormItem>
      <FormItem label={translate("address")}>
        <AddressCard backgrounded data={shippingInfo} />
      </FormItem>
    </>
  );
};
