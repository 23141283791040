import React, {
  FC,
  memo,
  PropsWithChildren,
  ReactElement,
  useContext,
} from "react";
import classNames from "classnames";
import { StyledComponentProps } from "@/interface/base";
import { selectContext } from "./context";
import { HorizontalAlign, ValidOptionValue } from "./interface";
import styles from "./select.module.less";
import { Icon } from "../icon";

export interface OptionProps<T> extends StyledComponentProps {
  value: T;
  data?: any; // carry data with option
  align?: HorizontalAlign;
  index?: number; // if not provided, use self hover
}

export const Option = memo(function <T extends ValidOptionValue>({
  value,
  data,
  className = "",
  style,
  children,
  align = "left",
  index,
}: PropsWithChildren<OptionProps<T>>): ReactElement {
  const { selectedValue, onChange, multi, pointer, setPointer } =
    useContext(selectContext);

  const isSelected = multi
    ? (selectedValue as T[]).includes(value)
    : value === selectedValue;

  const isPointable = index != null; // an option could either be pointable or hoverable
  const isHoverable = !isPointable;

  const isPointed = isPointable && pointer === index;

  return (
    <div
      className={classNames(
        styles.option,
        {
          [styles["option--selected"]]: isSelected,
          [styles["option--multi-selected"]]: isSelected && multi,
          [styles["option--pointed"]]: isPointed,
          [styles["option--hoverable"]]: isHoverable, // hoverable
        },
        {
          left: styles["option--left-aligned"],
          right: styles["option--right-aligned"],
          center: styles["option--center-aligned"],
        }[align],
        className
      )}
      style={style}
      onClick={() =>
        onChange(value, {
          value,
          name: children,
          data,
        })
      }
      onMouseEnter={isPointable ? () => setPointer(index) : undefined}
    >
      <div className={styles["option-text"]}>{children}</div>
      {isSelected && multi ? (
        <Icon className={styles["option-icon"]} type="check" />
      ) : null}
    </div>
  );
});

export const EmptyOption: FC<StyledComponentProps> = ({
  children,
  className = "",
  style,
}) => (
  <div
    className={classNames(styles.option, styles["option--empty"], className)}
    style={style}
  >
    <div className={styles["option-text"]}>{children}</div>
  </div>
);
