const subscriberMap = new Map<
  Element,
  {
    callback: () => void;
  }
>();

const resizeObserver = new ResizeObserver((entries) => {
  for (const { target } of entries) {
    if (subscriberMap.has(target)) {
      subscriberMap.get(target).callback();
    }
  }
});

export function onResize<T extends Element>(target: T, callback: () => void) {
  subscriberMap.set(target, { callback });
  resizeObserver.observe(target);

  return () => {
    subscriberMap.delete(target);
    resizeObserver.unobserve(target);
  };
}
