import { StringUtils } from "@reversible/common";

export function pathMatch(url: string, pathname: string) {
  return new RegExp(`^${url}`).test(pathname);
}

const { normalize, replaceAll } = StringUtils;

export const escapeName = (input: string) =>
  normalize(input)
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9-_]/g, "-")
    .replace(/-{1,}/g, "-")
    .replace(/-$/, "");

const encodeMappings: [string, string][] = [
  ["/", "|"],
  ["%", "^"],
  [" ", "_"],
];

export const encodeDesigner = (input: string) =>
  encodeMappings.reduce(
    (current, [from, to]) => replaceAll(current, from, to),
    input
  );

export const decodeDesigner = (input: string) =>
  encodeMappings.reduce(
    (current, [to, from]) => replaceAll(current, from, to),
    input
  );
