import qs from "qs";
import {
  headers,
  httpFetch,
  HttpMiddleware,
  httpOnion,
  HttpRequestObject,
  HttpResponseObject,
  httpUrl,
  jsonBody,
  mapRes,
  method,
  onReject,
  rejectOn,
  MaybePromise,
  ObjectPath,
  ArrayUtils,
  compressImageFile,
  DateDataFormat,
  DateUtils,
  MaybePromiseUtils,
  ObjectUtils,
  Resolvable,
  ScheduleUtils,
  StringUtils,
} from "@reversible/common";
import {
  ACCOUNT_STORE_KEY,
  OTHER_DESIGNERS,
  PREFERENCE_STORE_KEY,
} from "@/const";
import {
  ApiResponseCode,
  Condition,
  conditionNamer,
  CONDITIONS,
  ConversationType,
  Gender,
  GENDERS,
  GenderWithAll,
  MessageType,
  SortKey,
  OfferType,
} from "@/enum";
import { API_URL, APP_VERSION, NODE_ENV } from "@/env";
import { translate } from "@/i18n";
import { ImageSource, Item } from "@/interface/base";
import { SizeCategoryOptions } from "@/interface/data";
import { MessageData } from "@/interface/message";
import {
  ListingItemData,
  ListingOffer,
  ProductFilters,
} from "@/interface/product";
import { getState } from "@/store";
import { getDistinctId, tracker } from "@/system";
import { Toast } from "@/ui";
import { url } from "@/url";
import { getFileMD5 } from "@/util/blob";
import { BrandUtils } from "@/util/brand";
import { authTokenCacher } from "@/util/cacher";
import {
  compareCategory1,
  joinSize,
  mergeSizeCategoryOptions,
  toArr,
} from "@/util/data";
import { ListingUtils } from "@/util/listing";
import { MixedSet } from "@/util/mixed-set";
import { ApiResponse, UploadFile } from "./interface";

const { immutableSet, immutableSetAsyncable, prop, mapKeys } = ObjectUtils;
const { capitalize } = StringUtils;
const { map: mapMaybePromise } = MaybePromiseUtils;

const CURRENCY_HEADER = "x-currency";
const REGION_HEADER = "x-region";
const DEVICE_ID_HEADER = "x-device-id";
const DISTINCT_ID_HEADER = "x-distinct-id";
const APP_VERSION_HEADER = "x-app-version";

// common mws
export const commonPreMiddlewares = [
  headers(() => {
    // get distinctId
    const distinctId = getDistinctId();
    const [preferences] = getState(PREFERENCE_STORE_KEY);
    const headers: Record<string, string> = {
      [CURRENCY_HEADER]: preferences?.currency || "",
      [REGION_HEADER]: preferences?.productRegion || "",
      [DEVICE_ID_HEADER]: distinctId,
      [DISTINCT_ID_HEADER]: distinctId,
      [APP_VERSION_HEADER]: APP_VERSION,
    };
    const currentToken = authTokenCacher.get();
    if (currentToken) {
      headers.Authorization = currentToken;
    }
    return headers;
  }),
];

export const commonPostMiddlewares = [
  rejectOn((res) => "resolve" in res && !res.resolve?.success),
  httpFetch({
    credentials: "include",
  }),
];

export const urlMiddleware = httpUrl(
  `${NODE_ENV === "production" ? API_URL : ""}/api`
);

export const mapResolvedData =
  (mapper: (data: any, req: HttpRequestObject) => any): HttpMiddleware =>
  (req, next) =>
    mapMaybePromise(next(req), (res) =>
      "resolve" in res
        ? immutableSet(res, ["resolve", "data"], (prev) => mapper(prev, req))
        : res
    );

/**
 * middleware for parse certain response field as Date object
 * @param paths a list of path to target field
 * @param immutable should the process be immutable, default to false
 * @returns
 */
export const enDate = (
  pathFormats: Record<string, DateDataFormat>,
  immutable = true
): HttpMiddleware =>
  mapResolvedData((data) => {
    if (!data) return data;

    let nextData = data;

    for (const [path, format] of Object.entries(pathFormats)) {
      if (immutable) {
        nextData = immutableSet(nextData, path.split("."), (value: any) =>
          DateUtils.parse(value, format)
        );
      } else {
        const parentPath = path.split(".");
        const last = parentPath.pop();
        const parent = prop(nextData, parentPath);
        parent[last] = DateUtils.parse(parent[last]);
      }
    }

    return nextData;
  });

export const deDate =
  (
    pathFormats: Record<string, DateDataFormat>,
    immutable = true
  ): HttpMiddleware =>
  (req, next) => {
    let nextParams = req.params;
    if (!nextParams) {
      return next(req);
    }
    for (const [path, format] of Object.entries(pathFormats)) {
      if (immutable) {
        nextParams = immutableSet(nextParams, path.split("."), (date) =>
          DateUtils.serialize(date, format as any)
        );
      } else {
        const parentPath = path.split(".");
        const last = parentPath.pop();
        const parent = prop(nextParams, parentPath);
        parent[last] = DateUtils.serialize(parent[last], format as any);
      }
    }
    return next({
      ...req,
      params: nextParams,
    });
  };

/**
 * call the ui message method to alert error
 * when response is rejected
 */
export const alertOnReject = (
  preventCondition: (e: any) => boolean = () => false
) => {
  const getErrorText = (e: ApiResponse | Error): string => {
    if (!e || typeof e !== "object") {
      return translate("unknown_error");
    }
    if ("msg" in e) {
      return (
        (typeof e.data === "string" ? e.data : e.msg) ||
        translate("unknown_error")
      );
    }
    if ("message" in e) {
      return `${e.name}: ${e.message}`;
    }
    return translate("unknown_error");
  };
  return onReject((e) => {
    if (e instanceof Error || !preventCondition(e)) {
      // error is in no way prevented
      const errorText = getErrorText(e);
      Toast[e instanceof Error ? "error" : "warn"](errorText, 2.5);
    }
  });
};

export const mock = <T>(
  f: (count: number, params: any) => Promise<T>
): HttpMiddleware => {
  let count = 0;
  return (req) =>
    f(count++, req.params).then(
      (data) => ({
        headers: {},
        status: ApiResponseCode.Success,
        statusText: "Ok",
        resolve: {
          msg: "",
          data,
          success: true,
          code: 200,
        },
      }),
      (reject) => ({
        headers: {},
        status: ApiResponseCode.BadRequest,
        statusText: "error",
        reject,
      })
    );
};

export const adaptGetFavoredList = () =>
  mapResolvedData((data, req) => {
    const map = req.params.isMarketplace
      ? ({
          price,
          multiPrices,
          size,
          sizes = [size],
          condition,
          multiConditions,
          redirect,
          favoriteUserId,
          id,
          ...rest
        }: any) => {
          const parsed = qs.parse(redirect);
          const offerIds = toArr(parsed.offerId).map(Number);

          return {
            ...rest,
            // group info
            offerName: rest.name,
            lowPrice: price,
            highPrice: multiPrices ? price + 1 : price, // HACK: display as +
            soldPrice: price, // HACK: always provided
            sizes,
            conditions: multiConditions ? [condition, condition] : [condition], // HACK
            count: offerIds.length,
            offerIds,
            offerType: OfferType.Ask,
            canFavorite: true,
            userId: favoriteUserId,
            favoriteId: id,
          };
        }
      : ({ id, spuId, imgUrl, price, ...rest }: any) => {
          return {
            ...rest,
            id: spuId,
            favoriteId: id,
            thumbnail: imgUrl,
            price,
            maxBids: [],
            minAsks: [
              {
                offerPrice: price, // HACK: for the display of lowest ask price
              },
            ],
          };
        };
    return immutableSet(data, ["data", "*"], map);
  });

export const authClearTokenWhenUnauth = (callback?: () => void) =>
  mapRes((res) => {
    if ("reject" in res && res.reject?.code === ApiResponseCode.Forbidden) {
      authTokenCacher.clear();
      if (callback) {
        callback();
      }
    }
    return res;
  });

export const loginWhenUnauth = authClearTokenWhenUnauth(
  () =>
    (window.location.pathname = url.login({
      forward: window.location.pathname,
    }))
);

export const uploadImages = (() => {
  const getS3Fields: UploadFile = httpOnion(
    ...commonPreMiddlewares,
    method("post"),
    urlMiddleware("/user/file"),
    jsonBody(),
    ...commonPostMiddlewares
  ).fork();

  /**
   * a weak map to pre avoid multi time file uploading
   */
  const fileUrlWeakMap: WeakMap<File, string> = new WeakMap();

  return (path: ObjectPath, maxWidthOrHeight?: number): HttpMiddleware =>
    (req, next) => {
      const uploadTasks: {
        file: File;
        resolvable: Resolvable<string>;
      }[] = [];

      const nextReqMaybePromise = immutableSetAsyncable(
        req,
        ["params", ...path],
        (imageSource: ImageSource) => {
          if (imageSource instanceof File) {
            if (fileUrlWeakMap.has(imageSource)) {
              return fileUrlWeakMap.get(imageSource);
            }
            return (async () => {
              const resolvable = ScheduleUtils.createResolvable<string>();
              uploadTasks.push({
                file: imageSource,
                resolvable,
              });
              const url = await resolvable.promise;
              fileUrlWeakMap.set(imageSource, url);
              return url;
            })();
          }
          return imageSource;
        }
      );

      // batched uploading
      if (uploadTasks.length) {
        // run async task
        (async () => {
          const md5TaskDict: Record<
            string,
            {
              file: File;
              resolvables: Resolvable<string>[];
            }
          > = {};
          await Promise.all(
            uploadTasks.map(async ({ file, resolvable }) => {
              const md5 = await getFileMD5(file);
              if (!md5TaskDict[md5]) {
                md5TaskDict[md5] = {
                  file,
                  resolvables: [],
                };
              }
              md5TaskDict[md5].resolvables.push(resolvable);
            })
          );
          // transform dict -> list
          const tasksWithMd5 = Object.entries(md5TaskDict).map(
            ([checkSum, { file, resolvables }]) => ({
              checkSum,
              file,
              resolvables,
            })
          );
          const { data } = await getS3Fields(
            tasksWithMd5.map(({ checkSum, file }) => ({
              checkSum,
              mime: file.type,
            }))
          );
          data.forEach(async ({ fields, url, upload }, index) => {
            const { file, resolvables } = tasksWithMd5[index];
            if (upload) {
              const compressedFile = await compressImageFile(
                file,
                maxWidthOrHeight
              );
              const formData = new FormData();
              Object.entries(fields).forEach(([k, v]) => {
                formData.append(k, v);
              });
              formData.append("file", compressedFile);
              // CHECK: error uncatchable
              await fetch(url, {
                method: "post",
                body: formData,
              });
            }
            const s3Link = `${url.replace(/\/$/, "")}/${fields.key.replace(
              /^\//,
              ""
            )}`;
            resolvables.forEach((resolvable) => resolvable.resolve(s3Link));
          });
        })();
      }

      return mapMaybePromise(nextReqMaybePromise, (nextReq) =>
        next(nextReq)
      ) as MaybePromise<HttpResponseObject>;
    };
})();

// unique list
export const uniqueList =
  (path: ObjectPath): HttpMiddleware =>
  (req, next) => {
    return next(immutableSet(req, ["params", ...path], ArrayUtils.unique));
  };

// assign order type for order list
// CHECK:
export const assignOrderList: HttpMiddleware = mapResolvedData((data, req) =>
  immutableSet(data, ["data", "*", "orderType"], req.params.orderType)
);

// assign order type and id for order detail
export const assignOrderDetail: HttpMiddleware = mapResolvedData(
  (data, req) => ({
    ...data,
    id: req.params.orderId,
  })
);

const transformMessage = (message: any, key = "contentObject"): MessageData => {
  if (
    [MessageType.Tracking, MessageType.Offer, MessageType.Order].includes(
      message.msgType
    )
  ) {
    return {
      ...message,
      content: message[key],
    };
  }
  return message;
};

export const adaptMessageList: HttpMiddleware = mapResolvedData((data) => {
  const [
    {
      data: { account },
    },
  ] = getState(ACCOUNT_STORE_KEY);
  if (!data) return data;
  const transformedData = immutableSet(
    data,
    ["messages", "*"],
    transformMessage
  );
  const { buyerInfo } = transformedData;
  return immutableSet(
    transformedData,
    ["conversationType"],
    buyerInfo.id === account.id ? ConversationType.Buy : ConversationType.Sell
  );
});

export const adaptMessage: HttpMiddleware = mapResolvedData((data) =>
  transformMessage(data, "contentObj")
);

export const preSendMessage: HttpMiddleware[] = [
  (req, next) =>
    next(
      immutableSet(req, ["params", "images"], (images: File[]) =>
        images && !Array.isArray(images) ? [images] : images
      )
    ),
  uploadImages(["images", "*"]),
  (req, next) =>
    next(
      immutableSet(req, ["params"], ({ images, ...rest }) =>
        images
          ? {
              ...rest,
              content: images,
            }
          : rest
      )
    ),
];

export const withAccountRegion: HttpMiddleware = (req, next) =>
  next({
    ...req,
    params: getState(ACCOUNT_STORE_KEY)[0].data.account?.region,
  });

// adapt response data to private interface of Listing
export const adaptToOfferGroup = (
  path: ObjectPath,
  commonOverrides?: (params: any) => Partial<ListingItemData>
): HttpMiddleware =>
  mapResolvedData((data, { params }) => {
    const base = commonOverrides ? commonOverrides(params) : {};
    return immutableSet(data, path, (offerList: ListingOffer[]) => ({
      ...base,
      ...offerList[0],
      ...ListingUtils.createListingInfo(offerList),
    }));
  });

export const trackProductFlow =
  (page_tab: string, is_marketview = false): HttpMiddleware =>
  (req, next) => {
    if (!req.params.pageStart) {
      tracker.track(
        "view_page",
        {
          page_name: "product_flow",
          page_tab,
          is_marketview,
        },
        {
          clearContext: true,
        }
      );
    }
    return next(req);
  };

export const trackSearchSpu =
  (isMarketview: boolean): HttpMiddleware =>
  (req, next) => {
    if (!req.params.pageStart) {
      const {
        keyword = "",
        category1,
        category2,
        brand,
        gender,
        size,
        condition,
        soldOnly,
        lowestPrice,
        highestPrice,
      } = req.params;
      const common = {
        keyword,
        category1_name_list: toArr(category1),
        category2_name_list: toArr(category2),
        brand_name_list: toArr(brand),
        condition_name_list: toArr(condition).map(conditionNamer),
        size_name_list: toArr(size),
        is_marketview: isMarketview,
        lowest_display_price: lowestPrice ?? null,
        highest_display_price: highestPrice ?? null,
        display_currency: req.headers[CURRENCY_HEADER],
        gender,
        is_sold: Boolean(soldOnly),
      };
      tracker.track("product_search_request", {
        ...common,
        keyword_type: "type",
      });
      return mapMaybePromise(next(req), (res) => {
        if ("resolve" in res) {
          const { data: list, totalCount } = res.resolve.data;

          tracker.track(
            "product_search_result",
            {
              ...common,
              page_name: "product_search_result",
              first_page_limit: req.params.pageLimit,
              first_page_result_num: list.length,
              result_num: totalCount,
            },
            {
              context: common,
            }
          );
        }
        return res;
      });
    }

    return next(req);
  };

interface RowProductFilters {
  brandFilter: Partial<Record<Gender | "all", string[]>>;
  categoryFilter: Partial<Record<Gender, Record<string, string[]>>>;
  conditionFilter?: Partial<Record<Gender, string[]>>; // condition string
  sizeFilter?: Partial<Record<Gender, SizeCategoryOptions>>; // size options
}

export const adaptProductFilters = (sortKeys: SortKey[]) => {
  const mutateBrands = (brands: string[]) => {
    if (!Array.isArray(brands)) return;
    const otherIndex = brands.indexOf(OTHER_DESIGNERS);
    if (otherIndex !== -1) {
      brands.splice(otherIndex, 1);
      brands.push(OTHER_DESIGNERS);
    }
  };

  return mapResolvedData(
    ({
      brandFilter: rawBrandFilter,
      categoryFilter: rawCategoryFilter,
      conditionFilter: rawConditionFilter,
      sizeFilter: rawSizeFilter,
    }: RowProductFilters): ProductFilters => {
      const brandFilter = rawBrandFilter || {};
      const categoryFilter = rawCategoryFilter || {};
      const conditionFilter: Partial<Record<Gender, Condition[]>> = {};
      const sizeFilter = rawSizeFilter || {};

      // adapt conditions to
      for (const gender of GENDERS) {
        conditionFilter[gender] = (rawConditionFilter?.[gender] || []).map(
          (value) => Number(value)
        );
        mutateBrands(brandFilter[gender]); // sort brands
      }

      // make sets and options
      const genders: Gender[] = [];
      const sets: Partial<Record<Gender, MixedSet>> = {};

      const categoryDict: Record<string, Set<string>> = {};
      for (const gender of GENDERS) {
        const genderSet = new MixedSet();
        genderSet.add("sort", sortKeys);
        genderSet.add("brand", brandFilter[gender] || []);
        genderSet.add("condition", conditionFilter[gender] || []);

        if (brandFilter[gender]?.length) {
          genders.push(gender);
        }

        Object.entries(categoryFilter[gender] || {}).forEach(([c1, c2s]) => {
          const set = categoryDict[c1] || new Set<string>();
          c2s.forEach((c2) => set.add(c2));
          categoryDict[c1] = set;

          genderSet.add("category1", c1);
          genderSet.add("category2", c2s);

          const sizeOptions = sizeFilter[gender]?.[c1];
          if (sizeOptions) {
            sizeOptions.order.forEach((sizeUnit) =>
              (sizeOptions.sizeOptions[sizeUnit] ?? []).forEach((size) => {
                genderSet.add("size", joinSize(sizeUnit, size));
              })
            );
          }
        });

        sets[gender] = genderSet;
      }

      const subcategoryDict: Record<string, Item<string>[]> = {};
      const categories: Item<string>[] = Object.entries(categoryDict)
        .map(([c1, c2Set]) => {
          subcategoryDict[c1] = Array.from(c2Set)
            .sort()
            .map((c2) => ({
              value: c2,
              name: capitalize(c2),
            }));
          return {
            value: c1,
            name: capitalize(c1),
          };
        })
        .sort((a, b) => compareCategory1(a.value, b.value));

      const createGenderOptions = (gender: Gender) => {
        const set = sets[gender];
        const genderSubcategoryDict: Record<string, Item<string>[]> = {};
        const genderCategories: Item<string>[] = categories
          .filter(({ value }) => set.has("category1", value))
          .map((input) => {
            genderSubcategoryDict[input.value] = subcategoryDict[
              input.value
            ].filter(({ value }) => set.has("category2", value));
            return input;
          });
        return {
          brands: (brandFilter[gender] || []).map(BrandUtils.create),
          conditions: conditionFilter[gender] || [],
          categories: genderCategories,
          subcategoryDict: genderSubcategoryDict,
          sizeDict: sizeFilter[gender] ?? {},
          mixedSet: set,
        };
      };

      const allBrands =
        brandFilter.all ||
        ArrayUtils.unique(
          GENDERS.flatMap((gender) => brandFilter[gender] || [])
        ).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));

      const combinedSet = MixedSet.join(sets[Gender.Men], sets[Gender.Women]);
      return {
        genders,
        filtersList: [
          {
            [GenderWithAll.All]: {
              brands: allBrands.map(BrandUtils.create),
              categories,
              subcategoryDict,
              conditions: CONDITIONS.filter((condition) =>
                combinedSet.has("condition", condition)
              ),
              sizeDict: mergeSizeCategoryOptions(
                sizeFilter[Gender.Women] ?? {},
                sizeFilter[Gender.Men] ?? {}
              ),
              mixedSet: combinedSet,
            },
            [GenderWithAll.Men]: createGenderOptions(Gender.Men),
            [GenderWithAll.Women]: createGenderOptions(Gender.Women),
          },
        ],
      };
    }
  );
};

const commentEnDateDict: Record<string, DateDataFormat> = {
  createTime: "iso",
  "replies.data.*.createTime": "iso",
};
const postEnDateDict: Record<string, DateDataFormat> = {
  createTime: "iso",
  updateTime: "iso",
  ...mapKeys(commentEnDateDict, (key) => `comments.data.*.${key}`),
};
const postListEnDateDict: Record<string, DateDataFormat> = mapKeys(
  postEnDateDict,
  (key) => `data.*.${key}`
);
const commentListEnDateDict: Record<string, DateDataFormat> = mapKeys(
  commentEnDateDict,
  (key) => `data.*.${key}`
);

export const enDatePost = () => enDate(postEnDateDict);

export const enDatePostList = () => enDate(postListEnDateDict);

export const clearPostListIsSuggested = () =>
  mapResolvedData((data) =>
    immutableSet(data, ["data", "*", "isSuggested"], false)
  );

export const enDateCommentList = () => enDate(commentListEnDateDict);

export const adaptSizeSource = () => mapResolvedData((data) => data.sizeFilter);
