import React, { FC, useState, useEffect } from "react";
import classNames from "classnames";
import { translate } from "@/i18n";
import { AddressData } from "@/interface/address";
import { Button, ErrorAlert, Icon, Loading, Radio } from "@/ui";
import { AddressCardEditable } from "./address-card";
import { AddressForm } from "./address-form";
import styles from "./address-list.module.less";
import { useAddressModel } from "./address.model";

export interface AddressListProps {
  // all or non
  selectable?: boolean;
  selected?: AddressData;
  isAddressSelectable?(data: AddressData): boolean;
  onSelect?(address: AddressData): void;
  onClose?(): void;
}

export const AddressList: FC<AddressListProps> = ({
  selectable = false,
  isAddressSelectable,
  selected,
  onSelect,
  onClose,
}) => {
  const [{ data: addressList, initiated, error }] = useAddressModel();

  const [innerSelected, setInnerSelected] = useState(selected);
  const [creatingNewAddress, setCreatingNewAddress] = useState(false);

  useEffect(() => {
    if (initiated && selectable && !addressList.length) {
      setCreatingNewAddress(true);
    }
  }, [initiated]);

  // async to outer
  useEffect(() => {
    setInnerSelected(selected);
  }, [selected]);

  if (error) {
    return <ErrorAlert error={error} />;
  }

  if (!initiated) {
    return <Loading title={translate("loading_address")} />;
  }

  return (
    <>
      <ul>
        {addressList.map((data) => {
          const isSelected = innerSelected?.id === data.id;
          const isDisabled = isAddressSelectable
            ? !isAddressSelectable(data)
            : false;
          const onClick =
            selectable && !isDisabled
              ? () => setInnerSelected(data)
              : undefined;
          return (
            <div
              key={data.id}
              className={classNames(styles.card_row, {
                [styles.card_row_selectable]: selectable,
                [styles.card_row_selected]: isSelected,
                [styles.card_row_disabled]: isDisabled,
              })}
              onClick={onClick}
            >
              {selectable ? (
                <div
                  className={classNames(styles.radio, {
                    [styles.radio_hidden]: isDisabled,
                  })}
                >
                  <Radio disabled={isDisabled} value={isSelected} />
                </div>
              ) : null}
              <div className={styles.card_wrapper}>
                <AddressCardEditable disabled={isDisabled} data={data} />
              </div>
            </div>
          );
        })}
        {creatingNewAddress ? (
          <div className={styles.card_row}>
            <div className={styles.card_wrapper}>
              <AddressForm
                data={{
                  id: 0,
                  firstName: "",
                  lastName: "",
                  address: "",
                  city: "",
                  state: "",
                  country: undefined,
                  zipCode: "",
                  mobile: "",
                  email: "",
                  nickname: "",
                  address2: "",
                  isDefault: !addressList.length,
                  isDeletable: true,
                  phoneCode: undefined,
                }}
                onExit={() => setCreatingNewAddress(false)}
              />
            </div>
          </div>
        ) : null}
        {!creatingNewAddress ? (
          <footer className={styles.footer}>
            {addressList.length < 5 ? (
              <Button
                className={styles.btn}
                onClick={() => setCreatingNewAddress(true)}
              >
                <Icon type="plus" className={styles.button_icon} />{" "}
                {translate("new_address")}
              </Button>
            ) : null}
            {selectable ? (
              <Button
                className={styles.btn}
                type="fill"
                onClick={() => {
                  onSelect(innerSelected);
                  onClose();
                }}
              >
                {translate("continue")}
              </Button>
            ) : null}
          </footer>
        ) : null}
      </ul>
    </>
  );
};
