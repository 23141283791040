import React, {
  CSSProperties,
  FC,
  forwardRef,
  memo,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import {
  useSuperState,
  Mutator,
  StyleUtils,
  ObjectUtils,
} from "@reversible/common";
import { useWindowSize } from "@/hooks/use-window-size";
import { EmbeddedComponentProps, StyledComponentProps } from "@/interface/base";
import { onResize } from "@/util/resize-observer";
import { PopupAnchor, PopupControl, PopupTriggerType } from "./popup-anchor";
import styles from "./popup.module.less";
import { useLayerNode } from "../hooks/use-layer";
import { PositionData } from "../hooks/use-position";
import { VisibleTransition } from "../transition";

export type Placement = "left" | "right" | "top" | "bottom";

interface PopupCoreProps extends StyledComponentProps, EmbeddedComponentProps {
  visible: boolean;
  placement: string;
  position: PositionData;
  untouchable: boolean;
}

const { px } = StyleUtils;

const PopupCore: FC<PopupCoreProps> = memo(
  ({
    visible,
    position,
    placement,
    untouchable,
    className,
    style,
    children,
  }) => {
    const ref = useRef<HTMLDivElement>();
    const [size, setSize] = useSuperState({
      width: 0,
      height: 0,
    });
    useLayoutEffect(() => {
      return onResize(ref.current, () =>
        setSize(
          ObjectUtils.pick(ref.current.getBoundingClientRect(), [
            "width",
            "height",
          ])
        )
      );
    }, []);

    const windowWidth = useWindowSize(([w]) => w);
    const styleOverrides: CSSProperties = useMemo(() => {
      const { width: popupW, height: popupH } = size;
      const { left, top, width, height } = position;

      const maxLeft = windowWidth - popupW;
      const createOverrides = (left: number, top: number): CSSProperties => ({
        left: px(Math.max(0, Math.min(left, maxLeft))),
        top: px(top),
      });

      switch (placement) {
        case "top":
          return createOverrides(left + width / 2 - popupW / 2, top - popupH);
        case "bottom":
          return createOverrides(left + width / 2 - popupW / 2, top + height);
        case "left":
          return createOverrides(left - popupW, top + height / 2 - popupH / 2);
        case "right":
          return createOverrides(left + width, top + height / 2 - popupH / 2);
        default:
          return {};
      }
    }, [windowWidth, position, size]);

    return (
      <VisibleTransition
        ref={ref}
        visible={visible}
        className={classNames(
          styles.popup,
          {
            [styles.popup_untouchable]: untouchable,
          },
          className
        )}
        style={{
          ...styleOverrides,
          ...(style || {}),
        }}
      >
        {children}
      </VisibleTransition>
    );
  }
);

export type { PopupTriggerType };

export interface PopupProps
  extends StyledComponentProps,
    EmbeddedComponentProps {
  trigger?: PopupTriggerType;
  placement?: Placement;
  visible: boolean;
  setVisible(visible: Mutator<boolean>): void;
}

export const Popup = forwardRef<PopupControl, PopupProps>(
  (
    {
      children,
      placement = "top",
      trigger = "click",
      className,
      style,
      setVisible,
      visible,
    },
    ref
  ) => {
    // layerNode
    const layerNode = useLayerNode();

    return (
      <PopupAnchor
        visible={visible}
        setVisible={setVisible}
        layerNode={layerNode}
        trigger={trigger}
        ref={ref}
      >
        {(positionData) =>
          createPortal(
            <PopupCore
              visible={visible}
              placement={placement}
              position={positionData}
              className={className}
              data-type="popup"
              style={style}
              untouchable={trigger !== "click"}
            >
              {children}
            </PopupCore>,
            layerNode
          )
        }
      </PopupAnchor>
    );
  }
);
