import { isNumeric, ObjectUtils } from "@reversible/common";
import { CONVERSATION_ID_NOTIFICATION } from "@/const";
import { Condition, Gender, OfferType, SortKey } from "@/enum";
import { UserPublicInfo } from "@/interface/account";
import { TopicData } from "@/interface/post";
import {
  ProductDetailURLQuery,
  ProductFilterUrlQuery,
  UserHomeUrlQuery,
} from "@/interface/url";
import { url } from "@/url";
import { toArr, toSingle } from "./data";

const { omit } = ObjectUtils;

/**
 * Schemes for cross-end linking
 */
export interface SpuDetailsScheme {
  type: "spu";
  spuId: number;
  size?: string;
  condition?: Condition;
  offerType?: OfferType;
}

export interface OfferDetailsScheme {
  type: "offer";
  offerId: number | number[];
  spuId: number;
}

export interface MessageScheme {
  type: "message";
  conversationId?: number;
  messageId?: number;
}

export interface UserPostsScheme {
  type: "lookbook";
  userId: number;
  username: string;
}

export interface PostScheme {
  type: "post";
  postId: number;
}

export interface PostCommentScheme {
  type: "postComment";
  postId: number;
  commentId?: number;
}

export interface ProductListScheme extends ProductListFiltersSchemeFields {
  type: "productList";
  gender: Gender;
  source?:
    | "marketplace"
    | "new-in"
    | "recommended"
    | "designer"
    | "category"
    | "search";
}

export interface UserFavoritesScheme extends ProductListFiltersSchemeFields {
  type: "favorites";
  userId: number;
  username: string;
  gender?: Gender[];
}

export interface UserListingsScheme extends ProductListFiltersSchemeFields {
  type: "listings";
  userId: number;
  username: string;
  gender?: Gender[];
}

export interface EnsembleFollowingScheme {
  type: "following";
}

export interface EnsembleDiscoverScheme {
  type: "discover";
}

export interface EnsembleTopicScheme {
  type: "topic";
  topicId: number;
  topicName: string;
}

export interface SpuLookbookScheme {
  type: "spuLookbook";
  spuId: number;
}

export interface SystemMessageScheme {
  type: "systemMessage";
  messageId?: number;
}

export interface AccountWatchlistScheme {
  type: "accountWatchlist";
  offerType?: OfferType; // 0-bid, 1-ask
}

export interface AccountOffersScheme {
  type: "accountOffers";
  offerType?: OfferType; // 0-bid, 1-ask
}

export interface ResetPasswordScheme {
  type: "resetPassword";
  token: string;
  email: string;
}

interface ProductListFiltersSchemeFields {
  category1?: string; // single
  category2?: string[];
  brand?: string[];
  condition?: Condition[];
  size?: string[];
  lowestPrice?: number;
  highestPrice?: number;
  soldOnly?: 0 | 1;
  keyword?: string;
  region?: string[];
  sort?: SortKey;
  marketplace?: 0 | 1;
}

export type UnionedScheme =
  | SpuDetailsScheme
  | OfferDetailsScheme
  | MessageScheme
  | UserPostsScheme
  | PostScheme
  | PostCommentScheme
  | ProductListScheme
  | UserFavoritesScheme
  | UserListingsScheme
  | EnsembleFollowingScheme
  | EnsembleDiscoverScheme
  | EnsembleTopicScheme
  | SpuLookbookScheme
  | SystemMessageScheme
  | AccountWatchlistScheme
  | AccountOffersScheme
  | ResetPasswordScheme;

export class Scheme {
  static parse(scheme: UnionedScheme): string {
    switch (scheme?.type) {
      case "spu": {
        const { spuId, condition, size, offerType } = scheme;
        if (!spuId) {
          throw new Error();
        }
        return url.productDetail(
          {
            id: Number(spuId),
            gender: Gender.Women, // inaccurate
          },
          {
            condition: this.toSingle(condition, true),
            size,
            offerType: this.toSingle(offerType, true),
          }
        );
      }
      case "message": {
        const { conversationId } = scheme;
        return url.messages({
          id: this.toSingle(conversationId, true),
        });
      }
      case "offer": {
        const { offerId, spuId } = scheme;
        if (!offerId || !spuId) {
          throw new Error();
        }
        return url.productDetail(
          { id: spuId, gender: Gender.Women },
          {
            offerId: this.toArr(offerId, true),
          }
        );
      }
      case "post": {
        const { postId } = scheme;
        if (!isNumeric(postId)) {
          throw new Error();
        }
        return url.postDetail(Number(postId));
      }
      case "postComment": {
        const { postId, commentId } = scheme;
        if (!isNumeric(postId)) {
          throw new Error();
        }
        return url.postDetail(Number(postId), {
          comment: this.toSingle(commentId, true),
        });
      }
      case "discover":
        return url.ensembleDiscover();
      case "following":
        return url.ensembleFollowing();
      case "topic": {
        const { topicName } = scheme;
        if (!topicName) {
          throw new Error();
        }
        return url.ensembleTopic(topicName);
      }
      // user home page of different type of tabs
      case "lookbook": {
        const { username } = scheme;
        if (!username) {
          throw new Error();
        }
        return url.userHome(username, { type: "posts" });
      }
      case "favorites": {
        const { username } = scheme;
        return url.userHome(username, {
          type: "favorites",
          ...this.adaptSchemeFieldsToProductFilter(scheme),
        });
      }
      case "listings": {
        const { username, sort } = scheme;
        return url.userHome(username, {
          type: "listings",
          sort: this.toSingle(sort, true),
          ...this.adaptSchemeFieldsToProductFilter(scheme),
        });
      }
      case "spuLookbook": {
        const { spuId } = scheme;
        if (!isNumeric(spuId)) {
          throw new Error();
        }
        return url.productLookbook({
          id: Number(spuId),
          gender: Gender.Women,
        });
      }
      case "productList": {
        const { gender, brand, category1, source } = scheme;
        switch (source) {
          case "recommended":
            return url.newIn(gender);
          case "new-in":
            return url.newIn(
              gender,
              omit(this.adaptSchemeFieldsToProductFilter(scheme), [
                "sort",
                "marketplace",
              ])
            );
          case "marketplace":
            return url.marketplace(
              gender,
              omit(this.adaptSchemeFieldsToProductFilter(scheme), [
                "marketplace",
              ])
            );
          case "designer":
            return url.designerCollection(
              gender,
              this.toSingle(brand),
              omit(this.adaptSchemeFieldsToProductFilter(scheme), ["brand"])
            );
          case "category":
            return url.categoryItems(
              gender,
              this.toSingle(category1),
              omit(this.adaptSchemeFieldsToProductFilter(scheme), ["category1"])
            );
          default:
            return url.productSearch(
              gender,
              this.adaptSchemeFieldsToProductFilter(scheme)
            );
        }
      }
      case "systemMessage":
        return url.messages({
          id: CONVERSATION_ID_NOTIFICATION,
        });
      case "accountWatchlist":
        return url.accountWatchList({
          offerType: this.toSingle(scheme.offerType, true),
        });
      case "accountOffers":
        return url.accountOfferList({
          offerType: this.toSingle(scheme.offerType, true),
        });
      case "resetPassword":
        return url.resetPassword({
          email: this.toSingle(scheme.email),
          token: this.toSingle(scheme.token),
        });
      default:
        throw new Error();
    }
  }

  static spuDetails(
    spuId: number,
    { size, condition, offerType }: ProductDetailURLQuery = {}
  ): SpuDetailsScheme {
    return {
      type: "spu",
      spuId,
      size,
      condition,
      offerType,
    };
  }

  static offerDetails(
    offerId: number | number[],
    spuId: number
  ): OfferDetailsScheme {
    return {
      type: "offer",
      offerId,
      spuId,
    };
  }

  static message(conversationId?: number): MessageScheme {
    return {
      type: "message",
      conversationId,
    };
  }

  static userPosts({ username, id }: UserPublicInfo): UserPostsScheme {
    return {
      type: "lookbook",
      userId: id,
      username,
    };
  }

  static postDetails(postId: number): PostScheme {
    return {
      type: "post",
      postId,
    };
  }

  static postComments(postId: number): PostCommentScheme {
    return {
      type: "postComment",
      postId,
    };
  }

  static productList(
    gender: Gender,
    query: ProductFilterUrlQuery,
    source?: ProductListScheme["source"]
  ): ProductListScheme {
    return {
      type: "productList",
      gender,
      keyword: query.keyword,
      marketplace: query.marketplace,
      sort: query.sort,
      source,
      ...this.adaptProductFilterToSchemeFields(query),
    };
  }

  static userFavorites(
    { id, username }: UserPublicInfo,
    query: ProductFilterUrlQuery
  ): UserFavoritesScheme {
    return {
      type: "favorites",
      userId: id,
      username,
      gender: this.toArr(query.gender),
      ...this.adaptProductFilterToSchemeFields(query),
    };
  }

  static userListings(
    { id, username }: UserPublicInfo,
    query: UserHomeUrlQuery
  ): UserListingsScheme {
    return {
      type: "listings",
      userId: id,
      username,
      gender: this.toArr(query.gender),
      sort: query.sort,
      ...this.adaptProductFilterToSchemeFields(query),
    };
  }

  static ensembleFollowing(): EnsembleFollowingScheme {
    return {
      type: "following",
    };
  }

  static ensembleDiscover(): EnsembleDiscoverScheme {
    return {
      type: "discover",
    };
  }

  static ensembleTopic({ topicId, name }: TopicData): EnsembleTopicScheme {
    return {
      type: "topic",
      topicId,
      topicName: name,
    };
  }

  static spuLookbook(spuId: number): SpuLookbookScheme {
    return {
      type: "spuLookbook",
      spuId,
    };
  }

  static systemMessage(): SystemMessageScheme {
    return {
      type: "systemMessage",
    };
  }

  static accountWatchlist(offerType: OfferType): AccountWatchlistScheme {
    return {
      type: "accountWatchlist",
      offerType,
    };
  }

  static accountOffers(offerType: OfferType): AccountOffersScheme {
    return {
      type: "accountOffers",
      offerType,
    };
  }

  private static toSingle<T, N extends boolean = false>(
    input: T | T[],
    isNumber = false as N
  ): N extends false ? T : number {
    const value = toSingle(input);
    return isNumber
      ? isNumeric(value as any)
        ? Number(value)
        : undefined
      : (value as any);
  }

  private static toArr<T, N extends boolean = false>(
    input: T | T[],
    isNumber = false as N
  ): N extends false ? T[] : number[] {
    const arr = toArr(input);
    return isNumber ? arr.filter(isNumeric as any).map(Number) : (arr as any);
  }

  private static adaptProductFilterToSchemeFields({
    category1,
    category2,
    brand,
    condition,
    size,
    lowestPrice,
    highestPrice,
    soldOnly,
  }: ProductFilterUrlQuery | UserHomeUrlQuery): ProductListFiltersSchemeFields {
    return {
      brand: this.toArr(brand),
      category1: this.toSingle(category1),
      category2: this.toArr(category2),
      condition: this.toArr(condition, true),
      size: this.toArr(size),
      lowestPrice: this.toSingle(lowestPrice, true),
      highestPrice: this.toSingle(highestPrice, true),
      soldOnly: this.toSingle(soldOnly, true) ? 1 : undefined,
    };
  }

  private static adaptSchemeFieldsToProductFilter({
    brand,
    category1,
    category2,
    condition,
    size,
    keyword,
    region,
    lowestPrice,
    highestPrice,
    soldOnly,
    marketplace,
    sort,
  }: ProductListFiltersSchemeFields = {}): Omit<
    ProductFilterUrlQuery,
    "gender" | "page"
  > {
    return {
      brand: this.toArr(brand),
      category1: this.toSingle(category1),
      category2: this.toArr(category2),
      condition: this.toArr(condition, true),
      size: this.toArr(size),
      lowestPrice: this.toSingle(lowestPrice, true),
      highestPrice: this.toSingle(highestPrice, true),
      soldOnly: this.toSingle(soldOnly, true) ? 1 : undefined,
      marketplace: this.toSingle(marketplace) ? 1 : undefined,
      keyword: this.toSingle(keyword),
      region: this.toArr(region),
      sort: this.toSingle(sort, true),
    };
  }
}
