import {
  FC,
  ReactElement,
  useMemo,
  CSSProperties,
  MutableRefObject,
} from "react";
import { StyleUtils } from "@reversible/common";
import { styleConsts } from "@/style";
import { usePosition } from "../hooks/use-position";

const { px } = StyleUtils;

export interface OptionsPopupProps {
  visible: boolean;
  inputRef?: MutableRefObject<HTMLDivElement>;
  children: (style: CSSProperties) => ReactElement;
}

export const OptionsPopup: FC<OptionsPopupProps> = ({
  inputRef,
  visible,
  children,
}) => {
  const positionData = usePosition(() => inputRef.current, visible);

  const style = useMemo((): CSSProperties => {
    const { top, height, left, width } = positionData;
    const up = top + height > (window.innerHeight * 2) / 3;
    return {
      left: px(left),
      width: px(width),
      top: px(top + (up ? 0 : height)),
      transform: up ? "translateY(-100%)" : undefined,
      zIndex: styleConsts.optionsZIndex,
    };
  }, [positionData]);

  return children(style);
};
