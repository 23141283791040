import { ObjectUtils } from "@reversible/common";
import { useWindowSize } from "@/hooks/use-window-size";
import { styleConsts } from "@/style";
import { createModel } from ".";

export interface ScreenSizeBooleans {
  isFoldScreenSize: boolean;
  isMobileScreenSize: boolean;
  isSmScreenSize: boolean;
  isXsScreenSize: boolean;
  isLgScreenSize: boolean;
}

export const useScreenSize = createModel((): ScreenSizeBooleans => {
  const screenSizes = useWindowSize(
    ([w]) => ({
      isFoldScreenSize: w <= styleConsts.foldScreenMaxWidth,
      isMobileScreenSize: w <= styleConsts.mobileScreenMaxWidth,
      isSmScreenSize: w <= styleConsts.smScreenMaxWidth,
      isXsScreenSize: w <= styleConsts.xsScreenMaxWidth,
      isLgScreenSize: w > styleConsts.lgScreenMinWidth,
    }),
    ObjectUtils.shallowEqual
  );

  return screenSizes;
});
