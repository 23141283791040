import React, { FC } from "react";
import { Route } from "react-router";
import { IS_SAFARI } from "@reversible/common";
import { urls } from "../url";

const InstaIssueView: FC = () => {
  const {
    innerWidth,
    screen: { availWidth: screenWidth },
  } = window;
  return (
    <div
      style={{
        background:
          innerWidth === screenWidth
            ? "green"
            : innerWidth > screenWidth
            ? "red"
            : "blue",
        color: "white",
        padding: "20px",
        fontSize: "20px",
        lineHeight: "24px",
      }}
    >
      IS_SAFARI: {String(IS_SAFARI)}
      <br />
      innerWidth: {innerWidth}
      <br />
      screenAvailWidth: {screenWidth}
      <br />
      HTML clientWidth {document.documentElement.clientWidth}
      <br />
      match orientation portrait:{" "}
      {String(window.matchMedia("(orientation: portrait)").matches)}
      <br />
      match orientation landscape:{" "}
      {String(window.matchMedia("(orientation: landscape)").matches)}
    </div>
  );
};

export const InstaIssueRoute = () => (
  <Route exact path={urls.issueInsta()}>
    <InstaIssueView />
  </Route>
);
