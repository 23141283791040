import React, {
  forwardRef,
  memo,
  MutableRefObject,
  useEffect,
  useRef,
} from "react";
import autosize from "autosize";
import classnames from "classnames";
import { noop, compose } from "@reversible/common";
import { translate } from "@/i18n";
import styles from "./textarea.module.less";
import { useFormFieldContext } from "../form";

interface TextareaProps {
  className?: string;
  value?: string;
  placeholder?: string;
  naked?: boolean; // no extra style
  rows?: number;
  maxLength?: number;
  onChange?(value: string): void;
  onBlur?(): void;
}

export const Textarea = memo(
  forwardRef<HTMLTextAreaElement, TextareaProps>(
    (
      {
        value: controlledValue,
        onChange: controlledOnChange,
        placeholder = "",
        className = "",
        rows = 3,
        onBlur = noop,
        naked = false,
        maxLength,
      },
      forwardedRef
    ) => {
      const { field, value, onChange, onValidate } = useFormFieldContext(
        controlledValue,
        controlledOnChange
      );

      const ref =
        (forwardedRef as MutableRefObject<HTMLTextAreaElement>) ||
        useRef<HTMLTextAreaElement>();

      useEffect(() => {
        autosize(ref.current); // maybe update
        return () => {
          autosize.destroy(ref.current);
        };
      }, []);

      useEffect(() => {
        autosize.update(ref.current);
      }, [value]);

      return (
        <>
          <textarea
            id={field}
            className={classnames(className, {
              [styles.textarea]: !naked,
            })}
            ref={ref}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            rows={rows}
            onBlur={compose(onBlur, onValidate)}
            maxLength={maxLength}
          />
          {maxLength ? (
            <div className={styles.current_length}>
              {value?.length || 0}/{maxLength} {translate("characters")}
            </div>
          ) : null}
        </>
      );
    }
  )
);
