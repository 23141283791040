import React, {
  FC,
  memo,
  useRef,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ObjectUtils } from "@reversible/common";
import { Avatar } from "@/component/user-info";
import {
  ConversationType,
  LocalMessageStatus,
  MessageType,
  OfferType,
} from "@/enum";
import { translate } from "@/i18n";
import { UserInfoBrief } from "@/interface/account";
import {
  LocalMessage,
  LocalMessageSendData,
  MessageData,
} from "@/interface/message";
import { Icon, Img } from "@/ui";
import { url } from "@/url";
import { formatMessageTime } from "@/util/format";
import styles from "./message-display.module.less";
import { OfferMessageDisplay } from "./offer-message-display";
import { OrderMessageDisplay } from "./order-message-display";
import { TextWithLinks } from "./text-with-links";
import { messageListModelContext } from "../context";

export interface MessageDisplayProps {
  data: MessageData | LocalMessage;
  otherUserInfo: UserInfoBrief;
}

const SYSTEM_ID = 0;

export const MessageDisplay: FC<MessageDisplayProps> = memo(
  ({ data, otherUserInfo }) => {
    const isReceiver =
      "local" in data
        ? false
        : [otherUserInfo.id, SYSTEM_ID].includes(data.senderId);

    const [{ conversationType, focusId }, dispatchMessageAction] = useContext(
      messageListModelContext
    );

    const focused = data.id === focusId;

    const ref = useRef<HTMLDivElement>();
    const scroll = useCallback(() => {
      if (focused) {
        ref.current.scrollIntoView({
          block: "center",
        });
      }
    }, [focused]);
    useEffect(scroll, [scroll]);

    const timeDisplay = useMemo(() => {
      if ("local" in data) return "";
      return formatMessageTime(data.msgTime);
    }, [data]);

    if (data.msgType === MessageType.System) {
      return (
        <div className={classNames(styles.sys_message)} ref={ref}>
          <div className={styles.sys_time}>{timeDisplay}</div>
          <div className={styles.sys_content}>{data.content}</div>
        </div>
      );
    }

    const contentNode = (() => {
      switch (data.msgType) {
        case MessageType.Text:
          return (
            <div className={styles.content}>
              <div
                className={classNames(
                  styles.content_box,
                  styles.text_content_box
                )}
              >
                <TextWithLinks linkClassName={styles.link_in_text}>
                  {data.content}
                </TextWithLinks>
              </div>
            </div>
          );
        case MessageType.Image:
          return (
            <div className={styles.content}>
              <div
                className={classNames(
                  styles.content_box,
                  styles.img_content_box
                )}
              >
                <Img
                  type="auto"
                  className={styles.img}
                  src={data.images || data.content}
                  zoomable
                  onLoad={scroll}
                />
              </div>
            </div>
          );
        case MessageType.Offer: {
          const offerType =
            conversationType === ConversationType.Buy
              ? isReceiver
                ? OfferType.Ask
                : OfferType.Bid
              : isReceiver
              ? OfferType.Bid
              : OfferType.Ask;
          return (
            <div
              className={classNames(styles.content, styles.content_full_width)}
            >
              <OfferMessageDisplay
                offerMessage={data}
                offerType={offerType}
                isReceiver={isReceiver}
              />
            </div>
          );
        }
        case MessageType.Order: {
          return (
            <div
              className={classNames(styles.content, styles.content_full_width)}
            >
              <OrderMessageDisplay
                orderMessage={data}
                isReceiver={isReceiver}
                onLoad={scroll}
              />
            </div>
          );
        }
        case MessageType.Tracking: {
          const { carrier, trackingUrl, trackingNo } = data.content;
          return (
            <div
              className={classNames(styles.content, styles.content_full_width)}
            >
              <div className={classNames(styles.content_box, styles.card)}>
                <header className={styles.card_header}>
                  {translate("tracking")}
                </header>
                <div className={styles.card_content}>
                  {trackingNo ? (
                    <dl className={styles.dl}>
                      <dt className={styles.dt}>{translate("carrier")}</dt>
                      <dd className={styles.dd}>{carrier}</dd>
                      <dt className={styles.dt}>{translate("tracking_no")}</dt>
                      <dd className={styles.dd}>
                        {trackingUrl ? (
                          <a
                            className={styles.card_link}
                            href={trackingUrl}
                            target="_blank"
                          >
                            {trackingNo}
                          </a>
                        ) : (
                          <>{trackingNo}</>
                        )}
                      </dd>
                    </dl>
                  ) : (
                    <div className={styles.card_text_gray_out}>
                      {translate("no_tracking_info_provided")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }
        default:
          return null;
      }
    })();

    return (
      <div
        ref={ref}
        className={classNames(styles.message, {
          [styles.message_send]: !isReceiver,
          [styles.message_receive]: isReceiver,
        })}
      >
        {isReceiver ? (
          <Link
            className={styles.avatar}
            to={url.userHome(otherUserInfo.username, { type: "listings" })}
          >
            <Avatar
              className={styles.avatar_img}
              avatar={otherUserInfo.avatarUrl}
              alt={otherUserInfo.username}
            />
          </Link>
        ) : null}
        <div className={styles.content_wrapper}>
          {contentNode}
          <div className={styles.operators}>
            <div className={styles.operator_icon}>
              {"local" in data ? (
                data.status === LocalMessageStatus.Loading ? (
                  <Icon className={styles.loading_icon} type="loading" spin />
                ) : data.status === LocalMessageStatus.Failed ? (
                  <Icon
                    className={styles.reload_icon}
                    onClick={() => {
                      dispatchMessageAction({
                        type: "push",
                        message: ObjectUtils.omit(data, [
                          "local",
                          "status",
                          "syncedId",
                        ]) as LocalMessageSendData,
                      });
                    }}
                    type="reload-circle"
                  />
                ) : null
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.time}>{timeDisplay}</div>
      </div>
    );
  }
);
