import React, {
  ComponentType,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import classnames from "classnames";
import { useModal , noop, prevent, uniqueId } from "@reversible/common";
import { ImageSource } from "@/interface/base";
import { Icon } from "@/ui/icon";
import { getBlobKey } from "@/util/blob";
import { ImageBox } from "./image-box";
import styles from "./image-select.module.less";
import { useFormFieldContext } from "../form";
import { ImgGroup } from "../img";

const ACCEPT = "image/*";

export interface ImageSelectBoxProps {
  onSelect(file: FileList): void;
}

export const ImageSelectBox: FC<ImageSelectBoxProps> = ({ onSelect }) => {
  const inputId = useMemo(() => `file-selector-${uniqueId()}`, []);

  return (
    <>
      <input
        hidden
        id={inputId}
        type="file"
        accept={ACCEPT}
        multiple
        onChange={(e) => {
          const { files } = e.target;
          onSelect(files);
        }}
      />
      <label
        className={classnames(styles.box, styles["box--uploadable"])}
        htmlFor={inputId}
      >
        <div className={styles.img_wrapper}>
          <Icon type="plus" />
        </div>
      </label>
    </>
  );
};

export interface ImageSelectProps {
  value?: ImageSource[];
  onChange?(images: ImageSource[]): void;
  LocalImageEditor?: ComponentType<{
    visible: boolean;
    data: File;
    onChange(data: File): void;
    onClose(): void;
  }>;
  max?: number;
}

const zipWithKey = (value: ImageSource[]): [ImageSource, string][] => {
  let urlSourceCount = 0;
  return (value || []).map((source) => {
    if (source instanceof File) {
      return [source, getBlobKey(source)];
    }
    return [source, String(urlSourceCount++)];
  });
};

export const ImageSelect: FC<ImageSelectProps> = memo(
  ({
    value: controlledValue,
    onChange: controlledOnChange,
    LocalImageEditor,
    max,
  }) => {
    const { value, onChange } = useFormFieldContext(
      controlledValue,
      controlledOnChange
    );

    const onRemove = useCallback(
      (index) => {
        onChange(value.filter((_, i) => i !== index));
      },
      [value]
    );

    const valueWithKey = useMemo(() => zipWithKey(value), [value]);

    const [editorState, openEditor, closeEditor] = useModal<{
      index: number;
      data: File;
    }>({
      index: -1,
      data: null,
    });

    const selectorKey = useMemo(uniqueId, []); // the key of the current instance

    const onMove = useCallback(
      (from: number, to: number) => {
        const toBeMoved = value[from];
        const replaced = value.slice();
        replaced[from] = null;
        replaced.splice(to, 0, toBeMoved);
        onChange(replaced.filter((item) => item));
      },
      [value, onChange]
    );

    // prevent window dragover & drop events
    useEffect(() => {
      const callback = prevent(noop);
      window.addEventListener("dragover", callback, false);
      window.addEventListener("drop", callback, false);
      window.addEventListener("dragenter", callback, false);
      return () => {
        window.removeEventListener("dragover", callback);
        window.removeEventListener("drop", callback);
        window.removeEventListener("dragenter", callback);
      };
    }, []);

    return (
      <div className={styles.image_select}>
        <ImgGroup>
          {valueWithKey.map(([source, key], index) => (
            <ImageBox
              key={key}
              selectorKey={selectorKey}
              index={index}
              editable={LocalImageEditor && source instanceof Blob}
              deletable
              draggable
              source={source}
              onEdit={() =>
                openEditor({
                  index,
                  data: source as File,
                })
              }
              onDelete={() => onRemove(index)}
              onMove={onMove}
            />
          ))}
        </ImgGroup>
        {!max || valueWithKey.length < max ? (
          <ImageSelectBox
            onSelect={(fileList) => {
              const nextRawValue = [...value, ...fileList];
              onChange(max ? nextRawValue.slice(0, max) : nextRawValue);
            }}
          />
        ) : null}
        {/* {max ? (
          <div className={styles.current_length}>
            {value?.length || 0}/{max} {translate('images')}
          </div>
        ) : null} */}
        {LocalImageEditor ? (
          <LocalImageEditor
            visible={editorState.visible}
            data={editorState.data.data}
            onChange={(blob) => {
              onChange(
                value.map((item, index) =>
                  index === editorState.data.index ? blob : item
                )
              );
            }}
            onClose={closeEditor}
          />
        ) : null}
      </div>
    );
  }
);

export interface ImageSelectDisplayProps {
  value: ImageSource[];
}
export const ImageSelectDisplay: FC<ImageSelectDisplayProps> = memo(
  ({ value }) => {
    const valueWithKey = zipWithKey(value);

    return (
      <div className={styles.image_select}>
        {valueWithKey.map(([source, key]) => (
          <ImageBox key={key} source={source} />
        ))}
      </div>
    );
  }
);
