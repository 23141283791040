import React, { FC, memo, useEffect } from "react";
import classNames from "classnames";
import { useFlowEffect, CallNext } from "@reversible/common";
import { AddressCard } from "@/component/address-management/address-card";
import { conditionNamer } from "@/enum";
import { translate } from "@/i18n";
import { MessageData, OrderMessageContent } from "@/interface/message";
import { OrderDetailData } from "@/interface/product";
import * as apiService from "@/service";
import { GetOrderDetail } from "@/service/interface";
import { useSettingsInfo } from "@/store/use-settings-info";
import { AsyncDisplay, Loading } from "@/ui";
import styles from "./message-display.module.less";

export const OrderMessageDisplay: FC<{
  orderMessage: MessageData;
  isReceiver: boolean;
  onLoad(): void;
}> = memo(({ orderMessage, onLoad }) => {
  const {
    data: { currencySymbolDict },
  } = useSettingsInfo();

  const orderDetailState = useFlowEffect<OrderDetailData>(
    null,
    function* ({ call, put }) {
      const { orderId } =
        orderMessage.content as OrderMessageContent["content"];
      const { data }: CallNext<GetOrderDetail> = yield call(
        apiService.getOrderDetail,
        { orderId }
      );
      yield put(data);
    },
    [orderMessage]
  );

  const { ready } = orderDetailState;

  useEffect(() => {
    if (ready) {
      onLoad();
    }
  }, [ready]);

  return (
    <div className={classNames(styles.content_box, styles.card)}>
      <h4 className={styles.card_header}>
        <AsyncDisplay
          source={orderDetailState}
          loadingNode={translate("purchased")}
        >
          {({ currency, finalPrice }) =>
            translate("purchased_for_price", {
              symbol: currencySymbolDict[currency],
              currency,
              price: finalPrice,
            })
          }
        </AsyncDisplay>
      </h4>
      <div className={styles.card_content}>
        <AsyncDisplay
          source={orderDetailState}
          loadingNode={
            <Loading
              className={styles.card_loading}
              title={translate("loading_order")}
            />
          }
        >
          {({ offerName, brand, size, condition, shipmentInfo }) => (
            <>
              <p className={styles.paragraph}>
                {translate("i_bought", {
                  name: offerName,
                  condition: conditionNamer(condition).toLowerCase(),
                })}
                <em className={styles.offer_name}>{offerName}</em>
                {translate("by_brand_in_size", {
                  brand,
                  size,
                })}
              </p>
              <p className={styles.paragraph}>
                {translate("here_is_my_address")}
              </p>
              <AddressCard
                data={shipmentInfo}
                backgrounded
                className={styles.address_card}
              />
            </>
          )}
        </AsyncDisplay>
      </div>
    </div>
  );
});
