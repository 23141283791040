import React, {
  forwardRef,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  Dispatch,
  ReactElement,
  RefAttributes,
  useImperativeHandle,
} from "react";
import {
  useValue,
  useSuperState,
  MaybePromise,
  applyPartialMutator,
  identity,
  ObjectUtils,
  prevent,
  stop,
  isPromise,
  MaybePromiseUtils,
} from "@reversible/common";
import { StyledComponentProps } from "@/interface/base";
import {
  controlledFormContext,
  FieldRegisterContext,
  formContext,
  FormContextValue,
} from "./context";
import { FormFieldConfig, FormMethods, FormState } from "./interface";

const { map } = MaybePromiseUtils;

export const FORM_TEST_ID = "ui-form";

export interface FormProps<T> extends StyledComponentProps {
  id?: string;
  initialValues?: T;
  onChange?(values: T): void;
  onSubmit?(values: T): MaybePromise<void>;
}

interface FormType {
  <T = any>(
    props: PropsWithChildren<FormProps<T>> & RefAttributes<FormMethods>
  ): ReactElement;
}
export const Form: FormType = forwardRef<
  FormMethods,
  PropsWithChildren<FormProps<any>>
>(
  (
    { id, className, style, children, initialValues, onChange, onSubmit },
    ref
  ) => {
    const [state, setState] =
      useContext(controlledFormContext) ||
      (useSuperState({
        // use useSuperState to prevent update on destroyed component
        values: initialValues || {},
        alerts: {},
        loading: false,
      }) as [FormState, Dispatch<SetStateAction<FormState>>]);

    const fieldItemConfigsValue = useValue<Record<string, FormFieldConfig>>({});

    const { values } = state;

    const methods = useMemo(
      (): FormMethods => ({
        getValues: () => values,
        setValues: (mutator) => {
          setState((prev) => {
            const { values, alerts } = prev;
            const nextValues = applyPartialMutator(mutator, values);

            const alertsPatch: Record<string, string> = {};

            // update alerts
            Object.entries(alerts)
              .filter((kv) => kv[1])
              .forEach(([path]) => {
                if (
                  !Object.is(
                    ObjectUtils.strProp(values, path),
                    ObjectUtils.strProp(nextValues, path)
                  )
                ) {
                  // if mutated, clear
                  alertsPatch[path] = "";
                }
              });

            return {
              ...prev,
              values: nextValues,
              alerts: ObjectUtils.isEmpty(alertsPatch)
                ? alerts
                : {
                    ...alerts,
                    ...alertsPatch,
                  },
            };
          });
        },

        validateField: (fieldToValidate) => {
          const config = fieldItemConfigsValue.get()[fieldToValidate];
          if (!config?.validate) {
            return undefined;
          }
          const { validate, fieldPath, field } = config;
          const value = ObjectUtils.prop(values, fieldPath);
          return map(validate(value, values), (alert) => {
            setState((prev) => {
              // if the value of the field changed, no alert change should be commited
              // this strategy however may still lead to bugs caused by other field changing
              // in values, on which the result of alert may relay, take extra care for such
              // situation, and avoid relaying the async alert's result on field others than
              // the current field value
              if (!Object.is(ObjectUtils.prop(prev.values, fieldPath), value))
                return prev;
              const nextAlerts = ObjectUtils.immutableSet(
                prev.alerts,
                [field],
                alert
              );
              return {
                ...prev,
                alerts: nextAlerts,
              };
            });
          });
        },

        validateAll: () => {
          const fieldItemConfigs = fieldItemConfigsValue.get();
          setState((prev) => ({ ...prev, alerts: {} }));
          const alertMutations: MaybePromise<boolean>[] = Object.values(
            fieldItemConfigs
          ).map(({ field, fieldPath, validate }) => {
            if (!validate) return true;
            const value = ObjectUtils.prop(values, fieldPath);
            return map(validate(value, values), (alert) => {
              if (alert) {
                setState((prev) => ({
                  ...prev,
                  alerts: ObjectUtils.immutableSet(prev.alerts, [field], alert),
                }));
                return false;
              }
              return true;
            });
          });

          const final = (results) => {
            return results.every(identity);
          };

          return alertMutations.some(isPromise)
            ? Promise.all(alertMutations).then(final)
            : final(alertMutations as boolean[]);
        },
      }),
      [values, setState]
    );

    useImperativeHandle(ref, () => methods, [methods]);

    // call on value change
    useEffect(() => {
      if (onChange) {
        onChange(values);
      }
    }, [values]);

    /**
     * context data for form-item register
     */
    const registerContext = useMemo(
      (): FieldRegisterContext => ({
        baseField: "",
        baseFieldPath: [],
        registerField: (config) => {
          const { field, fieldPath, initialValue } = config;

          fieldItemConfigsValue.set((prev) => ({
            ...prev,
            [field]: config,
          }));
          setState((prev) => ({
            ...prev,
            // if the value already exits, it won't be overrided by initialValue
            values: ObjectUtils.immutableSet(prev.values, fieldPath, (prev) =>
              prev == null ? initialValue : prev
            ),
            alerts: ObjectUtils.immutableSet(prev.alerts, [field], ""),
          }));
          return () => {
            fieldItemConfigsValue.set((prev) => {
              if (prev[field]?.cleanup) {
                // clean the field up
                setState((prev) => ({
                  ...prev,
                  values: ObjectUtils.immutableRemove(prev.values, fieldPath),
                  alerts: ObjectUtils.immutableRemove(prev.alerts, fieldPath),
                }));
              }
              // remove the config from the config dict
              return ObjectUtils.omit(prev, [field]);
            });
          };
        },

        updateFieldConfig: (field, configOverrides) => {
          fieldItemConfigsValue.set((prev) =>
            field in prev
              ? {
                  ...prev,
                  [field]: {
                    ...prev[field],
                    ...configOverrides,
                  },
                }
              : prev
          );
        },
      }),
      []
    );

    const formStateContext = useMemo(() => {
      return {
        ...state,
        ...methods,
        ...registerContext,
      };
    }, [state, methods, registerContext]);

    return (
      <form
        className={className}
        style={style}
        id={id}
        onSubmit={stop(
          prevent(() => {
            setState((prev) => ({ ...prev, loading: true }));
            map(methods.validateAll(), (validationResult) => {
              map(
                validationResult && onSubmit ? onSubmit(state.values) : null,
                () => {
                  setState((prev) => ({ ...prev, loading: false }));
                }
              );
            });
          })
        )}
        data-test-id="ui-form"
      >
        <formContext.Provider value={formStateContext}>
          {children}
        </formContext.Provider>
      </form>
    );
  }
);

/**
 * if you want your form state to be held outside and controlled
 * use ControlledForm to wrap the Form
 */
export interface ControlledFormProviderProps<T> {
  value: FormState<T>;
  onChange: Dispatch<SetStateAction<FormState<T>>>;
}

export function ControlledFormProvider<T>({
  value,
  onChange,
  children,
}: PropsWithChildren<ControlledFormProviderProps<T>>) {
  const tuple = useMemo(
    () =>
      [value, onChange] as [
        FormState<T>,
        Dispatch<SetStateAction<FormState<T>>>
      ],
    [value, onChange]
  );

  return (
    <controlledFormContext.Provider value={tuple}>
      {children}
    </controlledFormContext.Provider>
  );
}

export function initFormState<T>(values: T): FormState<T> {
  return {
    values,
    alerts: {},
    loading: false,
  };
}

/**
 * with-form-state
 * helps to get the hold form state
 */
export interface WithFormProps<T> {
  children(formContextValue: FormContextValue<T>): ReactElement;
}
export function WithForm<T = any>({ children }: WithFormProps<T>) {
  const formContextValue = useContext(formContext);

  return children(formContextValue);
}
