import {
  CSSProperties,
  MutableRefObject,
  TouchEvent,
  useCallback,
  useMemo,
} from "react";
import { useSuperState , noop } from "@reversible/common";
import { DocumentController } from "@/util/dom";
import styles from "./use-gallery-swipe.module.less";

// consst
const ENTER_SWAPPING_DELTA_THRES = 8;
const ENTER_SWAPPING_ACC_THRES = 0.2;
const AUTO_SWAP_ACC_THRES = 0.2;

const percent = (v: number) => `${v * 100}%`;

enum Status {
  Idle, // not touching
  Judging, // judging whether should follow
  Following, // following touch movement
}

interface State {
  index: number;
  status: Status;
  clientWidth: number;
  startX: number;
  startY: number;
  currentX: number;
  currentY: number;
  stamp: number;
  acc: number;
  unlock(): void;
}

export const useGallerySwipe = (
  n: number,
  ref: MutableRefObject<HTMLDivElement>
) => {
  const [state, setState] = useSuperState<State>({
    index: 0,
    status: Status.Idle,
    clientWidth: 0,
    startX: 0,
    startY: 0,
    currentX: 0,
    currentY: 0,
    stamp: 0,
    acc: 0,
    unlock: noop,
  });

  const onTouchStart = useCallback((e: TouchEvent) => {
    const clientWidth = ref.current.clientWidth;
    const { clientX: startX, clientY: startY } = e.touches[0];
    setState({
      status: Status.Judging,
      clientWidth,
      startX,
      currentX: startX,
      startY,
      currentY: startY,
      stamp: Date.now(),
      acc: 0,
    });
  }, []);

  const onTouchMove = useCallback((e: TouchEvent) => {
    const { clientX: currentX, clientY: currentY } = e.touches[0];
    const stamp = Date.now();
    setState((prev) => {
      if (prev.status === Status.Idle) return prev;
      const deltaX = currentX - prev.startX;
      const acc = (currentX - prev.currentX) / (stamp - prev.stamp);
      if (prev.status === Status.Judging) {
        // TODO: for first or last image, this should be moving
        if (
          Math.abs(deltaX) > ENTER_SWAPPING_DELTA_THRES ||
          Math.abs(acc) > ENTER_SWAPPING_ACC_THRES
        ) {
          return {
            currentX,
            currentY,
            stamp,
            acc,
            status: Status.Following,
            unlock: DocumentController.lockScroll(),
          };
        }
        const deltaY = currentY - prev.startY;
        if (Math.abs(deltaY) > ENTER_SWAPPING_DELTA_THRES) {
          return {
            status: Status.Idle,
          };
        }
      }
      return {
        currentX,
        currentY,
        stamp,
        acc,
      };
    });
  }, []);

  const getOffset = useCallback(
    (
      { index, startX, currentX, clientWidth, status, acc }: State,
      touchEnd = false
    ) => {
      if (!n) return 0;
      if (status !== Status.Following) return index;
      const raw = index - (currentX - startX) / clientWidth;
      const continuous = Math.max(0, Math.min(raw, n - 1));
      if (touchEnd) {
        if (acc < -AUTO_SWAP_ACC_THRES && index < n - 1) {
          return index + 1;
        }
        if (acc > AUTO_SWAP_ACC_THRES && index > 0) {
          return index - 1;
        }
        return Math.round(continuous);
      }
      return continuous;
    },
    [n]
  );

  const onTouchEnd = useCallback(() => {
    setState((prev) => {
      if (prev.status === Status.Idle) {
        return prev;
      }
      if (prev.status === Status.Judging) {
        return {
          status: Status.Idle,
        };
      }
      prev.unlock();
      return {
        status: Status.Idle,
        index: getOffset(prev, true),
        unlock: noop,
      };
    });
  }, [getOffset]);

  const left = useMemo(() => {
    return -getOffset(state);
  }, [state, getOffset]);

  const containerStyle: CSSProperties = {
    position: "relative",
    height: percent(1),
    width: percent(1),
    transform: `translateX(${percent(left)})`,
    top: 0,
  };

  const { index, status } = state;

  const getBoxStyle = useMemo(() => {
    return (i: number): CSSProperties => ({
      position: "absolute",
      left: percent(i),
      top: 0,
      width: percent(1),
      height: percent(1),
    });
  }, [status, index]);

  return {
    index,
    onChangeIndex: (index: number) =>
      setState({
        index,
      }),
    containerStyle,
    getBoxStyle,
    containerClassName:
      status === Status.Following
        ? styles.container_following
        : styles.container_transition,
    touchListeners: {
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      onTouchCancel: onTouchEnd,
    },
  };
};
