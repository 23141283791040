import { FC, useEffect } from "react";
import { Condition, TimeGranularity } from "@reversible/schema";
import { UserPublicInfo } from "@/interface/account";
import { PriceHistoryData, PriceHistoryRecord } from "@/interface/product";
import "@/component/price-history-chart/entry";

const data: PriceHistoryData = {
  history: [
    ["2022-09-13T00:00:00.000Z", 430],
    ["2022-09-14T00:00:00.000Z", 430],
    ["2022-09-15T00:00:00.000Z", 430],
    ["2022-09-16T00:00:00.000Z", 430],
    ["2022-09-17T00:00:00.000Z", 450],
    ["2022-09-18T00:00:00.000Z", 435],
    ["2022-09-19T00:00:00.000Z", 400],
    ["2022-09-20T00:00:00.000Z", 395],
    ["2022-09-21T00:00:00.000Z", 420],
    ["2022-09-22T00:00:00.000Z", 430],
  ].map(
    ([date, price]: [string, number]): PriceHistoryRecord => ({
      date,
      price,
      offerId: 100,
      currency: "USD",
      currencySymbol: "$",
      condition: Condition.BrandNew,
      conditionDisplay: "Brand New",
      size: "XL",
      imgUrl: "",
      userInfo: {
        avatarUrl: "",
        username: "xxxx",
        isVerified: true,
      } as UserPublicInfo,
    })
  ),
  lowest: null,
  highest: null,
  retailPrice: 436,
  retailPriceCurrency: "USD",
  retailPriceCurrencySymbol: "$",
  granularity: TimeGranularity.Day,
};

export const Chart: FC = () => {
  useEffect(() => {
    window.postBridgeMessage({
      type: "set-price-history-data",
      payload: data,
    });
  }, []);

  return null;
};
