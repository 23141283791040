import {
  ObjectUtils,
  cache,
  combine,
  HttpMiddleware,
  httpOnion,
  jsonBody,
  mapRes,
  method,
  onResolve,
} from "@reversible/common";
import {
  ApiResponseCode,
  MARKETPLACE_SEARCH_SORT_KEYS,
  SPU_SEARCH_SORT_KEYS,
  SpuStatus,
  USER_SORT_KEYS,
} from "@/enum";
import { getBlobKey } from "@/util/blob";
import {
  authTokenCacher,
  messageTextInputsCacher,
  shoppingGenderCacher,
} from "@/util/cacher";
import * as t from "./interface";
import {
  adaptGetFavoredList,
  adaptMessage,
  adaptMessageList,
  adaptProductFilters,
  adaptSizeSource,
  adaptToOfferGroup,
  alertOnReject,
  assignOrderDetail,
  assignOrderList,
  authClearTokenWhenUnauth,
  clearPostListIsSuggested,
  commonPostMiddlewares,
  commonPreMiddlewares,
  deDate,
  enDate,
  enDateCommentList,
  enDatePost,
  enDatePostList,
  loginWhenUnauth,
  mapResolvedData,
  preSendMessage,
  trackProductFlow,
  trackSearchSpu,
  uniqueList,
  uploadImages,
  urlMiddleware as url,
  withAccountRegion,
} from "./middlewares";

// tools
const { omit, immutableSet } = ObjectUtils;

// middlewares

const f = (...mws: HttpMiddleware[]) =>
  httpOnion(...commonPreMiddlewares, ...mws, ...commonPostMiddlewares).fork();

// declarations
const enDateAccount = enDate({
  birthday: "local-date",
  createTime: "iso",
  registerTime: "iso",
  updateTime: "iso",
});
export const getUserInfo: t.GetUserInfo = f(
  authClearTokenWhenUnauth(),
  enDateAccount,
  url("/user/info")
);

export const updateUserInfo: t.UpdateUserInfo = f(
  deDate({
    birthday: "local-date", // date is treated as a static string, won't change according to locale
  }),
  enDate({
    createTime: "iso",
  }),
  enDateAccount,
  method("put"),
  url("/user/info"),
  uploadImages(["avatar"], 600),
  jsonBody(),
  loginWhenUnauth
);

export const getAccountPreferences: t.GetAccountPreferences = f(
  url("/user/preference"),
  loginWhenUnauth
);

export const updateAccountPreferences: t.UpdateAccountPreferences = f(
  method("put"),
  url("/user/preference"),
  jsonBody(),
  loginWhenUnauth
);

export const getGenderImages: t.GetGenderImages = f(url("/data/images/home"));

export const getBannerImage: t.GetBannerImage = f(
  url(({ gender }) => `/data/images/banner/${gender}`)
);

export const getDesigners: t.GetDesigners = f(url("/data/brand"));

export const getCategories: t.GetCategories = f(url("/data/category"));

export const getSizeOptions: t.GetSizeOptions = f(
  url("/data/size/option", true)
);

export const getRecommendSpuList: t.GetRecommendSpuList = f(
  url("/product/recommend", true),
  trackProductFlow("recommended")
);

export const getRecommendedNewInList: t.GetRecommendedNewInList = f(
  url("/product/new", true),
  trackProductFlow("new_in")
);

export const getRecommendedMarketplaceList: t.GetRecommendedMarketplaceList = f(
  url("/product/marketplace", true),
  adaptToOfferGroup(["data", "*"]),
  trackProductFlow("marketplace", true)
);

export const getSpuList: t.GetSpuList = f(
  url("/product/search", true),
  combine(),
  trackSearchSpu(false)
);

export const getSpuFiltersForKeyword: t.GetSpuFiltersForKeyword = f(
  url("/product/search/filter", true),
  combine(),
  adaptProductFilters(SPU_SEARCH_SORT_KEYS)
);

export const getSizes: t.GetSizes = f(
  url("/product/search/filter", {
    keyword: "",
  }),
  adaptSizeSource()
);

export const getMarketplaceList: t.GetMarketplaceList = f(
  url("/product/search/unclassified", ({ soldOnly, ...rest }) => ({
    ...rest,
    soldOnly: soldOnly && true,
  })),
  adaptToOfferGroup(["data", "*"]),
  trackSearchSpu(true)
);

export const getMarketplaceFiltersForKeyword: t.GetMarketplaceFiltersForKeyword =
  f(
    url("/product/search/unclassified/filter", true),
    combine(),
    adaptProductFilters(MARKETPLACE_SEARCH_SORT_KEYS)
  );

export const getSpuDetail: t.GetSpuDetail = f(
  url("/product/details", ({ spuId }) => ({ spuId })),
  enDate({
    "offers.*.offerTime": "iso",
    "offers.*.offerUpdateTime": "iso",
    "offers.*.offerSoldTime": "iso",
    "offers.*.couponInfo.*.expireTime": "iso",
    "offers.*.couponInfo.*.startTime": "iso",
  })
);

export const getOfferDetail: t.GetOfferDetail = f(
  url(({ offerId }) => `/offer/${offerId}`),
  combine(),
  enDate({
    offerTime: "iso",
    offerUpdateTime: "iso",
    offerSoldTime: "iso",
    "user.registerTime": "iso",
  })
);

export const getStoryImages: t.GetStoryImages = f(
  url(({ offerId }) => `/offer/${offerId}/story`)
);

export const login: t.Login = f(
  enDate({
    "user.createTime": "iso",
  }),
  method("post"),
  url("/user/login"),
  jsonBody(),
  onResolve(({ data: { jwt } }) => {
    authTokenCacher.set(jwt); // keep token
  })
);

export const loginWithGoogle: t.LoginWithGoogle = f(
  enDate({
    "user.createTime": "iso",
  }),
  url("/google/oauth", true),
  onResolve(({ data: { jwt } }) => {
    authTokenCacher.set(jwt); // keep token
  })
);

export const loginWithApple: t.LoginWithApple = f(
  method("post"),
  enDate({
    "user.createTime": "iso",
  }),
  jsonBody((params) => ({
    ...params,
    platform: "web",
  })),
  url("/apple/oauth"),
  onResolve(({ data: { jwt } }) => {
    authTokenCacher.set(jwt); // keep token
  })
);

export const logout: t.Logout = () => {
  authTokenCacher.clear();
  messageTextInputsCacher.clear();
};

export const updatePassword: t.UpdatePassword = f(
  alertOnReject(),
  method("post"),
  url("/user/password"),
  jsonBody(),
  loginWhenUnauth
);

export const register: t.Register = f(
  enDate({
    "user.createTime": "iso",
  }),
  method("post"),
  url("/user/register"),
  jsonBody(),
  onResolve(({ data: { jwt } }) => {
    authTokenCacher.set(jwt); // keep token
  })
);

export const createOffer: t.CreateOffer = f(
  alertOnReject(),
  method("post"),
  url("/offer"),
  uploadImages(["images", "*"]),
  uniqueList(["images"]),
  jsonBody(),
  loginWhenUnauth
);

export const updateOffer: t.UpdateOffer = f(
  alertOnReject(),
  method("put"),
  url(({ offerId }) => `/offer/${offerId}`),
  uploadImages(["data", "images", "*"]),
  uniqueList(["data", "images"]),
  jsonBody(({ data }) => data),
  loginWhenUnauth
);

// temporarily deprecated
export const addProduct: t.AddProduct = f(
  alertOnReject(),
  method("post"),
  url("/product"),
  uploadImages(["images", "*"]),
  uniqueList(["images"]),
  jsonBody(),
  loginWhenUnauth
);

export const classifyProductByImage: t.ClassifyProductByImage = f(
  alertOnReject(),
  cache({
    getKey: ({ params, headers }) => {
      const { imageUrl, ...rest } = params;
      return JSON.stringify({
        ...rest,
        imageUrl: getBlobKey(imageUrl),
        headers,
      });
    },
    maxCount: 1, // only caches the last one
  }),
  method("post"),
  url("/product/search/image"),
  uploadImages(["imageUrl"]),
  jsonBody(),
  mapRes((res) => {
    if ("resolve" in res) {
      return immutableSet(res, ["resolve", "data"], (raw) =>
        raw
          .filter(({ spuStatus }) => spuStatus === SpuStatus.Available)
          .slice(0, 5)
      );
    }
    return res;
  })
);

export const getFavoredListFilters: t.GetFavoredListFilters = f(
  url("/user/favorite/filter", true),
  loginWhenUnauth,
  combine(),
  adaptProductFilters([])
);

export const getFavoredList: t.GetFavoredList = f(
  url("/user/favorite", true),
  adaptGetFavoredList(),
  loginWhenUnauth
);

export const getUserOfferList: t.GetUserOfferList = f(url("/offer", true));

export const deleteOffer: t.DeleteOffer = f(
  alertOnReject(),
  method("delete"),
  url(({ offerId }) => `/offer/${offerId}`),
  loginWhenUnauth
);

export const getUserOrderList: t.GetUserOrderList = f(
  enDate({
    "data.*.updateTime": "iso",
    "data.*.createTime": "iso",
    "data.*.completeTime": "iso",
  }),
  assignOrderList,
  url("/order", true),
  loginWhenUnauth
);

export const getOrderDetail: t.GetOrderDetail = f(
  enDate({
    createTime: "iso",
    completeTime: "iso",
  }),
  assignOrderDetail,
  url(({ orderId }) => `/order/${orderId}`),
  loginWhenUnauth
);

export const trackOrder: t.TrackOrder = f(
  alertOnReject(),
  method("put"),
  url(({ orderId }) => `/order/${orderId}`),
  jsonBody((data) => omit(data, ["orderId"])),
  loginWhenUnauth
);

export const getSizeChart: t.GetSizeChart = f(
  cache({
    maxAge: 60 * 60,
  }),
  url("/data/size-chart", true)
);

export const getAddressList: t.GetAddressList = f(
  url("/user/address"),
  loginWhenUnauth
);

export const deleteAddress: t.DeleteAddress = f(
  alertOnReject(),
  method("delete"),
  url(({ addressId }) => `/user/address/${addressId}`),
  loginWhenUnauth
);

export const createAddress: t.CreateAddress = f(
  alertOnReject(),
  method("post"),
  url("/user/address"),
  jsonBody(),
  loginWhenUnauth
);

export const updateAddress: t.UpdateAddress = f(
  alertOnReject(),
  method("put"),
  url(({ addressId }) => `/user/address/${addressId}`),
  jsonBody((params) => omit(params, ["addressId"])),
  loginWhenUnauth
);

export const getFollowerList: t.GetFollowerList = f(
  url("/user/followers", true),
  loginWhenUnauth
);

export const getFollowingList: t.GetFollowingList = f(
  url("/user/following", true),
  loginWhenUnauth
);

export const getUserFollowerList: t.GetUserFollowerList = f(
  url(
    ({ userId }) => `/user/followers/${userId}`,
    (params) => omit(params, ["userId"])
  )
);

export const getUserFollowingList: t.GetUserFollowingList = f(
  url(
    ({ userId }) => `/user/following/${userId}`,
    (params) => omit(params, ["userId"])
  )
);

export const getSpecificUserInfo: t.GetSpecificUserInfo = f(
  url(({ userId }) => `/user/${userId}`)
);

export const getSpecificUserInfoByUsername: t.GetSpecificUserInfoByUsername = f(
  url(({ username }) => `/user/username/${username}`)
);

export const followUser: t.FollowUser = f(
  alertOnReject(),
  method("post"),
  url("/user/follow"),
  jsonBody(),
  loginWhenUnauth
);

export const unfollowUser: t.UnfollowUser = f(
  alertOnReject(),
  method("delete"),
  url(({ userId }) => `/user/follow/${userId}`),
  loginWhenUnauth
);

// messages
export const getConversationList: t.GetConversationList = f(
  // if conversationType == All, remove it from query
  url("/message", true),
  enDate({
    "data.*.updateTime": "iso",
    "data.*.lastMessage.msgTime": "iso",
    "data.*.lastMsgTime": "iso",
  }),
  loginWhenUnauth
);

export const getMessageListByConversationId: t.GetMessageListByConversationId =
  f(
    url(
      ({ conversationId }) => `/message/${conversationId}`,
      (params) => omit(params, ["conversationId"])
    ),
    enDate({
      "offerInfo.offerTime": "iso",
      "messages.*.msgTime": "iso",
    }),
    adaptMessageList,
    loginWhenUnauth
  );

export const getMessage: t.GetMessage = f(
  adaptMessage,
  url(
    ({ conversationId, messageId }) =>
      `/message/${conversationId}/msg/${messageId}`
  )
);

export const getConversationByOfferInfo: t.GetConversationByOfferInfo = f(
  url("/message/details", true),
  enDate({
    "offerInfo.offerTime": "iso",
    "messages.*.msgTime": "iso",
  }),
  adaptMessageList,
  loginWhenUnauth
);

export const createConversation: t.CreateConversation = f(
  method("post"),
  url("/message"),
  jsonBody(),
  loginWhenUnauth
);

export const sendMessage: t.SendMessage = f(
  ...preSendMessage,
  method("post"),
  url(({ conversationId }) => `/message/${conversationId}`),
  jsonBody((params) => omit(params, ["conversationId"])),
  loginWhenUnauth
);

export const updateMessage: t.UpdateMessage = f(
  method("put"),
  url(
    ({ conversationId, messageId }) =>
      `/message/${conversationId}/msg/${messageId}`
  ),
  jsonBody(({ action }) => ({ action }))
);

export const getSystemNotifications: t.GetSystemNotifications = f(
  url("/message/system", true),
  enDate({
    "messages.*.msgTime": "iso",
  }),
  loginWhenUnauth
);

export const updateSystemNotificationsReadStatus: t.UpdateSystemNotificationsReadStatus =
  f(method("put"), url("/message/system/status"), loginWhenUnauth);

export const getUnreadMessageCount: t.GetUnreadMessageCount = f(
  url("/message/unread"),
  loginWhenUnauth,
  mapResolvedData((data) => ({
    ...data,
    total: data.buy + data.sell + data.system,
  }))
);

export const getConversationLastUpdate: t.GetConversationLastUpdate = f(
  url("/message/poll/last-update", true),
  enDate({
    "*": "iso",
  }),
  loginWhenUnauth
);

export const addNewFavorite: t.AddNewFavorite = f(
  alertOnReject(),
  method("post"),
  url("/user/favorite"),
  jsonBody(),
  loginWhenUnauth
);

export const deleteFavorite: t.DeleteFavorite = f(
  alertOnReject(),
  method("delete"),
  url(({ favoriteId }) => `/user/favorite/${favoriteId}`),
  loginWhenUnauth
);

export const linkPaymentOAuth: t.LinkPaymentOauth = f(
  method("post"),
  url("/payment/paypal/oauth"),
  jsonBody(),
  loginWhenUnauth
);

export const approvePaymentOrder: t.ApprovePaymentOrder = f(
  method("post"),
  url("/payment/paypal/approve"),
  jsonBody(),
  loginWhenUnauth
);

export const getPaymentOrderInfo: t.GetPaymentOrderInfo = f(
  url(({ paypalOrderId }) => `/payment/paypal/${paypalOrderId}`),
  loginWhenUnauth
);

export const executePaymentOrder: t.ExecutePaymentOrder = f(
  method("post"),
  url("/payment/paypal/execute"),
  jsonBody(),
  loginWhenUnauth
);

export const getPaymentOrderId: t.GetPaymentOrderId = f(
  url("/payment/paypal", true),
  loginWhenUnauth
);

export const getUserOfferListFilters: t.GetUserOfferListFilters = f(
  url(({ userId }) => `/offer/user/${userId}/filter`),
  combine(),
  adaptProductFilters(USER_SORT_KEYS)
);

export const getOfferListOfUser: t.GetOfferListOfUser = f(
  url(
    ({ userId }) => `/offer/user/${userId}`,
    (params) => omit(params, ["userId"])
  ),
  adaptToOfferGroup(["data", "*"], ({ userId }) => ({ userId }))
);

export const checkDuplication: t.CheckDuplication = f(
  method("post"),
  jsonBody(),
  url("/user/exist"),
  mapRes((res) => {
    if ("reject" in res && res.reject?.code === ApiResponseCode.BadRequest) {
      const { reject, ...rest } = res;
      return {
        ...rest,
        resolve: {
          ...reject,
          data: false,
        },
      };
    }
    return immutableSet(res, ["resolve", "data"], true);
  })
);

export const forgetPassword: t.ForgetPassword = f(
  method("post"),
  jsonBody(),
  url("/user/password/forget")
);

export const resetPassword: t.ResetPassword = f(
  alertOnReject(),
  method("post"),
  jsonBody(),
  url("/user/password/reset")
);

export const addReview: t.AddReview = f(
  method("post"),
  url("/user/review"),
  jsonBody(),
  loginWhenUnauth
);

export const editReview: t.EditReview = f(
  method("put"),
  url(({ orderId }) => `/user/review/${orderId}`),
  jsonBody((params) => omit(params, ["orderId"])),
  loginWhenUnauth
);

export const getUserReviewList: t.GetUserReviewList = f(
  url("/user/review", true),
  enDate({
    "data.*.createTime": "iso",
  }),
  loginWhenUnauth
);

export const getPendingProducts: t.GetPendingSpuList = f(
  url("/product/user", true)
);

export const getCarriers: t.GetCarriers = f(cache(), url("/data/carrier"));

export const getSettingsInfo: t.GetSettingsInfo = f(
  cache(),
  url("/data/settings")
);

export const getShippingRegions: t.GetShippingRegions = f(
  withAccountRegion,
  cache({
    getKey: ({ params }) => params,
  }),
  url("/user/shipping/options")
);

export const getRegionByIp: t.GetRegionByIp = f(
  cache({
    getKey: () => "CONSTANT_IP_REGION_KEY",
  }),
  url("/user/address/ip")
);

export const getGooglePlacePredictions: t.GetGooglePlacePredictions = f(
  method("post"),
  url("/google/place/prediction"),
  jsonBody((params) => omit(params, ["countryCode"]))
);

export const getGooglePlaceDetail: t.GetGooglePlaceDetail = f(
  method("post"),
  url("/google/place/detail"),
  jsonBody()
);

export const addToWatchList: t.AddToWatchList = f(
  alertOnReject(),
  method("post"),
  url("/product/watch"),
  jsonBody()
);

export const getWatchList: t.GetWatchList = f(url("/product/watch", true));

export const deleteFromWatchList: t.DeleteFromWatchList = f(
  alertOnReject(),
  method("delete"),
  url(({ watchId }) => `/product/watch/${watchId}`)
);

// post related
export const createPost: t.CreatePost = f(
  method("post"),
  url("/lookbook/post"),
  jsonBody(),
  enDatePost()
);

export const deletePost: t.DeletePost = f(
  method("delete"),
  url(({ postId }) => `/lookbook/post/${postId}`)
);

export const updatePost: t.UpdatePost = f(
  method("put"),
  url(({ postId }) => `/lookbook/post/${postId}`),
  enDatePost()
);

export const getPostDetails: t.GetPostDetails = f(
  url(({ postId }) => `/lookbook/post/${postId}`),
  enDatePost()
);

export const getPostDiscoverList: t.GetPostDiscoverList = f(
  url("/lookbook/post/discover", (params) => ({
    ...params,
    gender: shoppingGenderCacher.get(),
  })),
  enDatePostList(),
  clearPostListIsSuggested()
);

export const getPostFollowingList: t.GetPostFollowingList = f(
  url("/lookbook/post/following", true),
  enDatePostList()
);

export const getPostListOfUser: t.GetPostListOfUser = f(
  url(
    ({ userId }) => `/lookbook/post/user/${userId}`,
    (query) => omit(query, ["userId"])
  ),
  enDatePostList(),
  clearPostListIsSuggested()
);

export const searchPosts: t.SearchPosts = f(
  url("/lookbook/search/post", true),
  enDatePostList(),
  clearPostListIsSuggested()
);

export const getSpuLookbook: t.GetSpuLookbook = f(
  url(
    ({ spuId }) => `/product/lookbook/${spuId}`,
    (query) => omit(query, ["spuId"])
  ),
  enDatePostList(),
  clearPostListIsSuggested()
);

export const likePost: t.LikePost = f(
  method("post"),
  url("/lookbook/like"),
  jsonBody()
);

export const unlikePost: t.UnlikePost = f(
  method("delete"),
  url(({ postId }) => `/lookbook/like/${postId}`)
);

export const getLikesOfPost: t.GetLikesOfPost = f(
  url(
    ({ postId }) => `/lookbook/like/post/${postId}`,
    (params) => omit(params, ["postId"])
  )
);

export const createComment: t.CreateComment = f(
  method("post"),
  url("/lookbook/comment"),
  jsonBody()
);

export const deleteComment: t.DeleteComment = f(
  method("delete"),
  url(({ commentId }) => `/lookbook/comment/${commentId}`)
);

export const getCommentsOfPost: t.GetCommentsOfPost = f(
  url(
    ({ postId }) => `/lookbook/comment/post/${postId}`,
    (params) => omit(params, ["postId"])
  ),
  enDateCommentList()
);

export const getRepliesOfComment: t.GetRepliesOfComment = f(
  url(
    ({ commentId }) => `/lookbook/comment/reply/${commentId}`,
    (params) => omit(params, ["commentId"])
  ),
  enDateCommentList()
);

export const getPostKeywords: t.GetPostKeywords = f(
  url("/lookbook/search/keyword", true)
);

export const searchSpusForPost: t.SearchSpusForPost = f(
  url("/lookbook/search/product", true)
);

export const searchTopics: t.SearchTopics = f(
  url("/lookbook/search/topic", true)
);

export const searchPostUsers: t.SearchPostUsers = f(
  url("/lookbook/search/user", true)
);

export const reportPost: t.ReportPost = f(
  method("post"),
  url("/lookbook/post/report"),
  jsonBody()
);

export const blockPost: t.BlockPost = f(
  method("post"),
  url("/lookbook/post/block"),
  jsonBody()
);

export const blockUser: t.BlockUser = f(
  alertOnReject(),
  method("post"),
  url("/user/block"),
  jsonBody()
);

export const unblockUser: t.UnblockUser = f(
  method("delete"),
  url(({ userId }) => `/user/block/${userId}`)
);

export const createProductUserFilter: t.CreateProductUserFilter = f(
  method("post"),
  url("/user/filter"),
  jsonBody(),
  alertOnReject()
);

export const deleteProductUserFilter: t.DeleteProductUserFilter = f(
  method("delete"),
  url(({ filterId }) => `/user/filter/${filterId}`)
);

export const getProductUserFilterData: t.GetProductUserFilterData = f(
  url(({ filterId }) => `/user/filter/${filterId}`)
);

export const getAllProductUserFilters: t.GetAllProductUserFilters = f(
  url("/user/filter")
);

export const updateProductUserFilter: t.UpdateProductUserFilter = f(
  method("put"),
  url(({ filterId }) => `/user/filter/${filterId}`),
  jsonBody(({ name }) => ({ name })),
  alertOnReject()
);

export const bumpUpListing: t.BumpUpListing = f(
  method("post"),
  url("/offer/bump"),
  jsonBody()
);

export const getPriceHistory: t.GetPriceHistory = f(
  url(
    ({ spuId }) => `/product/history/price/${spuId}`,
    (params) => omit(params, ["spuId"])
  )
);
