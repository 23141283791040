import dayjs, { Dayjs } from "dayjs";
import * as echarts from "echarts";
import { uniqueId } from "@reversible/common";
import { PUBLIC_URL } from "@/env";
import type { PriceHistoryData, PriceHistoryRecord } from "@/interface/product";

import "@/style/font/font.less";

import "./price-history-chart.less";

// mjs

export interface PriceHistoryChartConfig {
  container: HTMLElement;
  data: PriceHistoryData;
  colorMode?: ColorMode;
  onGotoOffer(priceHistoryRecord: PriceHistoryRecord): void;
}

export type ColorMode = "light" | "dark";

export interface PriceHistoryChart {
  blur(): void;
  destroy(): void;
  setMode(mode: "light" | "dark"): void;
}

interface ColorModeConfig {
  areaGradientStart: string;
  areaGradientEnd: string;
  background: string;
  main: string;
  majorLine: string;
  minorLine: string;
  splitLine: string;
  tooltipLine: string;
  avatarOutline: string;
}

const colorModeConfigs: Record<ColorMode, ColorModeConfig> = {
  dark: {
    areaGradientStart: "rgba(76, 76, 76, 60%)",
    areaGradientEnd: "rgba(76, 76, 76, 5%)",
    background: "#1c1c1c",
    main: "#ccc",
    majorLine: "#6f6f6f",
    minorLine: "#646464",
    splitLine: "#343434",
    tooltipLine: "#797979",
    avatarOutline: "#797979",
  },
  light: {
    areaGradientStart: "rgba(221, 221, 221, 60%)",
    areaGradientEnd: "rgba(221, 221, 221, 5%)",
    background: "#fff",
    main: "#202020",
    majorLine: "#aaa",
    minorLine: "#bbb",
    splitLine: "#f4f4f4",
    tooltipLine: "#999",
    avatarOutline: "#999",
  },
};

function createEchartsColorOption({
  areaGradientStart,
  areaGradientEnd,
  background,
  main,
  majorLine,
  minorLine,
  splitLine,
  tooltipLine,
}: ColorModeConfig): echarts.EChartsOption {
  const areaColor: echarts.Color = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: areaGradientStart,
      },
      {
        offset: 1,
        color: areaGradientEnd,
      },
    ],
  };

  return {
    color: main,
    yAxis: {
      splitLine: {
        lineStyle: {
          color: splitLine,
        },
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: tooltipLine,
        },
      },
      backgroundColor: background,
      borderColor: background,
      textStyle: {
        color: main,
      },
    },
    series: [
      {
        id: "history",
        lineStyle: {
          color: majorLine,
        },
        itemStyle: {
          color: majorLine,
        },
        markPoint: {
          itemStyle: {
            color: background,
            borderColor: majorLine,
          },
        },
        areaStyle: {
          color: areaColor,
        },
        emphasis: {
          itemStyle: {
            color: background,
            borderColor: main,
          },
        },
      },
      {
        id: "retailPrice",
        lineStyle: {
          color: minorLine,
        },
        markPoint: {
          itemStyle: {
            color: main,
          },
        },
      },
    ],
    textStyle: {
      color: main,
    },
    backgroundColor: background,
  };
}

function createGridOption(width: number): echarts.EChartsOption {
  if (width >= 1200) {
    return {
      grid: {
        left: "6%",
        right: "3%",
        top: "15%",
        bottom: "18%",
      },
    };
  }
  return {
    grid: {
      left: "10%",
      right: "6%",
      top: "15%",
      bottom: "18%",
    },
  };
}

const AXIS_FONT_SIZE = 10;

export const createPriceHistoryChart = ({
  container,
  data,
  colorMode = "light",
  onGotoOffer,
}: PriceHistoryChartConfig): PriceHistoryChart => {
  let colors: ColorModeConfig = colorModeConfigs.light;

  const history = data.history.map((item) =>
    item?.price != null
      ? {
          ...item,
          price: Math.ceil(item.price),
        }
      : item
  );
  const retailPrice = Math.ceil(data.retailPrice);

  const n = history.length;

  const availableHistory = history.filter(({ price }) => price != null);
  const availableN = availableHistory.length;

  // find something useful
  const { currency, currencySymbol } = availableHistory[0] || {
    currency: "",
    currencySymbol: "",
  };

  let min: PriceHistoryRecord;
  let max: PriceHistoryRecord;

  for (const record of history) {
    if (!max || record.price > max.price) {
      max = record;
    }
    if (!min || record.price < min.price) {
      min = record;
    }
  }
  const showMinMax = max?.price - min?.price > 0;

  const now = dayjs();
  const startOfToday = now.startOf("day");
  const startOfYear = now.startOf("year");
  function formatAxis(day: Dayjs) {
    if (startOfToday <= day) {
      return "Today"; // LATER: i18n
    }

    if (startOfYear <= day) {
      return day.format("MMM D");
    }

    return day.format("MMM D, YYYY");
  }

  const id = uniqueId();

  const TARGET_ATTR = "data-echarts-click-target";
  const INDEX_ATTR = "data-index";

  const clickCallback = (e: MouseEvent) => {
    const dom = e.target as Element;
    if (dom.hasAttribute(TARGET_ATTR) && dom.getAttribute(TARGET_ATTR) === id) {
      const index = Number(dom.getAttribute(INDEX_ATTR));
      const record = history[index];
      if (record) {
        onGotoOffer(record);
      }
    }
  };

  container.addEventListener("click", clickCallback);

  function tooltipFormatter([{ data }]: any) {
    if (!data?.record || data.price == null) return "";
    const { standardDate, record, index } = data as {
      record: PriceHistoryRecord;
      standardDate: string;
      index: number;
    };
    return `
      <h6 data-field="price" >
        ${currencySymbol}${record.price} ${currency}
        <span
          data-field="icon"
          style="font-size: 16px"
        >
          <svg
            ${TARGET_ATTR}="${id}"
            ${INDEX_ATTR}="${index}"
            width="1em"
            height="1em"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <g fillRule="evenodd" fill="currentColor">
              <use href="${PUBLIC_URL}/icon.svg#goto" >
            </g>
          </svg>
        </span>
      </h6>
      <div data-field="user" >
        <img data-field="avatar" style="box-shadow: 0 0 0 .5px ${
          colors.avatarOutline
        }" src="${record.userInfo.avatarUrl}" />
        <span data-field="username" >${record.userInfo.username}</span>
        ${
          record.userInfo.isVerified
            ? `
          <span data-field="verified" style="font-size: 12px" >
          <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fillRule="evenodd" fill="currentColor">
              <use href="${PUBLIC_URL}/icon.svg#check-circle" >
            </g>
          </svg>
        `
            : ""
        }
      </div>
      <div data-field="size" >
        Size: ${record.size}
      </div>
      <div data-field="time" >
        ${standardDate}
      </div>
    `;
  }

  const echartOption: echarts.EChartsOption = {
    xAxis: {
      type: "category",
      boundaryGap: false,
      axisLabel: {
        fontSize: AXIS_FONT_SIZE,
        margin: 16,
        align: "center",
        interval: Math.floor(history.length / 4),
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      scale: true,
      splitNumber: 3,
      axisLabel: {
        fontSize: AXIS_FONT_SIZE,
        formatter: `${currencySymbol}{value}`, // eslint-disable-line
      },
    },
    tooltip: {
      show: true,
      trigger: "axis",
      triggerOn: "mousemove",
      axisPointer: {
        type: "line",
        axis: "x",
        lineStyle: {
          type: "solid",
        },
        z: 1,
      },
      className: "chart-tooltip",
      formatter: tooltipFormatter,
      confine: true,
      enterable: true,
    },
    series: [
      {
        id: "history",
        type: "line",
        lineStyle: {
          width: 1.6,
        },
        itemStyle: {
          opacity: availableN === 1 ? 1 : 0,
        },
        smooth: true,
        markPoint: {
          symbol: "circle",
          symbolSize: 6,
          label: {
            fontWeight: 700,
            formatter: `${currencySymbol}{c}`,
          },
          data: showMinMax
            ? [
                {
                  name: "max",
                  type: "max",
                  label: {
                    position: "top",
                    offset: [0, 2],
                  },
                },
                {
                  name: "min",
                  type: "min",
                  label: {
                    position: "bottom",
                    offset: [0, -2],
                  },
                },
              ]
            : [],
        },
        emphasis: {
          scale: 2.4 as any, // typing error
          areaStyle: {
            color: "inherit",
          },
          itemStyle: {
            opacity: 1,
            borderWidth: 3,
          },
        },
      },
      {
        id: "retailPrice",
        type: "line",
        lineStyle: {
          type: [2.4, 3],
          dashOffset: 3,
          width: 1,
        },
        itemStyle: {
          opacity: 0,
        },
        endLabel: {
          show: true,
          distance: 0,
          offset: [10, -6],
          align: "right",
          verticalAlign: "bottom",
          formatter: `Retailer Price ${currencySymbol}${retailPrice}`,
          fontWeight: 700,
          valueAnimation: false,
        },
        markPoint: {
          symbol: "triangle",
          symbolSize: 6,
          symbolRotate: 90,
          symbolOffset: ["100%", 0],
          label: {
            show: false,
          },
          data: [
            {
              name: "end",
              type: "max",
            },
          ],
        },
        animation: false,
      },
    ],
    dataset: {
      dimensions: ["xAxisDate", "price", "retailPrice"],
      source: history.map((record, i): any => {
        const day = dayjs(record.date);
        return {
          record,
          index: i,
          xAxisDate: formatAxis(day),
          standardDate: day.format("YYYY-MM-DD"),
          price: record.price,
          retailPrice: retailPrice + (i === n - 1 ? 0.01 : 0), // HACK:
        };
      }),
    },
    textStyle: {
      fontSize: AXIS_FONT_SIZE,
      fontFamily: "main",
      fontWeight: "normal",
    },
    animationDuration: 600,
  };

  const chart = echarts.init(container);

  function setMode(colorMode: ColorMode) {
    colors = colorModeConfigs[colorMode];
    chart.setOption(createEchartsColorOption(colors));
  }

  function resizeOption() {
    chart.setOption(createGridOption(window.innerWidth));
  }

  chart.setOption(echartOption);
  setMode(colorMode);
  resizeOption();

  const resize = () => {
    resizeOption();
    chart.resize();
  };

  window.addEventListener("resize", resize);

  return {
    blur: () => {
      chart.dispatchAction({
        type: "hideTip",
      });
      chart.dispatchAction({
        type: "downplay",
      });
    },
    destroy: () => {
      window.removeEventListener("resize", resize);
      container.removeEventListener("click", clickCallback);
      chart.dispose();
    },
    setMode,
  };
};
