import React, {
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
  FC,
} from "react";
import { createPortal } from "react-dom";
import { ScheduleUtils } from "@reversible/common";
import styles from "./toast.module.less";
import { useLayerNode } from "../hooks/use-layer";
import { Icon, IconType } from "../icon";
import { VisibleTransition } from "../transition";

const ANIMATION_TIME = 400;

interface ToastInfo {
  id: number;
  content: ReactNode;
  duration: number; // time in seconds
  visible: boolean;
}

let toastQueueSetterResolvable =
  ScheduleUtils.createResolvable<Dispatch<SetStateAction<ToastInfo[]>>>();

export const ToastTrack: FC = () => {
  const [toastQueue, setToastQueueInner] = useState<ToastInfo[]>([]);

  useEffect(() => {
    toastQueueSetterResolvable.resolve(setToastQueueInner);
    return () => {
      toastQueueSetterResolvable =
        ScheduleUtils.createResolvable<Dispatch<SetStateAction<ToastInfo[]>>>();
    };
  }, []);

  const mountNode = useLayerNode();

  return createPortal(
    <div id="toast-track" className={styles.toast_track}>
      {toastQueue.map(({ content, id, visible }) => (
        <VisibleTransition
          enterTime={ANIMATION_TIME}
          visible={visible}
          className={styles.toast_row}
          key={id}
        >
          <div className={styles.toast}>{content}</div>
        </VisibleTransition>
      ))}
    </div>,
    mountNode
  );
};

export const Toast = (() => {
  let id = 0;

  const ToastMessage = async (
    content: ReactNode,
    duration = 1
  ): Promise<void> => {
    const setToastQueue = await toastQueueSetterResolvable.promise;

    const toastId = id++;
    const toastInfo: ToastInfo = {
      id: toastId,
      content,
      duration,
      visible: true,
    };

    setToastQueue((prev) => [...prev, toastInfo]);

    await ScheduleUtils.timeout(duration * 1000);

    setToastQueue((prev) =>
      prev.map((item) =>
        item.id === toastId
          ? {
              ...item,
              visible: false,
            }
          : item
      )
    );

    await ScheduleUtils.timeout(ANIMATION_TIME);

    setToastQueue((prev) => prev.filter(({ id }) => id !== toastId));
  };

  // short cuts;
  const createIconShortCut =
    (iconType: IconType, defaultDuration: number) =>
    (content: ReactNode, durationInSeconds: number = defaultDuration) =>
      ToastMessage(
        <div className={styles.toast_with_icon}>
          <Icon type={iconType} className={styles.toast_icon} />
          <div className={styles.toast_with_icon_content}>{content}</div>
        </div>,
        durationInSeconds
      );

  ToastMessage.error = createIconShortCut("close-circle", 3);
  ToastMessage.info = createIconShortCut("exclamation-circle", 3);
  ToastMessage.warn = createIconShortCut("exclamation-circle", 3);
  ToastMessage.success = createIconShortCut("check-circle", 1.5);

  return ToastMessage;
})();
