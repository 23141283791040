import mixpanel from "mixpanel-browser";
import { connectFlowEffects, noop, ScheduleUtils } from "@reversible/common";
import { DEPLOY_ENV, MIXPANEL_PROJECT_TOKEN } from "@/env";
import { AccountInfo } from "@/interface/account";

function getQueryParam(url: string, param: string) {
  const results = new RegExp(`[\?&]${param}=([^&#]*)`).exec(url);
  return results
    ? decodeURIComponent(results?.[1] || "").replace(/\W/gi, " ")
    : "";
}

export type MixpanelAction =
  | {
      type: "init"; // user status
    }
  | {
      type: "identify";
      account: null | AccountInfo;
    }
  | {
      type: "increment";
      name: string;
      count: number;
    }
  | {
      type: "track";
      name: string;
      props: Record<string, any>;
      id: string;
      delay?: number;
    }
  | {
      type: "cancel";
      id: string;
    };

const { dispatchAction } = connectFlowEffects<void, MixpanelAction>(
  {
    set: noop,
    get: () => undefined,
    call: (f, ...params) => f(...params),
  },
  function* ({ wait, call, cancel }, action) {
    switch (action.type) {
      case "init": {
        mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
          debug: DEPLOY_ENV !== "prod",
        });
        // super properties
        mixpanel.register({
          platform_type: "web",
        });
        // utm
        const utmKeywords =
          "utm_source utm_medium utm_campaign utm_content utm_term".split(" ");
        const params: Record<string, string> = {};
        const firstParams: Record<string, string> = {};
        for (const key of utmKeywords) {
          const value = getQueryParam(document.URL, key);
          if (value) {
            params[`${key} [last touch]`] = value;
            firstParams[`${key} [first touch]`] = value;
          }
        }
        mixpanel.people.set(params);
        mixpanel.people.set_once(firstParams);
        mixpanel.register(params);
        yield call(ScheduleUtils.timeout, 5000); // wait at most 5 seconds to be cancelled by other events
        break;
      }
      case "identify": {
        const { account } = action;
        if (account) {
          mixpanel.identify(String(account.id));
        } else {
          mixpanel.reset();
        }
        yield cancel(({ type }) => type === "init");
        break;
      }
      case "track": {
        yield wait(({ type }) => type === "init");
        const { name, props, delay } = action;
        if (delay) {
          yield call(ScheduleUtils.timeout, delay);
        }
        mixpanel.track(name, props);
        break;
      }
      case "cancel": {
        yield cancel((item) => item.type === "track" && action.id === item.id);
        break;
      }
      case "increment": {
        yield wait(({ type }) => type === "init");
        const { name, count } = action;
        mixpanel.people.increment(name, count);
        break;
      }
      default:
        break;
    }
  }
);

const getDistinctId = (): string => mixpanel.get_distinct_id() || "";

export { dispatchAction, getDistinctId };
