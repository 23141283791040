import { useCallback, useEffect, useRef } from "react";
import { useSuperState } from "@reversible/common";
import { useOnClickOutside } from "@/util/dom";

export const useExpandable = ({
  targetFocusable = false,
  onBlur,
}: {
  targetFocusable?: boolean;
  onBlur?(): void;
} = {}) => {
  const [{ expand, focus }, setExpandingState] = useSuperState({
    expand: false,
    focus: false,
  });

  const onClickOutside = useCallback(() => {
    if (focus) {
      if (onBlur) {
        onBlur();
      }
      setExpandingState({ focus: false, expand: false });
    }
  }, [focus, onBlur]);

  // callBlurOnOtherElements won't be mutated
  const ref = useRef<HTMLDivElement>();
  const callBlurOnOtherElements = useOnClickOutside(onClickOutside, ref);

  const toggleExpand = useCallback(() => {
    setExpandingState((prev) => {
      if (!prev.expand) {
        return {
          expand: true,
          focus: true,
        };
      }
      return {
        expand: !prev.expand,
        focus: true,
      };
    });
  }, [focus]);

  const focusImperatively = useCallback(() => {
    setExpandingState((prev) => (prev.focus ? prev : { ...prev, focus: true }));
    callBlurOnOtherElements();
  }, []);

  useEffect(() => {
    if (targetFocusable) {
      const target = ref.current;
      target.tabIndex = 0;
      const onFocus = () => {
        setExpandingState((prev) =>
          prev.focus ? prev : { ...prev, focus: true }
        );
        callBlurOnOtherElements();
      };
      target.addEventListener("focus", onFocus);
      return () => {
        target.removeEventListener("focus", onFocus);
      };
    }
    return undefined;
  }, []);

  return {
    ref,
    expand,
    focus,
    toggleExpand,
    focusImperatively,
  };
};
