import React, {
  FC,
  HTMLAttributes,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { uniqueId } from "@reversible/common";
import { StyledComponentProps } from "@/interface/base";
import styles from "./input.module.less";
import { useFormFieldContext } from "../form";
import { InputWrapper } from "../input-box";

interface IntegerInputProps
  extends StyledComponentProps,
    Omit<HTMLAttributes<HTMLInputElement>, "onChange"> {
  placeholder?: string;
  value?: number;
  onChange?(nextValue: number): void;
  onFocus?(): void;
  onBlur?(): void;
  prefix?: string;
  disabled?: boolean;
  id?: string;
  autoFillZero?: boolean;
}

export const IntegerInput: FC<IntegerInputProps> = memo(
  ({
    className = "",
    style,
    placeholder = "",
    prefix = "",
    value: controlledValue,
    onChange: controlledOnChange,
    onFocus: onFocusCallback,
    onBlur: onBlurCallback,
    autoFillZero = false,
    disabled = false,
    id,
    ...rest
  }) => {
    const { field, value, onChange, onValidate } = useFormFieldContext(
      controlledValue,
      controlledOnChange
    );

    const [focused, setFocused] = useState(false);

    const onFocus = () => {
      setFocused(true);
      if (onFocusCallback) {
        onFocusCallback();
      }
    };
    const onBlur = () => {
      setFocused(false);
      onValidate();
      if (onBlurCallback) {
        onBlurCallback();
      }
    };

    const strValue = useMemo(() => {
      if (value === undefined) return "";
      if (value === 0 && autoFillZero && focused) return "";
      return String(value);
    }, [focused, value, autoFillZero]);

    const onChangeStr = useCallback(
      (str: string) => {
        if (str === "" && !autoFillZero) {
          onChange(undefined);
        } else {
          const num = Number(str);
          onChange(Number.isNaN(num) ? undefined : Math.floor(Math.abs(num)));
        }
      },
      [autoFillZero, onChange]
    );

    const inputId = useMemo(() => id || field || uniqueId(), []);

    return (
      <InputWrapper disabled={disabled} className={className} style={style}>
        {prefix ? (
          <label htmlFor={inputId} className={styles.prefix}>
            {prefix}
          </label>
        ) : null}
        <input
          id={inputId}
          disabled={disabled}
          type="number"
          value={strValue}
          step="1"
          min="0"
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={(e) => onChangeStr(e.target.value)}
          className={styles.input}
          {...rest}
        />
      </InputWrapper>
    );
  }
);
