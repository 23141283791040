import React, {
  FC,
  HTMLAttributes,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { uniqueId } from "@reversible/common";
import { StyledComponentProps } from "@/interface/base";
import styles from "./input.module.less";
import { useFormFieldContext } from "../form";
import { Icon, IconType } from "../icon";
import { InputWrapper } from "../input-box";

interface InputProps
  extends StyledComponentProps,
    Omit<HTMLAttributes<HTMLInputElement>, "onChange"> {
  placeholder?: string;
  value?: string;
  allowClear?: boolean;
  password?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  inputMode?: React.HTMLAttributes<HTMLInputElement>["inputMode"];
  icon?: IconType;
  onChange?(nextValue: string): void;
  onFocus?(): void;
  onBlur?(): void;
}

export const Input: FC<InputProps> = memo(
  ({
    className = "",
    style,
    placeholder = "",
    password = false,
    value: controlledValue,
    onChange: controlledOnChange,
    onFocus: onFocusCallback,
    onBlur: onBlurCallback,
    disabled = false,
    allowClear = true,
    autoComplete = "off",
    inputMode = "text",
    icon,
    id,
    maxLength,
    ...rest
  }) => {
    const [showPassword, setShowPassword] = useState(false);

    const { field, value, onChange, onValidate } = useFormFieldContext(
      controlledValue,
      controlledOnChange
    );

    const onBlur = useCallback(() => {
      onValidate();
      if (onBlurCallback) {
        onBlurCallback();
      }
    }, [onValidate, onBlurCallback]);

    const inputRef = useRef<HTMLInputElement>();

    const inputId = useMemo(() => id || field || uniqueId(), []);

    return (
      <InputWrapper disabled={disabled} className={className} style={style}>
        {icon ? (
          <label htmlFor={inputId} className={styles.prefix_icon}>
            <Icon type={icon} />
          </label>
        ) : null}
        {password ? (
          <label
            htmlFor={inputId}
            className={styles.suffix_icon}
            onClick={() => setShowPassword((v) => !v)}
          >
            <Icon type={showPassword ? "eye-on" : "eye-off"} />
          </label>
        ) : value && allowClear ? (
          <label
            htmlFor={inputId}
            className={styles.suffix_icon}
            onClick={() => {
              onChange("");
              inputRef.current.focus();
            }}
          >
            <Icon type="close" />
          </label>
        ) : null}

        <input
          id={inputId}
          ref={inputRef}
          inputMode={inputMode}
          autoComplete={autoComplete}
          disabled={disabled}
          type={password && !showPassword ? "password" : "text"}
          value={value}
          onFocus={onFocusCallback}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          className={styles.input}
          maxLength={maxLength}
          {...rest}
        />
      </InputWrapper>
    );
  }
);
