import React, {
  forwardRef,
  HTMLAttributes,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
} from "react";
import classNames from "classnames";
import { noop } from "@reversible/common";
import { EmbeddedComponentProps, StyledComponentProps } from "@/interface/base";
import styles from "./input-box.module.less";
import { Icon, IconType } from "../icon";

export interface InputBoxProps extends StyledComponentProps {
  focus: boolean;
  icon?: IconType;
  iconSpin?: boolean;
  hoverIcon?: IconType;
  disabled?: boolean;
  onClick(): void;
  onClickIcon?(e: MouseEvent): void;
  onClickHoverIcon?(e: MouseEvent): void;
}

export const InputBox = forwardRef<
  HTMLDivElement,
  PropsWithChildren<InputBoxProps>
>(
  (
    {
      focus,
      iconSpin = false,
      className = "",
      disabled = false,
      style,
      onClick,
      children,
      icon,
      onClickIcon,
      hoverIcon,
      onClickHoverIcon,
    },
    ref
  ) => {
    return (
      <div
        className={classNames(
          styles.input_box,
          {
            [styles.input_box_focused]: !disabled && focus,
            [styles.input_box_disabled]: disabled,
          },
          className
        )}
        onClick={disabled ? noop : onClick}
        style={style}
        ref={ref}
      >
        {children}
        {icon ? (
          <a
            className={classNames(styles.icon_link, {
              [styles.icon_link_interactable]: onClickIcon || onClickHoverIcon,
            })}
          >
            <Icon
              className={classNames(styles.icon, {
                [styles.unhover_icon]: hoverIcon,
              })}
              onClick={onClickIcon}
              type={icon}
              spin={iconSpin}
            />
            {hoverIcon ? (
              <Icon
                className={classNames(styles.icon, styles.hover_icon)}
                onClick={onClickHoverIcon}
                type={hoverIcon}
              />
            ) : null}
          </a>
        ) : null}
      </div>
    );
  }
);

// a new version of input box
// in which the focusing state is totally managed by the inner focusable component
export interface InputWrapperProps<T extends HTMLElement>
  extends HTMLAttributes<T>,
    EmbeddedComponentProps {
  disabled?: boolean;
  render?: (props: HTMLAttributes<T> & EmbeddedComponentProps) => ReactElement;
}

export function InputWrapper<T extends HTMLElement = HTMLDivElement>({
  className,
  style,
  disabled,
  render,
  children,
  ...rest
}: InputWrapperProps<T>) {
  const props = {
    children,
    className: classNames(
      styles.input_wrapper,
      {
        [styles.input_wrapper_disabled]: disabled,
      },
      className
    ),
    style,
    ...rest,
  };
  return render ? render(props) : <div {...(props as any)} />;
}
