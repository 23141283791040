import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { StyledComponentProps } from "@/interface/base";
import styles from "./dots.module.less";

export interface DotsProps extends StyledComponentProps {
  value: number;
  count: number;
  onChange?(index: number): void;
}

const INVISIBLE_THRES = 3;
export const Dots: FC<DotsProps> = ({
  count,
  value,
  onChange,
  className,
  style,
}) => {
  const [sequence, minStop, maxStop] = useMemo(
    () =>
      [
        Array(count)
          .fill(null)
          .map((_, index) => index),
        INVISIBLE_THRES,
        count - 1 - INVISIBLE_THRES,
      ] as const,
    [count]
  );

  return count > 1 ? (
    <ol className={classNames(styles.dots, className)} style={style}>
      {sequence.map((index) => {
        const isSelected = value === index;
        const onClick = isSelected
          ? undefined
          : onChange
          ? () => onChange(index)
          : undefined;

        // style calculating
        const distance =
          value < minStop
            ? index < minStop
              ? 0
              : index - minStop
            : value > maxStop
            ? index > maxStop
              ? 0
              : maxStop - index
            : Math.abs(value - index);
        const isSmall = distance === INVISIBLE_THRES - 1;
        const isSmaller = distance === INVISIBLE_THRES;
        const isInvisible = distance > INVISIBLE_THRES;
        return (
          <li
            key={index}
            onClick={onClick}
            className={classNames(styles.dot, {
              [styles.dot_interactable]: onChange,
              [styles.dot_active]: isSelected,
              [styles.dot_small]: isSmall,
              [styles.dot_smaller]: isSmaller,
              [styles.dot_invisible]: isInvisible,
            })}
          />
        );
      })}
    </ol>
  ) : null;
};
