import React, { CSSProperties, forwardRef, MouseEvent, ReactNode } from "react";
import { createPortal } from "react-dom";
import classnames from "classnames";
import { StyledComponentProps } from "@/interface/base";
import styles from "./transition.module.less";
import { useLayerNode } from "../hooks/use-layer";

export interface VisibleTransitionProps extends StyledComponentProps {
  visible: boolean;
  enterTime?: number; // ms
  delayTime?: number; // ms
  children?: ReactNode;
  preserveChildren?: boolean;
  onClick?(e: MouseEvent): void;
}

export const VisibleTransition = forwardRef<
  HTMLDivElement,
  VisibleTransitionProps
>(
  (
    {
      visible,
      children = null,
      enterTime = 200,
      delayTime,
      className,
      style,
      onClick,
      preserveChildren = false,
    },
    ref
  ) => {
    const visibilityStyle: CSSProperties = visible
      ? {
          transitionDelay: `${delayTime || 1}ms`, // must be a delay time, otherwise the transition won't work
          transitionDuration: `${enterTime}ms`,
        }
      : {};

    return (
      <div
        className={classnames(
          styles[visible ? "visible" : "invisible"],
          className
        )}
        style={{
          ...visibilityStyle,
          ...(style || {}),
        }}
        onClick={onClick}
        ref={ref}
      >
        {preserveChildren || visible ? children : null}
      </div>
    );
  }
);

export const PortalVisibleTransition = forwardRef<
  HTMLDivElement,
  VisibleTransitionProps
>((props, ref) => {
  const layerNode = useLayerNode();

  return createPortal(<VisibleTransition {...props} ref={ref} />, layerNode);
});

export interface MaybePortalVisibleTransitionProps
  extends VisibleTransitionProps {
  portal: boolean;
}

export const MaybePortalVisibleTransition = forwardRef<
  HTMLDivElement,
  MaybePortalVisibleTransitionProps
>(({ portal, ...props }, ref) => {
  return portal ? (
    <PortalVisibleTransition {...props} ref={ref} />
  ) : (
    <VisibleTransition {...props} ref={ref} />
  );
});
