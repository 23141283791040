/**
 * account preference atore
 * different logic for login and none-login statuses
 */
import { useCallback, useEffect, useState } from "react";
import { useValue } from "@reversible/common";
import { DEFAULT_PREFERENCES, PREFERENCE_STORE_KEY } from "@/const";
import { AuthStatus } from "@/enum";
import { AccountPreferences } from "@/interface/account";
import * as httpService from "@/service";
import { preferenceCacher } from "@/util/cacher";
import { useAccount } from "./use-account";
import { createModel } from ".";

type Subscriber = (data: AccountPreferences) => void;

interface PreferencesModel {
  data: AccountPreferences;
  set(overrides: Partial<AccountPreferences>): void;
  notify(subscriber: Subscriber): void;
  destroy(): void;
}

class LocalPreferencesModel implements PreferencesModel {
  public data: AccountPreferences;

  private alive = true;

  private subscriber: Subscriber = null;

  constructor() {
    const data = (() => {
      const cachedPreference = preferenceCacher.get();
      if (cachedPreference) {
        return cachedPreference; // use cache as initial value
      }
      return DEFAULT_PREFERENCES;
    })();
    this.data = data;
  }

  set(overrides: Partial<AccountPreferences>) {
    if (!this.alive) return;
    const data = {
      ...this.data,
      ...overrides,
    };
    this.data = data;
    if (this.subscriber) {
      this.subscriber(data);
    }
  }

  notify(subscriber: Subscriber) {
    this.subscriber = subscriber;
  }

  destroy() {
    this.alive = false;
  }
}

class RemotePreferencesModel implements PreferencesModel {
  private syncedData: AccountPreferences = null;

  public data: AccountPreferences = null;

  private alive = true;

  private subscriber: Subscriber = null;

  constructor() {
    Promise.resolve(httpService.getAccountPreferences()).then(
      ({ data }) => {
        this.data = data;
        this.syncedData = data;
        if (this.subscriber) {
          this.subscriber(data);
        }
      },
      () => {
        // TODO: error handling
      }
    );
  }

  set(overrides: Partial<AccountPreferences>) {
    if (!this.alive || !this.syncedData) return; // initialize fails
    const data = {
      ...this.data,
      ...overrides,
    };
    this.data = data;
    this.subscriber(this.data);
    Promise.resolve(httpService.updateAccountPreferences(data)).then(
      () => {
        this.syncedData = data;
      },
      () => {
        this.data = this.syncedData;
        this.subscriber(this.data);
      }
    );
  }

  notify(subscriber: Subscriber) {
    this.subscriber = subscriber;
  }

  destroy() {
    this.alive = false;
  }
}

export const usePreferences = createModel(PREFERENCE_STORE_KEY, () => {
  const modelValue = useValue<PreferencesModel>(
    () => new LocalPreferencesModel()
  );

  const [preference, setPreference] = useState(() => modelValue.get().data);

  const [
    // model can dep on each other
    {
      data: { status },
    },
  ] = useAccount();

  useEffect(() => {
    const model = modelValue.get();
    if (
      status === AuthStatus.LoggedIn &&
      model instanceof LocalPreferencesModel
    ) {
      model.destroy();
      modelValue.set(new RemotePreferencesModel());
    }
    if (
      status !== AuthStatus.LoggedIn &&
      model instanceof RemotePreferencesModel
    ) {
      model.destroy();
      modelValue.set(new LocalPreferencesModel());
    }
    modelValue.get().notify(setPreference);
  }, [status]);

  const modifyPreference = useCallback(
    (overrides: Partial<AccountPreferences>) => {
      modelValue.get().set(overrides);
    },
    []
  );

  /**
   * update value to cache
   */
  useEffect(() => {
    preferenceCacher.set(preference);
  }, [preference]);

  return [preference, modifyPreference] as const;
});
