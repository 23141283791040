import React, { FC } from "react";
import classNames from "classnames";
import { EmbeddedComponentProps, StyledComponentProps } from "@/interface/base";
import styles from "./tag.module.less";

export const Tag: FC<StyledComponentProps & EmbeddedComponentProps> = ({
  children,
  className = "",
  style,
}) => (
  <span className={classNames(styles.tag, className)} style={style}>
    {children}
  </span>
);
