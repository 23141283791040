import React, { FC } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { CallNext, useFlowEffect , MaybePromise , URLUtils } from "@reversible/common";
import { ApiResponseCode } from "@/enum";
import { PAYPAL_CLIENT_ID } from "@/env";
import { translate } from "@/i18n";
import { AsyncDisplay, Toast } from "@/ui";
import { loadScript } from "@/util/dom";
import styles from "./paypal-button.module.less";

interface PaypalButtonProps {
  disabled?: boolean;
  currency: string;
  createOrder(): Promise<string>;
  onApprove(): void;
}

interface PayPalSdk {
  Buttons: {
    driver: any;
  };
}

declare global {
  interface Window {
    paypal: PayPalSdk;
  }
}

const loadPaypalSdk = (() => {
  let paypal: PayPalSdk;
  let loadedCurrency: string;
  return (currency: string): MaybePromise<PayPalSdk> => {
    if (paypal && currency === loadedCurrency) {
      return paypal;
    }
    const paypalSdkSrc = URLUtils.apiJoinParams(
      "https://www.paypal.com/sdk/js",
      {
        "client-id": PAYPAL_CLIENT_ID,
        "disable-funding": "credit,card",
        intent: "capture",
        currency,
      }
    );
    return loadScript(paypalSdkSrc).then(
      () => {
        paypal = window.paypal;
        loadedCurrency = currency;
        return paypal;
      },
      () => {
        throw new Error("Paypal SDK could not be loaded.");
      }
    );
  };
})();

export const PayPalButton: FC<PaypalButtonProps> = ({
  currency,
  createOrder,
  onApprove,
  disabled = false,
}) => {
  const payPalReactButtonState = useFlowEffect<{
    Button: FC<{
      onApprove(): void;
      createOrder(): Promise<string>;
      onError: any;
      style: any;
    }>;
  }>(
    null,
    function* ({ call, put }) {
      const paypalSdk: CallNext<typeof loadPaypalSdk> = yield call(
        loadPaypalSdk,
        currency
      );
      yield put({
        Button: paypalSdk.Buttons.driver("react", {
          React,
          ReactDOM,
        }),
      });
    },
    [currency]
  );

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapper_disabled]: disabled,
      })}
    >
      <AsyncDisplay source={payPalReactButtonState}>
        {({ Button }) => (
          <Button
            onApprove={onApprove}
            createOrder={createOrder}
            onError={(e: any) => {
              if (e?.code === ApiResponseCode.NotAcceptable) {
                Toast.warn(e.msg, 5);
              } else {
                Toast.error(
                  e?.msg || e?.message || translate("payment_error"),
                  3
                );
              }
            }}
            style={{
              color: "black",
              height: 46,
            }}
          />
        )}
      </AsyncDisplay>
    </div>
  );
};
