export const DEPLOY_ENV: "dev" | "live" | "prod" =
  (process.env.DEPLOY_ENV as any) || "dev";

export const PAYPAL_CLIENT_ID: string = process.env.PAYPAL_CLIENT_ID;
export const PAYPAL_WEB_URL: string = process.env.PAYPAL_WEB_URL;

export const GOOGLE_CLIENT_ID: string = process.env.GOOGLE_CLIENT_ID;
export const GOOGLE_PLACE_API_KEY: string = process.env.GOOGLE_PLACE_API_KEY;

// apple
export const APPLE_WEB_CLIENT_ID: string = process.env.APPLE_WEB_CLIENT_ID;
export const APPLE_REDIRECT_URI: string = process.env.APPLE_REDIRECT_URI;

export const NODE_ENV: NodeJS.ProcessEnv["NODE_ENV"] =
  (process.env.NODE_ENV as any) || "development";

export const PUBLIC_URL: string = process.env.PUBLIC_URL;

export const API_URL: string = process.env.API_URL;

export const APP_VERSION: string = process.env.APP_VERSION;

// firebase
export const FIREBASE_API_KEY: string = process.env.FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN: string = process.env.FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID: string = process.env.FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET: string =
  process.env.FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID: string =
  process.env.FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID: string = process.env.FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID: string =
  process.env.FIREBASE_MEASUREMENT_ID;

// mixpanel
export const MIXPANEL_PROJECT_TOKEN = process.env.MIXPANEL_PROJECT_TOKEN;
export const GOOGLE_ADS_CONVERSION_EVENT_ID =
  process.env.GOOGLE_ADS_CONVERSION_EVENT_ID;
