import React, { FC } from "react";
import { StringUtils } from "@reversible/common";
import { StyledComponentProps } from "@/interface/base";
import { useSettingsInfo } from "@/store/use-settings-info";
import { Option, OptionFilter, Select } from "@/ui";
import styles from "./setting-select.module.less";

const { match } = StringUtils;

export interface SettingsSelectProps extends StyledComponentProps {
  value?: string;
  allowClear?: boolean;
  placeholder?: string;
  onChange?(value: string): void;
}

const regionSelectFilter: OptionFilter<string> = (text) => {
  const matcher = match(text, {
    start: true,
    trim: false,
    emptyStrategy: "all",
  });
  return ({ value }) => matcher(value);
};

export const RegionDisplay: FC<{
  name: string;
  image: string;
}> = ({ name, image }) => (
  <>
    <img src={image} className={styles.img} alt="flag" loading="lazy" />
    <span className={styles.text}>{name}</span>
  </>
);

export const RegionSelect: FC<SettingsSelectProps> = (props) => {
  const {
    data: { regions },
    loading,
  } = useSettingsInfo();

  return (
    <Select {...props} loading={loading} filterable filter={regionSelectFilter}>
      {regions.map((item) => (
        <Option
          key={item.region}
          value={item.region}
          className={styles.region_option}
        >
          <RegionDisplay name={item.region} image={item.image} />
        </Option>
      ))}
    </Select>
  );
};

export const LanguageSelect: FC<SettingsSelectProps> = (props) => {
  const {
    data: { languages },
    loading,
  } = useSettingsInfo();

  return (
    <Select {...props} loading={loading}>
      {languages.map((language) => (
        <Option key={language} value={language}>
          {language}
        </Option>
      ))}
    </Select>
  );
};

export const CurrencySelect: FC<SettingsSelectProps> = (props) => {
  const {
    data: { currencies },
    loading,
  } = useSettingsInfo();

  return (
    <Select {...props} loading={loading}>
      {currencies.map(({ currency, symbol }) => (
        <Option key={currency} value={currency}>
          {currency} ({symbol})
        </Option>
      ))}
    </Select>
  );
};
