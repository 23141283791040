import React, {
  KeyboardEvent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import classnames from "classnames";
import { identity, isString, stop, StringUtils } from "@reversible/common";
import { translate } from "@/i18n";
import { StyledComponentProps } from "@/interface/base";
import atomicStyles from "@/style/atomic.module.less";
import { selectContext } from "./context";
import { OptionItem, ValidOptionValue } from "./interface";
import { EmptyOption, Option, OptionProps } from "./option";
import { OptionsPopup } from "./options-popup";
import { SelectInput } from "./select-input";
import styles from "./select.module.less";
import { useFormFieldContext } from "../form";
import { useExpandable } from "../hooks/use-expandable";
import { Icon } from "../icon";
import { InputBox } from "../input-box";
import { VisibleTransition } from "../transition";

export interface OptionFilter<T> {
  (searchText: string): (optionItem: OptionItem<T>) => boolean;
}

interface CommonSelectProps<T> extends StyledComponentProps {
  placeholder?: string;
  allowClear?: boolean;
  loading?: boolean;
  filterable?: boolean;
  filter?: OptionFilter<T>;
  options?: OptionItem<T>[];
  disabled?: boolean;
  emptyNode?: ReactNode;
}

const defaultOptionFilter: OptionFilter<any> = (searchText) => {
  const matcher = StringUtils.match(searchText, {
    trim: false,
    start: false,
    emptyStrategy: "all",
  });
  return ({ name }) => (isString(name) ? matcher(name) : true);
};

export interface SelectProps<T> extends CommonSelectProps<T> {
  value?: T;
  onChange?(value: T): void;
}

export interface MultiSelectProps<T> extends CommonSelectProps<T> {
  value?: T[];
  onChange?(value: T[]): void;
}

function createSelect<M extends boolean>(multi: M) {
  function SelectComponent<T extends ValidOptionValue>(
    props: PropsWithChildren<
      M extends true ? MultiSelectProps<T> : SelectProps<T>
    >
  ): ReactElement {
    const {
      value: controlledValue,
      onChange: controlledOnChange,
      placeholder = "",
      allowClear = true,
      className = "",
      filterable = false,
      filter = defaultOptionFilter,
      style,
      options,
      children,
      loading = false,
      disabled = false,
      emptyNode,
    } = props;

    const { value, onChange, onValidate } = useFormFieldContext<
      T | T[],
      (value: T | T[]) => void
    >(controlledValue, controlledOnChange);

    const { expand, focus, toggleExpand, ref } = useExpandable({
      targetFocusable: true,
      onBlur: onValidate,
    });

    const hasValue = useMemo(
      () => (multi ? (value as T[])?.length : value !== undefined),
      [value, multi]
    );

    const allOptions = useMemo((): OptionItem<T>[] => {
      if (options)
        return options.map((data) => ({
          value: data.value,
          name: data.name,
          data,
        }));
      return React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const { value, data, children } = child.props as PropsWithChildren<
            OptionProps<T>
          >;
          return {
            value,
            data,
            name: children,
          };
        }
        return null;
      }).filter(identity);
    }, [options, children]);

    const getValueName = useMemo(() => {
      const map: Map<T, ReactNode> = new Map(
        allOptions.map(({ value, name }) => [value, name])
      );
      return (value: T) => (map.has(value) ? map.get(value) : String(value));
    }, [allOptions]);

    const displayElement = ((): ReactElement => {
      if (!hasValue) return null;
      if (!multi) {
        return (
          <div className={styles.display_text}>{getValueName(value as T)}</div>
        );
      }
      return (
        <div className={styles.tags}>
          {(value as T[]).map((currentValue: T) => {
            const onDelete = () => {
              onChange(
                (value as T[]).filter(
                  (singleValue) => currentValue !== singleValue
                )
              );
            };
            return (
              <div
                className={styles.tag}
                key={String(currentValue)}
                onClick={stop()}
              >
                <div className={styles["tag-text"]}>
                  {getValueName(currentValue)}
                </div>
                <a className={styles["tag-icon"]} onClick={onDelete}>
                  <Icon type="close" />
                </a>
              </div>
            );
          })}
        </div>
      );
    })();

    const onClear = useCallback(() => {
      if (multi) {
        onChange([]);
      } else {
        onChange(undefined);
      }
    }, [multi, onChange]);

    const showInput = focus && filterable && !disabled;

    // option filter
    const [searchText, onChangeSearchText] = useState("");
    const availableOptions = useMemo(
      () => allOptions.filter(filter(searchText)),
      [searchText, allOptions, filter]
    );
    const optionLength = availableOptions.length;

    const [pointer, setPointer] = useState(0);

    useEffect(() => {
      // set pointer to zero when pointer values unmatched with optionLength
      if (pointer && (pointer < 0 || pointer >= optionLength)) {
        setPointer(0);
      }
    }, [pointer, optionLength]);

    const contextValue = useMemo(() => {
      return {
        pointer,
        setPointer,
        multi,
        selectedValue: value,
        onChange: multi
          ? (nextValue: T) => {
              const prevValue = value as T[];
              onChange(
                prevValue.includes(nextValue)
                  ? prevValue.filter((i) => i !== nextValue)
                  : [...prevValue, nextValue]
              );
            }
          : (nextValue: T) => {
              onChange(nextValue);
              toggleExpand();
            },
      };
    }, [value, onChange, toggleExpand, pointer, setPointer]);

    const onKeyDown = (e: KeyboardEvent) => {
      if (disabled) return;
      switch (e.key) {
        case "Escape":
          if (expand) {
            toggleExpand();
          }
          break;
        case "ArrowDown":
          if (!expand) {
            toggleExpand();
          } else {
            setPointer((prev) => (prev < optionLength - 1 ? prev + 1 : 0));
          }
          e.preventDefault(); // prevent page scrolling by up and down
          break;
        case "ArrowUp":
          if (expand) {
            setPointer((prev) => (prev > 0 ? prev - 1 : optionLength - 1));
            e.preventDefault(); // prevent page scrolling by up and down
          }
          break;
        case "Enter":
          if (expand) {
            const option = availableOptions[pointer];
            if (option) {
              if (multi) {
                const prevValue = value as T[];
                onChange(
                  prevValue.includes(option.value)
                    ? prevValue.filter((item) => item !== option.value)
                    : [...prevValue, option.value]
                );
              } else {
                onChange(option.value);
                toggleExpand();
              }
            }
          } else {
            toggleExpand();
          }
          break;
        default:
          break;
      }
    };

    return (
      <div
        onKeyDown={onKeyDown}
        className={classnames(
          styles.select,
          {
            [styles.select_disabled]: disabled,
          },
          className
        )}
        style={style}
        ref={ref}
      >
        <InputBox
          disabled={disabled}
          focus={focus}
          className={styles.input}
          onClick={toggleExpand}
          icon={
            loading ? "loading" : showInput ? "search" : expand ? "up" : "down"
          }
          iconSpin={loading}
          hoverIcon={allowClear && value !== undefined ? "close" : undefined}
          onClickHoverIcon={stop(onClear)}
        >
          {!showInput && !hasValue ? (
            <div className={styles.placeholder}>{placeholder}</div>
          ) : null}
          {hasValue ? (
            <div
              className={classnames(styles.display, {
                [styles.display_no_grow]: showInput,
                [styles.display_no_width]: showInput && !multi,
              })}
            >
              {displayElement}
            </div>
          ) : null}
          {showInput ? (
            <SelectInput
              expand={expand}
              displayElement={multi ? null : displayElement}
              onChange={onChangeSearchText}
              onExpand={toggleExpand}
              value={value}
            />
          ) : null}
        </InputBox>
        <OptionsPopup visible={expand} inputRef={ref}>
          {(style) => (
            <VisibleTransition
              className={classnames(styles.options, atomicStyles["no-scroll"])}
              visible={expand}
              style={style}
            >
              <selectContext.Provider value={contextValue}>
                {availableOptions.map(({ value, name, data }, index) => (
                  <Option
                    key={String(value)}
                    value={value}
                    index={index}
                    data={data}
                  >
                    {name}
                  </Option>
                ))}
                {!availableOptions.length ? (
                  <EmptyOption>
                    {loading ? (
                      <>
                        <Icon type="loading" spin />
                        {translate("loading")}
                      </>
                    ) : (
                      emptyNode || translate("no_available_options")
                    )}
                  </EmptyOption>
                ) : null}
              </selectContext.Provider>
            </VisibleTransition>
          )}
        </OptionsPopup>
      </div>
    );
  }
  return SelectComponent;
}

export const Select = createSelect(false);

export const MultiSelect = createSelect(true);
