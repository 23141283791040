import React, { FC, useMemo, useContext, useState, useCallback } from "react";
import { OriginalPriceDisplay, Price } from "@/component/price";
import { LocalMessage, MessageData } from "@/interface/message";
import {
  AVAILABLE_OFFER_STATUSES,
  LocalMessageStatus,
  MessageType,
  OfferStatus,
  OfferType,
} from "@/enum";
import { translate } from "@/i18n";
import { Button } from "@/ui";
import classNames from "classnames";
import {
  BuyModal,
  SellModal,
  TargetAskOffer,
  TargetBidOffer,
} from "../buy-sell-modal";
import { messageListModelContext } from "../context";
import styles from "./message-display.module.less";

export const OfferMessageDisplay: FC<{
  offerMessage: (MessageData | LocalMessage) & { msgType: MessageType.Offer };
  isReceiver: boolean;
  offerType: OfferType;
}> = ({ offerMessage, offerType, isReceiver }) => {
  const [_, dispatchMessageAction] = useContext(messageListModelContext);

  const [visible, setVisible] = useState(false);

  const onClose = useCallback(() => setVisible(false), []);

  const [askInfo, bidInfo] = useMemo((): [TargetAskOffer, TargetBidOffer] => {
    if ("local" in offerMessage) return [null, null];

    const {
      content: {
        offerPrice,
        offerCurrency,
        originalOfferCurrency,
        originalOfferPrice,
        paymentOrderId,
        offerCountry,
      },
      id,
    } = offerMessage;

    return offerType === OfferType.Ask
      ? [
          {
            offerPrice,
            offerCurrency,
            originalOfferCurrency,
            originalOfferPrice,
            offerCountry,
            messageId: id,
          },
          null,
        ]
      : [
          null,
          {
            paymentOrderId,
            messageId: id,
          },
        ];
  }, [offerMessage]);

  const priceDisplay = useMemo(() => {
    if ("local" in offerMessage) {
      const { offerCurrency, offerPrice } = offerMessage.content;
      return (
        <Price
          className={styles.offer_price}
          showExactCurrency
          currency={offerCurrency}
        >
          {offerPrice}
        </Price>
      );
    }

    const {
      offerCurrency,
      originalOfferCurrency,
      offerPrice,
      originalOfferPrice,
    } = offerMessage.content;
    return (
      <OriginalPriceDisplay
        price={offerPrice}
        currency={offerCurrency}
        originalPrice={originalOfferPrice}
        originalCurrency={originalOfferCurrency}
        includesShipping
      />
    );
  }, [offerMessage]);

  const title = useMemo(() => {
    if ("local" in offerMessage) {
      return translate(
        (
          {
            [LocalMessageStatus.Failed]: "failed_to_send_offer",
            [LocalMessageStatus.Sent]: "offer_sent",
            [LocalMessageStatus.Loading]: "sending_offer",
          } as const
        )[offerMessage.status]
      );
    }
    return translate(
      (
        {
          [OfferStatus.Completed]: "offer_accepted",
          [OfferStatus.Rejected]: "offer_rejected",
          [OfferStatus.Unavailable]: "offer_not_available",
          [OfferStatus.Available]: isReceiver ? "offer_received" : "offer_sent",
          [OfferStatus.Deleted]: "offer_deleted", // not accessible
          [OfferStatus.Expired]: "offer_expired",
          [OfferStatus.Preapproved]: isReceiver
            ? "offer_received"
            : "offer_sent",
        } as const
      )[offerMessage.content.offerStatus]
    );
  }, [offerMessage, isReceiver]);

  const cardDisabled = useMemo(() => {
    if ("local" in offerMessage) return false;
    return [
      OfferStatus.Deleted,
      OfferStatus.Rejected,
      OfferStatus.Unavailable,
      OfferStatus.Expired,
    ].includes(offerMessage.content.offerStatus);
  }, [offerMessage]);

  return (
    <div
      className={classNames(styles.content_box, styles.card, {
        [styles.content_box_disabled]: cardDisabled,
        [styles.card_disabled]: cardDisabled,
      })}
    >
      <header className={styles.card_header}>{title}</header>
      <div className={styles.card_content}>
        {priceDisplay}
        {!("local" in offerMessage) ? (
          <div className={styles.shipping}>
            {translate(offerType === OfferType.Ask ? "ship_from" : "ship_to")}{" "}
            {offerMessage.content.offerCountry}
          </div>
        ) : null}
      </div>
      {!("local" in offerMessage) &&
      AVAILABLE_OFFER_STATUSES.includes(offerMessage.content.offerStatus) &&
      isReceiver ? (
        <footer className={styles.card_footer}>
          <Button
            size="small"
            className={styles.card_btn}
            onClick={async () => {
              await dispatchMessageAction({
                type: "update-offer-status",
                messageId: offerMessage.id,
                status: OfferStatus.Rejected,
              });
              dispatchMessageAction({
                type: "latest",
              });
            }}
          >
            {translate("reject")}
          </Button>
          <Button
            size="small"
            type="fill"
            className={styles.card_btn}
            onClick={() => setVisible(true)}
          >
            {translate("accept")}
          </Button>
          {offerType === OfferType.Ask ? (
            <BuyModal visible={visible} onClose={onClose} askInfo={askInfo} />
          ) : (
            <SellModal visible={visible} onClose={onClose} bidInfo={bidInfo} />
          )}
        </footer>
      ) : null}
    </div>
  );
};
