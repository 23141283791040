import React, { FC, Dispatch } from "react";
import { noop } from "@reversible/common";
import {
  Condition,
  LocalMessageStatus,
  MessageType,
  OfferStatus,
  OfferType,
  UserStatus,
} from "@/enum";
import { UserInfoBrief } from "@/interface/account";
import { LocalMessage, MessageData } from "@/interface/message";
import {
  MessageListFlowAction,
  MessageListModel,
  messageListModelContext,
} from "@/view/messages/component/context";
import { MessageDisplay } from "@/view/messages/component/message-display";
import {
  messageListModel,
  INITIAL_MESSAGE_LIST_STATE,
} from "@/view/messages/component/message-list.model";

const SELF: UserInfoBrief = {
  id: 900,
  username: "user A",
  userReview: 3.5,
  userTransaction: 20,
  isVerified: false,
  avatarUrl: "https://dummyimage.com/200x200/FF0000/FF0000",
  userStatus: UserStatus.Normal,
};

const OTHER: UserInfoBrief = {
  id: 800,
  username: "user B",
  userReview: 4.5,
  userTransaction: 10,
  isVerified: true,
  avatarUrl: "https://dummyimage.com/200x200/00FFBB/00FFBB",
  userStatus: UserStatus.Normal,
};

const mockConversationContextValue: [
  MessageListModel,
  Dispatch<MessageListFlowAction>
] = [
  messageListModel({
    ...INITIAL_MESSAGE_LIST_STATE,
    offerInfo: {
      brand: "AcneStudio",
      offerCondition: Condition.BrandNew,
      offerConditionDisplay: "Brand new",
      offerCountry: "United States",
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerPrice: 200,
      originalOfferCurrency: "CNY",
      originalOfferCurrencySymbol: "¥",
      originalOfferPrice: 1600,
      offerDescription: "",
      offerId: 123,
      offerName: "hahah",
      offerStatus: OfferStatus.Available, // enumerate OfferStatus
      offerTime: new Date(),
      offerType: OfferType.Ask,
      offerValidTime: 1809,
      size: "XS",
      spuId: 180,
      acceptOffer: true,
      acceptBuyNow: true,
    },
  }),
  noop,
];

const messages: (MessageData | LocalMessage)[] = [
  // local
  // text messages
  {
    local: true,
    id: "local-text-sending",
    status: LocalMessageStatus.Loading,
    msgType: MessageType.Text,
    content: "local text sending",
  },
  {
    local: true,
    id: "local-text-sent",
    status: LocalMessageStatus.Sent,
    msgType: MessageType.Text,
    content: "local text sent",
  },
  {
    local: true,
    id: "local-text-failed",
    status: LocalMessageStatus.Failed,
    msgType: MessageType.Text,
    content: "local text failed",
  },
  // img messages
  {
    local: true,
    id: "local-img-sending",
    status: LocalMessageStatus.Loading,
    msgType: MessageType.Image,
    content: "",
    images: new File([], "hahaha"),
  },
  {
    local: true,
    id: "local-img-sent",
    status: LocalMessageStatus.Sent,
    msgType: MessageType.Image,
    content: "",
    images: new File([], "hahaha"),
  },
  {
    local: true,
    id: "local-img-failed",
    status: LocalMessageStatus.Failed,
    msgType: MessageType.Image,
    content: "",
    images: new File([], "hahaha"),
  },
  // offer images
  {
    local: true,
    id: "local-offer-sending",
    status: LocalMessageStatus.Loading,
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerPrice: 200,
    },
  },
  {
    local: true,
    id: "local-offer-sent",
    status: LocalMessageStatus.Sent,
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerPrice: 200,
    },
  },
  {
    local: true,
    id: "local-offer-failed",
    status: LocalMessageStatus.Failed,
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerPrice: 200,
    },
  },
  // synced
  // text messages
  {
    id: 1,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Text,
    content: "This is a text content sent by other",
  },
  {
    id: 2,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Text,
    content: "This is a text content sent by self",
  },
  // img messages
  {
    id: 3,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Image,
    content: "https://dummyimage.com/800x400/EEEEEE/000000",
  },
  {
    id: 4,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Image,
    content: "https://dummyimage.com/400x400/00CCFF/FFFFFF",
  },
  // robot messages
  {
    id: 5,
    senderId: 0,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.System,
    content: "This is a long system message, it's so long that it wrapps",
  },
  {
    id: 6,
    senderId: 0,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.System,
    content: "This is a short system message",
  },
  // tracking messages
  {
    id: 7,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Tracking,
    content: {
      carrier: "FedEx",
      trackingNo: "8012398189E11900",
      trackingUrl: "https://www.baidu.com",
    },
  },
  {
    id: 8,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Tracking,
    content: {
      carrier: "",
      trackingNo: "",
      trackingUrl: "",
    },
  },
  {
    id: 9,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Tracking,
    content: {
      carrier: "FedEx",
      trackingNo: "8012398189E11911",
      trackingUrl: "https://www.baidu.com",
    },
  },
  {
    id: 10,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Tracking,
    content: {
      carrier: "",
      trackingNo: "",
      trackingUrl: "",
    },
  },
  // offer
  {
    id: 11,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Available,
      originalOfferCurrency: "EUR",
      originalOfferPrice: 240,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 12,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Completed,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 13,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Deleted,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 14,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Rejected,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 15,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Unavailable,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 16,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Expired,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 17,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Preapproved,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  // offer sent by other
  {
    id: 18,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Available,
      originalOfferCurrency: "EUR",
      originalOfferPrice: 240,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 19,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Completed,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 20,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Deleted,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 21,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Rejected,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 22,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Unavailable,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 23,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Expired,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  {
    id: 24,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Offer,
    content: {
      offerCurrency: "USD",
      offerCurrencySymbol: "$",
      offerId: 20,
      offerPrice: 200,
      offerStatus: OfferStatus.Preapproved,
      originalOfferCurrency: "USD",
      originalOfferPrice: 200,
      paymentOrderId: "xxx90aasdnasdk",
      offerCountry: "United States",
    },
  },
  // order
  {
    id: 25,
    senderId: OTHER.id,
    receiverId: SELF.id,
    msgTime: new Date(),
    msgType: MessageType.Order,
    content: {
      orderId: 199786571,
    },
  },
  {
    id: 26,
    senderId: SELF.id,
    receiverId: OTHER.id,
    msgTime: new Date(),
    msgType: MessageType.Order,
    content: {
      orderId: 199786571,
    },
  },
];

const MessageDemoView: FC = () => {
  return (
    <messageListModelContext.Provider value={mockConversationContextValue}>
      <div style={{ background: "#333", padding: "30px 0" }}>
        <div
          style={{
            width: "50%",
            margin: "auto",
            background: "white",
            padding: "20px",
          }}
        >
          {messages.map((data, i) => (
            <MessageDisplay key={i} data={data} otherUserInfo={OTHER} />
          ))}
        </div>
      </div>
    </messageListModelContext.Provider>
  );
};

export default MessageDemoView;
