import React, { FC, memo, ReactNode, useCallback } from "react";
import classNames from "classnames";
import { translate } from "@/i18n";
import { StyledComponentProps } from "@/interface/base";
import { usePreferences } from "@/store/use-preferences";
import { useSettingsInfo } from "@/store/use-settings-info";
import styles from "./price.module.less";

interface PriceProps extends StyledComponentProps {
  currencySymbol?: string;
  currency?: string;
  showExactCurrency?: boolean;
  inline?: boolean;
  suffix?: ReactNode;
  children: number;
}

export const Price: FC<PriceProps> = memo(
  ({
    currencySymbol,
    currency,
    showExactCurrency = false,
    suffix,
    children,
    className,
    style,
    inline = false,
  }) => {
    const wrap = useCallback(
      (node: ReactNode) => {
        if (inline)
          return (
            <span className={className} style={style}>
              {node}
            </span>
          );
        return (
          <div className={className} style={style}>
            {node}
          </div>
        );
      },
      [inline, className]
    );

    if (showExactCurrency && !currency) {
      // error branch
      return wrap("invalid currency");
    }

    const {
      data: { currencySymbolDict },
    } = useSettingsInfo();

    const [{ currency: preferredCurrency }] = usePreferences();

    const currencyForDisplay = currency || preferredCurrency;

    const displayCurrencySymbol =
      currencySymbol || currencySymbolDict[currencyForDisplay];

    if (children == null || !Number.isFinite(children)) {
      return wrap("--");
    }

    return wrap(
      <>
        <meta itemProp="priceCurrency" content={currencyForDisplay} />
        <meta itemProp="price" content={String(children)} />
        <>{displayCurrencySymbol}</>
        <>{Math.ceil(children).toLocaleString()}</>
        {showExactCurrency ? <> {currencyForDisplay}</> : null}
        {suffix}
      </>
    );
  }
);

export interface OriginalPriceProps extends StyledComponentProps {
  originalPrice: number;
  originalCurrency: string;
  price?: number;
  currency?: string;
  includesShipping?: boolean;
  displayClassName?: string;
  originalClassName?: string;
  inline?: boolean;
}

export const OriginalPriceDisplay: FC<OriginalPriceProps> = ({
  className,
  style,
  originalPrice,
  originalCurrency,
  price = originalPrice,
  currency = originalCurrency,
  includesShipping = false,
  displayClassName,
  originalClassName,
  inline = false,
}) => {
  const contentNode = (
    <>
      <div
        className={classNames(
          styles.original_price_wrapper,
          {
            [styles.inline_wrapper]: inline,
          },
          originalClassName
        )}
      >
        <Price
          inline
          className={styles.original_price}
          showExactCurrency
          currency={originalCurrency}
        >
          {originalPrice}
        </Price>
        {includesShipping ? (
          <span className={styles.shipping_text}>
            {translate("includes_shipping")}
          </span>
        ) : null}
      </div>
      {currency !== originalCurrency ? (
        <div
          className={classNames(
            styles.display_price_wrapper,
            {
              [styles.inline_wrapper]: inline,
            },
            displayClassName
          )}
        >
          (<span className={styles.approx}>{translate("approx")}</span>
          <Price
            inline
            showExactCurrency
            className={styles.display_price}
            currency={currency}
          >
            {price}
          </Price>
          )
        </div>
      ) : null}
    </>
  );

  return inline ? (
    contentNode
  ) : (
    <div className={className} style={style}>
      {contentNode}
    </div>
  );
};
