import type { CallNext, Flow, GetNext } from "@reversible/common";
import {
  ArrayUtils,
  ObjectUtils,
  ScheduleUtils,
  uniqueId,
} from "@reversible/common";
import { CONVERSATION_ID_NEW } from "@/const";
import {
  ApiResponseCode,
  AVAILABLE_OFFER_STATUSES,
  ConversationType,
  LocalMessageStatus,
  MessageType,
  MessageUpdateAction,
  OfferStatus,
} from "@/enum";
import { translate } from "@/i18n";
import type {
  LocalMessage,
  MessageData,
  MessageSendData,
  OfferMessageData,
} from "@/interface/message";
import * as apiService from "@/service";
import type {
  ApiResponse,
  CreateConversation,
  GetConversationByOfferInfo,
  GetConversationLastUpdate,
  GetMessage,
  GetMessageListByConversationId,
  SendMessage,
} from "@/service/interface";
import { Toast } from "@/ui";
import type {
  MessageDoubleLinkNode,
  MessageListFlowAction,
  MessageListModel,
  MessageListState,
} from "./context";

export const NULL_ID = -1;

type DirectlyUpdatableFIelds =
  | "id"
  | "conversationType"
  | "buyerInfo"
  | "sellerInfo"
  | "offerInfo"
  | "orderInfo"
  | "lastPrivateOffer"
  | "lastUpdateTime"
  | "hasReachedTop";

// dict of compare functions judge whether a field should update
const isSame = Object.is;
const isSameId = <T extends { id: any }>(a: T, b: T) => isSame(a?.id, b?.id);
const fieldCompareDict: {
  [K in DirectlyUpdatableFIelds]: (
    a: MessageListState[K],
    b: MessageListState[K]
  ) => boolean;
} = {
  id: isSame,
  conversationType: isSame,
  buyerInfo: isSameId,
  sellerInfo: isSameId,
  offerInfo: (a, b) => a?.offerStatus === b?.offerStatus,
  orderInfo: (a, b) =>
    a?.orderStatus === b?.orderStatus &&
    a?.orderReviewInfo?.length === b?.orderReviewInfo?.length,
  lastPrivateOffer: isSameId,
  lastUpdateTime: (a, b) => a?.valueOf() === b?.valueOf(),
  hasReachedTop: isSame,
};

export const INITIAL_MESSAGE_LIST_STATE: MessageListState = {
  // link nodes
  linkDict: {},
  firstSyncedId: NULL_ID,
  lastSyncedId: NULL_ID,
  // firstMessageId: NULL_ID,
  hasReachedTop: true, // if not reached top, init process would set this to be false
  // local
  localList: [],
  // conversation
  id: 0,
  conversationType: undefined,
  buyerInfo: null,
  sellerInfo: null,
  offerInfo: null,
  orderInfo: null,
  lastPrivateOffer: null,
  lastUpdateTime: null,
  // the focused message id
  focusId: 0,
};

/**
 * messageList modal
 * @param state
 */
export const messageListModel = (
  state: MessageListState = INITIAL_MESSAGE_LIST_STATE
) => {
  const {
    id,
    conversationType,
    linkDict,
    firstSyncedId,
    lastSyncedId,
    localList,
    hasReachedTop,
    buyerInfo,
    sellerInfo,
    offerInfo,
    orderInfo,
    lastUpdateTime, // served for poll only
    lastPrivateOffer,
    focusId,
  } = state;

  const firstSyncedMessage = linkDict[firstSyncedId]?.value || null;
  const lastSyncedMessage = linkDict[lastSyncedId]?.value || null;
  const lastDisplayedMessage = localList.length
    ? ArrayUtils.last(localList)
    : lastSyncedMessage;

  const messages: (LocalMessage | MessageData)[] = [];
  const idSet = new Set<string | number>();

  // traverse from top to bottom
  let currentId = firstSyncedId;
  while (currentId !== NULL_ID) {
    const node = linkDict[currentId];
    if (!node) break;
    if (idSet.has(currentId)) {
      console.info("fatal: message merging cycular error"); // eslint-disable-line no-console
      break;
    }

    idSet.add(id);
    messages.push(node.value);
    currentId = node.next;
  }

  messages.push(...localList);

  const model = {
    // has reached top
    hasReachedTop,
    // message computed
    firstSyncedMessage,
    lastSyncedMessage,
    lastDisplayedMessage,
    messages,
    // conversationInfo,
    id,
    conversationType,
    buyerInfo,
    sellerInfo,
    offerInfo,
    orderInfo,
    lastUpdateTime,
    lastPrivateOffer,
    focusId,

    // methods
    // update directly updatable fields of state
    update(
      overrides: Partial<Pick<MessageListState, DirectlyUpdatableFIelds>>
    ) {
      const statePatches: typeof overrides = {};
      Object.entries(overrides).forEach(([field, nextValue]) => {
        const compareFunc = fieldCompareDict[field];
        if (!compareFunc || compareFunc(state[field], nextValue)) return;
        statePatches[field] = nextValue;
      });

      if (ObjectUtils.isEmpty(statePatches)) return model;
      return messageListModel({
        ...state,
        ...statePatches,
      });
    },
    // merge message
    // the merge method only create new nodes, it won't change the status of existing nodes
    merge(list: MessageData[], fromMessageId: number = NULL_ID) {
      if (fromMessageId !== NULL_ID && !linkDict[fromMessageId]) return model; // error merge, could be fatal
      if (!list.length) {
        return hasReachedTop
          ? model
          : messageListModel({
              ...state,
              hasReachedTop: true,
            });
      }

      // order check
      let sorted = true; // check if the list is sort from latest to oldest
      const compare = (
        { msgTime: t1, id: id1 }: MessageData,
        { msgTime: t2, id: id2 }: MessageData
      ) => (t2 > t1 ? 1 : id2 > id1 ? 1 : -1);
      for (let i = 1; i < list.length; i++) {
        const prev = list[i - 1];
        const current = list[i];
        if (compare(prev, current) > 0) {
          // prev greater than current?
          sorted = false;
          break;
        }
      }
      const sortedList = sorted ? list : list.slice().sort(compare);
      const firstNewNodeIndex = sortedList.findIndex(({ id }) => !linkDict[id]);

      if (firstNewNodeIndex === -1) {
        // no new nodes
        return model;
      }

      const listToMerge = sortedList.slice(firstNewNodeIndex); // remove head messages that already exist

      const syncedLocalMessages = localList.filter(
        ({ status }) => status === LocalMessageStatus.Sent
      );

      // a dict of syncedId -> images
      const syncedIdLocalImagesDict: Record<number, File> = {};
      for (const message of syncedLocalMessages) {
        if (message.msgType === MessageType.Image) {
          syncedIdLocalImagesDict[message.syncedId] = message.images;
        }
      }

      // update lastId
      const newLastSyncedId =
        fromMessageId === NULL_ID ? listToMerge[0].id : lastSyncedId;
      let newFirstSyncedId = ArrayUtils.last(listToMerge).id;

      // link
      let nextNode: MessageDoubleLinkNode =
        fromMessageId === NULL_ID ? null : linkDict[fromMessageId];
      const patchDict: Record<number, MessageDoubleLinkNode> = {};

      for (const message of listToMerge) {
        const currentId = message.id;
        const nextId = nextNode?.value.id || NULL_ID;
        if (nextNode) {
          nextNode.prev = currentId;
        }
        if (linkDict[currentId]) {
          // linked to existed messages
          linkDict[currentId].next = nextId;
          newFirstSyncedId = firstSyncedId;
          break; // linked together
        }
        const currentNode = {
          value: {
            // a specialized optimize logic, to assign the image-file to synced-image message
            ...message,
            images: syncedIdLocalImagesDict[message.id],
          },
          next: nextId,
          prev: NULL_ID,
        };
        Object.assign(patchDict, {
          [currentId]: currentNode,
        });
        nextNode = currentNode;
      }

      const latestLinkingFailed =
        fromMessageId === NULL_ID && newFirstSyncedId !== firstSyncedId; // failed to link the latest requested dict with the existing dict

      const newLinkDict = latestLinkingFailed
        ? patchDict
        : { ...linkDict, ...patchDict };
      const newLocalList = localList.filter(
        ({ status }) => status !== LocalMessageStatus.Sent
      );

      // LATER: focus should be managed in view, not in model
      // map the localSyncedIdDict so that the focused id won't change for send message
      const syncedIdLocalIdDict = Object.fromEntries(
        syncedLocalMessages.map(({ id, syncedId }) => [syncedId, id] as const)
      );
      const newFocusId =
        fromMessageId === NULL_ID
          ? ArrayUtils.last(newLocalList)?.id ||
            syncedIdLocalIdDict[newLastSyncedId] ||
            newLastSyncedId
          : fromMessageId;

      const overrides: Partial<MessageListState> = {
        linkDict: newLinkDict,
        firstSyncedId: newFirstSyncedId,
        lastSyncedId: newLastSyncedId,
        localList: newLocalList,
        focusId: newFocusId,
      };

      if (!state.hasReachedTop && latestLinkingFailed) {
        overrides.hasReachedTop = false; // data unlinked
      }

      return messageListModel({
        ...state,
        ...overrides,
      });
    },
    // push local message
    push(localMessages: LocalMessage[]) {
      let nextFocusId = focusId;
      let nextLocalList = localList;

      const pushSingle = (localMessage: LocalMessage) => {
        const sameIdIndex = localList.findIndex(
          ({ id }) => id === localMessage.id
        );

        if (sameIdIndex === -1) {
          nextFocusId = localMessage.id;
          nextLocalList = nextLocalList.concat(localMessage);
        }

        nextLocalList = ObjectUtils.immutableSet(
          nextLocalList,
          [sameIdIndex],
          localMessage
        );
      };

      localMessages.forEach(pushSingle);

      return messageListModel({
        ...state,
        localList: nextLocalList,
        focusId: nextFocusId,
      });
    },
    // recall message, only local message for current
    recall(localMessages: LocalMessage[]) {
      const withdrawIdSet = new Set(localMessages.map(({ id }) => id));
      return messageListModel({
        ...state,
        localList: localList.filter(({ id }) => !withdrawIdSet.has(id)),
      });
    },

    // update the status of all offers
    checkAllMessageStatus(list: MessageData[]) {
      // the dict of offer statuses that are certain
      const certainOfferStatusDict: Record<number, OfferStatus> = {};

      for (const item of list) {
        if (item.msgType === MessageType.Offer) {
          certainOfferStatusDict[item.id] = item.content.offerStatus;
        }
      }

      if (lastPrivateOffer) {
        // the last available offer provided by the other is of certain available
        certainOfferStatusDict[lastPrivateOffer.id] =
          lastPrivateOffer.content.offerStatus;
      }

      const selfId =
        conversationType === ConversationType.Buy
          ? buyerInfo.id
          : sellerInfo.id;

      const linkDictPatches: typeof linkDict = {};
      const uncertainMessages: MessageData[] = [];
      let currentId = lastSyncedId;
      let selfLastOfferFound = false;

      while (currentId !== NULL_ID) {
        const node = linkDict[currentId];
        if (!node) break;
        currentId = node.prev;

        const message = node.value;
        if (message.msgType !== MessageType.Offer) continue; // eslint-disable-line

        const {
          senderId,
          content: { offerStatus },
          id,
        } = message;
        const isLastSelfOffer = !selfLastOfferFound && senderId === selfId;
        if (isLastSelfOffer) {
          selfLastOfferFound = true;
        }

        // if a status is unavailable, it's a final status, it won't change in anyway
        if (!AVAILABLE_OFFER_STATUSES.includes(offerStatus)) continue; // eslint-disable-line

        if (isLastSelfOffer && certainOfferStatusDict[id] == null) {
          // this is the last self-sent offer
          // and it's status is available
          // and it's not in the certain status dict
          // thus it's actual status is uncertain
          uncertainMessages.push(message);
          continue; // eslint-disable-line
        }

        const targetOfferStatus =
          certainOfferStatusDict[id] ?? OfferStatus.Unavailable; // this logic is a little arbitary and could fail in extreme rare cases
        if (offerStatus !== targetOfferStatus) {
          linkDictPatches[id] = ObjectUtils.immutableSet(
            node,
            ["value", "content", "offerStatus"],
            targetOfferStatus
          );
        }
      }

      return [
        ObjectUtils.isEmpty(linkDictPatches)
          ? model
          : messageListModel({
              ...state,
              linkDict: {
                ...linkDict,
                ...linkDictPatches,
              },
            }),
        uncertainMessages,
      ] as const;
    },
    // update offer status manually, instead of basing on the latest auto checking
    updateOfferStatus(id: number, status: OfferStatus) {
      if (!linkDict[id]) return model;
      return messageListModel({
        ...state,
        linkDict: ObjectUtils.immutableSet(
          linkDict,
          [id, "value", "content", "offerStatus"],
          status
        ),
      });
    },
  };
  return model;
};

// message list flow
export const messageListFlow: Flow<MessageListModel, MessageListFlowAction> =
  function* ({ get, put, call, cancel, dispatch, block }, action) {
    // get current data;
    const {
      id,
      firstSyncedMessage,
      offerInfo,
      buyerInfo,
      sellerInfo,
    }: MessageListModel = yield get();

    // all actions should wait until id ready
    if (!id && action.type !== "init") return;

    switch (action.type) {
      // init the conversation and message list
      case "init": {
        const { data } = action;
        yield put((prev) => prev.update(data).merge(data.messages));
        break;
      }

      // reject offer
      case "update-offer-status": {
        const { messageId, status } = action;
        if (status === OfferStatus.Rejected) {
          // for rejection, a request should be sent
          yield call(apiService.updateMessage, {
            conversationId: id,
            messageId,
            action: MessageUpdateAction.Reject,
          });
        }

        yield put((prev) => prev.updateOfferStatus(messageId, status));
        break;
      }

      // fetch the latest message
      case "latest": {
        // pull latest message
        yield cancel(({ type }) => type === "latest"); //  cancel unfinished latest process
        yield block(({ type }) => type === "check-update"); // block incoming poll action
        yield call(ScheduleUtils.timeout, 100); // debounce for 100 milliseconds
        const LATEST_QUERY_SIZE = 10;

        if (id === CONVERSATION_ID_NEW) {
          // for new conversation creation, check whether the conversation is created or not
          const { data }: CallNext<GetConversationByOfferInfo> = yield call(
            apiService.getConversationByOfferInfo,
            {
              buyerId: buyerInfo.id,
              sellerId: sellerInfo.id,
              offerId: offerInfo.offerId,
              size: LATEST_QUERY_SIZE,
            }
          );
          if (data) {
            // init conversation
            yield dispatch({
              type: "init",
              data,
            });
          }
          return;
        }

        const { data }: CallNext<GetMessageListByConversationId> = yield call(
          apiService.getMessageListByConversationId,
          {
            conversationId: id,
            size: LATEST_QUERY_SIZE, // latest fetch less message than init
          }
        );
        const prev: GetNext<MessageListModel> = yield get();
        const [next, uncertainMessages] = prev
          .update(data)
          .merge(data.messages)
          .checkAllMessageStatus(data.messages);
        yield put(next);

        if (uncertainMessages.length) {
          for (const message of uncertainMessages) {
            const { data: syncMessage }: CallNext<GetMessage> = yield call(
              apiService.getMessage,
              {
                conversationId: id,
                messageId: message.id,
              }
            );
            if (syncMessage?.msgType === MessageType.Offer) {
              const targetStatus = syncMessage.content.offerStatus;
              if (
                targetStatus !==
                (message as OfferMessageData).content.offerStatus
              ) {
                yield put((prev) =>
                  prev.updateOfferStatus(message.id, targetStatus)
                );
              }
            }
          }
        }

        break;
      }

      // fetch message data from different directions
      case "previous": {
        if (!firstSyncedMessage) return;
        yield block(({ type }) => type === "previous");
        const { data }: CallNext<GetMessageListByConversationId> = yield call(
          apiService.getMessageListByConversationId,
          {
            conversationId: id,
            startFrom: firstSyncedMessage.id,
            size: 20,
          }
        );
        yield put((prev) => prev.merge(data.messages, firstSyncedMessage.id));
        break;
      }

      // push message to server
      case "push": {
        const { message, onSuccess } = action;
        // transform LocalMessageSendData -> LocalMessage
        const localMessages: LocalMessage[] =
          message.msgType === MessageType.Image
            ? Array.isArray(message.images)
              ? // must not be a reloading
                message.images.map((singleImage) => ({
                  id: uniqueId(),
                  msgType: MessageType.Image,
                  content: "",
                  images: singleImage,
                  local: true,
                  status: LocalMessageStatus.Loading,
                }))
              : [
                  {
                    id: message.id || uniqueId(), // could possibly be a reloading
                    msgType: MessageType.Image,
                    content: "",
                    images: message.images,
                    local: true,
                    status: LocalMessageStatus.Loading,
                  },
                ]
            : [
                {
                  id: uniqueId(),
                  ...message,
                  local: true,
                  status: LocalMessageStatus.Loading,
                },
              ];

        // cancel all actions except for a previous pull action
        yield cancel(
          ({ type }) => type === "check-update" || type === "latest"
        );
        yield block(({ type }) => type === "check-update");
        // set as loading
        yield put((prev) => prev.push(localMessages));

        try {
          if (id === CONVERSATION_ID_NEW) {
            // create conversation
            const { buyerInfo, sellerInfo, offerInfo }: MessageListState =
              yield get();
            const { data }: CallNext<CreateConversation> = yield call(
              apiService.createConversation,
              {
                buyerId: buyerInfo.id,
                sellerId: sellerInfo.id,
                offerId: offerInfo.offerId,
              }
            );

            yield put((prev) => prev.update(data));
          }

          const prev: MessageListState = yield get();

          const { data: syncedIds }: CallNext<SendMessage> = yield call(
            apiService.sendMessage,
            {
              conversationId: prev.id,
              ...ObjectUtils.omit(message, ["id"]),
            } as MessageSendData
          );
          if (onSuccess) {
            onSuccess(syncedIds.map(({ id }) => id));
          }
          yield put((prev) =>
            prev.push(
              localMessages.map((localMessage, index) => ({
                ...localMessage,
                status: LocalMessageStatus.Sent,
                syncedId: syncedIds[index].id,
              }))
            )
          );
        } catch (e) {
          if ((e as ApiResponse)?.code === ApiResponseCode.NotAcceptable) {
            Toast.warn(e.msg, 5);
            // the message is not acceptable
            yield put((prev) => prev.recall(localMessages));
          } else {
            Toast.error(e?.msg || translate("unknown_error"));
            yield put((prev) =>
              prev.push(
                localMessages.map((localMessage) => ({
                  ...localMessage,
                  status: LocalMessageStatus.Failed,
                }))
              )
            );
          }
        }
        yield dispatch({ type: "latest" });
        break;
      }

      // check update
      case "check-update": {
        if (id === CONVERSATION_ID_NEW) return;
        yield cancel(({ type }) => type === "check-update");
        let fetchedLastUpdateTime: Date;
        try {
          const { data }: CallNext<GetConversationLastUpdate> = yield call(
            apiService.getConversationLastUpdate,
            {
              conversationId: [id],
            }
          );
          fetchedLastUpdateTime = data[id];
        } catch (e) {
          console.warn("message poll error", e); // eslint-disable-line no-console
          return;
        }

        const { lastUpdateTime }: MessageListModel = yield get();
        yield put((prev) =>
          prev.update({ lastUpdateTime: fetchedLastUpdateTime })
        );
        if (lastUpdateTime && fetchedLastUpdateTime > lastUpdateTime) {
          yield dispatch({ type: "latest" }); // pull latest data
        }
        break;
      }
      default:
        break;
    }
  };
