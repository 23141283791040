import React, { FC, ReactNode } from "react";
import classnames from "classnames";
import { translate } from "@/i18n";
import { HTMLElementProps } from "@/interface/base";
import styles from "./empty.module.less";

export interface EmptyProps
  extends Omit<HTMLElementProps<HTMLDivElement>, "title"> {
  title?: ReactNode;
  cover?: boolean;
}
export const Empty: FC<EmptyProps> = ({
  title,
  className,
  cover,
  ...props
}) => (
  <div
    className={classnames(
      styles.empty,
      {
        [styles.empty_cover]: cover,
      },
      className
    )}
    {...props}
  >
    {title || translate("no_results_found")}
  </div>
);
