import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { noop, stop } from "@reversible/common";
import { translate } from "@/i18n";
import { NestableItem } from "@/interface/base";
import styles from "./cascader.module.less";
import { OptionsPopup } from "./options-popup";
import { useFormFieldContext } from "../form";
import { useExpandable } from "../hooks/use-expandable";
import { Icon } from "../icon";
import { InputBox } from "../input-box";
import { VisibleTransition } from "../transition";

export interface CascaderProps<T> {
  placeholder?: string;
  allowClear?: boolean;
  className?: string;
  splitter?: string;
  value?: T[];
  onChange?(value: T[]): void;
  options: NestableItem<T>[];
}

export function Cascader<T>({
  value: controlledValue,
  onChange: controlledOnChange,
  placeholder = "",
  allowClear = true,
  splitter = "/",
  className = "",
  options,
}: CascaderProps<T>) {
  const { value, onChange, onValidate } = useFormFieldContext(
    controlledValue,
    controlledOnChange
  );

  const { focus, expand, toggleExpand, ref } = useExpandable({
    targetFocusable: true,
    onBlur: onValidate,
  });

  const [innerValue, setInnerValue] = useState([]);

  useEffect(() => {
    setInnerValue(value || []);
  }, [value]);

  const selectedName = useMemo(() => {
    const names: string[] = [];
    let layerOptions = options;
    for (const layerValue of value || []) {
      const selectedLayerOption = layerOptions.find(
        ({ value }) => layerValue === value
      );
      if (!selectedLayerOption) break;
      names.push(selectedLayerOption.name);
      layerOptions = selectedLayerOption.children;
    }
    return names.join(` ${splitter} `);
  }, [options, value]);

  const currentOptionsByLayer = useMemo(() => {
    const optionsByLayer: {
      options: NestableItem<T>[];
      value: T;
    }[] = [];
    let currentOptions: NestableItem<T>[] = options;
    for (const layerValue of innerValue) {
      const selectedLayerOption = currentOptions.find(
        ({ value }) => layerValue === value
      );
      if (!selectedLayerOption) break;
      optionsByLayer.push({
        options: currentOptions,
        value: layerValue,
      });
      currentOptions = selectedLayerOption.children;
    }
    if (currentOptions) {
      optionsByLayer.push({
        value: undefined,
        options: currentOptions,
      });
    }
    return optionsByLayer;
  }, [innerValue, options]);

  return (
    <div className={classNames(styles.cascader, className)} ref={ref}>
      <InputBox
        focus={focus}
        onClick={toggleExpand}
        className={styles.input}
        icon={expand ? "up" : "down"}
        hoverIcon={allowClear && value?.length ? "close" : null}
        onClickHoverIcon={stop(() => onChange(undefined))}
      >
        {!value?.length ? (
          <div className={styles.placeholder}>{placeholder}</div>
        ) : (
          <div className={styles.display}>{selectedName}</div>
        )}
      </InputBox>
      <OptionsPopup visible={expand} inputRef={ref}>
        {(style) => (
          <VisibleTransition
            className={styles.options}
            visible={expand}
            style={{
              ...style,
              width: "auto",
            }}
          >
            {currentOptionsByLayer.map(
              ({ value: layerValue, options: layerOptions }, index) => {
                return (
                  <div className={styles["layer-options"]} key={index}>
                    {layerOptions.map(({ value, name, children }) => {
                      const isSelected = value === layerValue;
                      const isLeaf = !children?.length;
                      const onClick = isSelected
                        ? noop
                        : () => {
                            const nextInnerValue = [
                              ...innerValue.slice(0, index),
                              value,
                            ];
                            setInnerValue(nextInnerValue);
                            if (isLeaf) {
                              onChange(nextInnerValue);
                              toggleExpand();
                            }
                          };
                      return (
                        <div
                          className={classNames(styles.option, {
                            [styles["option--selected"]]: isSelected,
                          })}
                          key={String(value)}
                          onClick={onClick}
                        >
                          <div className={styles["option-text"]}>{name}</div>
                          {isLeaf ? null : <Icon type="right" />}
                        </div>
                      );
                    })}
                  </div>
                );
              }
            )}
            {!options.length ? (
              <div
                className={classNames(styles.option, styles["option--empty"])}
              >
                {translate("no_available_options")}
              </div>
            ) : null}
          </VisibleTransition>
        )}
      </OptionsPopup>
    </div>
  );
}
