// import { IS_MOBILE, IS_SAFARI } from "@reversible/common";
import { MutableRefObject, useEffect, useCallback, useMemo } from "react";
import { uniqueId, MaybePromise } from "@reversible/common";

const delayExecution = (callback: () => void) =>
  window.requestAnimationFrame(callback);

export class DocumentController {
  static SPECIAL_LOCK = false; // IS_MOBILE && IS_SAFARI;

  private static body = document.body;

  private static html = document.documentElement;

  private static lockerCount = 0;

  private static iosScrollPosition = 0;

  static get scrollTop(): number {
    return this.html.scrollTop;
  }

  static set scrollTop(value: number) {
    this.html.scrollTop = value;
  }

  static forceScroll(scrollTop: number) {
    const MAX_TRY = 5; // count
    const MAX_TIME = 200; // ms
    const TOLERANCE = 50;
    const docMinHeight = scrollTop + window.innerHeight - TOLERANCE;
    const start = Date.now();

    const tryScroll = (leftCount = MAX_TRY) => {
      delayExecution(() => {
        if (this.html.scrollHeight >= docMinHeight) {
          delayExecution(() => {
            this.html.scrollTop = scrollTop;
          });
        } else {
          const now = Date.now();
          if (leftCount > 0 && now - start <= MAX_TIME) {
            tryScroll(leftCount - 1);
          } else {
            // scroll to the position anyway
            this.html.scrollTop = scrollTop;
          }
        }
      });
    };
    tryScroll();
  }

  static addClassToBody(className: string) {
    this.body.classList.add(className);
  }

  static removeClassFromBody(className: string) {
    this.body.classList.remove(className);
  }

  static lockScroll(): () => void {
    if (DocumentController.SPECIAL_LOCK) {
      this.iosScrollPosition = window.pageYOffset;
      this.body.style.overflow = "hidden";
      this.body.style.position = "fixed";
      this.body.style.top = `-${this.iosScrollPosition}px`;
      this.body.style.width = "100%";
    } else {
      this.body.style.overflow = "hidden";
    }

    this.lockerCount++;

    return () => {
      this.unlockScroll();
    };
  }

  private static unlockScroll() {
    this.lockerCount--;
    if (!this.lockerCount) {
      if (DocumentController.SPECIAL_LOCK) {
        this.body.style.removeProperty("overflow");
        this.body.style.removeProperty("position");
        this.body.style.removeProperty("top");
        this.body.style.removeProperty("width");
        window.scrollTo(0, this.iosScrollPosition);
      } else {
        this.body.style.removeProperty("overflow");
      }
    }
  }

  static listenScroll(callback: () => void): () => void {
    document.addEventListener("scroll", callback, { passive: true });

    return () => document.removeEventListener("scroll", callback);
  }

  // static listenScrollDirection(callback: (downDirection: boolean) => void, {
  //   thres = 0,
  // }: {
  //   thres?: number,
  // } = {}): () => void {
  //   // unable to detect key-based page scroll
  //   let lastDirection: boolean;
  //   const tryUpdateDirection = (deltaY: number) => {
  //     if (Math.abs(deltaY) > thres) {
  //       const direction = deltaY > 0;
  //       if (lastDirection !== direction) {
  //         callback(direction);
  //       }
  //       lastDirection = direction;
  //     }
  //   }
  //   const onWheel = ({ deltaY }: WheelEvent) => {
  //     tryUpdateDirection(deltaY);
  //   };
  //   document.addEventListener('wheel', onWheel);
  //   // for mobile
  //   let touchTime = 0;
  //   const onTouchMove = () => {
  //     touchTime = Date.now();
  //   };
  //   let scrollTop = this.scrollTop;
  //   const scrollListener = this.listenScroll(() => {
  //     const currentScrollTop = this.scrollTop;
  //     const deltaY = currentScrollTop - scrollTop;
  //     scrollTop = currentScrollTop;
  //     const deltaTime = Date.now() - touchTime;
  //     if (deltaTime < 12) {
  //       tryUpdateDirection(deltaY)
  //     }
  //   });
  //   document.addEventListener('touchmove', onTouchMove);
  //   return () => {
  //     document.removeEventListener('wheel', onWheel);
  //     document.removeEventListener('touchmove', onTouchMove);
  //     scrollListener();
  //   }
  // }

  static listenKeydown(handlers: Record<string, () => void>): () => void {
    const listener = (e: KeyboardEvent) => {
      const handler = handlers[e.key];

      if (handler) {
        handler();
      }
    };

    document.addEventListener("keydown", listener);

    return () => document.removeEventListener("keydown", listener);
  }
}

export interface CopyResult {
  success: boolean;
  message: string;
}

function fallbackCopyTextToClipboard(text: string): CopyResult {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let result: CopyResult;
  try {
    const success = document.execCommand("copy");
    result = {
      success,
      message: success ? "" : "Failed to copy",
    };
  } catch (err) {
    result = {
      success: false,
      message: err,
    };
  }

  document.body.removeChild(textArea);

  return result;
}

export function copyTextToClipboard(text: string): MaybePromise<CopyResult> {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }

  return navigator.clipboard.writeText(text).then(
    () => ({
      success: true,
      message: "",
    }),
    (err) => ({
      success: false,
      message: err.message,
    })
  );
}

export function loadScript(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = (e) => reject(e);
    document.body.appendChild(script);
  });
}

export const onClickOutside = (
  el: HTMLElement | HTMLElement[],
  callback: () => void
) => {
  const els = Array.isArray(el) ? el : [el];
  const listener = (e: MouseEvent) => {
    if (els.some((el) => !el || el.contains(e.target as HTMLElement))) {
      return;
    }
    callback();
  };

  document.addEventListener("mousedown", listener);
  document.addEventListener("touchstart", listener);

  return () => {
    document.removeEventListener("mousedown", listener);
    document.removeEventListener("touchstart", listener);
  };
};

const subscriberMap = new Map<string, () => void>();

export function useOnClickOutside(
  handler: () => void,
  ...refs: MutableRefObject<HTMLElement>[]
) {
  const selfKey = useMemo(uniqueId, []);

  useEffect(() => {
    const clickOutsideSubscription = onClickOutside(
      refs.map((ref) => ref.current),
      handler
    );
    subscriberMap.set(selfKey, handler);
    return () => {
      clickOutsideSubscription();
      subscriberMap.delete(selfKey);
    };
  }, [handler]);

  /**
   * imperatively call focus, so that the other clickOutside subscribers gets a click outside event
   */
  const focusImperatively = useCallback(() => {
    for (const [key, otherHandler] of subscriberMap.entries()) {
      if (key !== selfKey) {
        otherHandler();
      }
    }
  }, []);

  return focusImperatively;
}

export function triggerVirtualClickOutside() {
  for (const handler of subscriberMap.values()) {
    handler();
  }
}
