import React, { FC, useState } from "react";
import classNames from "classnames";
import { Popup, PopupProps } from "./popup";
import styles from "./tooltip.module.less";

export type TooltipProps = Omit<PopupProps, "visible" | "setVisible">;

export const Tooltip: FC<TooltipProps> = ({
  className,
  trigger = "hover-parent",
  children,
  style,
  placement,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Popup
      visible={visible}
      setVisible={setVisible}
      trigger={trigger}
      className={classNames(styles.tooltip, className)}
      placement={placement}
      style={style}
    >
      {children}
    </Popup>
  );
};
