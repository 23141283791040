// sort in alphabetical order

import dictEnUs from "./dict-en-us";

/* eslint-disable */
export type LangKey = keyof typeof dictEnUs;

export const translate = (key: LangKey, source: Record<string, any> = {}) => {
  return (dictEnUs[key] || "").replace(/\{\{([^\][]+?)\}\}/g, (_, field) =>
    source[field] !== undefined ? String(source[field]) : ""
  );
};

export const createEnumNamer =
  <T extends number | string>(map: Record<T, LangKey>) =>
  (enumValue: T) =>
    translate(map[enumValue]);
