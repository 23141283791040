import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { MaybePromise, stop, useFlow } from "@reversible/common";
import { MaybeLink } from "@/component/maybe-link";
import { EmbeddedComponentProps, StyledComponentProps } from "@/interface/base";
import { useScreenSize } from "@/store/use-screen-size";
import styles from "./action-sheet.module.less";
import { Popup, Placement, PopupProps } from "./popup";
import { PopupAnchor, PopupControl } from "./popup-anchor";
import { Icon } from "../icon";
import { Dialog } from "../modal";

export const closeActionSheetContext = createContext<() => void>(null);

export type ActionButtonType = "normal" | "primary" | "danger";

export interface ActionButtonProps
  extends EmbeddedComponentProps,
    StyledComponentProps {
  type?: ActionButtonType;
  icon?: ReactNode;
  to?: string;
  final?: boolean;
  onClick?(): MaybePromise<void>;
}

export const ActionButton: FC<ActionButtonProps> = ({
  children,
  className,
  style,
  onClick,
  to,
  icon,
  final = true,
  type = "normal",
}) => {
  const closeActionSheet = useContext(closeActionSheetContext);

  const [{ loading }, dispatch] = useFlow(null, function* ({ call }) {
    if (onClick) {
      yield call(onClick);
    }
    if (final) {
      closeActionSheet();
    }
  });

  return (
    <MaybeLink
      to={to}
      style={style}
      className={classNames(
        styles.action_button,
        styles[`action_button_${type}`],
        className
      )}
      onClick={dispatch}
    >
      {loading ? <Icon spin type="loading" /> : icon}
      {children}
    </MaybeLink>
  );
};

export interface ActionSheetProps
  extends EmbeddedComponentProps,
    StyledComponentProps,
    Pick<PopupProps, "trigger">,
    Partial<Pick<PopupProps, "visible" | "setVisible">> {
  header?: ReactNode;
  footer?: ReactNode;
  placement?: Placement;
  align?: "left" | "center";
  onShow?(): void;
}

export const ActionSheet: FC<ActionSheetProps> = ({
  header,
  footer,
  placement = "bottom",
  align = "left",
  trigger,
  children,
  style,
  visible: controlledVisible,
  setVisible: controlledSetVisible,
  className,
  onShow,
}) => {
  const innerTuple = useState(false);

  const [visible, setVisible] = controlledSetVisible
    ? [controlledVisible, controlledSetVisible]
    : innerTuple;

  const cancelAnchorFlowRef = useRef<PopupControl>();
  const closeActionSheet = useCallback(() => {
    if (cancelAnchorFlowRef.current) {
      cancelAnchorFlowRef.current.cancel();
    }
    setVisible(false);
  }, []);

  const { isMobileScreenSize } = useScreenSize();

  useEffect(() => {
    closeActionSheet();
  }, [isMobileScreenSize]);

  useEffect(() => {
    if (visible && onShow) {
      onShow();
    }
  }, [visible]);

  const content = (
    <>
      {header && <header className={styles.header}>{header}</header>}
      <ul className={styles.sheets}>{children}</ul>
      {footer ? <footer className={styles.footer}>{footer}</footer> : null}
    </>
  );

  return (
    <closeActionSheetContext.Provider value={closeActionSheet}>
      {isMobileScreenSize ? (
        <>
          <PopupAnchor
            trigger="click"
            visible={visible}
            setVisible={setVisible}
            ref={cancelAnchorFlowRef}
          />
          <Dialog visible={visible} onClose={closeActionSheet}>
            <div className={styles.mask} onClick={stop(closeActionSheet)}>
              <div
                className={classNames(
                  styles.action_sheet,
                  styles[`action_sheet_${align}`]
                )}
                onClick={stop()}
              >
                {content}
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        <Popup
          visible={visible}
          setVisible={setVisible}
          trigger={trigger}
          placement={placement}
          style={style}
          className={classNames(
            styles.action_sheet,
            styles[`action_sheet_${align}`],
            className
          )}
          ref={cancelAnchorFlowRef}
        >
          {content}
        </Popup>
      )}
    </closeActionSheetContext.Provider>
  );
};
