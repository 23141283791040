import React, { ReactNode, ReactNodeArray } from "react";
import { FlowObject , isFunction } from "@reversible/common";
import { ApiResponseCode } from "@/enum";
import { ErrorAlert } from "../alert";
import { Empty } from "../empty";
import { Loading } from "../loading";

export interface AsyncDisplayProps<T> {
  source: FlowObject<T>;
  loadingText?: ReactNode;
  loadingNode?: ReactNode | ((data: T) => ReactNode);
  emptyText?: ReactNode;
  emptyNode?: ReactNode | ((data: T) => ReactNode);
  cover?: boolean;
  treatNotFoundErrorAsEmpty?: boolean;
  emptyWhen?(data: T): boolean;
  children: (data: T) => ReactNode | ReactNodeArray;
  onRetry?(): void;
  renderError?(error: any): ReactNode;
}

const defaultEmptyWhen = (data: any) => {
  return data == null || (Array.isArray(data) && !data.length);
};

// LATER: memo
export function AsyncDisplay<T>({
  source: { loading, data, initiated, error },
  emptyWhen = defaultEmptyWhen,
  onRetry,
  children,
  loadingText,
  loadingNode,
  emptyText,
  emptyNode,
  renderError,
  cover = false,
  treatNotFoundErrorAsEmpty = false,
}: AsyncDisplayProps<T>) {
  if (loading) {
    if (cover && initiated) {
      return (
        <>
          <>{children(data)}</>
          {loadingNode ? (
            <>{isFunction(loadingNode) ? loadingNode(data) : loadingNode}</>
          ) : (
            <Loading cover title={loadingText} />
          )}
        </>
      );
    }

    return loadingNode ? (
      <>{isFunction(loadingNode) ? loadingNode(data) : loadingNode}</>
    ) : (
      <Loading cover={cover} title={loadingText} />
    );
  }

  const isNotFoundErrorEmpty =
    treatNotFoundErrorAsEmpty && error?.code === ApiResponseCode.NotFound;
  if (error && !isNotFoundErrorEmpty) {
    return renderError ? (
      <>{renderError(error)}</>
    ) : (
      <ErrorAlert error={error} onRetry={onRetry} />
    );
  }

  const renderEmpty = () =>
    emptyNode ? (
      <>{isFunction(emptyNode) ? emptyNode(data) : emptyNode}</>
    ) : (
      <Empty cover={cover} title={emptyText} />
    );

  if (isNotFoundErrorEmpty) {
    // if error empty, surely is not initiated
    return renderEmpty();
  }

  if (!initiated) {
    return null;
  }

  if (emptyWhen(data)) {
    return renderEmpty();
  }

  return (
    <>
      <>{children(data)}</>
    </>
  );
}

// export const AsyncDisplay = memo(RawAsyncDisplay);

export interface AsyncListDisplayProps<T> {
  source: FlowObject<T>;
  loadingText?: ReactNode;
  loadingNode?: ReactNode;
  emptyText?: ReactNode;
  emptyNode?: ReactNode;
  emptyWhen(data: T): boolean;
  children: (data: T) => ReactNode | ReactNodeArray;
  onRetry?(): void;
}

export function AsyncListDisplay<T>({
  source: { error, loading, data },
  loadingText,
  loadingNode,
  emptyText,
  emptyNode,
  emptyWhen,
  children,
  onRetry,
}: AsyncListDisplayProps<T>) {
  if (error) {
    return <ErrorAlert error={error} onRetry={onRetry} />;
  }

  if (!emptyWhen(data)) {
    return <>{children(data)}</>;
  }

  if (loading) {
    return loadingNode ? (
      <>{loadingNode}</>
    ) : (
      <Loading cover title={loadingText} />
    );
  }

  return emptyNode ? <>{emptyNode}</> : <Empty cover title={emptyText} />;
}
