import React, { FC, memo, useState } from "react";
import { isString } from "@reversible/common";
import { Form, FormItem, Input, Toast } from "@/ui";

const BEZIER_COORDS: [number, number][] = [
  [0, 1],
  [0.12, 0.6],
  [0.94, 0.42],
  [1, 0.11],
];

const MAX = 255;

const parseHexInput = (hexStr: string) => {
  const hex = hexStr.replace(/^#/, "");
  return parseInt(hex.length > 1 ? hex.slice(0, 2) : `${hex}${hex}`, 16) / MAX;
};

const displayAsHex = (value: number | string) => {
  if (isString(value)) {
    if (/^([0-9a-fA-F])*$/.test(value)) {
      switch (value.length) {
        case 1:
          return `#${Array(6).fill(value).join("")}`;
        case 2:
          return `#${Array(3).fill(value).join("")}`;
        case 3:
          return `#${value
            .split("")
            .map((v) => Array(2).fill(v))
            .join("")}`;
        default:
          return `#${value}`;
      }
    }
    return value;
  }
  const HH = (value * MAX)
    .toString(16)
    .split(".")[0]
    .padStart(2, "0")
    .toUpperCase();
  return `#${Array(3).fill(HH).join("")}`;
};

const getCoord = (() => {
  const cache = new Map<number, [number, number]>();
  return (p: number) => {
    if (cache.has(p)) {
      return cache.get(p);
    }
    const q = 1 - p;
    const r = BEZIER_COORDS.map(([x, y], i) => {
      const c = i === 1 || i === 2 ? 3 : 1;
      const a = q ** i * p ** (3 - i) * c;
      return [x * a, y * a];
    }).reduce<[number, number]>(([x, y], [sx, sy]) => [sx + x, sy + y], [0, 0]);
    cache.set(p, r);
    return r;
  };
})();

const getYForX = (() => {
  const E = 1 / MAX / 3;
  return (x: number) => {
    let [left, right] = [1, 0];
    while (true) {
      const [[leftX], [rightX]] = [getCoord(left), getCoord(right)];
      if (Math.abs(rightX - x) < E) {
        return getCoord(right)[1];
      }
      if (Math.abs(leftX - x) < E) {
        return getCoord(left)[1];
      }
      if (Math.abs(rightX - leftX) < E) return getCoord(left)[1];
      const mid = (left + right) / 2;
      const [midX] = getCoord(mid);
      if (midX > x) {
        right = mid;
      } else {
        left = mid;
      }
    }
  };
})();

const VARS: [string, string, string][] = [
  ["text+", "0", "f"],
  ["text", "20", "c"],
  ["bg*", "3", "b"],
  ["bg*-", "4", "ae"],
  ["text-", "6", "97"],
  ["text--", "9", "79"],
  ["text*-", "a", "6f"],
  ["text---", "b", "64"],
  ["text*--", "c0", "61"],
  ["edge++", "c", "59"],
  ["edge+", "d", "4c"],
  ["edge", "e", "3b"],
  ["edge-", "f4", "34"],
  ["bg-", "f7", "2f"],
  ["bg", "f", "1c"],
  ["bg+", "f", "0"],
];

const CONSTS: [string, string][] = [
  ["dark+", "0"],
  ["dark", "3"],
  ["dark-", "4"],
  ["dark--", "6"],
  ["light-", "9"],
  ["light", "c"],
  ["light+", "f"],
  ["red", "ff4741"],
  ["blue-", "4193ef"],
  ["blue", "0088ff"],
  ["blue+", "007aff"],
  ["mask--", "00000033"],
  ["mask-", "00000040"],
  ["mask", "00000073"],
  ["mask+", "00000099"],
];

const ColorPair: FC<{
  light: number | string;
  dark?: number | string;
}> = memo(({ light, dark }) => {
  const lr = isString(light) ? parseHexInput(light) : light;
  const [l, d] = [displayAsHex(light), displayAsHex(dark ?? light)];

  return (
    <div
      style={{
        display: "flex",
        marginTop: "4px",
        borderRadius: "4px",
        boxShadow: "0 2px 7px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
        width: "400px",
        textAlign: "center",
        fontWeight: "bolder",
      }}
    >
      <div
        style={{
          background: l,
          flex: 1,
          padding: "8px",
          color: lr > 0.5 ? "black" : "white",
        }}
      >
        {l}
      </div>
      {dark != null ? (
        <div
          style={{
            background: d,
            flex: 1,
            padding: "8px",
            color: lr > 0.5 ? "white" : "black",
          }}
        >
          {d}
        </div>
      ) : null}
    </div>
  );
});

export const ColorModes: FC = () => {
  const [queryColors, setQueryColors] = useState<[number, number]>(undefined);

  return (
    <main
      style={{
        padding: "20px",
        display: "grid",
        gap: "20px",
        gridTemplateColumns: "1fr 1fr 1fr",
      }}
    >
      <ul>
        {VARS.map(([name, l, d], i) => {
          return (
            <li style={{ marginBottom: "8px" }} key={i}>
              <div style={{ fontWeight: 700, fontSize: "10px" }}>{name}</div>
              <ColorPair light={l} dark={d} />
            </li>
          );
        })}
      </ul>
      <ul>
        {CONSTS.map(([name, l], i) => {
          return (
            <li style={{ marginBottom: "8px" }} key={i}>
              <div style={{ fontWeight: 700, fontSize: "10px" }}>{name}</div>
              <ColorPair light={l} />
            </li>
          );
        })}
      </ul>
      <section>
        <Form
          onSubmit={({ light }) => {
            const value = parseHexInput(light);
            if (Number.isNaN(value)) {
              Toast.error("Invalid Color");
              return;
            }
            setQueryColors([value, getYForX(value)]);
          }}
        >
          <FormItem field="light" initialValue="">
            <Input placeholder="input light-mode color to get dark-mode color" />
          </FormItem>
        </Form>
        {queryColors ? (
          <ColorPair light={queryColors[0]} dark={queryColors[1]} />
        ) : null}
      </section>
    </main>
  );
};
