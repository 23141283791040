import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { ObjectPath ,
  isFunction,
  MaybePromiseUtils,
  noop,
  ObjectUtils,
} from "@reversible/common";
import { EmbeddedComponentProps } from "@/interface/base";
import { formContext, formFieldContext } from "./context";
import { FormFieldState, FormValidationRule } from "./interface";

const { map } = MaybePromiseUtils;

/**
 * FormField is a data component
 * it provides a data structure layer
 */
export interface FormFieldProps {
  field: string | number; // field path of the form item, set this to switch the downstream form to be auto-controlled
  initialValue?: any; // this intial value of formField
  rules?: FormValidationRule[];
  alert?: string; // overrided alert
  cleanup?: boolean; // should the field be cleaned up after it's destroyed
}

export const FormField: FC<FormFieldProps> = ({
  field: relativeField,
  initialValue,
  rules,
  alert: alertOverride,
  children,
  cleanup = false,
}) => {
  const formStateContext = useContext(formContext);

  if (!formStateContext) {
    throw new Error("Form Field must be in a context of Form");
  }

  const { baseField, baseFieldPath } = formStateContext;

  const [field, fieldPath] = useMemo(
    () =>
      [
        `${baseField && `${baseField}.`}${relativeField}`,
        [...baseFieldPath, relativeField] as ObjectPath,
      ] as const,
    [relativeField, baseField, baseFieldPath]
  );

  const registeredRef = useRef(false);

  // method for validation
  const validate = useMemo(() => {
    const validateFlow = function* (value: string, values: any) {
      for (const rule of rules || []) {
        yield rule(value, values);
      }
    };
    return (value: string, values: any) => {
      const iterator = validateFlow(value, values);
      const run = () => {
        const { done, value } = iterator.next();
        return map(value, (alert) => {
          if (alert) {
            return alert;
          }
          if (!done) {
            return run();
          }
          return "";
        });
      };
      return run();
    };
  }, [rules]);

  // update rules registry
  useEffect(() => {
    if (registeredRef.current) {
      formStateContext.updateFieldConfig(field, {
        validate,
        cleanup,
      });
    }
  }, [validate, cleanup]);

  // initial registry
  useEffect(() => {
    registeredRef.current = true;
    return formStateContext.registerField({
      initialValue,
      field,
      fieldPath,
      validate,
      cleanup,
    });
  }, []);

  const formFieldState = useMemo((): FormFieldState => {
    const { values, alerts, setValues, validateField } = formStateContext;
    const valueFromFormStateContext = ObjectUtils.prop(values, fieldPath);
    return {
      // if the value provided by the form state context is `undefined` and register not ready, use initial value
      value:
        !registeredRef.current && valueFromFormStateContext === undefined
          ? initialValue
          : valueFromFormStateContext,
      field,
      alert: alertOverride != null ? alertOverride : alerts[field] || "",
      onChange: (mutator) =>
        setValues((prev) => ObjectUtils.immutableSet(prev, fieldPath, mutator)),
      onValidate: () => validateField(field),
    };
  }, [alertOverride, fieldPath, formStateContext]);

  // formStateContext provid for downstream components
  const downstreamFormStateContext = useMemo(() => {
    return {
      ...formStateContext,
      baseField: field,
      baseFieldPath: fieldPath,
    };
  }, [formStateContext, field, fieldPath]);

  return (
    <formContext.Provider value={downstreamFormStateContext}>
      <formFieldContext.Provider value={formFieldState}>
        {children}
      </formFieldContext.Provider>
    </formContext.Provider>
  );
};

/**
 * hook for the child specific form, for the accessing of the form item context
 * @param controlledValue
 * @param controlledOnChange
 */
export function useFormFieldContext<
  T,
  U extends (value: T, ...params: any[]) => void = (value: T) => void
>(
  controlledValue: T,
  controlledOnChange: U
): Omit<FormFieldState<T>, "onChange"> & {
  onChange: U;
} {
  const formFieldState: FormFieldState<T> = useContext(formFieldContext);

  if (!formFieldState) {
    return {
      value: controlledValue,
      onChange: controlledOnChange,
      onValidate: noop,
      field: undefined,
      alert: "",
    };
  }

  return {
    ...formFieldState,
    onChange: ((nextValue: T, ...params) => {
      formFieldState.onChange(nextValue);
      if (isFunction(controlledOnChange)) {
        controlledOnChange(nextValue, ...params);
      }
    }) as U,
  };
}

export interface WithFormFieldProps<T> {
  children: (state: FormFieldState<T>) => ReactElement;
}

export function WithFormField<T>({
  children,
}: WithFormFieldProps<T>): ReactElement {
  const formItemState: FormFieldState<T> = useContext(formFieldContext);

  return children(formItemState);
}

/**
 * for formContext blocking
 * often used when a controlled scope is required
 */
export const WithoutFormField: FC<EmbeddedComponentProps> = ({ children }) => {
  return (
    <formFieldContext.Provider value={null}>
      {children}
    </formFieldContext.Provider>
  );
};
