import {
  Condition,
  ConversationType,
  ExpireTime,
  Gender,
  OfferStatus,
  OfferType,
  OrderStatus,
  OrderType,
  SortKey,
  SpuStatus,
  UserGender,
  TimeRange,
} from "@reversible/schema";
import { createEnumNamer } from "./i18n";

export * from "@reversible/schema";

/**
 * login status
 */
export enum AuthStatus {
  Unknown,
  LoggedIn,
  NotLoggedIn,
}

export const genderNamer = createEnumNamer({
  [Gender.Men]: "men",
  [Gender.Women]: "women",
});

export const userGenderNamer = createEnumNamer({
  [UserGender.Men]: "men",
  [UserGender.Women]: "women",
});

export const offerTypeNamer = createEnumNamer({
  [OfferType.Ask]: "ask",
  [OfferType.Bid]: "bid",
});

export const offerTypeTrackerNamer = (offerType: OfferType) =>
  ({
    [OfferType.Ask]: "ask",
    [OfferType.Bid]: "bid",
  }[offerType] || "");

export const offerTypeActionNamer = createEnumNamer({
  [OfferType.Ask]: "sell",
  [OfferType.Bid]: "buy",
});

export const orderTypeNamer = createEnumNamer({
  [OrderType.Buy]: "buy",
  [OrderType.Sell]: "sell",
});

export const conditionNamer = createEnumNamer({
  [Condition.BrandNew]: "brand_new",
  [Condition.LikeNew]: "like_new",
  [Condition.VeryGood]: "very_good",
  [Condition.Good]: "good",
  [Condition.Acceptable]: "acceptable",
});

export const sortKeyNamer = createEnumNamer({
  [SortKey.Featured]: "featured",
  [SortKey.LowPrice]: "low_price",
  [SortKey.HighPrice]: "high_price",
  [SortKey.Latest]: "latest",
  [SortKey.Popular]: "most_popular",
  [SortKey.NewLowestAsks]: "new_lowest_asks",
  [SortKey.NewHighestBids]: "new_highest_bids",
  [SortKey.MostRelevant]: "most_relevant",
});

export const conversationTypeNamer = createEnumNamer({
  [ConversationType.Buy]: "buy",
  [ConversationType.Sell]: "sell",
});

export const mapConversationTypeToCorrespondingOrderType = (
  conversationType: ConversationType
) =>
  ({
    [ConversationType.Buy]: OrderType.Buy,
    [ConversationType.Sell]: OrderType.Sell,
  }[conversationType]);

export enum LocalMessageStatus {
  Loading,
  Failed,
  Sent,
}

export const offerStatusNamer = createEnumNamer({
  [OfferStatus.Unavailable]: "offer_not_available",
  [OfferStatus.Available]: "offer_available",
  [OfferStatus.Deleted]: "offer_deleted",
  [OfferStatus.Rejected]: "offer_rejected",
  [OfferStatus.Completed]: "offer_sold",
  [OfferStatus.Expired]: "offer_expired",
  [OfferStatus.Preapproved]: "offer_available",
  [OfferStatus.Removed]: "removed",
});

export const offerStatusPureNamer = createEnumNamer({
  [OfferStatus.Unavailable]: "not_available",
  [OfferStatus.Available]: "available",
  [OfferStatus.Deleted]: "deleted",
  [OfferStatus.Rejected]: "rejected",
  [OfferStatus.Completed]: "sold",
  [OfferStatus.Expired]: "expired",
  [OfferStatus.Preapproved]: "available",
  [OfferStatus.Removed]: "removed",
});

export const AVAILABLE_OFFER_STATUSES = [
  OfferStatus.Available,
  OfferStatus.Preapproved,
];

export const orderStatusNamer = createEnumNamer<OrderStatus>({
  [OrderStatus.ShipmentPending]: "paid",
  [OrderStatus.DeliveryPending]: "shipped",
  [OrderStatus.Completed]: "completed",
  [OrderStatus.RefundPending]: "refunding",
  [OrderStatus.Refunded]: "refunded",
  [OrderStatus.PartialRefunded]: "refunded",
  // unsused
  [OrderStatus.PaymentPending]: "nil",
  [OrderStatus.Cancelled]: "nil",
});

export const expireTimeNamer = createEnumNamer({
  [ExpireTime.Never]: "never",
  [ExpireTime.OneDay]: "one_day",
  [ExpireTime.TwoDays]: "two_days",
  [ExpireTime.SevenDays]: "seven_days",
  [ExpireTime.FourteenDays]: "fourteen_days",
  [ExpireTime.ThirtyDays]: "thirty_days",
  [ExpireTime.Never]: "never",
  [ExpireTime.Debug10Min]: "debug_10_min",
  [ExpireTime.SixHours]: "six_hours",
});

export enum MeasuringUnit {
  Centimeter,
  Inch,
}

export const measuringUnitNamer = createEnumNamer({
  [MeasuringUnit.Centimeter]: "cm",
  [MeasuringUnit.Inch]: "inch",
});

export const MEASURING_UNITS = [MeasuringUnit.Centimeter, MeasuringUnit.Inch];

export const spuStateNamer = createEnumNamer({
  [SpuStatus.Unavailable]: "unavailable",
  [SpuStatus.Available]: "available",
  [SpuStatus.Unclassified]: "unclassified", // this is deprecated
  [SpuStatus.PendingAsk]: "pending",
  [SpuStatus.PendingBid]: "pending",
  [SpuStatus.Deleted]: "deleted",
});

export const SPU_SORT_KEYS: SortKey[] = [
  SortKey.Featured,
  SortKey.LowPrice, // Price low to high
  SortKey.HighPrice, // Price high to low
  SortKey.Latest,
  SortKey.Popular, // Most popular
  SortKey.NewLowestAsks, // New lowest asks
  SortKey.NewHighestBids, // New highest bids
];

export const MARKETPLACE_SORT_KEYS: SortKey[] = [
  SortKey.Featured,
  SortKey.LowPrice, // Price low to high
  SortKey.HighPrice, // Price high to low
  SortKey.Latest,
  SortKey.Popular, // Most popular
];

export const SPU_SEARCH_SORT_KEYS = [SortKey.MostRelevant, ...SPU_SORT_KEYS];

export const MARKETPLACE_SEARCH_SORT_KEYS = [
  SortKey.MostRelevant,
  ...MARKETPLACE_SORT_KEYS,
];

export const USER_SORT_KEYS = [
  SortKey.Latest,
  SortKey.LowPrice,
  SortKey.HighPrice,
];

/**
 * @private enumerate
 */
export enum GenderWithAll {
  All = "all",
  Men = "men",
  Women = "women",
}

export const GENDER_WITH_ALLS = [
  GenderWithAll.All,
  GenderWithAll.Men,
  GenderWithAll.Women,
];

export const mapGenderToGenderWithAll = (
  selectedGenders: Gender | Gender[]
): GenderWithAll => {
  if (
    Array.isArray(selectedGenders)
      ? selectedGenders.length !== 1
      : !selectedGenders
  )
    return GenderWithAll.All;
  return (Array.isArray(selectedGenders)
    ? selectedGenders[0]
    : selectedGenders) === Gender.Men
    ? GenderWithAll.Men
    : GenderWithAll.Women;
};

// conversation type extends
export enum ConversationTypeWithAll {
  All = -1,
  Buy = ConversationType.Buy,
  Sell = ConversationType.Sell,
}

export const mapConversationTypeWithAllToConversationType = (
  typeWithAll: ConversationTypeWithAll
) =>
  ({
    [ConversationTypeWithAll.All]: undefined,
    [ConversationTypeWithAll.Buy]: ConversationType.Buy,
    [ConversationTypeWithAll.Sell]: ConversationType.Sell,
  }[typeWithAll]);

export const mapConversationTypeToConversationTypeWithAll = (
  type: ConversationType
) =>
  ({
    [ConversationType.Buy]: ConversationTypeWithAll.Buy,
    [ConversationType.Sell]: ConversationTypeWithAll.Sell,
  }[type] ?? ConversationTypeWithAll.All);

export enum PostSearchContext {
  NewPost = 0,
  Discover = 1,
}

export enum BlockFrom {
  EnsembleFollowing = 0,
  EnsembleDiscover = 1,
  UserHome = 2,
}

export enum BlockReason {
  NotInterested = 0,
}

export const timeRangeNamer = createEnumNamer({
  [TimeRange.All]: "all",
  [TimeRange.OneMonth]: "1m",
  [TimeRange.ThreeMonths]: "3m",
  [TimeRange.SixMonths]: "6m",
  [TimeRange.OneYear]: "1y",
});
