import React, { FC, memo } from "react";
import { useLocation } from "react-router";
import classnames from "classnames";
import { URLUtils } from "@reversible/common";
import { PAYPAL_CLIENT_ID, PAYPAL_WEB_URL } from "@/env";
import { translate } from "@/i18n";
import { StyledComponentProps } from "@/interface/base";
import { Img } from "@/ui";
import { absoluteUrl } from "@/url";
import svgImage from "./paypal-icon.svg";
import styles from "./paypal-login-button.module.less";

const createRandomNumber = () => (1e8 * Math.random()) >> 0; // eslint-disable-line no-bitwise

export interface PayPalLoginButtonProps extends StyledComponentProps {
  forward?: string;
  withInstructions?: boolean;
}

export const PayPalLoginButton: FC<PayPalLoginButtonProps> = memo(
  ({ className, style, withInstructions = false, forward }) => {
    const location = useLocation();

    const onClick = () => {
      const link = URLUtils.apiJoinParams(`${PAYPAL_WEB_URL}/connect`, {
        flowEntry: "lipp_button",
        client_id: PAYPAL_CLIENT_ID,
        scope: "https://uri.paypal.com/services/paypalattributes",
        redirect_uri: absoluteUrl.callback("paypal-link"),
        response_type: "code",
        newUI: "Y",
        nonce: createRandomNumber(),
        state:
          forward || `${location.pathname}${location.search}${location.hash}`,
      });
      window.open(link, "__ppax__");
    };

    const buttonNode = (
      <button
        className={classnames(styles.button, className)}
        style={style}
        onClick={onClick}
        type="button"
      >
        <Img src={svgImage} type="auto" className={styles.img} />
        <span className={styles.text}>{translate("connect_with_paypal")}</span>
      </button>
    );

    return withInstructions ? (
      <>
        <h4 className={styles.instr}>
          {translate("connect_paypal_to_continue")}
        </h4>
        {buttonNode}
      </>
    ) : (
      buttonNode
    );
  }
);
