/**
 * img processors
 */

import SparkMD5 from "spark-md5";
import { loadImage, uniqueId } from "@reversible/common";

export const squareCropImageFile = async (imageFile: File) => {
  const blobSrc = URL.createObjectURL(imageFile);
  const imgElement = await loadImage(blobSrc);
  const { width, height } = imgElement;
  const size = Math.min(width, height);
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(
    imgElement,
    (width - size) / 2,
    (height - size) / 2,
    size,
    size,
    0,
    0,
    size,
    size
  );
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(
        new File([blob], imageFile.name, {
          type: imageFile.type,
        })
      );
    });
  });
};

export const getFileMD5 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const CHUNK_SIZE = 2097152; // Read in chunks of 2MB
    const chunkCount = Math.ceil(file.size / CHUNK_SIZE);
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    let currentChunk = 0;

    const loadNext = () => {
      const start = currentChunk * CHUNK_SIZE;
      const end =
        start + CHUNK_SIZE >= file.size ? file.size : start + CHUNK_SIZE;

      fileReader.readAsArrayBuffer(file.slice(start, end));
    };

    fileReader.onload = (e) => {
      spark.append(e.target.result);
      currentChunk++;

      if (currentChunk < chunkCount) {
        loadNext();
      } else {
        const hash = spark.end();

        resolve(hash);
      }
    };

    fileReader.onerror = (e) => {
      reject(e);
    };

    loadNext();
  });

/**
 * use a weak map to cache blob key globally
 */
const blobKeyWeakMap = new WeakMap<Blob, string>();

export const getBlobKey = (blob: Blob): string => {
  if (blobKeyWeakMap.has(blob)) {
    return blobKeyWeakMap.get(blob);
  }
  const uniqueKey = uniqueId();
  blobKeyWeakMap.set(blob, uniqueKey);
  return uniqueKey;
};
