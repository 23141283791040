import React, { FC } from "react";
import { ArrayUtils } from "@reversible/common";
import { Button, Tooltip, IconType, Icon } from "@/ui";

const ICON_LIST: IconType[] = ArrayUtils.unique([
  "left",
  "right",
  "up",
  "down",
  "close",
  "plus",
  "check",
  "share",
  "goto",
  "menu",
  "filter",
  "list",
  "eye-on",
  "eye-off",
  "more",
  "loading",
  "delete",
  "edit",
  "pin",
  "reload-circle",
  "exclamation-circle",
  "close-circle",
  "check-circle",
  "question",
  "send",
  "image",
  "message",
  "user",
  "sell",
  "favor",
  "favor-hollow",
  "search",
  "bell",
  "twitter",
  "instagram",
  "facebook",
  "history",
  "home",
  "location",
  "apple",
  "android",
]);

const UiDemoView: FC = () => {
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            gap: "20px",
          }}
        >
          <Button>
            hover me for tooltip
            <Tooltip trigger="hover-parent">
              Im not interactable, you cant hover on me
            </Tooltip>
          </Button>
          <Button>
            click me for tooltip
            <Tooltip trigger="click">Im interactable, try click me</Tooltip>
          </Button>
          <Button>
            hover me for tooltip
            <Tooltip trigger="hover">Im interactable, try hover on me</Tooltip>
          </Button>
        </div>
      </div>
      {ICON_LIST.map((type, index) => (
        <div key={type} style={{ fontSize: "24px", verticalAlign: "middle" }}>
          {index + 1} <Icon type={type} />
          {type}
        </div>
      ))}
    </>
  );
};

export default UiDemoView;
