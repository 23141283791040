import React, { FC } from "react";
import classNames from "classnames";
import { CallNext, useFlow, useFlowEffect ,
  MaybePromiseUtils,
  ObjectUtils,
  stop,
  uniqueId,
} from "@reversible/common";
import { translate } from "@/i18n";
import { AddressData } from "@/interface/address";
import * as apiService from "@/service";
import { CreateAddress, GetRegionByIp } from "@/service/interface";
import { useScreenSize } from "@/store/use-screen-size";
import { useSettingsInfo } from "@/store/use-settings-info";
import atomicStyles from "@/style/atomic.module.less";
import {
  AutoComplete,
  Form,
  FormItem,
  FormRules,
  Icon,
  Input,
  Tooltip,
  WithFormField,
  WithForm,
  Option,
  Select,
  Button,
} from "@/ui";
import { OptionItem } from "@/ui/select/interface";
import cardStyles from "./address-card.module.less";
import styles from "./address-form.module.less";
import { useAddressModel } from "./address.model";
import { RegionSelect } from "../setting-select";

const { map } = MaybePromiseUtils;

const AddressAutoLocate: FC<{
  onChange(overrides: Partial<AddressData>): void;
}> = ({ onChange }) => {
  const {
    data: { regionCodeDict, mapping },
  } = useSettingsInfo();

  useFlowEffect<void>(
    null,
    function* ({ call }) {
      const { data }: CallNext<GetRegionByIp> = yield call(
        apiService.getRegionByIp
      );
      if (data?.status === "success") {
        const { city, country, zip, regionName } = data;

        onChange({
          country: regionCodeDict[country] ? country : undefined,
          state: regionName,
          city,
          zipCode: zip,
          phoneCode: mapping[country]?.phoneCode || undefined,
        });
      }
    },
    []
  );

  return null;
};

/**
 * address edition or creation form
 */
interface AddressFormProps {
  data: AddressData;
  onExit(): void;
}

const { required, mobile: mobileRule } = FormRules;

const googlePlaceSessionToken = (() => {
  let current: string = uniqueId();
  return {
    update: () => {
      current = uniqueId();
    },
    get: () => current,
  };
})();

export const AddressForm: FC<AddressFormProps> = ({ data, onExit }) => {
  const {
    initiated: settingsInitiated,
    data: { regionCodeDict, mapping, phoneCodes },
  } = useSettingsInfo();

  const [, dispatchModelAction] = useAddressModel();

  const [{ loading }, onSubmit] = useFlow(
    null,
    function* ({ call, block }, formValue: AddressData) {
      yield block();
      const nextData = {
        ...data,
        ...formValue,
      };
      if (nextData.id) {
        yield call(apiService.updateAddress, {
          addressId: nextData.id,
          ...ObjectUtils.omit(nextData, ["id", "isDeletable"]),
        });
        dispatchModelAction({
          type: "update",
          addressId: nextData.id,
          overrides: ObjectUtils.omit(nextData, ["id"]),
        });
      } else {
        const {
          data: { id },
        }: CallNext<CreateAddress> = yield call(
          apiService.createAddress,
          ObjectUtils.omit(nextData, ["id", "isDeletable"])
        );
        dispatchModelAction({
          type: "create",
          data: {
            ...nextData,
            id,
          },
        });
      }
      onExit();
    }
  );

  const countryFormItem = (
    <FormItem
      className={styles.field}
      field="country"
      rules={[required(translate("please_select_country"))]}
    >
      <WithFormField<string>>
        {({ value, onChange }) => (
          <WithForm<AddressData>>
            {({ setValues }) => (
              <RegionSelect
                value={value}
                onChange={(country) => {
                  onChange(country);
                  setValues({
                    state: "",
                    phoneCode: mapping[country]?.phoneCode || "",
                  });
                }}
                placeholder={translate("country_region")}
              />
            )}
          </WithForm>
        )}
      </WithFormField>
    </FormItem>
  );

  const cityZipcodeFormItems = (
    <>
      <FormItem
        className={styles.field}
        field="state"
        rules={[required(translate("please_enter_state"))]}
      >
        <Input placeholder={translate("state")} />
      </FormItem>
      <FormItem
        className={styles.field}
        field="zipCode"
        rules={[required(translate("please_enter_zip_code"))]}
      >
        <Input placeholder={translate("zip_code")} />
      </FormItem>
    </>
  );

  const { isMobileScreenSize } = useScreenSize();

  return (
    <div onClick={stop()}>
      <Form<AddressData>
        className={styles.form}
        onSubmit={onSubmit}
        initialValues={data}
      >
        {!data.id && settingsInitiated ? (
          <WithForm<AddressData>>
            {({ setValues }) => <AddressAutoLocate onChange={setValues} />}
          </WithForm>
        ) : null}
        <div className={styles.fields}>
          <div className={styles.row}>
            <FormItem
              className={styles.field}
              field="firstName"
              rules={[required(translate("please_enter_first_name"))]}
            >
              <Input placeholder={translate("first_name")} />
            </FormItem>
            <FormItem
              className={styles.field}
              field="lastName"
              rules={[required(translate("please_enter_last_name"))]}
            >
              <Input placeholder={translate("last_name")} />
            </FormItem>
          </div>
          <div className={styles.row}>
            <FormItem
              className={styles.field}
              field="address"
              rules={[required(translate("please_enter_address"))]}
            >
              <WithFormField<string>>
                {({ value: address, onChange: onChangeAddress }) => (
                  <WithForm<AddressData>>
                    {({ values, setValues }) => {
                      const onRequestOptions = (text: string) =>
                        text.trim()
                          ? map(
                              apiService.getGooglePlacePredictions({
                                input: text,
                                countryCode: regionCodeDict[values.country],
                                sessiontoken: googlePlaceSessionToken.get(),
                              }),
                              ({ data: predictions }) =>
                                predictions.map((prediction) => ({
                                  value: prediction.description,
                                  name: prediction.description,
                                  data: prediction.id,
                                }))
                            )
                          : [];
                      const onChange = (
                        address: string,
                        option: OptionItem<string>
                      ) => {
                        onChangeAddress(address);
                        if (option) {
                          const sessiontoken = googlePlaceSessionToken.get();
                          googlePlaceSessionToken.update();
                          map(
                            apiService.getGooglePlaceDetail({
                              id: option.data,
                              sessiontoken,
                            }),
                            ({ data: matched }) => {
                              // make sure country is valid
                              const { country, ...rest } = matched;
                              setValues(
                                country && regionCodeDict[country]
                                  ? {
                                      ...matched,
                                      phoneCode: mapping[country]?.phoneCode,
                                    }
                                  : rest
                              );
                            }
                          );
                        }
                      };
                      return (
                        <AutoComplete
                          onFocus={googlePlaceSessionToken.update}
                          onRequestOptions={onRequestOptions}
                          delayTime={(text) => (text.trim() ? 200 : 0)}
                          value={address}
                          onChange={onChange}
                          placeholder={translate("address")}
                        />
                      );
                    }}
                  </WithForm>
                )}
              </WithFormField>
            </FormItem>
          </div>
          <div className={styles.row}>
            <FormItem className={styles.field} field="address2">
              <Input placeholder={translate("address2")} />
            </FormItem>
          </div>
          <div className={styles.row}>
            <FormItem
              className={styles.field}
              field="city"
              rules={[required(translate("please_enter_city"))]}
            >
              <Input placeholder={translate("city")} />
            </FormItem>
          </div>
          <div className={styles.row}>
            {countryFormItem}
            {!isMobileScreenSize ? cityZipcodeFormItems : null}
          </div>
          {isMobileScreenSize ? (
            <div className={styles.row}>{cityZipcodeFormItems}</div>
          ) : null}
          <div className={styles.row}>
            <FormItem
              className={styles.field}
              field="phoneCode"
              rules={[required(translate("please_select_phone_code"))]}
            >
              <Select
                placeholder={translate("phone_code")}
                filterable
                allowClear={false}
              >
                {phoneCodes.map((code) => (
                  <Option key={code} value={code}>
                    {code}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              className={classNames(styles.field, styles.field_span_2)}
              field="mobile"
              rules={[mobileRule]}
            >
              <Input placeholder={translate("phone")} inputMode="tel" />
            </FormItem>
          </div>
        </div>
        <div className={classNames(styles.operators, atomicStyles.mobile_none)}>
          <button
            className={cardStyles.link_btn}
            type="button"
            onClick={onExit}
          >
            <Icon type="close" />
            <Tooltip>{translate("cancel")}</Tooltip>
          </button>
          <button className={cardStyles.link_btn} type="submit">
            <Icon type={loading ? "loading" : "check"} spin={loading} />
            <Tooltip>{translate("ok")}</Tooltip>
          </button>
        </div>
        <div
          className={classNames(styles.operators, atomicStyles.mobile_display)}
        >
          <Button className={styles.mobile_button} inline onClick={onExit}>
            {translate("cancel")}
          </Button>
          <Button
            inline
            type="fill"
            className={styles.mobile_button}
            htmlType="submit"
          >
            {translate("ok")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
