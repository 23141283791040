import { useCallback } from "react";
import { Flow, useFlow , Initiator, MaybePromise ,
  isFunction,
  MaybePromiseUtils,
  ScheduleUtils,
} from "@reversible/common";
import { AuthStatus } from "@/enum";
import { useAccount } from "@/store/use-account";
import { useDisplayStates } from "@/store/use-display-states";
import { tracker } from "@/system";
import { authFormModalResolvableCacher } from "@/util/cacher";

const { map } = MaybePromiseUtils;

/**
 * @param loginReason for tracking
 * @returns
 */
export const useRequestAuth = () => {
  const [
    {
      data: { status },
    },
  ] = useAccount();

  const [, setDisplayStates] = useDisplayStates();

  return useCallback(
    (loginReason = ""): MaybePromise<boolean> => {
      if (status === AuthStatus.LoggedIn) {
        return true;
      }

      tracker.track(
        "view_page",
        {
          page_name: "login",
          login_reason: loginReason,
        },
        {
          sourceType: "current-as-last",
          avoidSourceCreation: true,
        }
      );
      const resolvable = ScheduleUtils.createResolvable<boolean>();
      authFormModalResolvableCacher.set(resolvable); // set global resolvable cacher
      setDisplayStates({ authFormModalVisible: true }); // open auth modal
      return resolvable.promise; // waiting for login result
    },
    [status]
  );
};

export const useAuthFlow = <T, A = null>(
  initiator: Initiator<T>,
  flowAfterAuthed: Flow<T, A>,
  loginReason = ""
) => {
  const requestAuth = useRequestAuth();

  return useFlow<T, A>(initiator, function* ({ flow, call }) {
    const isAuthed = yield call(requestAuth, loginReason);

    if (isAuthed) {
      yield flow(flowAfterAuthed);
    }
  });
};

export const useAuthCallback = <Params extends any[]>(
  callback: (...params: Params) => void,
  deps: any[] = [callback],
  loginReason: string | ((...params: Params) => string) = ""
) => {
  const requestAuth = useRequestAuth();

  return useCallback(
    (...params: Params) => {
      const reasonStr = isFunction(loginReason)
        ? loginReason(...params)
        : loginReason;
      return map(requestAuth(reasonStr), (authed) => {
        if (authed) {
          callback(...params);
        }
      });
    },
    [requestAuth, ...deps]
  );
};
