import { matchLinks } from "@/util/format";
import React, { FC, memo, CSSProperties, Fragment } from "react";

export interface TextWithLinksProps {
  children: string;
  linkStyle?: CSSProperties;
  linkClassName?: string;
}

export const TextWithLinks: FC<TextWithLinksProps> = memo(
  ({ children, linkClassName, linkStyle }) => {
    return (
      <>
        {matchLinks(children).map(({ type, content }, index) =>
          type === "link" ? (
            <a
              target="_blank"
              key={index}
              className={linkClassName}
              style={linkStyle}
              href={content}
            >
              {content}
            </a>
          ) : (
            <Fragment key={index}>{content}</Fragment>
          )
        )}
      </>
    );
  }
);
