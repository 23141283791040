import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useFlow, Mutator, ScheduleUtils } from "@reversible/common";
import { onClickOutside } from "@/util/dom";
import { PositionData, usePosition } from "../hooks/use-position";

const listenHover = (
  targets: HTMLElement[],
  onShow: () => void,
  onHide: () => void
) => {
  for (const target of targets) {
    target.addEventListener("mouseover", onShow);
    target.addEventListener("mouseleave", onHide);
  }

  return () => {
    for (const target of targets) {
      target.removeEventListener("mouseover", onShow);
      target.removeEventListener("mouseleave", onHide);
    }
  };
};

export type PopupTriggerType = "hover-parent" | "click" | "hover";

export interface PopupAnchorProps {
  trigger: PopupTriggerType;
  layerNode?: HTMLDivElement;
  visible: boolean;
  setVisible(value: Mutator<boolean>): void;
  children?(positionData: PositionData): ReactNode;
}

export interface PopupControl {
  cancel(): void;
}
export const PopupAnchor = forwardRef<PopupControl, PopupAnchorProps>(
  ({ trigger, layerNode, visible, setVisible, children }, ref) => {
    // the anchor node to get the parent node
    const anchorRef = useRef<HTMLMetaElement>();

    const getParent = () => anchorRef.current?.parentNode as HTMLElement;

    type Action =
      | {
          type: "set";
          value: boolean;
          delay?: number;
        }
      | {
          type: "cancel";
        };
    const [, dispatch] = useFlow<void, Action>(
      null,
      function* ({ cancel, call }, action) {
        yield cancel();
        if (action.type === "set") {
          const { value, delay } = action;
          if (delay) {
            yield call(ScheduleUtils.timeout, delay);
          }
          yield call(setVisible, value);
        }
      }
    );

    useImperativeHandle(
      ref,
      () => ({
        cancel: () => {
          dispatch({ type: "cancel" });
        },
      }),
      []
    );

    useEffect(() => {
      const parentNode = getParent();

      if (!parentNode) return undefined;

      switch (trigger) {
        case "hover-parent":
          return listenHover(
            [parentNode],
            () => dispatch({ type: "set", value: true }),
            () => dispatch({ type: "set", value: false })
          );
        case "hover":
          return listenHover(
            [parentNode, layerNode],
            () => dispatch({ type: "set", value: true, delay: 150 }),
            () => dispatch({ type: "set", value: false, delay: 150 })
          );
        case "click": {
          const togglePopup = () => {
            setVisible((prev) => !prev);
          };

          parentNode.addEventListener("mousedown", togglePopup);

          return () => {
            parentNode.removeEventListener("mousedown", togglePopup);
          };
        }
        default:
          return undefined;
      }
    }, [trigger]);

    // subscribe click outside
    useEffect(() => {
      onClickOutside([layerNode, getParent()], () => setVisible(false));
    }, []);

    const positionData = usePosition(getParent, visible);

    return (
      <>
        <meta ref={anchorRef} name="popup-anchor" />
        {children && children(positionData)}
      </>
    );
  }
);
