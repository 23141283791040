import React, { FC, memo, useMemo } from "react";
import classNames from "classnames";
import { stop } from "@reversible/common";
import { UserStatus } from "@/enum";
import { UserInfoBrief } from "@/interface/account";
import { StyledComponentProps } from "@/interface/base";
import { reportConversion } from "@/system";
import { Icon } from "@/ui";
import { url } from "@/url";
import {
  createPersonOrOrganizationSchema,
  createSchemaLink,
} from "@/util/schema";
import { Avatar } from "./avatar";
import styles from "./user-shopping-info.module.less";
import { MaybeLink } from "../maybe-link";

export interface UserInfoBriefDisplayProps extends StyledComponentProps {
  data: UserInfoBrief;
  display?: boolean;
  dark?: boolean;
  size?: "small" | "mid" | "large";
  itemProp?: string;
  onClick?(): void;
}

export const UserShoppingInfo: FC<UserInfoBriefDisplayProps> = memo(
  ({
    data,
    size = "small",
    className = "",
    display = false,
    dark = false,
    itemProp,
    onClick,
    style,
  }) => {
    const userSchema = useMemo(
      () => createPersonOrOrganizationSchema(data),
      [data]
    );

    const {
      avatarUrl,
      username,
      isVerified,
      userTransaction,
      userReview,
      userStatus,
    } = data;

    const [onClickLink, linkTo] = useMemo(() => {
      if (display) return [undefined, undefined];
      return [
        stop(() => {
          reportConversion();
          if (onClick) {
            onClick();
          }
        }),
        url.userHome(username, { type: "listings" }),
      ];
    }, [display, username, onClick]);

    return (
      <div
        itemProp={itemProp}
        itemScope
        itemType={createSchemaLink(userSchema["@type"])}
        className={classNames(
          styles.user_brief,
          styles[`user_brief_${size}`],
          dark ? styles.user_brief_dark : styles.user_brief_light,
          className
        )}
        style={style}
        onClick={onClick}
      >
        <MaybeLink className={styles.avatar} onClick={onClickLink} to={linkTo}>
          <Avatar
            avatar={avatarUrl}
            className={styles.avatar_img}
            alt={username}
          />
          <meta name="image" content={avatarUrl} />
        </MaybeLink>
        <MaybeLink
          className={classNames(styles.name_row, {
            [styles.name_row_linkable]: !display,
          })}
          to={linkTo}
          onClick={onClickLink}
        >
          <h5 className={styles.name}>
            {username}
            {(["name", "identifier"] as const).map((key) => (
              <meta itemProp={key} key={key} content={userSchema[key]} />
            ))}
            <link itemProp="url" href={userSchema.url} />
          </h5>
          {isVerified ? (
            <Icon type="check-circle" className={styles.brief_verify_icon} />
          ) : null}
        </MaybeLink>
        {userStatus !== UserStatus.Official ? (
          <div className={styles.review_row}>
            <Icon type="favor" className={styles.review_icon} />
            <span className={styles.review}>{userReview.toFixed(1)}</span>
            <span className={styles.transaction}>({userTransaction})</span>
          </div>
        ) : null}
      </div>
    );
  }
);
