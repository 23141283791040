// import { createStore } from '@@/react-store';
import { createStore } from "@reversible/common";

const store = createStore();

export const StoreProvider = store.Provider;

export const createModel = store.createModel;

export const getState = store.getState;
