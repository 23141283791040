import React from "react";
import { Route } from "react-router";
import FormView from "./form";
import { DevLayout } from "../component/dev-layout";
import { urls } from "../url";

export const formRoute = () => (
  <Route exact path={urls.form()}>
    <DevLayout>
      <FormView />
    </DevLayout>
  </Route>
);
