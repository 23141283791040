import React, {
  FC,
  ButtonHTMLAttributes,
  MouseEvent,
  useState,
  useCallback,
  useContext,
} from "react";
import classnames from "classnames";
import { MaybePromise , noop } from "@reversible/common";
import { EmbeddedComponentProps, StyledComponentProps } from "@/interface/base";
import styles from "./button.module.less";
import { formContext } from "../form/context";
import { Icon, IconType } from "../icon";

export interface ButtonProps
  extends StyledComponentProps,
    EmbeddedComponentProps {
  // button styling props
  inline?: boolean;
  size?: "small" | "mid" | "large";
  type?: "fill" | "hollow";
  color?: "danger" | "black" | "white"; // exlusive to disabled
  icon?: IconType;
  suffixIcon?: IconType;
  // button status
  disabled?: boolean;
  loading?: boolean;
  showLoadingIcon?: boolean;
  htmlType?: ButtonHTMLAttributes<any>["type"];
  form?: string;
  onClick?(e?: MouseEvent): MaybePromise<void>;
  onMouseOver?(e?: MouseEvent): void;
  onMouseLeave?(e?: MouseEvent): void;
}

export const Button: FC<ButtonProps> = ({
  disabled = false,
  children,
  className = "",
  style,
  type = "hollow",
  color = "black",
  inline = false,
  icon,
  suffixIcon,
  size = "mid",
  onClick = noop,
  htmlType = "button",
  form,
  loading,
  showLoadingIcon = true,
  onMouseOver,
  onMouseLeave,
}) => {
  const formContextValue = useContext(formContext);

  const [innerLoading, setInnerLoading] = useState(false);

  const isLoading =
    loading != null
      ? loading
      : (htmlType === "submit" && formContextValue?.loading) || innerLoading;

  const innerOnClick = useCallback(
    (e: MouseEvent) => {
      const result = onClick(e);
      // if promise, set loading
      if (result instanceof Promise) {
        setInnerLoading(true);
        result.finally(() => {
          setInnerLoading(false);
        });
      }
    },
    [onClick]
  );

  const loadingIconVisible = showLoadingIcon && isLoading;

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      disabled={disabled}
      className={classnames(
        className,
        styles.button,
        {
          small: styles.button_small,
          mid: styles.button_mid,
          large: styles.button_large,
        }[size],
        {
          hollow: styles.button_hollow,
          fill: styles.button_fill,
          link: styles.button_link,
        }[type],
        {
          danger: styles.button_danger,
          black: styles.button_black,
          white: styles.button_white,
        }[color],
        {
          [styles.button_disabled]: disabled,
          [styles.button_loading]: isLoading,
          [styles.button_inline]: inline,
          [styles.button_block]: !inline,
        }
      )}
      style={style}
      type={htmlType}
      form={form}
      onClick={isLoading || disabled ? noop : innerOnClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {icon && !loadingIconVisible ? (
        <Icon className={styles.button_icon} type={icon} />
      ) : null}
      {loadingIconVisible ? (
        <Icon className={styles.button_icon} type="loading" spin />
      ) : null}
      {children}
      {suffixIcon ? (
        <Icon className={styles.button_icon} type={suffixIcon} />
      ) : null}
    </button>
  );
};
