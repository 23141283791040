import React, {
  MouseEvent,
  memo,
  useState,
  forwardRef,
  PropsWithChildren,
  CSSProperties,
  useContext,
  ReactNode,
} from "react";
import classnames from "classnames";
import { translate } from "@/i18n";
import { EmbeddedComponentProps, StyledComponentProps } from "@/interface/base";
import styles from "./img.module.less";
import { imgSourceContext } from "./share";

export type ImageType = "cover" | "contain" | "auto" | "fill";

export interface ImgCoreProps
  extends StyledComponentProps,
    EmbeddedComponentProps {
  zoomable?: boolean;
  alt?: string;
  itemProp?: string;
  imgClassName?: string;
  imgStyle?: CSSProperties;
  easeIn?: boolean;
  loadingNode?: ReactNode;
  type?: ImageType;
  primary?: boolean;
  fallback?: ReactNode;
  onClick?(e: MouseEvent): void;
  onClickImg?(e: MouseEvent): void;
  onLoad?(): void;
  onError?(): void;
}

enum ImageStatus {
  Loading,
  Error,
  Loaded,
}

export const ImgCore = memo(
  forwardRef<HTMLImageElement, PropsWithChildren<ImgCoreProps>>(
    (
      {
        className = "",
        style,
        zoomable,
        children,
        loadingNode,
        imgClassName = "",
        imgStyle,
        onClick,
        onError,
        onLoad,
        easeIn = true,
        type = "auto",
        primary = false,
        onClickImg,
        fallback,
        ...rest
      },
      ref
    ) => {
      const [imgStatus, setImgStatus] = useState<ImageStatus>(
        ImageStatus.Loading
      );

      const src = useContext(imgSourceContext);

      if (!src) {
        return (
          <div className={classnames(className, styles.no_image)}>
            {translate("no_image")}
          </div>
        );
      }

      return (
        <picture
          className={classnames(
            styles.picture,
            styles[`picture_${type}`],
            {
              [styles.picture_zoomable]: zoomable,
            },
            className
          )}
          style={style}
          onClick={onClick}
        >
          {imgStatus !== ImageStatus.Error ? (
            <div
              className={classnames(styles.hint, {
                [styles.hint_hidden]: imgStatus === ImageStatus.Loaded,
              })}
            >
              {loadingNode}
            </div>
          ) : null}
          {children}
          <img
            loading={primary ? "eager" : "lazy"}
            decoding={primary ? "sync" : undefined}
            ref={ref}
            className={classnames(
              styles.img,
              {
                [styles.img_loaded]: imgStatus === ImageStatus.Loaded,
                [styles.img_error]: imgStatus === ImageStatus.Error,
                [styles.img_transitive]: easeIn,
              },
              imgClassName
            )}
            onClick={onClickImg}
            style={imgStyle}
            src={src}
            alt="with-fallback"
            onError={() => {
              setImgStatus(ImageStatus.Error);
              if (onError) {
                onError();
              }
            }}
            onLoad={() => {
              setImgStatus(ImageStatus.Loaded);
              if (onLoad) {
                onLoad();
              }
            }}
            {...rest}
          />
          {imgStatus === ImageStatus.Error
            ? fallback ?? (
                <div className={styles.hint}>{translate("failed_to_load")}</div>
              )
            : null}
        </picture>
      );
    }
  )
);
