import React from "react";
import { Route } from "react-router";
import { DevLayout } from "../component/dev-layout";
import { urls } from "../url";
import MessageView from "./message";

export const messageRoute = () => (
  <Route exact path={urls.message()}>
    <DevLayout>
      <MessageView />
    </DevLayout>
  </Route>
);
