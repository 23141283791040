import React, { FC, memo, useMemo, ReactNode, Fragment } from "react";
import { useLocation } from "react-router";
import { translate } from "@/i18n";
import { useAccount } from "@/store/use-account";
import { tracker } from "@/system";
import {
  ActionButton,
  ActionSheet,
  ActionSheetProps,
  PopupTriggerType,
} from "@/ui";
import { url } from "@/url";
import { pathMatch } from "@/util/url";
import styles from "./account-menu.module.less";

export interface AccountMenu {
  name: string;
  path: string;
}

const accountMenuList: Omit<AccountMenu, "selected">[] = [
  {
    name: translate("edit_profile"),
    path: url.accountProfile(),
  },
  {
    name: translate("change_password"),
    path: url.changePassword(),
  },
  {
    name: translate("watchlist"),
    path: url.accountWatchList(),
  },
  {
    name: translate("my_offers"),
    path: url.accountOffers(),
  },
  {
    name: translate("my_orders"),
    path: url.accountOrders(),
  },
  {
    name: translate("feedbacks"),
    path: url.accountFeedbacks(),
  },
  {
    name: translate("payment"),
    path: url.accountPayment(),
  },
  {
    name: translate("manage_address"),
    path: url.accountAddress(),
  },
  {
    name: translate("preferences"),
    path: url.accountPreferences(),
  },
];

export interface AccountMenuProps {
  children: (menu: AccountMenu, selected: boolean) => ReactNode;
  matched?: AccountMenu;
}

export const useAccountMenuMatched = (specifiedMatched?: AccountMenu) => {
  const location = useLocation();
  return useMemo((): AccountMenu => {
    if (specifiedMatched !== undefined) return specifiedMatched;
    const { pathname } = location;
    return (
      accountMenuList.find(({ path }) => pathMatch(path, pathname)) || null
    );
  }, [location, specifiedMatched]);
};

export const AccountMenu: FC<AccountMenuProps> = memo(
  ({ children, matched }) => {
    const matchedAccountMenu = useAccountMenuMatched(matched);

    return (
      <>
        {accountMenuList.map((item) => (
          <Fragment key={item.name}>
            {children(item, item.name === matchedAccountMenu?.name)}
          </Fragment>
        ))}
      </>
    );
  }
);

export type AccountMenuActionSheetProps = Pick<
  ActionSheetProps,
  "trigger" | "header" | "onShow"
>;

export const AccountMenuActionSheet: FC<AccountMenuActionSheetProps> = (
  props
) => {
  const [
    {
      data: { account },
    },
    dispatch,
  ] = useAccount();

  return account ? (
    <ActionSheet
      className={styles.account_action_sheet}
      {...props}
      footer={
        <ActionButton
          onClick={() => {
            dispatch({
              type: "logout",
              reload: true,
            });
          }}
        >
          {translate("log_out")}
        </ActionButton>
      }
    >
      <AccountMenu>
        {({ name, path }) => <ActionButton to={path}>{name}</ActionButton>}
      </AccountMenu>
    </ActionSheet>
  ) : null;
};
