/**
 * view for development
 */

import React, { FC } from "react";
import { Switch, Redirect, HashRouter } from "react-router-dom";
import { FlowObject, noop } from "@reversible/common";
import { AccountPreferences } from "@/interface/account";
import { SettingsInfo } from "@/interface/data";
import { usePreferences } from "@/store/use-preferences";
import { useSettingsInfo } from "@/store/use-settings-info";
import { UiMount } from "@/ui";
import { addressRoute } from "./address";
import { chartRoute } from "./chart";
import { colorModesRoute } from "./color-modes";
import { formRoute } from "./form";
import { imageCompressRoute } from "./image-compress";
import { InstaIssueRoute } from "./insta-issue";
import { lottieRoute } from "./lottie";
import { messageRoute } from "./message";
import { uiRoute } from "./ui";
import { urls } from "./url";

const { context: settingsInfoContext } = useSettingsInfo;

const settingsInfoContextValue: FlowObject<SettingsInfo> = {
  data: {
    phoneCodes: [],
    regions: [],
    regionCodeDict: {},
    codeRegionDict: {},
    currencies: [],
    currencySymbolDict: {
      USD: "$",
    },
    languages: [],
    mapping: {},
  },
  ready: true,
  initiated: true,
  error: null,
  loading: false,
  latestEffectiveAction: null,
};

const { context: preferenceContext } = usePreferences;

const preferenceContextValue: readonly [
  AccountPreferences,
  (overrides: Partial<AccountPreferences>) => void
] = [
  {
    productRegion: "Canada", // User preferred region，displayName as value
    language: "English", // User preferred language
    currency: "USD", // User preferred currency
  },
  noop,
];

const DevView: FC = () => (
  <>
    <UiMount />
    <preferenceContext.Provider value={preferenceContextValue}>
      <settingsInfoContext.Provider value={settingsInfoContextValue}>
        <HashRouter>
          <Switch>
            {formRoute()}
            {uiRoute()}
            {messageRoute()}
            {addressRoute()}
            {InstaIssueRoute()}
            {imageCompressRoute()}
            {lottieRoute()}
            {colorModesRoute()}
            {chartRoute()}
            <Redirect to={urls.form()} />
          </Switch>
        </HashRouter>
      </settingsInfoContext.Provider>
    </preferenceContext.Provider>
  </>
);

export default DevView;
