import React, { FC, useCallback } from "react";
import { useValue } from "@reversible/common";
import { imgZoomController, imgGroupContext } from "./share";

let c = 0;

export const ImgGroup: FC = ({ children }) => {
  const group = useValue<
    {
      key: number; // symbol;
      src: string;
    }[]
  >([]);

  const register = useCallback((src: string) => {
    const key = c++; // Symbol("img-group-item");

    group.set((prev) => [
      ...prev,
      {
        key,
        src,
      },
    ]);

    return {
      zoom: () => {
        const groupQueue = group.get();
        const index = groupQueue.findIndex((item) => item.key === key);
        if (index !== -1) {
          imgZoomController.zoom({
            list: groupQueue.map(({ src }) => src),
            index,
          });
        }
      },
      unregister: () => {
        group.set((prev) => prev.filter((item) => item.key !== key));
      },
    };
  }, []);

  return (
    <imgGroupContext.Provider value={register}>
      {children}
    </imgGroupContext.Provider>
  );
};
