import variablesLess from "./shared-variables.module.less";

export const styleConsts = {
  deskHeaderHeight: Number(variablesLess.deskHeaderHeight),
  deskHeaderHeightTaller: Number(variablesLess.deskHeaderHeightTaller),
  mobileHeaderHeight: Number(variablesLess.mobileHeaderHeight),
  foldHeaderHeight: Number(variablesLess.foldHeaderHeight),
  foldScreenMaxWidth: Number(variablesLess.foldScreenMaxWidth),
  mobileScreenMaxWidth: Number(variablesLess.mobileScreenMaxWidth),
  xsScreenMaxWidth: Number(variablesLess.xsScreenMaxWidth),
  smScreenMaxWidth: Number(variablesLess.smScreenMaxWidth),
  lgScreenMinWidth: Number(variablesLess.lgScreenMinWidth),
  modalZIndex: Number(variablesLess.modalZIndex),
  optionsZIndex: Number(variablesLess.optionsZIndex),
  popupZIndex: Number(variablesLess.popupZIndex),
  toastZIndex: Number(variablesLess.toastZIndex),
  deltaZ: Number(variablesLess.deltaZ),
};
