import React, { FC } from "react";
import { useDrag, useDrop } from "react-dnd";
import classNames from "classnames";
import { ImageSource } from "@/interface/base";
import styles from "./image-select.module.less";
import { Icon } from "../icon";
import { Img } from "../img";

interface DroppableAreaProps {
  type: "pre" | "post";
  index: number;
  selectorKey: string;
  onDrop(targetIndex: number): void;
}
const DroppableArea: FC<DroppableAreaProps> = ({
  type,
  index,
  selectorKey,
  onDrop,
}) => {
  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: selectorKey,
      collect: (monitor) => {
        const draggingIndex = (monitor.getItem() as DragItem)?.index;

        return {
          isOver: monitor.isOver(),
          canDrop:
            monitor.canDrop() &&
            draggingIndex !== index &&
            draggingIndex + 1 !== index,
        };
      },
      drop: (indexDropped: DragItem) => onDrop(indexDropped.index),
    }),
    [index, type, onDrop]
  );

  return (
    <div
      className={classNames(
        styles.drop_area,
        {
          [styles.drop_area_over]: isOver,
          [styles.drop_area_droppable]: canDrop,
        },
        styles[`drop_area_${type}`]
      )}
      ref={dropRef}
    />
  );
};

export interface ImageBoxProps {
  selectorKey?: string;
  source: ImageSource;
  editable?: boolean;
  deletable?: boolean;
  draggable?: boolean;
  index?: number;
  onEdit?(): void;
  onMove?(from: number, to: number): void;
  onDelete?(): void;
}

interface DragItem {
  index: number;
}

export const ImageBox: FC<ImageBoxProps> = ({
  selectorKey = "",
  index = 0,
  source,
  editable = false,
  deletable = false,
  draggable = false,
  onEdit,
  onDelete,
  onMove,
}) => {
  const [{ isDragging }, dragRef] = useDrag(
    {
      type: selectorKey,
      item: { index } as DragItem,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: draggable,
    },
    [index]
  );

  return (
    <div
      className={classNames(styles.box, styles["box--uploaded"], {
        [styles.box__dragging]: isDragging,
      })}
      ref={dragRef}
    >
      <Img
        type="contain"
        className={styles.img_wrapper}
        imgClassName={styles.img}
        src={source}
        zoomable
      />
      <div className={styles.overlays}>
        {deletable ? (
          <a className={styles.btn} onClick={onDelete}>
            <Icon className={styles.icon} type="close" />
          </a>
        ) : null}
        {editable ? (
          <a className={styles.btn} onClick={onEdit}>
            <Icon className={styles.icon} type="edit" />
          </a>
        ) : null}
      </div>
      {draggable ? (
        <>
          <DroppableArea
            selectorKey={selectorKey}
            index={index}
            type="pre"
            onDrop={(from) => onMove(from, index)}
          />
          <DroppableArea
            selectorKey={selectorKey}
            index={index + 1}
            type="post"
            onDrop={(from) => onMove(from, index + 1)}
          />
        </>
      ) : null}
    </div>
  );
};
