import React, { FC, memo } from "react";
import classNames from "classnames";
import { stop } from "@reversible/common";
import { EmbeddedComponentProps, StyledComponentProps } from "@/interface/base";
import styles from "./checkable-input.module.less";
import { useFormFieldContext } from "../form";

// checkable
interface CheckableInputProps
  extends StyledComponentProps,
    EmbeddedComponentProps {
  value?: boolean;
  size?: "mid" | "large";
  checkerClassName?: string;
  disabled?: boolean;
  id?: string;
  onChange?(value: boolean): void;
  onClick?(): void;
}

const createCheckableInput = <T extends CheckableInputProps>(
  type: "checkbox" | "radio"
): FC<T> =>
  memo(
    ({
      className = "",
      style,
      size = "mid",
      disabled = false,
      checkerClassName,
      value: controlledValue,
      onChange: controlledOnChange,
      onClick,
      children,
      id: idOverride,
    }) => {
      const { field, value, onChange } = useFormFieldContext(
        controlledValue,
        controlledOnChange
      );

      const id = idOverride || field;

      const checkableNode = (
        <div
          className={classNames(
            styles.checkable,
            {
              [styles.checkable_checked]: value,
              [styles.checkable_radio]: type === "radio",
              [styles.checkable_checkbox]: type === "checkbox",
            },
            styles[`checkable_${size}`],
            checkerClassName
          )}
        >
          <input
            id={id}
            className={classNames(styles.checkable_input, {
              [styles.checkable_input_disabled]: disabled,
            })}
            disabled={disabled}
            type={type}
            checked={value}
            onChange={() => {
              if (onChange) {
                onChange(!value);
              }
            }}
          />
        </div>
      );

      return React.Children.count(children) ? (
        <div
          className={classNames(
            styles.checkable_wrapper,
            {
              [styles.checkable_wrapper_disabled]: disabled,
            },
            className
          )}
          style={style}
          onClick={onClick}
        >
          {checkableNode}
          <label
            onClick={id ? stop() : undefined}
            htmlFor={id}
            className={styles.label}
          >
            {children}
          </label>
        </div>
      ) : (
        <div className={className} style={style} onClick={onClick}>
          {checkableNode}
        </div>
      );
    }
  );

export type CheckboxProps = CheckableInputProps;
export type RadioProps = CheckableInputProps;

export const Checkbox = createCheckableInput<CheckboxProps>("checkbox");
export const Radio = createCheckableInput<RadioProps>("radio");
