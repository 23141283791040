import React, { FC, useRef } from "react";
import { useLottie } from "@/hooks/use-lottie";

const LottieView: FC = () => {
  const ref = useRef();

  useLottie("lowest_ask", () => ({
    container: ref.current,
    renderer: "canvas",
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "none",
    },
  }));

  return (
    <div
      style={{
        width: "1000px",
        height: "100px",
      }}
      ref={ref}
    />
  );
};

export default LottieView;
