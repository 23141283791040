import React, { FC } from "react";
import { useLayerInitiator } from "./hooks/use-layer";
import { ImgZoomer } from "./img";
import { useDialogInitiator } from "./modal";
import { ToastTrack } from "./toast";

export const UiMount: FC = () => {
  useLayerInitiator(document.body);
  useDialogInitiator();

  return (
    <>
      <ImgZoomer />
      <ToastTrack />
    </>
  );
};

export * from "./button";
export * from "./form";
export * from "./dots";
export * from "./gallery";
export * from "./icon";
export * from "./image-select";
export * from "./img";
export * from "./input";
export * from "./loading";
export * from "./modal";
export * from "./search-input";
export * from "./select";
export * from "./toggle";
export * from "./tab";
export * from "./table";
export * from "./textarea";
export * from "./popup";
export * from "./transition";
export * from "./toast";
export * from "./empty";
export * from "./async-display";
export * from "./tag";
export * from "./alert";
export * from "./pagination";
export * from "./unselectable";
export * from "./stars";
