/**
 * @author jinchenhao <jinchenhao@reversible.cn>
 * @since 2020-09-10 08:37:50
 */

import dayjs from "dayjs";
import { DateUtils } from "@reversible/common";
import { translate } from "@/i18n";

export const createDateFormater = (local = "en-US") => {
  // LATER: more locale
  const formater = new Intl.DateTimeFormat(local).format;
  return (date: number | Date) =>
    (date instanceof Date || typeof date === "number") &&
    DateUtils.isValid(date)
      ? formater(date)
      : "";
};

export const formatMessageTime = (() => {
  const now = dayjs();
  const startOfToday = now.startOf("day");
  const currentYearString = now.format("YYYY");
  return (date: Date, precise = true): string => {
    const day = dayjs(date);
    const timeString = day.format("HH:mm");
    if (startOfToday <= day) {
      // this day
      return timeString;
    }
    if (startOfToday.subtract(1, "day") <= day) {
      return precise
        ? `${translate("yesterday")} ${timeString}`
        : translate("yesterday");
    }
    const dateString = day.format("MMM D");
    const yearString = day.format("YYYY");
    return yearString === currentYearString
      ? precise
        ? `${dateString}, ${timeString}`
        : dateString
      : precise
      ? `${yearString} ${dateString}, ${timeString}`
      : `${yearString} ${dateString}`;
  };
})();

export const formatPostTime = (date: Date) => {
  const now = dayjs();
  const time = dayjs(date);
  const minutes = now.diff(time, "minute");
  if (!minutes) {
    return translate("just_now");
  }
  const hours = now.diff(time, "hour");
  if (!hours) {
    return translate("minutes_ago", { minutes });
  }
  const days = now.diff(time, "day");
  if (!days) {
    return translate("hours_ago", { hours });
  }
  if (days <= 7) {
    return translate("days_ago", { days });
  }
  const startOfYear = now.startOf("year");
  return time.format(startOfYear < time ? "MMM D" : "MMM D, YYYY");
};

export const formatCommentTime = (time: Date) => {
  const now = dayjs();
  const minutes = now.diff(time, "minutes");
  if (!minutes) {
    return `${now.diff(time, "seconds") || 1}s`;
  }
  const hours = now.diff(time, "hours");
  if (!hours) {
    return `${minutes}m`;
  }
  const days = now.diff(time, "days");
  if (!days) {
    return `${hours}h`;
  }
  const weeks = now.diff(time, "weeks");
  return weeks ? `${weeks}w` : `${days}d`;
};

export const formatOfferTime = (date: Date) => {
  const now = dayjs();
  const time = dayjs(date);
  const minutes = now.diff(time, "minute");
  if (!minutes) {
    return translate("just_now");
  }
  const hours = now.diff(time, "hour");
  if (!hours) {
    return translate("minutes_ago", { minutes });
  }
  if (hours < 24) {
    return translate("hours_ago", { hours });
  }
  if (hours < 48) {
    return `${translate("yesterday")} ${time.format("HH:mm")}`;
  }
  const startOfYear = now.startOf("year");
  const timeStr = time.format(startOfYear < time ? "MMM D" : "MMM D YYYY");
  return `${translate("on")} ${timeStr}`;
};

export const formatExpireTime = (
  baseTime: Date,
  validDurationInSeconds: number
) => {
  if (!validDurationInSeconds) {
    return translate("never_expires");
  }
  const ms =
    dayjs(baseTime).subtract(-validDurationInSeconds, "seconds").valueOf() -
    Date.now();
  if (ms < 0) {
    return translate("expired");
  }
  const MINUTE_MS = 1000 * 60;
  const HOUR_MS = MINUTE_MS * 60;
  const DAY_MS = HOUR_MS * 24;
  if (ms < MINUTE_MS) {
    return translate("expires_soon");
  }
  if (ms < HOUR_MS) {
    return translate("expires_in_minutes", {
      minute: Math.floor(ms / MINUTE_MS),
    });
  }
  if (ms < DAY_MS) {
    return translate("expires_in_hours", {
      hour: Math.floor(ms / HOUR_MS),
    });
  }
  return translate("expires_in_days", {
    day: Math.floor(ms / DAY_MS),
  });
};

export interface TextMatchResult {
  type: "link" | "text";
  content: string;
}
export const matchLinks = (text: string): TextMatchResult[] => {
  const regex =
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

  const output: TextMatchResult[] = [];

  let offset = 0;

  for (const result of text.matchAll(regex)) {
    const contentOffset = result.index;
    const content = result[0];

    // pre link push
    if (contentOffset > offset) {
      output.push({
        type: "text",
        content: text.slice(offset, contentOffset),
      });
    }

    output.push({
      type: "link",
      content,
    });

    offset = contentOffset + content.length;
  }

  if (text.length > offset) {
    output.push({
      type: "text",
      content: text.slice(offset),
    });
  }

  return output;
};
