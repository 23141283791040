import { useSuperState } from "@reversible/common";
import { createModel } from ".";

export type ModuleName = "shopping" | "lookbook" | "message" | "account";

/**
 * display related global states
 */
export const useDisplayStates = createModel(() =>
  useSuperState({
    paymentModalVisible: false,
    authFormModalVisible: false,
    tallerHeader: false,
    showNavSearch: false,
    moduleName: null as ModuleName,
  })
);
