import { UseUrlQueryConfig , identity, isNumeric, ObjectUtils, URLUtils } from "@reversible/common";
import { Gender } from "@/enum";
import {
  AppRedirectURLQuery,
  CallbackAction,
  EnsemblePostUrlQuery,
  EnsembleSearchUrlQuery,
  FollowURLQuery,
  LoginURLQuery,
  MessageURLQuery,
  OfferListUrlQuery,
  OrderListURLQuery,
  PasswordResetURLQuery,
  ProductDetailURLQuery,
  ProductFilterUrlQuery,
  ProductSearchUrlQuery,
  ProductUrlParam,
  UserHomeUrlQuery,
  WatchlistUrlQuery,
} from "@/interface/url";
import { marketplaceCacher } from "./util/cacher";
import { toArr } from "./util/data";
import { encodeDesigner, escapeName } from "./util/url";

const PRODUCT_DETAILS_OFFER_SPLITTER = "-";
export const productDetailsQueryConfig: Partial<
  UseUrlQueryConfig<ProductDetailURLQuery>
> = {
  queryToState: ({ offerId, ...rest }) => ({
    ...rest,
    offerId:
      offerId &&
      (isNumeric(offerId)
        ? Number(offerId)
        : offerId
            .split(PRODUCT_DETAILS_OFFER_SPLITTER)
            .map(Number)
            .filter((v) => !Number.isNaN(v))),
  }),
  stateToQuery: ({ offerId: raw, ...rest }) => {
    const arr = toArr(raw);

    return {
      ...rest,
      offerId: !arr.length
        ? undefined
        : arr.length === 1
        ? arr[0]
        : arr.join(PRODUCT_DETAILS_OFFER_SPLITTER),
    };
  },
};

const { joinParams, apiJoinParams } = URLUtils;

export const shoppingUrl = {
  shopping: () => "/shopping",
  selectGender: () => `${shoppingUrl.shopping()}/`,
  shoppingGender: (gender: Gender) =>
    `${shoppingUrl.shopping()}/${
      {
        [Gender.Men]: "men",
        [Gender.Women]: "women",
      }[gender] || ""
    }`,
  shoppingGenderHome: (gender: Gender) =>
    `${shoppingUrl.shoppingGender(gender)}/`,
  categoryItems: (
    gender: Gender,
    category: string,
    query: ProductFilterUrlQuery = {}
  ) =>
    joinParams(
      `${shoppingUrl.shoppingGender(gender)}/category/${category}`,
      query
    ),
  // product list filtered by designer
  newIn: (gender: Gender, query: ProductFilterUrlQuery = {}) =>
    joinParams(`${shoppingUrl.shoppingGender(gender)}/new-in`, query),
  marketplace: (gender: Gender, query: ProductFilterUrlQuery = {}) =>
    joinParams(`${shoppingUrl.shoppingGender(gender)}/marketplace`, query),
  designerCollection: (
    gender: Gender,
    designer: string,
    query: ProductFilterUrlQuery = {}
  ) =>
    joinParams(
      `${shoppingUrl.shoppingGender(gender)}/designer/${encodeDesigner(
        designer
      )}`,
      query
    ),
  productSearch: (gender: Gender, query: ProductSearchUrlQuery = {}) =>
    joinParams(`${shoppingUrl.shoppingGender(gender)}/search`, query),
  product: ({ id, gender, brand = "", name = "" }: ProductUrlParam) =>
    `${shoppingUrl.shoppingGender(gender)}/item/${[
      escapeName(brand),
      escapeName(name),
      id,
    ]
      .filter(identity)
      .join("-")}`,
  productDetail: (param: ProductUrlParam, query: ProductDetailURLQuery = {}) =>
    joinParams(
      `${shoppingUrl.product(param)}/`,
      productDetailsQueryConfig.stateToQuery(query)
    ),
  // buy offer
  buyOffer: (param: ProductUrlParam, offerId: string | number) =>
    `${shoppingUrl.product(param)}/buy/${offerId}`,
  // sell offer, unused
  sellOffer: (param: ProductUrlParam, offerId: string | number) =>
    `${shoppingUrl.product(param)}/sell/${offerId}`,
  // create ask
  createAsk: (param: ProductUrlParam, query: ProductDetailURLQuery = {}) =>
    joinParams(`${shoppingUrl.product(param)}/ask`, query),
  // create big
  createBid: (param: ProductUrlParam, query: ProductDetailURLQuery = {}) =>
    joinParams(`${shoppingUrl.product(param)}/bid`, query),
  // lookbook
  productLookbook: (param: ProductUrlParam) =>
    `${shoppingUrl.product(param)}/lookbook`,
  // i want to sell
  sell: () => `${shoppingUrl.shopping()}/sell`,
} as const;

export const ensembleUrl = {
  ensemble: () => "/ensemble",
  ensembleFollowing: () => `${ensembleUrl.ensemble()}/following`,
  ensembleDiscover: () => `${ensembleUrl.ensemble()}/discover`,
  ensembleSearch: (query: EnsembleSearchUrlQuery = {}) =>
    joinParams(`${ensembleUrl.ensemble()}/search`, query),
  ensembleTopic: (topicName: string) =>
    `${ensembleUrl.ensemble()}/topic/${topicName}`,
  postDetail: (postId: number | string, query: EnsemblePostUrlQuery = {}) =>
    joinParams(`${ensembleUrl.ensemble()}/${postId}`, query),
  createPost: () => `${ensembleUrl.ensemble()}/post`,
} as const;

export const accountUrl = {
  // account routes
  account: () => "/account",
  // account profile
  accountProfile: () => `${accountUrl.account()}/profile`,
  // change password
  changePassword: () => `${accountUrl.account()}/password`,
  // account payment
  accountPayment: () => `${accountUrl.account()}/payment`,
  // my offers
  accountOffers: () => `${accountUrl.account()}/offers`,
  // under my offers:
  accountOfferList: (query: OfferListUrlQuery = {}) =>
    joinParams(`${accountUrl.accountOffers()}/`, query),
  accountPendingProduct: () => `${accountUrl.accountOffers()}/pending-products`,
  accountOfferDetail: (offerId: number | string) =>
    `${accountUrl.accountOffers()}/${offerId}`,
  // my orders
  accountOrders: () => `${accountUrl.account()}/orders`,
  accountOrderList: (query: OrderListURLQuery = {}) =>
    joinParams(`${accountUrl.accountOrders()}/`, query),
  accountOrderDetail: (orderId: number | string) =>
    `${accountUrl.accountOrders()}/${orderId}`,
  // my address
  accountAddress: () => `${accountUrl.account()}/address`,
  // my feedback
  accountFeedbacks: () => `${accountUrl.account()}/feedbacks`,
  // preferences
  accountPreferences: () => `${accountUrl.account()}/preferences`,
  // watch list
  accountWatchList: (query: WatchlistUrlQuery = {}) =>
    joinParams(`${accountUrl.account()}/watch`, query),
} as const;

export const userUrl = {
  // user
  user: (username: string) => `/user/${username}`,
  // userhome
  userHome: (username: string, query: UserHomeUrlQuery = {}) =>
    joinParams(`${userUrl.user(username)}/`, query),
  // follower list of a user
  userFollow: (username: string, query: FollowURLQuery = {}) =>
    joinParams(`${userUrl.user(username)}/follow`, query),
} as const;

export const docsUrl = {
  // legal / faqs / measurable
  legal: () => `/legal`,
  terms: () => `${docsUrl.legal()}/terms-and-conditions`,
  privacy: () => `${docsUrl.legal()}/privacy-policy`,
  disclosure: () => `${docsUrl.legal()}/disclosure`,
  faq: () => "/faqs",
  measurable: () => `/measurable`,
} as const;

export const functionalUrl = {
  // app
  appRedirect: (query: AppRedirectURLQuery = {}) =>
    joinParams("/app-redirect", query),
  // callback
  callback: (action: CallbackAction | ":action") => `/callback/${action}`,
  // redirect to other platforms
  redirect: (query: any = {}) => apiJoinParams("/redirect", query),
} as const;

export const authUrl = {
  // login
  login: (query: LoginURLQuery = {}) => joinParams("/login", query),
  // password forgot and reset
  forgotPassword: () => "/forgot-password",
  resetPassword: (query: PasswordResetURLQuery = {}) =>
    joinParams("/reset-password", query),
} as const;

export const messageUrl = {
  // messages
  messages: (query: MessageURLQuery = {}) => joinParams("/messages", query),
} as const;

/**
 * url factories
 */
export const url = {
  // index
  index: () => "/",
  ...shoppingUrl,
  ...ensembleUrl,
  ...accountUrl,
  ...userUrl,
  ...messageUrl,
  ...authUrl,
  ...functionalUrl,
  ...docsUrl,
} as const;

/**
 * generate absolute url
 */
export const prefixUrlFactories = <
  T extends Record<string, (...params: any[]) => string>
>(
  factories: T,
  origin: string
): T =>
  ObjectUtils.immutableSet(
    factories,
    ["*"],
    (prev) =>
      (...params) =>
        `${origin}${prev(...params)}`
  );

// new

export const absoluteUrl = prefixUrlFactories(url, window.location.origin);

export const webviewDocsUrl = prefixUrlFactories(docsUrl, "/view");

export const proxiedUrl = {
  designerCollection: (
    gender: Gender,
    designer: string,
    query: ProductFilterUrlQuery = {}
  ) => {
    return url.designerCollection(gender, designer, {
      marketplace: marketplaceCacher.get() ? 1 : undefined,
      ...query,
    });
  },
  categoryItems: (
    gender: Gender,
    category: string,
    query: ProductFilterUrlQuery = {}
  ) => {
    return url.categoryItems(gender, category, {
      marketplace: marketplaceCacher.get() ? 1 : undefined,
      ...query,
    });
  },
  productSearch: (gender: Gender, query: ProductFilterUrlQuery) => {
    return url.productSearch(gender, {
      marketplace: marketplaceCacher.get() ? 1 : undefined,
      ...query,
    });
  },
};
