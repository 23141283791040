import React, { FC, useLayoutEffect, useState } from "react";
import { Route } from "react-router-dom";
import {
  useFlow,
  CallNext,
  useFlowEffect,
  MaybePromiseUtils,
  compressImageFile,
  MaybePromise,
} from "@reversible/common";
import { urls } from "@/dev/url";
import { ImageSelectBox, Img, IntegerInput } from "@/ui";
import { DevLayout } from "../component/dev-layout";

const { all, map } = MaybePromiseUtils;

const ImageGrid: FC<{
  data: Blob[];
}> = ({ data }) => (
  <div
    style={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
    }}
  >
    {data.map((file, i) => (
      <Img src={file} key={i} />
    ))}
  </div>
);

interface CompressedState {
  compressed: Blob[];
  details: string;
  timeCost: number;
}

const processImage = (
  file: File,
  maxSize: number
): MaybePromise<CompressedState> => {
  const start = Date.now();
  return map(compressImageFile(file, maxSize), (compressed) => {
    const timeCost = Date.now() - start;
    const originalSize = file.size / 1024 / 1024;
    const compressedSize = compressed.size / 1024 / 1024;
    const ratio = (compressedSize / originalSize) * 100;
    const details = `time cost: ${timeCost}ms, file size: ${originalSize.toFixed(
      2
    )}MB -> ${compressedSize.toFixed(2)}MB (${ratio.toFixed(2)}%)\n`;
    return {
      compressed: [compressed],
      details,
      timeCost,
    };
  });
};

const ImageCompressRoute: FC = () => {
  const [fileList, setFileList] = useState<File[]>([]);
  const [maxSize, setMaxSize] = useState<number>();

  const [{ data: oneByOneData, error }, dispatch] = useFlow<
    CompressedState,
    void
  >(null, function* ({ put, call, cancel }) {
    yield cancel();
    yield put(null);
    if (!fileList.length) return;
    const reducers: ((prev: CompressedState) => CompressedState)[] = [];
    for (const file of fileList) {
      const { compressed, details, timeCost }: CallNext<typeof processImage> =
        yield call(processImage, file, maxSize);
      reducers.push((prev) => ({
        compressed: (prev?.compressed || []).concat(compressed),
        details: (prev?.details || "").concat(details),
        timeCost: (prev?.timeCost || 0) + timeCost,
      }));
    }
    yield put((prev) => {
      return reducers.reduce((current, next) => next(current), prev);
    });
  });

  const { data: parallelData } = useFlowEffect<CompressedState>(
    null,
    function* ({ put, call, cancel }) {
      yield cancel();
      yield put(null);
      if (!fileList.length || !oneByOneData) return;
      const parallel = () => all(fileList.map(processImage));
      const output: CallNext<typeof parallel> = yield call(parallel);
      const details = output
        .reduce((current, { details }) => `${current}${details}`, "")
        .concat(``);
      yield put({
        compressed: output.flatMap(({ compressed }) => compressed),
        details,
        timeCost: output.reduce(
          (current, { timeCost }) => (timeCost > current ? timeCost : current),
          0
        ),
      });
    },
    [oneByOneData]
  );

  useLayoutEffect(() => {
    dispatch();
  }, [fileList]);

  return (
    <>
      <ImageSelectBox onSelect={(files) => setFileList(Array.from(files))} />
      <IntegerInput
        style={{ width: "200px", marginBottom: "50px" }}
        placeholder="max width or height"
        value={maxSize}
        onChange={setMaxSize}
        onBlur={dispatch}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "10px",
        }}
      >
        <section>
          <h2>Original</h2>
          <ImageGrid data={fileList} />
        </section>
        {[oneByOneData, parallelData].map((data) =>
          data ? (
            <section>
              <h2>One By One</h2>
              <pre>{oneByOneData.details}</pre>
              <pre>total time cost: {data.timeCost}ms</pre>
              <ImageGrid data={oneByOneData.compressed} />
            </section>
          ) : null
        )}
      </div>
    </>
  );
};

export const imageCompressRoute = () => (
  <Route exact path={urls.imageCompress()}>
    <DevLayout>
      <ImageCompressRoute />
    </DevLayout>
  </Route>
);
