import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
} from "react";
import { Mutator , stop, applyMutator } from "@reversible/common";
import { DocumentController } from "@/util/dom";
import { ImgCore } from "./img-core";
import styles from "./img.module.less";
import { imgZoomController, ZoomingState, imgSourceContext } from "./share";
import { Icon } from "../icon";
import { Dialog } from "../modal";

export const ImgZoomer: FC = () => {
  const [zoomingState, setZoomingState] = useState<ZoomingState>(null);

  const onClose = () => setZoomingState(null);

  const [onPrev, onNext] = useMemo(() => {
    const applyIndexMutator = (indexMutator: Mutator<number>) => () =>
      setZoomingState((prev) => {
        const nextIndex = applyMutator(indexMutator, prev.index);
        if (
          nextIndex !== prev.index &&
          nextIndex >= 0 &&
          nextIndex < prev.list.length
        ) {
          return {
            ...prev,
            index: nextIndex,
          };
        }
        return prev;
      });
    return [
      applyIndexMutator((index) => index - 1),
      applyIndexMutator((index) => index + 1),
    ];
  }, []);

  useLayoutEffect(() => {
    imgZoomController.zoom = setZoomingState;
  }, []);

  const visible = !!zoomingState;

  useEffect(() => {
    if (visible) {
      return DocumentController.listenKeydown({
        ArrowLeft: onPrev,
        ArrowRight: onNext,
      });
    }
    return undefined;
  }, [visible]);

  return (
    <Dialog visible={visible} onClose={onClose} global>
      {visible ? (
        <imgSourceContext.Provider
          value={zoomingState.list[zoomingState.index]}
        >
          <ImgCore
            key={zoomingState.list[zoomingState.index]}
            className={styles.zoom_img}
            imgClassName={styles.zoom_img_el}
            alt="zoom"
            type="contain"
            onClick={onClose}
            onClickImg={stop()}
          />
          <button type="button" className={styles.zoom_close} onClick={onClose}>
            <Icon className={styles.close_icon} type="close" />
          </button>
          {zoomingState.list.length > 1 ? (
            <div className={styles.controller}>
              <button
                disabled={zoomingState.index <= 0}
                type="button"
                className={styles.controller_prev}
                onClick={onPrev}
              >
                <Icon type="left" />
              </button>
              <span className={styles.controller_text}>
                {zoomingState.index + 1}/{zoomingState.list.length}
              </span>
              <button
                disabled={zoomingState.index >= zoomingState.list.length - 1}
                type="button"
                className={styles.controller_next}
                onClick={onNext}
              >
                <Icon type="right" />
              </button>
            </div>
          ) : null}
        </imgSourceContext.Provider>
      ) : null}
    </Dialog>
  );
};
