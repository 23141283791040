import { createContext } from "react";

export const selectContext =
  createContext<{
    multi: boolean;
    selectedValue: any;
    onChange(value: any, data: any): void;
    pointer?: number;
    setPointer?(index: number): void;
  }>(null);
