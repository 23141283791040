import React, { FC, useContext } from "react";
import { styleConsts } from "@/style";
import { imgSourceContext } from "./share";

export type MediaType = "md" | "sm" | "xs" | "mobile";

const mediaTypeDict: Record<MediaType, string> = {
  md: "lgScreenMinWidth",
  sm: "smScreenMaxWidth",
  xs: "xsScreenMaxWidth",
  mobile: "mobileScreenMaxWidth",
};

export interface SourceProps {
  media?: MediaType;
  size: 80 | 200 | 400 | 800;
}

export const Source: FC<SourceProps> = ({ media, size }) => {
  const src = useContext(imgSourceContext);

  const mediaStr = media
    ? `(max-width: ${styleConsts[mediaTypeDict[media]]}px)`
    : undefined;

  return <source srcSet={`${src}-width${size}`} media={mediaStr} />;
};
