import { toArr } from "./data";

export class MixedSet {
  protected sets: Record<string, Set<any>[]> = {};

  static join(...mixedSets: MixedSet[]) {
    const jointSet = new MixedSet();
    const fieldSet = new Set(
      mixedSets.flatMap((mixedSet) => Object.keys(mixedSet.sets))
    );
    for (const field of fieldSet) {
      jointSet.sets[field] = mixedSets.flatMap(
        (mixedSet) => mixedSet.sets[field] || []
      );
    }
    return jointSet;
  }

  has(field: string, value: any): boolean {
    return field in this.sets && this.sets[field].some((set) => set.has(value));
  }

  /**
   * batched add method for certain field
   * @param field
   * @param value
   */
  add(field: string, value: any) {
    if (!this.sets[field]) {
      this.sets[field] = [new Set()];
    }
    const valueList = toArr(value);
    for (const item of valueList) {
      this.sets[field][0].add(item);
    }
  }
}
