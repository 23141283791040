import React, { FC } from "react";
import { noop } from "@reversible/common";
import { AddressForm } from "@/component/address-management/address-form";
import { AddressModelProvider } from "@/component/address-management/address.model";

const AddressView: FC = () => {
  return (
    <AddressModelProvider>
      <AddressForm
        data={{
          id: 0,
          firstName: "",
          lastName: "",
          address: "",
          city: "",
          state: "",
          country: "Canada",
          zipCode: "",
          mobile: "",
          email: "",
          nickname: "",
          address2: "",
          isDefault: false,
          isDeletable: true,
          phoneCode: "+1",
        }}
        onExit={noop}
      />
    </AddressModelProvider>
  );
};

export default AddressView;
