/**
 * the ultimate component for offer acception
 */

import React, {
  FC,
  memo,
  ReactElement,
  ReactNode,
  useMemo,
  useState,
} from "react";
import {
  CallNext,
  GetNext,
  useFlow,
  useFlowEffect,
  useValue,
  ObjectUtils,
} from "@reversible/common";
import { PaypalOrderDisplay } from "@/component/paypal-order-display";
import {
  Condition,
  conditionNamer,
  offerTypeTrackerNamer,
  OfferType,
} from "@/enum";
import { useShippingPrice } from "@/hooks/use-shipping-price";
import { translate } from "@/i18n";
import { UserInfoBrief, UserInfoBriefAlter } from "@/interface/account";
import { Shipping } from "@/interface/address";
import { PayPalOrderInfo, SpuData } from "@/interface/product";
import * as apiService from "@/service";
import {
  ApprovePaymentOrder,
  GetPaymentOrderId,
  GetPaymentOrderInfo,
} from "@/service/interface";
import { useAccount } from "@/store/use-account";
import { tracker } from "@/system";
import { AsyncDisplay, Button, ErrorAlert, FormItem, Icon } from "@/ui";
import styles from "./offer-acception-form.module.less";
import { AddressSelect } from "../address-management";
import { PayPalButton } from "../paypal-button";
import { PayPalLoginButton } from "../paypal-login-button";
import { OriginalPriceDisplay } from "../price";
import { SizeUnit } from "../size-unit";
import { UserShoppingInfo } from "../user-info";

const { pick } = ObjectUtils;

export type AskInfoForAcception = (
  | {
      id: number;
      offerShipping?: Shipping;
    }
  | {
      messageId: number;
    }
) & {
  size: string;
  sizeUnit: string;
  price: number;
  condition: Condition;
  currency: string;
  originalCurrency: string;
  originalPrice: number;
  offerCountry: string;
} & UserInfoBriefAlter;

export interface OfferAcceptionFormRender {
  (title: ReactNode, content: ReactNode): ReactElement;
}

export interface AskOfferAcceptionFormProps {
  askInfo: AskInfoForAcception;
  spuData: SpuData;
  publicOfferId: number; // for tracker
  onComplete?(): void;
  completeOperator: ReactNode;
  children: OfferAcceptionFormRender;
}

// const defaultRender: OfferAcceptionFormRender = (title, content) => (
//   <>
//     {title}
//     {content}
//   </>
// );

type PaymentAction =
  | {
      type: "create";
    }
  | {
      type: "execute";
    };

enum PaymentOrderStatus {
  Init,
  Created,
  Executed,
}

interface PaymentState {
  status: PaymentOrderStatus;
  paypalOrder: PayPalOrderInfo;
}

export const AskOfferAcceptionForm: FC<AskOfferAcceptionFormProps> = ({
  askInfo,
  spuData,
  publicOfferId,
  completeOperator,
  onComplete,
  children,
}) => {
  const [
    {
      data: {
        account: { paymentAccount },
      },
    },
  ] = useAccount();

  const paypalOrderIdValue = useValue<string>("");

  const [address, setAddress] = useState(undefined);

  const shippingPrice = useShippingPrice(
    address?.country,
    "id" in askInfo ? askInfo.offerShipping : null
  );

  const {
    size,
    currency,
    price,
    originalCurrency,
    originalPrice,
    condition,
    userId,
    username,
    sizeUnit,
  } = askInfo;

  const paymentTrackerInfo = useMemo(
    () => ({
      payment_method: "paypal",
      is_preapprove: false,
      spu_id: spuData.id,
      gender: spuData.gender,
      brand_name: spuData.brand,
      category1_name: spuData.category1,
      category2_name: spuData.category2,
      offer_id: publicOfferId,
      offer_user_id: userId,
      offer_user_name: username,
      offer_condition_id: condition,
      offer_size_id: size,
      offer_price: originalPrice,
      offer_ship_price: shippingPrice?.originalPrice || 0,
      offer_total_price: originalPrice + (shippingPrice?.originalPrice || 0),
      offer_currency: originalCurrency,
      offer_type_name: offerTypeTrackerNamer(OfferType.Ask),
    }),
    [publicOfferId, askInfo, shippingPrice]
  );

  const userData = useMemo(
    (): UserInfoBrief => ({
      ...pick(askInfo, [
        "username",
        "userReview",
        "userStatus",
        "userTransaction",
        "isVerified",
        "avatarUrl",
      ]),
      id: askInfo.userId,
    }),
    [askInfo]
  );

  const [
    {
      data: { status, paypalOrder },
      error,
    },
    dispatch,
  ] = useFlow<PaymentState, PaymentAction>(
    {
      status: PaymentOrderStatus.Init,
      paypalOrder: null,
    },

    function* ({ get, call, put, block }, action) {
      const { status: currentStatus }: GetNext<PaymentState> = yield get();

      yield block();

      switch (action.type) {
        case "create": {
          if (currentStatus !== PaymentOrderStatus.Init) {
            return;
          }
          // track
          tracker.track("payment", paymentTrackerInfo);

          // create order
          const { data: paypalOrderId }: CallNext<GetPaymentOrderId> =
            yield call(
              apiService.getPaymentOrderId,
              "id" in askInfo
                ? {
                    offerId: askInfo.id,
                    addressId: address.id,
                  }
                : {
                    messageId: askInfo.messageId,
                    addressId: address.id,
                  }
            );
          paypalOrderIdValue.set(paypalOrderId);
          yield put({ status: PaymentOrderStatus.Created, paypalOrder: null });
          break;
        }
        case "execute": {
          if (currentStatus !== PaymentOrderStatus.Created) {
            return;
          }
          const { data }: CallNext<ApprovePaymentOrder> = yield call(
            apiService.approvePaymentOrder,
            {
              paypalOrderId: paypalOrderIdValue.get(),
            }
          );
          yield call(apiService.executePaymentOrder, {
            paypalOrderId: paypalOrderIdValue.get(),
          });

          // track
          tracker.track("payment_result", paymentTrackerInfo);

          yield put({ status: PaymentOrderStatus.Executed, paypalOrder: data });
          if (onComplete) {
            onComplete();
          }
          break;
        }
        default:
          break;
      }
    }
  );

  if (error) {
    return children(translate("payment_error"), <ErrorAlert error={error} />);
  }

  switch (status) {
    case PaymentOrderStatus.Init:
    case PaymentOrderStatus.Created: {
      return children(
        translate("payment"),
        <>
          <FormItem label={translate("seller")}>
            <UserShoppingInfo size="mid" data={userData} display />
          </FormItem>
          <FormItem label={translate("condition")}>
            {conditionNamer(condition)}
          </FormItem>
          <FormItem
            label={
              <>
                {translate("size")}
                <SizeUnit>{sizeUnit}</SizeUnit>
              </>
            }
          >
            {size}
          </FormItem>
          <FormItem label={translate("price")}>
            <OriginalPriceDisplay
              className={styles.price}
              price={price + (shippingPrice?.price || 0)}
              currency={currency}
              originalPrice={
                originalPrice + (shippingPrice?.originalPrice || 0)
              }
              originalCurrency={originalCurrency}
              includesShipping
            />
          </FormItem>
          {paymentAccount ? (
            <>
              <FormItem required label={translate("address")}>
                <AddressSelect
                  value={address?.id}
                  onChange={(_, address) => setAddress(address)}
                  shipping={"id" in askInfo ? askInfo.offerShipping : undefined}
                />
              </FormItem>
              <footer className={styles.footer}>
                <PayPalButton
                  currency={originalCurrency}
                  disabled={!address}
                  createOrder={async () => {
                    await dispatch({ type: "create" });
                    return paypalOrderIdValue.get();
                  }}
                  onApprove={() => dispatch({ type: "execute" })}
                />
              </footer>
            </>
          ) : (
            <footer className={styles.footer}>
              <PayPalLoginButton />
            </footer>
          )}
        </>
      );
    }
    case PaymentOrderStatus.Executed: {
      return children(
        <>
          <Icon className={styles.success_icon} type="check-circle" />
          {translate("payment_completed")}
        </>,
        <>
          <PaypalOrderDisplay
            orderInfo={paypalOrder}
            spuData={spuData}
            shippingPrice={shippingPrice}
          />
          <footer className={styles.footer}>{completeOperator}</footer>
        </>
      );
    }
    default:
      return null;
  }
};

export type BidInfoForAcception = {
  paymentOrderId: string;
};

export interface BidOfferAcceptionFormProps {
  bidInfo: BidInfoForAcception;
  spuData: SpuData;
  completeOperator: ReactNode;
  children: OfferAcceptionFormRender;
  onComplete?(): void;
}

export const BidOfferAcceptionForm: FC<BidOfferAcceptionFormProps> = memo(
  ({ bidInfo, spuData, completeOperator, onComplete, children }) => {
    const paypalOrderState = useFlowEffect<PayPalOrderInfo>(
      null,
      function* ({ cancel, call, put }) {
        yield cancel();
        const { data }: CallNext<GetPaymentOrderInfo> = yield call(
          apiService.getPaymentOrderInfo,
          {
            paypalOrderId: bidInfo.paymentOrderId,
          }
        );
        yield put(data);
      },
      [bidInfo.paymentOrderId]
    );

    const [{ data: isExecuted, error }, execute] = useFlow(
      false,
      function* ({ call, block, put }) {
        yield block();
        yield call(apiService.executePaymentOrder, {
          paypalOrderId: bidInfo.paymentOrderId,
        });

        const { offerInfo, buyerInfo } = paypalOrderState.data;
        tracker.track("payment_result", {
          payment_method: "paypal",
          is_preapprove: true,
          spu_id: spuData.id,
          gender: spuData.gender,
          brand_name: spuData.brand,
          category1_name: spuData.category1,
          category2_name: spuData.category2,
          offer_id: offerInfo.id, //
          offer_user_id: buyerInfo.id,
          offer_user_name: buyerInfo.username,
          offer_condition_id: offerInfo.offerCondition,
          offer_size_id: offerInfo.size,
          offer_price: offerInfo.originalOfferPrice,
          offer_ship_price: 0,
          offer_total_price: offerInfo.originalOfferPrice,
          offer_currency: offerInfo.originalOfferCurrency,
          offer_type_name: offerTypeTrackerNamer(OfferType.Bid),
        });

        yield put(true);
        if (onComplete) {
          onComplete();
        }
      }
    );

    if (error) {
      return children(translate("payment_error"), <ErrorAlert error={error} />);
    }

    return (
      <AsyncDisplay source={paypalOrderState}>
        {(orderInfo) => {
          if (isExecuted) {
            return children(
              translate("order_confirmed"),
              <>
                <PaypalOrderDisplay spuData={spuData} orderInfo={orderInfo} />
                <footer className={styles.footer}>{completeOperator}</footer>
              </>
            );
          }
          return children(
            translate("confirm_order"),
            <>
              <PaypalOrderDisplay spuData={spuData} orderInfo={orderInfo} />
              <footer className={styles.footer}>
                <Button type="fill" onClick={execute}>
                  {translate("confirm")}
                </Button>
              </footer>
            </>
          );
        }}
      </AsyncDisplay>
    );
  }
);
