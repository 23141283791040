import React, {
  MouseEvent,
  memo,
  useCallback,
  useMemo,
  useContext,
  forwardRef,
  useEffect,
} from "react";
import { useValue , noop } from "@reversible/common";
import { ImageSource } from "@/interface/base";
import { ImgCore, ImgCoreProps } from "./img-core";
import { imgGroupContext, imgZoomController, imgSourceContext } from "./share";

export interface ImgProps extends ImgCoreProps {
  src: ImageSource;
}

const blobWeakMap = new WeakMap<Blob, string>();

export const Img = memo(
  forwardRef<HTMLImageElement, ImgProps>(
    ({ onClick, zoomable, src, ...rest }, ref) => {
      const stringSrc = useMemo(() => {
        if (src instanceof Blob) {
          const cachedObjectURL = blobWeakMap.get(src);
          if (cachedObjectURL) {
            return cachedObjectURL;
          }
          const objectURL = URL.createObjectURL(src);
          blobWeakMap.set(src, objectURL);
          return objectURL;
        }
        return src;
      }, [src]);

      const groupContext = useContext(imgGroupContext);

      const zoomerHolder = useValue<{
        zoom: () => void;
      }>({
        zoom: noop,
      });

      useEffect(() => {
        if (zoomable) {
          if (groupContext) {
            const { zoom, unregister } = groupContext(stringSrc);
            zoomerHolder.set({ zoom });
            return unregister;
          }

          zoomerHolder.set({
            zoom: () =>
              imgZoomController.zoom({
                index: 0,
                list: [stringSrc],
              }),
          });
        }

        return undefined;
      });

      const onClickImage = useCallback(
        (e: MouseEvent) => {
          if (zoomable) {
            zoomerHolder.get().zoom();
          }
          if (onClick) {
            onClick(e);
          }
        },
        [onClick]
      );

      const props: ImgCoreProps = {
        onClick: onClickImage,
        zoomable,
        ...rest,
      };

      return (
        <imgSourceContext.Provider value={stringSrc}>
          {/* use key to ensure a whole different component is loaded, so that no state competition would occur */}
          <ImgCore ref={ref} key={stringSrc} zoomable={zoomable} {...props} />
        </imgSourceContext.Provider>
      );
    }
  )
);
