import React, { FC, useContext, useMemo, ReactNode } from "react";
import classnames from "classnames";
import { EmbeddedComponentProps } from "@/interface/base";
import { formContext, formFieldContext } from "./context";
import { FormField, FormFieldProps } from "./form-field";
import styles from "./form-item.module.less";

export interface FormItemCoreProps extends EmbeddedComponentProps {
  className?: string;
  label?: ReactNode;
  labelClassName?: string;
  contentClassName?: string;
  alertClassName?: string;
  vertical?: boolean;
  alwaysHorizontal?: boolean;
  alert?: string; // alert
  required?: boolean;
}

const LabelDisplay: FC<
  Pick<FormItemCoreProps, "label" | "required" | "className">
> = ({ label, className, required }) => {
  const formFieldState = useContext(formFieldContext);

  return (
    <label className={className} htmlFor={formFieldState?.field}>
      {label}
      {required ? <i className={styles.require_symbol}>*</i> : null}
    </label>
  );
};

const AlertDisplay: FC<Pick<FormItemCoreProps, "className" | "children">> = ({
  className,
  children,
}) => {
  const formFieldState = useContext(formFieldContext);

  // there could be situation when not formFieldContext is provided
  // while an alert text is provided through the props
  const alertText = children != null ? children : formFieldState?.alert;

  return alertText ? (
    <div className={classnames(styles["form-alert"], className)}>
      {alertText}
    </div>
  ) : null;
};

export type FormItemProps = EmbeddedComponentProps &
  (FormItemCoreProps | (FormItemCoreProps & FormFieldProps));

/**
 * the FormItem component
 */
export const FormItem: FC<FormItemProps> = ({
  label,
  children,
  alert,
  vertical = false,
  alwaysHorizontal = false,
  required = false,
  className,
  labelClassName,
  contentClassName,
  alertClassName,
  ...restProps
}) => {
  const wrappedChildren = useMemo(() => {
    const childrenType = typeof children;
    if (childrenType === "string" || childrenType === "number") {
      return (
        <div className={classnames(styles["text-content"])}>{children}</div>
      );
    }
    return children;
  }, [children]);

  const formContentNode = (
    <>
      <div className={classnames(styles["form-content"], contentClassName)}>
        {wrappedChildren}
      </div>
      <AlertDisplay className={alertClassName}>{alert}</AlertDisplay>
    </>
  );

  const coreNode = label ? (
    <div
      className={classnames(
        styles.form_item,
        {
          [styles.form_item_horizontal]: !vertical,
          [styles.form_item_vertical]: vertical,
          [styles.form_item_horizontal_always]: !vertical && alwaysHorizontal,
        },
        className
      )}
    >
      <LabelDisplay
        label={label}
        required={required}
        className={classnames(styles.label, labelClassName)}
      />
      {formContentNode}
    </div>
  ) : (
    <div className={classnames(styles.form_item_no_label, className)}>
      {formContentNode}
    </div>
  );

  const formStateContext = useContext(formContext);

  return "field" in restProps && formStateContext ? (
    <FormField {...restProps} alert={alert}>
      {coreNode}
    </FormField>
  ) : (
    coreNode
  );
};
