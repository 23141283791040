import React, { FC, memo, useMemo, useState } from "react";
import classNames from "classnames";
import { stop } from "@reversible/common";
import { translate } from "@/i18n";
import { AddressData, Shipping } from "@/interface/address";
import { EmbeddedComponentProps, StyledComponentProps } from "@/interface/base";
import atomicStyles from "@/style/atomic.module.less";
import {
  ErrorAlert,
  Icon,
  Loading,
  Tag,
  Tooltip,
  ActionSheet,
  ActionButton,
} from "@/ui";
import styles from "./address-card.module.less";
import { AddressForm } from "./address-form";
import { useAddressModel } from "./address.model";

/**
 * pure display card
 * enables operation embedding
 */
interface AddressCardProps
  extends StyledComponentProps,
    EmbeddedComponentProps {
  data: AddressData;
  disabled?: boolean;
  backgrounded?: boolean;
}

export const AddressCard: FC<AddressCardProps> = ({
  data,
  disabled = false,
  children,
  backgrounded = false,
  className,
  style,
}) => {
  const {
    firstName,
    lastName,
    zipCode,
    address,
    address2,
    city,
    mobile,
    phoneCode,
    isDefault,
    state,
    country,
  } = data;

  return (
    <div
      className={classNames(
        styles.card,
        {
          [styles.card_backgrounded]: backgrounded,
          [styles.card_disabled]: disabled,
        },
        className
      )}
      style={style}
    >
      <header className={styles.header}>
        <h3 className={styles.name}>
          {firstName} {lastName}
        </h3>
        <div className={styles.phone}>
          {phoneCode} {mobile}
        </div>
        {isDefault ? (
          <Tag className={styles.tag}>{translate("default")}</Tag>
        ) : null}
        {disabled ? (
          <Tag className={classNames(styles.tag, styles.tag_disabled)}>
            {translate("unavailable")}
          </Tag>
        ) : null}
        <span className={atomicStyles.spaceholder} />
        {children}
      </header>
      <div className={styles.address}>{address}</div>
      <div className={styles.address}>{address2}</div>
      <div className={styles.zip_code}>
        {city}, {state} {zipCode}
      </div>
      <div className={styles.country}>{country}</div>
      {backgrounded ? (
        <Icon className={styles.location_icon} type="location" />
      ) : null}
    </div>
  );
};

interface AddressCardSelectedProps {
  selectedId: number;
  shipping: Shipping;
  onManageAddress(): void;
}

/**
 * the card displays the selected address
 */
export const AddressCardSelected: FC<AddressCardSelectedProps> = memo(
  ({ selectedId, shipping, onManageAddress }) => {
    const [{ data: addressList, initiated, error }] = useAddressModel();

    const selectedAddress = useMemo(
      () => addressList.find(({ id }) => id === selectedId),
      [addressList, selectedId]
    );

    if (error) {
      return <ErrorAlert error={error} />;
    }

    if (!initiated) {
      return <Loading title={translate("loading_address")} />;
    }

    if (!addressList.length) {
      return (
        <div className={classNames(styles.card, styles.card_empty)}>
          <div className={styles.empty_title}>{translate("no_address")}</div>
          <div className={styles.empty_link_row}>
            <a className={styles.create_link} onClick={() => onManageAddress()}>
              {translate("create_now")}
            </a>
          </div>
          <Icon className={styles.location_icon} type="location" />
        </div>
      );
    }

    if (!selectedAddress) {
      return (
        <div className={classNames(styles.card, styles.card_empty)}>
          <div className={styles.empty_title}>
            {translate("seller_only_ships_to_regions_below")}:
          </div>
          <ul className={styles.region_list}>
            {(shipping || []).map(({ region }) => (
              <li className={styles.region} key={region}>
                {region}
              </li>
            ))}
          </ul>
          <div className={styles.empty_link_row}>
            <a className={styles.create_link} onClick={() => onManageAddress()}>
              {translate("manage_address")}
            </a>
          </div>
          <Icon className={styles.location_icon} type="location" />
        </div>
      );
    }

    return (
      <AddressCard backgrounded data={selectedAddress}>
        <button
          className={styles.link_btn}
          type="button"
          onClick={stop(() => onManageAddress())}
        >
          <Icon type="edit" />
          <Tooltip>{translate("manage_address")}</Tooltip>
        </button>
      </AddressCard>
    );
  }
);

/**
 * editable card for address
 */
export interface AddressCardEditableProps {
  data: AddressData;
  disabled?: boolean;
}

export const AddressCardEditable: FC<AddressCardEditableProps> = ({
  data,
  disabled,
}) => {
  const [editing, setEditing] = useState(false);

  const [, dispatchModelAction] = useAddressModel();

  const { id, isDefault, isDeletable } = data;

  const onDelete = () => {
    return dispatchModelAction({
      type: "delete",
      addressId: id,
    });
  };

  return editing ? (
    <AddressForm data={data} onExit={() => setEditing(false)} />
  ) : (
    <AddressCard data={data} disabled={disabled}>
      {!isDefault ? (
        <button
          className={styles.link_btn}
          type="button"
          onClick={stop(() =>
            dispatchModelAction({
              type: "set-default",
              data,
            })
          )}
        >
          <Icon className={styles.icon} type="pin" />
          <Tooltip>{translate("set_as_default")}</Tooltip>
        </button>
      ) : null}
      <button
        className={styles.link_btn}
        type="button"
        onClick={stop(() => setEditing(true))}
      >
        <Icon className={styles.icon} type="edit" />
        <Tooltip>{translate("edit_address")}</Tooltip>
      </button>
      {isDeletable ? (
        <button className={styles.link_btn} type="button" onClick={stop()}>
          <Icon className={styles.icon} type="delete" />
          <ActionSheet align="center" header={translate("delete_this_address")}>
            <ActionButton type="danger" onClick={onDelete}>
              {translate("delete")}
            </ActionButton>
          </ActionSheet>
        </button>
      ) : null}
    </AddressCard>
  );
};
