import { useCallback } from "react";
import { getWindowSizes, identity } from "@reversible/common";
import { useSyncExternalStore } from "@/data-store/use-sync-external-store";
import { SizeDims } from "@/interface/base";
import { createListenerCollection } from "@/util/listener-collection";

const listenerCollection = createListenerCollection<void>();

let windowSize = getWindowSizes() as SizeDims;

window.addEventListener("resize", () => {
  windowSize = getWindowSizes() as SizeDims;
  listenerCollection.notify();
});

export const useWindowSize = <T = SizeDims>(
  selector: (data: SizeDims) => T = identity as (data: SizeDims) => T,
  isEqual?: (a: T, b: T) => boolean
): T => {
  const getSnapshot = useCallback((): T => {
    const selected = selector(windowSize);
    return selected;
  }, [selector]);

  return useSyncExternalStore(
    listenerCollection.subscribe,
    getSnapshot,
    isEqual
  );
};
