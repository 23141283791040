import React from "react";
import { Route } from "react-router";
import { DevLayout } from "../component/dev-layout";
import { urls } from "../url";
import UiDemoView from "./ui";

export const uiRoute = () => (
  <Route exact path={urls.ui()}>
    <DevLayout>
      <UiDemoView />
    </DevLayout>
  </Route>
);
