import React, { FC, useEffect, useState, useMemo } from "react";
import { translate } from "@/i18n";
import { AddressData, Shipping } from "@/interface/address";
import { useSettingsInfo } from "@/store/use-settings-info";
import { WithoutFormField, Modal, AsyncDisplay } from "@/ui";
import { AddressCard, AddressCardSelected } from "./address-card";
import { AddressList } from "./address-list";
import styles from "./address-management.module.less";
import { AddressModelProvider, useAddressModel } from "./address.model";

/**
 * for account address management
 * @returns
 */
export const AddressManagementList: FC = () => (
  <AddressModelProvider>
    <AddressList />
  </AddressModelProvider>
);

export interface AddressSelectProps {
  value: number;
  shipping?: Shipping; // shipping info is to limit the address selection
  onChange(id: number, address: AddressData): void;
}

const AddressSelectCore: FC<AddressSelectProps> = ({
  value,
  shipping,
  onChange,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [addressModelState] = useAddressModel();

  const { data: addressList, initiated } = addressModelState;

  const settingsState = useSettingsInfo();

  const onChangeAddress = (address: AddressData) => {
    onChange(address?.id, address);
  };

  const isAddressSelectableFunc = useMemo(() => {
    if (!settingsState.ready) return null;
    if (!shipping) return () => true;
    const availableRegionKeySet = new Set(shipping.map(({ region }) => region));
    return ({ country }: AddressData) => {
      if (availableRegionKeySet.has(country)) return true;
      const mapped = settingsState.data.mapping[country];
      return mapped && availableRegionKeySet.has(mapped.shippingZoneName);
    };
  }, [shipping, settingsState]);

  const isFirstAddress = !addressList.length;

  const availableAddressList = useMemo(() => {
    if (!initiated || !isAddressSelectableFunc) return null;
    return addressList.filter(isAddressSelectableFunc);
  }, [isAddressSelectableFunc, addressList, initiated]);

  // auto correct the selection
  // avoiding selecting an address that's not in the list
  // could happen when the edit bidding
  useEffect(() => {
    if (
      availableAddressList &&
      value &&
      !availableAddressList.find(({ id }) => id === value)
    ) {
      onChangeAddress(undefined);
    }
  }, [initiated, value, addressList, isAddressSelectableFunc]);

  // auto select available default address
  // for accepting offer
  useEffect(() => {
    if (availableAddressList && !value) {
      const defaultAddress =
        availableAddressList.find(({ isDefault }) => isDefault) ||
        availableAddressList[0];
      if (defaultAddress) {
        onChangeAddress(defaultAddress);
      }
    }
  }, [isAddressSelectableFunc, initiated, addressList]);

  return (
    <>
      <AddressCardSelected
        shipping={shipping}
        selectedId={value}
        onManageAddress={() => setModalVisible(true)}
      />
      <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        className={styles.modal}
        title={translate(
          initiated
            ? isFirstAddress
              ? "create_address"
              : "manage_address"
            : "loading_address"
        )}
      >
        {modalVisible ? (
          <AsyncDisplay source={settingsState}>
            {() => (
              <AsyncDisplay
                source={addressModelState}
                cover
                loadingNode={<></>}
                emptyWhen={() => false}
              >
                {(addressList) => {
                  const selectedAddress = addressList.find(
                    ({ id }) => id === value
                  );
                  return (
                    <>
                      {shipping ? (
                        <div>
                          <div className={styles.available_region_title}>
                            {translate("seller_only_ships_to_regions_below")}:
                          </div>
                          <ul className={styles.region_list}>
                            {(shipping || []).map(({ region }) => (
                              <li className={styles.region} key={region}>
                                {region}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                      <AddressList
                        isAddressSelectable={isAddressSelectableFunc}
                        selected={selectedAddress}
                        selectable
                        onSelect={onChangeAddress}
                        onClose={() => setModalVisible(false)}
                      />
                    </>
                  );
                }}
              </AsyncDisplay>
            )}
          </AsyncDisplay>
        ) : null}
      </Modal>
    </>
  );
};

export const AddressSelect: FC<AddressSelectProps> = (props) => (
  <WithoutFormField>
    <AddressModelProvider>
      <AddressSelectCore {...props} />
    </AddressModelProvider>
  </WithoutFormField>
);

/**
 * selected card for display
 * used in pure display scenario when you only have addressId
 */
export interface AddressSelectDisplayProps {
  addressId: number;
}

const AddressSelectDisplayCore: FC<AddressSelectDisplayProps> = ({
  addressId,
}) => {
  const [addressListState] = useAddressModel();

  return (
    <AsyncDisplay source={addressListState}>
      {(addressList) => {
        const selectedAddress = addressList.find(({ id }) => id === addressId);

        if (!selectedAddress) return null;

        return <AddressCard backgrounded data={selectedAddress} />;
      }}
    </AsyncDisplay>
  );
};

export const AddressSelectDisplay: FC<AddressSelectDisplayProps> = (props) => (
  <AddressModelProvider>
    <AddressSelectDisplayCore {...props} />
  </AddressModelProvider>
);
