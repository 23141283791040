import { DEPLOY_ENV, GOOGLE_ADS_CONVERSION_EVENT_ID } from "@/env";
import { AnyFunc } from "@/interface/base";

declare global {
  interface Window {
    gtag: AnyFunc;
  }
}

export function reportConversion(): void {
  if (DEPLOY_ENV !== "dev") {
    window.gtag("event", "conversion", {
      send_to: GOOGLE_ADS_CONVERSION_EVENT_ID,
      value: 1.0,
    });
  } else {
    console.log("[debug]: report conversion"); // eslint-disable-line no-console
  }
}
