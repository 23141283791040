import React, { FC, useState } from "react";
import classnames from "classnames";
import { noop } from "@reversible/common";
import { StyledComponentProps } from "@/interface/base";
import styles from "./stars.module.less";
import { useFormFieldContext } from "../form";
import { Icon } from "../icon";

export interface StarsProps extends StyledComponentProps {
  value?: number;
  onChange?(value: number): void;
}

export interface StarsDisplayProps {
  value: number;
  starsClassName?: string;
  onClickStar?(starValue: number): void;
  onHoverStar?(starValue: number): void;
  onUnhoverStars?(): void;
}

export const StarsDisplay: FC<StarsDisplayProps> = ({
  value,
  starsClassName,
  onHoverStar = noop,
  onUnhoverStars = noop,
  onClickStar = noop,
}) => (
  <div className={styles.wrapper}>
    <div
      className={classnames(styles.stars, starsClassName)}
      onMouseLeave={onUnhoverStars}
    >
      {Array(5)
        .fill(null)
        .map((_, index) => {
          const starValue = index + 1;
          const lighted = starValue <= value;
          return (
            <a
              className={classnames(styles.star, {
                [styles.star_lighted]: lighted,
              })}
              key={index + 1}
              onMouseEnter={() => onHoverStar(starValue)}
              onClick={() => onClickStar(starValue)}
            >
              <Icon
                className={styles.star_icon}
                type={lighted ? "favor" : "favor-hollow"}
              />
            </a>
          );
        })}
    </div>
    <div className={styles.star_value}>{value ? value.toFixed(1) : ""}</div>
  </div>
);

export const Stars: FC<StarsProps> = ({
  value: controlledValue,
  onChange: controlledOnChange,
}) => {
  const { value, onChange } = useFormFieldContext(
    controlledValue,
    controlledOnChange
  );

  const [hoverValue, setHoverValue] = useState(0);

  const displayValue = hoverValue || value;

  return (
    <StarsDisplay
      value={displayValue}
      starsClassName={styles.stars_interactable}
      onClickStar={onChange}
      onHoverStar={setHoverValue}
      onUnhoverStars={() => setHoverValue(0)}
    />
  );
};
