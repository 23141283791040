import React, { FC, memo } from "react";
import classnames from "classnames";
import { StyledComponentProps } from "@/interface/base";
import styles from "./toggle.module.less";
import { useFormFieldContext } from "../form";

interface ToggleProps extends StyledComponentProps {
  value?: boolean;
  size?: "small" | "mid" | "large";
  onChange?(value: boolean): void;
}

export const Toggle: FC<ToggleProps> = memo(
  ({
    className = "",
    style,
    size = "mid",
    value: controlledValue,
    onChange: controlledOnChange,
  }) => {
    const { value, onChange } = useFormFieldContext(
      controlledValue,
      controlledOnChange
    );

    return (
      <div
        onClick={() => onChange(!value)}
        style={style}
        className={classnames(
          className,
          styles.switch,
          {
            [styles["switch--on"]]: value,
          },
          {
            small: styles.switch_small,
            mid: styles.switch_mid,
            large: styles.switch_large,
          }[size]
        )}
      >
        <div
          className={classnames(styles.toggle, {
            [styles["toggle--on"]]: value,
          })}
        />
      </div>
    );
  }
);
