import { ObjectUtils, uniqueId } from "@reversible/common";
import { LOCATION_QUEUE_STORE_KEY } from "@/const";
import { DEPLOY_ENV } from "@/env";
import { AccountInfo } from "@/interface/account";
import { PrimitiveValue } from "@/interface/base";
import { getState } from "@/store";
import { LocationState } from "@/store/use-location-stack";
import { initHealth } from "./health";
import {
  dispatchAction,
  getDistinctId as getMixpanelDistinctId,
  MixpanelAction,
} from "./mixpanel";

export * from "./google-ads-conversion";

const mixpanelRef = {
  dispatch: (action: MixpanelAction) => {
    console.log("[DEV ONLY] TRACKER ACTION", action); // eslint-disable-line no-console
  },
  getDistinctId: () => "",
};

type SourceType = "last" | "current-as-last" | "inherit";

const fieldNames = {
  CURRENT_PAGE_NAME: "page_name",
  CURRENT_PAGE_TAB: "page_tab",
  LAST_PAGE_NAME: "last_page_name",
  LAST_PAGE_TAB: "last_page_tab",
  CONTEXT: "context",
};

const historyMetaIO = {
  setCurrent: (meta: Record<string, any>) => {
    const { currentLocation } = getState(
      LOCATION_QUEUE_STORE_KEY
    ) as LocationState;
    if (currentLocation) {
      Object.assign(currentLocation.meta, meta); // overrides meta with a mutable method
    }
  },
  getSource: () => {
    const { sourceLocation } = getState(
      LOCATION_QUEUE_STORE_KEY
    ) as LocationState;
    return sourceLocation?.meta || {};
  },
  getCurrent: () => {
    const { currentLocation } = getState(
      LOCATION_QUEUE_STORE_KEY
    ) as LocationState;
    return currentLocation?.meta || {};
  },
};

const { pick } = ObjectUtils;
export const tracker = {
  identify: (account: AccountInfo) => {
    mixpanelRef.dispatch({
      type: "identify",
      account,
    });
  },
  cancel: (id: string) => {
    mixpanelRef.dispatch({
      type: "cancel",
      id,
    });
  },
  track: (
    name: string,
    props: Record<string, any> = {},
    {
      sourceType,
      avoidSourceCreation = false,
      context,
      clearContext,
      trackWithContext,
      delay,
    }: {
      sourceType?: SourceType;
      avoidSourceCreation?: boolean;
      context?: Record<string, PrimitiveValue>;
      clearContext?: boolean;
      trackWithContext?: boolean;
      delay?: number;
    } = {}
  ): string => {
    let trackProps = props;
    const currentMeta = historyMetaIO.getCurrent();
    const currentContext = currentMeta[fieldNames.CONTEXT];
    if (trackWithContext) {
      if (!currentContext) {
        return "";
      }
      trackProps = {
        ...currentContext,
        ...trackProps,
      };
    }
    switch (sourceType) {
      case "current-as-last": {
        trackProps = {
          [fieldNames.LAST_PAGE_NAME]:
            currentMeta[fieldNames.CURRENT_PAGE_NAME] || "",
          [fieldNames.LAST_PAGE_TAB]:
            currentMeta[fieldNames.CURRENT_PAGE_TAB] || "",
          ...trackProps,
        };
        break;
      }
      case "last": {
        const soueceMeta = historyMetaIO.getSource();
        trackProps = {
          [fieldNames.LAST_PAGE_NAME]:
            soueceMeta[fieldNames.CURRENT_PAGE_NAME] || "",
          [fieldNames.LAST_PAGE_TAB]:
            soueceMeta[fieldNames.CURRENT_PAGE_TAB] || "",
          ...trackProps,
        };
        break;
      }
      case "inherit":
        trackProps = {
          ...pick(currentMeta, [
            fieldNames.CURRENT_PAGE_NAME,
            fieldNames.CURRENT_PAGE_TAB,
          ]),
          ...trackProps,
        };
        break;
      default:
        break;
    }
    if (!avoidSourceCreation && props[fieldNames.CURRENT_PAGE_NAME]) {
      historyMetaIO.setCurrent({
        [fieldNames.CURRENT_PAGE_NAME]: props[fieldNames.CURRENT_PAGE_NAME],
        [fieldNames.CURRENT_PAGE_TAB]: props[fieldNames.CURRENT_PAGE_TAB] || "",
      });
    }
    if (context) {
      historyMetaIO.setCurrent({
        [fieldNames.CONTEXT]: context,
      });
    } else if (clearContext) {
      historyMetaIO.setCurrent({
        [fieldNames.CONTEXT]: undefined,
      });
    }
    const id = uniqueId();
    mixpanelRef.dispatch({
      type: "track",
      name,
      props: trackProps,
      delay,
      id,
    });
    return id;
  },
  increment: (name: string, count = 1) => {
    mixpanelRef.dispatch({
      type: "increment",
      name,
      count,
    });
  },
};

export const initSystem = () => {
  if (DEPLOY_ENV !== "dev") {
    dispatchAction({
      type: "init",
    });
    mixpanelRef.dispatch = dispatchAction;
    mixpanelRef.getDistinctId = getMixpanelDistinctId;
  }

  initHealth();
};

export const getDistinctId = () => mixpanelRef.getDistinctId();
