import React, {
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { useFlow , MaybePromise , isFunction, ScheduleUtils, uniqueId } from "@reversible/common";
import type { StyledComponentProps } from "@/interface/base";
import atomicStyles from "@/style/atomic.module.less";
import styles from "./auto-complete.module.less";
import { selectContext } from "./context";
import { OptionItem } from "./interface";
import { Option } from "./option";
import { OptionsPopup } from "./options-popup";
import selectStyles from "./select.module.less";
import { useFormFieldContext } from "../form";
import { Icon } from "../icon";
import { InputWrapper } from "../input-box";
import { Loading } from "../loading";
import { VisibleTransition } from "../transition";

export type OptionTextMapper = (option: OptionItem<string>) => string;

export interface AutoCompleteProps extends StyledComponentProps {
  value?: string;
  delayTime?: number | ((text: string) => number); // ms
  disabled?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  id?: string;
  onFocus?(): void;
  onBlur?(): void;
  onChange?(value: string, option?: OptionItem<string>): void;
  onRequestOptions: (input: string) => MaybePromise<OptionItem<string>[]>;
}

type ExpandAction =
  | {
      type: "toggle";
      value?: boolean;
      delay?: boolean;
    }
  | {
      type: "cancel";
    };

type RequestOptionAction =
  | {
      type: "request";
      text: string;
    }
  | {
      type: "commit"; // commit a text query
      text: string;
    };

// LATER: support form item
export const AutoComplete: FC<AutoCompleteProps> = ({
  className,
  style,
  value: controlledValue,
  onChange: controlledOnChange,
  disabled = false,
  allowClear = true,
  delayTime = 0,
  placeholder = "",
  id,
  onRequestOptions,
  onFocus: onFocusCallback,
  onBlur: onBlurCallback,
}) => {
  const { field, value, onChange, onValidate } = useFormFieldContext(
    controlledValue,
    controlledOnChange
  );

  const [{ data: expand }, dispatchExpandAction] = useFlow(
    false,
    function* ({ call, put, cancel }, action: ExpandAction) {
      switch (action.type) {
        case "cancel":
          yield cancel();
          break;
        case "toggle":
          if (action.delay) {
            yield call(ScheduleUtils.timeout, 1);
          }
          yield put(action.value ?? ((prev) => !prev));
          break;
        default:
          break;
      }
    }
  );

  // focusable first blur then focused
  const onFocusableFocus = useCallback(() => {
    // HACK:
    dispatchExpandAction({ type: "cancel" });
  }, []);

  const onFocusableBlur = useCallback(() => {
    dispatchExpandAction({ type: "toggle", value: false, delay: true });
  }, []);

  const onInputFocus = useCallback(() => {
    dispatchExpandAction({ type: "toggle", value: true });
    if (onFocusCallback) {
      onFocusCallback();
    }
  }, [onFocusCallback]);

  const onInputBlur = useCallback(() => {
    onValidate();
    if (onBlurCallback) {
      onBlurCallback();
    }
  }, [onValidate, onBlurCallback]);

  const [{ data: options, loading }, dispatch] = useFlow<
    OptionItem<string>[],
    RequestOptionAction
  >([], function* ({ call, put, cancel, dispatch }, action) {
    switch (action.type) {
      case "commit": {
        yield cancel(({ type }) => type === "commit");
        dispatchExpandAction({ type: "toggle", value: true });
        const delayTimeValue = isFunction(delayTime)
          ? delayTime(action.text)
          : delayTime;
        if (delayTimeValue) {
          yield call(ScheduleUtils.timeout, delayTimeValue); // debounce
        }

        yield dispatch({
          type: "request",
          text: action.text,
        });
        break;
      }
      case "request": {
        const options: OptionItem<string>[] = yield call(
          onRequestOptions,
          action.text
        );
        yield put(options);
        break;
      }
      default:
        break;
    }
  });

  const [pointer, setPointer] = useState(0);

  useEffect(() => {
    // set pointer to zero when pointer values unmatched with optionLength
    if (pointer && (pointer < 0 || pointer >= options.length)) {
      setPointer(0);
    }
  }, [pointer, options]);

  const contextValue = useMemo(
    () => ({
      pointer,
      setPointer,
      multi: false,
      selectedValue: null,
      onChange: (nextValue: string, option: OptionItem<string>) => {
        onChange(nextValue, option);
        dispatchExpandAction({ type: "toggle", value: false });
      },
    }),
    [onChange, pointer, setPointer]
  );

  const onKeyDown = (e: KeyboardEvent) => {
    if (disabled) return;
    switch (e.key) {
      case "Escape":
        dispatchExpandAction({ type: "toggle", value: false });
        break;
      case "ArrowDown":
        dispatchExpandAction({ type: "toggle", value: true });
        if (expand) {
          setPointer((prev) => (prev < options.length - 1 ? prev + 1 : 0));
        }
        e.preventDefault(); // prevent page scrolling by up and down
        break;
      case "ArrowUp":
        if (expand) {
          setPointer((prev) => (prev > 0 ? prev - 1 : options.length - 1));
          e.preventDefault(); // prevent page scrolling by up and down
        }
        break;
      case "Enter":
        e.preventDefault();
        if (expand) {
          const option = options[pointer];
          if (option) {
            onChange(option.value, option);
            dispatchExpandAction({ type: "toggle", value: false });
          }
        } else {
          dispatchExpandAction({ type: "toggle", value: true });
        }
        break;
      default:
        break;
    }
  };

  const inputRef = useRef<HTMLInputElement>();

  const onClear = () => {
    onChange("");
    dispatch({ type: "commit", text: "" });
    inputRef.current.focus();
  };

  const optionsVisible = expand && !!options.length;

  const inputId = useMemo(() => {
    return id || field || uniqueId();
  }, []);

  return (
    <InputWrapper
      disabled={disabled}
      className={classNames(
        selectStyles.select,
        {
          [selectStyles.select_disabled]: disabled,
        },
        className
      )}
      style={style}
      onKeyDown={onKeyDown}
      onFocus={onFocusableFocus}
      onBlur={onFocusableBlur}
      tabIndex={-1}
    >
      {loading ? (
        <label htmlFor={inputId} className={styles.label}>
          <Icon type="loading" spin />
        </label>
      ) : allowClear && value ? (
        <label htmlFor={inputId} className={styles.label} onClick={onClear}>
          <Icon type="close" />
        </label>
      ) : null}
      <input
        id={inputId}
        className={styles.input}
        ref={inputRef}
        placeholder={placeholder}
        value={value}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onChange={(e) => {
          const text = e.target.value;
          onChange(text);
          dispatch({ type: "commit", text });
        }}
        autoComplete="off"
      />
      <OptionsPopup visible={optionsVisible} inputRef={inputRef}>
        {(style) => (
          <VisibleTransition
            className={classNames(
              selectStyles.options,
              atomicStyles["no-scroll"]
            )}
            style={style}
            visible={optionsVisible}
          >
            <selectContext.Provider value={contextValue}>
              {loading ? (
                <Loading />
              ) : (
                options.map(({ value, data, name }, index) => (
                  <Option key={value} value={value} data={data} index={index}>
                    {name}
                  </Option>
                ))
              )}
            </selectContext.Provider>
          </VisibleTransition>
        )}
      </OptionsPopup>
    </InputWrapper>
  );
};
