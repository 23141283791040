/**
 * @since 2020-09-10 10:15:53
 */

import { AccountPreferences } from "./interface/account";

export const REGION_VALUE_US = "United States";

export const OTHER_DESIGNERS = "Other Designers";

export const DEFAULT_PREFERENCES: AccountPreferences = {
  productRegion: REGION_VALUE_US,
  language: "English",
  currency: "USD",
};

export const GENDER_STORE_KEY = "gender";

export const ACCOUNT_STORE_KEY = "account";

export const PREFERENCE_STORE_KEY = "preferences";

export const LOCATION_QUEUE_STORE_KEY = "location-queue";

export const APP_STORE_LINK =
  "https://apps.apple.com/us/app/reversible-different-fashion/id1582279514";

export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.reversible";

// messages
export const CONVERSATION_ID_NEW = -1;
export const CONVERSATION_ID_NOTIFICATION = 0;
export const MESSAGE_LIST_SIZE = 20;

export const FIREBASE_BASE_LINK = "https://app.reversible.com";
export const FIREBASE_APP_LINK = "https://app.reversible.com/redirect";
export const FIREBASE_APN = "com.reversible";
export const FIREBASE_ISI = 1582279514;
export const FIREBASE_IBI = "com.reversibleapp.www";

export const OS_UNIT = "OS";
