import React, { FC, ReactNode } from "react";
import classnames from "classnames";
import { translate } from "@/i18n";
import { HTMLElementProps } from "@/interface/base";
import styles from "./loading.module.less";
import { Icon } from "../icon";

export interface LoadingProps
  extends Omit<HTMLElementProps<HTMLDivElement>, "title"> {
  title?: ReactNode;
  cover?: boolean;
}

export const Loading: FC<LoadingProps> = ({
  title = translate("loading"),
  className,
  cover = false,
  ...props
}) => {
  return (
    <div
      className={classnames(
        styles.loading,
        {
          [styles.loading_cover]: cover,
        },
        className
      )}
      {...props}
    >
      <Icon type="loading" spin />
      {title}
    </div>
  );
};
