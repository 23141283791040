import React from "react";
import { Route } from "react-router";
import { DevLayout } from "../component/dev-layout";
import { urls } from "../url";
import LottieDemoView from "./lottie";

export const lottieRoute = () => (
  <Route exact path={urls.lottie()}>
    <DevLayout>
      <LottieDemoView />
    </DevLayout>
  </Route>
);
