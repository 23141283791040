/**
 * for multi image display
 * @since 2020-09-13 15:50:21
 */

import React, { FC, useEffect, memo, useRef } from "react";
import classnames from "classnames";
import { useGallerySwipe } from "@/hooks/use-gallery-swipe";
import styles from "./gallery.module.less";
import { Dots } from "../dots";
import { Icon } from "../icon";
import { Img } from "../img";
import { Unselectable } from "../unselectable";

export interface GalleryControlsProps {
  index: number;
  count: number;
  onChange(index: number): void;
}

export const GalleryControls: FC<GalleryControlsProps> = memo(
  ({ index, count, onChange }) => {
    return (
      <>
        {index > 0 ? (
          <button
            type="button"
            className={classnames(styles.btn, styles["btn--left"])}
            onClick={() => onChange(index - 1)}
          >
            <Icon type="left" />
          </button>
        ) : null}
        {index < count - 1 ? (
          <button
            type="button"
            className={classnames(styles.btn, styles["btn--right"])}
            onClick={() => onChange(index + 1)}
          >
            <Icon type="right" />
          </button>
        ) : null}
        <Dots
          className={styles.dots}
          value={index}
          onChange={onChange}
          count={count}
        />
      </>
    );
  }
);

export interface GallerySource {
  imageUrl: string;
  isPrimary: boolean;
}

interface GalleryProps {
  data: GallerySource[];
}

export const Gallery: FC<GalleryProps> = memo(({ data }) => {
  const wrapperRef = useRef<HTMLDivElement>();

  const n = data.length;
  const {
    touchListeners,
    containerStyle,
    getBoxStyle,
    index,
    onChangeIndex,
    containerClassName,
  } = useGallerySwipe(n, wrapperRef);

  useEffect(() => {
    onChangeIndex(0);
  }, [data]);

  const onChangeIndexWithConversion = (index: number) => {
    onChangeIndex(index);
  };

  return (
    <>
      <Unselectable className={styles.major}>
        <div className={styles.wrapper} ref={wrapperRef}>
          <div
            style={containerStyle}
            {...touchListeners}
            className={classnames(styles.container, containerClassName)}
          >
            {data.map(({ imageUrl }, i) => (
              <div style={getBoxStyle(i)} key={i}>
                <Img
                  className={styles.pic}
                  type="contain"
                  alt="main"
                  src={imageUrl}
                  itemProp="image"
                />
              </div>
            ))}
          </div>
          <GalleryControls
            index={index}
            count={n}
            onChange={onChangeIndexWithConversion}
          />
        </div>
      </Unselectable>
      <footer className={styles.footer}>
        {data.map(({ imageUrl }, i) => {
          const isCurrent = index === i;
          return (
            <div
              className={classnames({
                [styles.thumbnail]: true,
                [styles["thumbnail--current"]]: isCurrent,
              })}
              key={i}
              onClick={
                isCurrent ? undefined : () => onChangeIndexWithConversion(i)
              }
            >
              <Img
                className={styles.thumbnail_img}
                type="contain"
                src={imageUrl}
                primary={!i}
                alt="thumbnail"
              />
            </div>
          );
        })}
      </footer>
    </>
  );
});
