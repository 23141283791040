import { ArrayUtils } from "@reversible/common";
import { Condition } from "@reversible/schema";
import { conditionNamer, CONDITIONS } from "@/enum";
import { translate } from "@/i18n";
import {
  OfferData,
  ListingInfo,
  ListingOffer,
  SpuData,
} from "@/interface/product";

export class ListingUtils {
  static createListingInfo(
    offers: (OfferData | ListingOffer)[],
    spu?: SpuData
  ): ListingInfo {
    // adapt to offerGroup
    const offerPrices = offers
      .map((offer) => ("offerPrice" in offer ? offer.offerPrice : offer.price))
      .sort((p1, p2) => p1 - p2);
    const lowPrice = offerPrices[0];
    const highPrice = ArrayUtils.last(offerPrices);

    const sizes = ArrayUtils.unique(offers.map(({ size }) => size));
    if (spu) {
      const dict = Object.fromEntries(
        spu.sizes.map((size, index) => [size, index])
      );
      sizes.sort((a, b) => dict[a] - dict[b]);
    }

    const conditionDict = Object.fromEntries(CONDITIONS.map((c, i) => [c, i]));
    const conditions = ArrayUtils.unique(
      offers.map((offer) =>
        "offerCondition" in offer ? offer.offerCondition : offer.condition
      )
    ).sort((a, b) => conditionDict[a] - conditionDict[b]);

    return {
      lowPrice,
      highPrice,
      offerIds: offers.map(({ id }) => id),
      sizes,
      conditions,
      count: offers.length,
    };
  }

  static isListing(item: any): item is ListingInfo {
    return Array.isArray(item?.offerIds);
  }

  static displaySize(sizes: string[]) {
    const sizeShowAll =
      sizes.length === 1 || (sizes.length <= 3 && sizes.join("").length <= 12);
    return [
      sizeShowAll,
      sizeShowAll ? sizes.join(", ") : translate("multiple_sizes"),
    ] as const;
  }

  static displayCondition(conditions: Condition[]) {
    const conditionShowAll = conditions.length === 1;
    return [
      conditionShowAll,
      conditionShowAll
        ? conditionNamer(conditions[0])
        : translate("varied_conditions"),
    ] as const;
  }

  static createComparer(spuData: SpuData) {
    const conditionWeightDict = Object.fromEntries(
      CONDITIONS.map((value, index) => [value, index])
    );
    const sizeWeightDict = Object.fromEntries(
      spuData.sizes.map((size, index) => [size, index])
    );

    return (a: OfferData, b: OfferData) => {
      return a.price !== b.price
        ? a.price - b.price
        : a.condition !== b.condition
        ? conditionWeightDict[a.condition] - conditionWeightDict[b.condition]
        : a.size !== b.size
        ? sizeWeightDict[a.size] - sizeWeightDict[b.size]
        : 0;
    };
  }
}
