/**
 * these codes are from useSyncExternalStore Lib
 */
import { useDebugValue, useEffect, useLayoutEffect, useState } from "react";

const { is: defaultIsEqual } = Object;

interface Inst<T> {
  value: T;
  getSnapshot: () => T;
}

function checkIfSnapshotChanged<T>(
  inst: Inst<T>,
  isEqual: (a: T, b: T) => boolean
) {
  const latestGetSnapshot = inst.getSnapshot;
  const prevValue = inst.value;
  try {
    const nextValue = latestGetSnapshot();
    return !isEqual(prevValue, nextValue);
  } catch (error) {
    return true;
  }
}

export function useSyncExternalStore<T>(
  subscribe: (subscriber: () => void) => () => void,
  getSnapshot: () => T,
  isEqual: (a: T, b: T) => boolean = defaultIsEqual
): T {
  const value = getSnapshot();

  const [{ inst }, forceUpdate] = useState<{
    inst: Inst<T>;
  }>({ inst: { value, getSnapshot } });

  // Track the latest getSnapshot function with a ref. This needs to be updated
  // in the layout phase so we can access it during the tearing check that
  // happens on subscribe.
  useLayoutEffect(() => {
    inst.value = value;
    inst.getSnapshot = getSnapshot;

    // Whenever getSnapshot or subscribe changes, we need to check in the
    // commit phase if there was an interleaved mutation. In concurrent mode
    // this can happen all the time, but even in synchronous mode, an earlier
    // effect may have mutated the store.
    if (checkIfSnapshotChanged(inst, isEqual)) {
      // Force a re-render.
      forceUpdate({ inst });
    }
  }, [subscribe, value, getSnapshot]);

  useEffect(() => {
    // Check for changes right before subscribing. Subsequent changes will be
    // detected in the subscription handler.
    if (checkIfSnapshotChanged(inst, isEqual)) {
      // Force a re-render.
      forceUpdate({ inst });
    }
    const handleStoreChange = () => {
      // TODO: Because there is no cross-renderer API for batching updates, it's
      // up to the consumer of this library to wrap their subscription event
      // with unstable_batchedUpdates. Should we try to detect when this isn't
      // the case and print a warning in development?

      // The store changed. Check if the snapshot changed since the last time we
      // read from the store.
      if (checkIfSnapshotChanged(inst, isEqual)) {
        // Force a re-render.
        forceUpdate({ inst });
      }
    };
    // Subscribe to the store and return a clean-up function.
    return subscribe(handleStoreChange);
  }, [subscribe]);

  useDebugValue(value);
  return value;
}
