/**
 * layer manager
 * not used directly
 */
import { useEffect, useMemo } from "react";
import { uniqueId } from "@reversible/common";

const layerMountNode = document.createElement("div");

export const useLayerInitiator = (rootNode: HTMLElement) => {
  useEffect(() => {
    layerMountNode.style.pointerEvents = "none";
    layerMountNode.id = "layers";

    rootNode.append(layerMountNode);

    return () => {
      rootNode.removeChild(layerMountNode);
    };
  }, []);
};

export const useLayerNode = () => {
  const mountNode = useMemo(() => {
    const node = document.createElement("div");
    node.id = uniqueId();
    return node;
  }, []);

  useEffect(() => {
    layerMountNode.append(mountNode);
    return () => {
      layerMountNode.removeChild(mountNode);
    };
  }, []);

  return mountNode;
};
