import { createContext } from "react";
import type { Dispatch } from "@reversible/common";
import type { ConversationType, OfferStatus } from "@/enum";
import type { UserInfoBrief } from "@/interface/account";
import type {
  ConversationDataWithMessages,
  ConversationOfferInfo,
  ConversationOrderInfo,
  LocalMessage,
  LocalMessageSendData,
  MessageData,
  OfferMessageData,
} from "@/interface/message";
import type { messageListModel } from "./message-list.model";

export interface MessageDoubleLinkNode {
  prev: number;
  value: MessageData;
  next: number;
}

// messages are divided into synced and local
export interface MessageListState {
  // conversation info
  id: number;
  conversationType: ConversationType;
  buyerInfo: UserInfoBrief;
  sellerInfo: UserInfoBrief;
  offerInfo: ConversationOfferInfo;
  orderInfo: ConversationOrderInfo;
  lastPrivateOffer: OfferMessageData;
  lastUpdateTime: Date; // the last update time of conversation from service
  // synced messages
  linkDict: Record<number, MessageDoubleLinkNode>; // a link dict of message
  firstSyncedId: number; // first synced id
  lastSyncedId: number; // latest id
  // firstMessageId: number;
  hasReachedTop: boolean; // Whether the list has reached top
  // local message
  localList: LocalMessage[];
  // message common
  focusId: number | string; // focused on which message, scroll into view
}

export type MessageListModel = ReturnType<typeof messageListModel>;

export type MessageListFlowAction =
  | {
      type: "init";
      data: ConversationDataWithMessages;
    }
  | { type: "latest" }
  | {
      type: "previous";
    }
  | {
      type: "push";
      message: LocalMessageSendData;
      onSuccess?(messageIds: number[]): void;
    }
  | {
      type: "check-update";
    }
  | {
      type: "update-offer-status";
      messageId: number;
      status: OfferStatus;
    };

export const messageListModelContext =
  createContext<[MessageListModel, Dispatch<MessageListFlowAction>]>(null);
