import { createContext, Dispatch, SetStateAction } from "react";
import { ObjectPath } from "@reversible/common";
import {
  FormFieldConfig,
  FormFieldState,
  FormMethods,
  FormState,
} from "./interface";

/**
 * the context state provided to Form
 */
export const controlledFormContext =
  createContext<[FormState, Dispatch<SetStateAction<FormState>>]>(null);

/**
 * private form registry state
 */
export interface FieldRegisterContext {
  baseField: string;
  baseFieldPath: ObjectPath;
  registerField(formItemState: FormFieldConfig): () => void;
  updateFieldConfig(
    field: string,
    configOverrides: Partial<Pick<FormFieldConfig, "validate" | "cleanup">>
  ): void;
}

/**
 * the context provided: Form -> FormItem
 */
export type FormContextValue<T> = FormState<T> &
  FormMethods<T> &
  FieldRegisterContext;
export const formContext = createContext<FormContextValue<any>>(null);

/**
 * the context provided: FormItem -> FormItem or specific forms like Input / Select
 */
export const formFieldContext = createContext<FormFieldState>(null);
