import { StringUtils } from "@reversible/common";
import { BrandItem } from "@/interface/product";

const { match, normalize } = StringUtils;

const accentChar = /[\u00C0-\u024F\u1E00-\u1EFF]/;

export class BrandUtils {
  static createMatcher(keyword: string) {
    const matcher = match(keyword, {
      trim: true,
      start: false,
      emptyStrategy: "all",
    });
    return ({ matches, value }: BrandItem) =>
      matcher(value) || matches.some(matcher);
  }

  static create(brand: string): BrandItem {
    const matches = [];
    if (accentChar.test(brand)) {
      matches.push(normalize(brand));
    }
    return {
      value: brand,
      name: brand,
      matches,
    };
  }
}
