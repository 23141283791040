import React, { FC, ReactNode, useEffect, useRef, useMemo } from "react";
import { createPortal } from "react-dom";
import classnames from "classnames";
import { stop, ArrayUtils } from "@reversible/common";
import { EmbeddedComponentProps, StyledComponentProps } from "@/interface/base";
import { styleConsts } from "@/style";
import { DocumentController } from "@/util/dom";
import styles from "./modal.module.less";
import { useLayerNode } from "../hooks/use-layer";
import { Icon } from "../icon";
import { VisibleTransition } from "../transition";

let max = styleConsts.modalZIndex;
const zIndexes: Record<number, [number, number]> = {
  [styleConsts.modalZIndex]: [undefined, undefined], // current -> [prev, next]
};

export interface DialogLayer {
  mountNode: HTMLElement;
  zIndex: number;
}

export const useDialogLayer = (global = false): DialogLayer => {
  // manage all dialog z index with this
  const zIndex = useMemo(() => {
    const value = max + 1;
    zIndexes[max][1] = value;
    zIndexes[value] = [max, undefined];
    max = value;
    return value;
  }, []);

  useEffect(
    () => () => {
      const [prev, next] = zIndexes[zIndex];
      zIndexes[prev][1] = next;
      if (next) {
        zIndexes[next][0] = prev;
      }
      Reflect.deleteProperty(zIndexes, zIndex);
      if (zIndex === max) {
        max = prev;
      }
    },
    []
  );

  const mountNode = useLayerNode();

  return {
    mountNode,
    zIndex: zIndex + (global ? styleConsts.deltaZ : 0), // a global modal is initialy much higher
  };
};

const dialogStack: (() => void)[] = [];

// listen to escape keydown event to close modal
export const useDialogInitiator = () => {
  useEffect(() => {
    return DocumentController.listenKeydown({
      Escape: () => {
        const last = ArrayUtils.last(dialogStack);
        if (last) {
          last();
        }
      },
    });
  }, []);
};

// dialog
interface DialogProps extends StyledComponentProps, EmbeddedComponentProps {
  visible: boolean;
  layer?: DialogLayer;
  preserveChildren?: boolean;
  global?: boolean;
  onClick?(e: React.MouseEvent): void;
  onClose?(): void;
}

export const Dialog: FC<DialogProps> = ({
  visible,
  children,
  className,
  style,
  global,
  onClick,
  preserveChildren,
  layer: controlledLayer,
  onClose,
}) => {
  const { zIndex, mountNode } = controlledLayer || useDialogLayer(global); // eslint-disable-line

  // keeps the latest onClose
  const onCloseRef = useRef<() => void>(onClose);

  onCloseRef.current = onClose;

  useEffect(() => {
    if (visible) {
      // commit onClose to Escape-Keydown subscription
      if (onCloseRef.current) {
        dialogStack.push(() => {
          if (onCloseRef.current) {
            onCloseRef.current();
          }
        });
      }
      const unlockBodyScroll = DocumentController.lockScroll();

      return () => {
        dialogStack.pop();
        unlockBodyScroll();
      };
    }
    return undefined;
  }, [visible]);

  return createPortal(
    <VisibleTransition
      visible={visible}
      className={classnames(styles.dialog, className)}
      onClick={onClick}
      style={{
        zIndex,
        ...(style || {}),
      }}
      preserveChildren={preserveChildren}
    >
      {children}
    </VisibleTransition>,
    mountNode
  );
};
export interface ModalProps extends Omit<DialogProps, "onClick"> {
  title?: ReactNode;
  maskClosable?: boolean;
  maskOpaque?: boolean;
  onClose?(): void; // required
}

export const Modal: FC<ModalProps> = ({
  onClose,
  children,
  className = "",
  style,
  maskClosable = true,
  title,
  ...rest
}) => {
  return (
    <Dialog
      className={classnames(styles.mask)}
      onClick={maskClosable ? onClose : undefined}
      onClose={onClose}
      {...rest}
    >
      <div
        className={classnames(className, styles.modal)}
        onClick={stop()}
        style={style}
      >
        {onClose ? (
          <a className={styles.close_btn} onClick={onClose}>
            <Icon type="close" />
          </a>
        ) : null}
        {title ? <h2 className={styles.modal_title}>{title}</h2> : null}
        <div className={styles.modal_content}>{children}</div>
      </div>
    </Dialog>
  );
};
