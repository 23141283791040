import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { stop } from "@reversible/common";
import styles from "./select.module.less";

export interface SelectInputProps {
  expand: boolean;
  displayElement: ReactElement;
  value: any;
  onExpand(): void;
  onChange(text: string): void;
}

export const SelectInput: FC<SelectInputProps> = ({
  displayElement,
  expand,
  onChange,
  onExpand,
  value,
}) => {
  const [searchText, setSearchText] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  // clear search text when expand is false (indicates some value has been selected)
  useEffect(() => {
    if (!expand) {
      setSearchText("");
    }
  }, [expand]);

  // call onchange
  // trigger expand if something is been searched
  useEffect(() => {
    onChange(searchText);
    if (searchText && !expand) {
      onExpand();
    }
  }, [searchText]);

  // focus the input when initiated
  useEffect(() => {
    inputRef.current.focus();
  }, [expand, value]);

  return (
    <div className={styles.select_input_wrapper}>
      <input
        tabIndex={-1}
        className={styles.select_input}
        ref={inputRef}
        value={searchText}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            // prevent submission by enter
            e.preventDefault();
          }
        }}
        onClick={stop()}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {!searchText ? (
        <div
          className={classNames(styles.display, styles.display_cover, {
            [styles.display_as_placeholder]: expand,
          })}
        >
          {displayElement}
        </div>
      ) : null}
    </div>
  );
};
