import React, { FC, ReactNode, useMemo } from "react";
import { ApiResponse } from "@/service/interface";
import { translate } from "@/i18n";
import { StyledComponentProps } from "@/interface/base";
import { Icon } from "../icon";
import styles from "./alert.module.less";

export interface ErrorAlertProps {
  error: Error | string | ApiResponse;
  retryNode?: ReactNode;
  onRetry?(): void;
}

export const ErrorAlert: FC<ErrorAlertProps> = ({
  error,
  retryNode,
  onRetry,
}) => {
  const text = useMemo(() => {
    if (typeof error === "string") return error;
    if (typeof error !== "object") return "";
    if ("code" in error) {
      if (error?.msg) return error.msg;
      if (typeof error.data === "string") return error.data;
      return "";
    }
    return error?.message || "";
  }, [error]);

  return (
    <div className={styles.error_alert}>
      <Icon className={styles.error_icon} type="close-circle" />
      <div className={styles.error_content}>
        {text ? (
          <div dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <div>{translate("unknown_error")}</div>
        )}
        {onRetry ? (
          <button
            type="button"
            className={styles.retry_button}
            onClick={onRetry}
          >
            {retryNode || translate("retry")}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export interface SuccessAlertProps extends StyledComponentProps {
  title: ReactNode;
}
export const SuccessAlert: FC<SuccessAlertProps> = ({
  className,
  style,
  title,
  children,
}) => {
  return (
    <div className={className} style={style}>
      <div className={styles.success_icon_row}>
        <Icon className={styles.success_icon} type="check-circle" />
      </div>
      <div className={styles.success_title}>{title}</div>
      {React.Children.count(children) ? (
        <div className={styles.success_content}>{children}</div>
      ) : null}
    </div>
  );
};
