import React, {
  ComponentType,
  FC,
  memo,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { CallNext, useFlowEffect, ObjectUtils } from "@reversible/common";
import {
  AskOfferAcceptionForm,
  BidOfferAcceptionForm,
} from "@/component/offer-acception-form";
import { OfferStatus, ApiResponseCode } from "@/enum";
import { translate } from "@/i18n";
import { SpuData } from "@/interface/product";
import * as apiService from "@/service";
import { GetSpuDetail } from "@/service/interface";
import { AsyncDisplay, Button, Toast, Modal } from "@/ui";
import styles from "./buy-sell-modal.module.less";
import { messageListModelContext } from "./context";

export type TargetAskOffer =
  | {
      id: number; // offerId
    }
  | {
      messageId: number;
      offerCountry: string;
      offerPrice: number;
      offerCurrency: string;
      originalOfferPrice: number;
      originalOfferCurrency: string;
    };

interface BuyFormProps {
  askInfo: TargetAskOffer;
  spuData: SpuData;
  onClose(): void;
}

const BuyForm: FC<BuyFormProps> = memo(({ askInfo, spuData, onClose }) => {
  const [{ offerInfo, sellerInfo }, dispatchMessageAction] = useContext(
    messageListModelContext
  );

  // offer info adaption
  const askInfoForAcception = useMemo(() => {
    const offerFromSpuData = spuData.offers.find(
      ({ id }) => offerInfo.offerId === id
    );
    const common = {
      ...ObjectUtils.omit(sellerInfo, ["id"]),
      userId: sellerInfo.id,
      condition: offerFromSpuData.condition,
      size: offerFromSpuData.size,
      sizeUnit: spuData.sizeUnit,
    };

    return "id" in askInfo
      ? {
          id: offerFromSpuData.id,
          offerShipping: offerFromSpuData.offerShipping, // needs shipping info
          price: offerFromSpuData.price, // this is the real latest
          currency: offerFromSpuData.currency,
          originalCurrency: offerFromSpuData.originalCurrency,
          originalPrice: offerFromSpuData.originalPrice,
          offerCountry: offerFromSpuData.offerCountry,
          ...common,
        }
      : {
          messageId: askInfo.messageId,
          price: askInfo.offerPrice,
          currency: askInfo.offerCurrency,
          originalCurrency: askInfo.originalOfferCurrency,
          originalPrice: askInfo.originalOfferPrice,
          offerCountry: askInfo.offerCountry,
          ...common,
        };
  }, [askInfo, spuData]);

  return (
    <AskOfferAcceptionForm
      spuData={spuData}
      askInfo={askInfoForAcception}
      completeOperator={
        <Button type="fill" onClick={onClose}>
          {translate("ok")}
        </Button>
      }
      publicOfferId={offerInfo.offerId}
      onComplete={() => {
        if ("messageId" in askInfo) {
          dispatchMessageAction({
            type: "update-offer-status",
            messageId: askInfo.messageId,
            status: OfferStatus.Completed,
          });
        }
        dispatchMessageAction({ type: "latest" }); // refresh
      }}
    >
      {(title, content) => (
        <>
          <h3 className={styles.title}>{title}</h3>
          {content}
        </>
      )}
    </AskOfferAcceptionForm>
  );
});

export type TargetBidOffer = {
  messageId: number;
  paymentOrderId: string;
};

interface SellFormProps {
  bidInfo: TargetBidOffer;
  spuData: SpuData;
  onClose(): void;
}

const SellForm: FC<SellFormProps> = ({ bidInfo, spuData, onClose }) => {
  const [, dispatchMessageAction] = useContext(messageListModelContext);

  return (
    <BidOfferAcceptionForm
      spuData={spuData}
      bidInfo={bidInfo}
      onComplete={() => {
        dispatchMessageAction({
          type: "update-offer-status",
          messageId: bidInfo.messageId,
          status: OfferStatus.Completed,
        });
        dispatchMessageAction({ type: "latest" });
      }}
      completeOperator={
        <Button type="fill" onClick={onClose}>
          {translate("ok")}
        </Button>
      }
    >
      {(title, content) => (
        <>
          <h3 className={styles.title}>{title}</h3>
          {content}
        </>
      )}
    </BidOfferAcceptionForm>
  );
};

function withModal<T extends { spuData: SpuData; onClose(): void }>(
  FormComponent: ComponentType<T>,
  title: ReactNode
) {
  return (props: Omit<T, "spuData"> & { visible: boolean }) => {
    const [{ offerInfo }, dispatchMessageAction] = useContext(
      messageListModelContext
    );

    const spuDataState = useFlowEffect<SpuData>(
      null as SpuData,
      function* ({ call, put }) {
        if (!props.visible) return; // once open the modal, load the latest spuData
        yield put(null);
        let spuId = offerInfo.spuId;
        while (true) {
          const { data, code }: CallNext<GetSpuDetail> = yield call(
            apiService.getSpuDetail,
            {
              spuId,
            }
          );
          switch (code) {
            case ApiResponseCode.Success: {
              const spuData = data as SpuData;
              if (!spuData.offers.find(({ id }) => offerInfo.offerId === id)) {
                Toast.warn("offer_unavailable");
                dispatchMessageAction({ type: "latest" });
                props.onClose();
              } else {
                yield put(spuData);
              }
              return;
            }
            case ApiResponseCode.Redirect: {
              spuId = data as number;
              // continue
              break;
            }
            default:
              return;
          }
        }
      },
      [props.visible]
    );

    return (
      <Modal
        className={styles.modal}
        visible={props.visible}
        onClose={props.onClose}
        title={title}
      >
        <AsyncDisplay source={spuDataState}>
          {(spuData) => {
            if (!spuData) return null;

            const childrenProps = {
              ...props,
              spuData,
            } as unknown as T;
            return <FormComponent {...childrenProps} />;
          }}
        </AsyncDisplay>
      </Modal>
    );
  };
}

export const BuyModal = withModal(BuyForm, translate("buy"));
export const SellModal = withModal(SellForm, translate("sell"));
