import React, { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";

export const MaybeLink = forwardRef<any, LinkProps>((props, ref) =>
  props.to ? (
    <Link {...props} ref={ref} />
  ) : (
    <span {...(props as any)} ref={ref} />
  )
);
