import React, { FC, CSSProperties } from "react";
import { Link } from "react-router-dom";
import { urls } from "../../url";

const linkStyle: CSSProperties = {
  marginRight: "20px",
};

export const DevLayout: FC = ({ children }) => (
  <div style={{ height: "100%", overflow: "auto" }}>
    <header style={{ borderBottom: "1px solid #eee", padding: "20px" }}>
      {(
        [
          ["form", "Form"],
          ["ui", "UI"],
          ["menu", "Menu"],
          ["message", "Message"],
          ["address", "Address"],
          ["imageCompress", "Image Compress"],
          ["lottie", "Lottie Demo"],
        ] as const
      ).map(([link, name]) => (
        <Link key={name} style={linkStyle} to={urls[link]()}>
          {name}
        </Link>
      ))}
    </header>
    <main style={{ padding: "20px" }}>{children}</main>
  </div>
);
