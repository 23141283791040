import { isString, MaybePromiseUtils } from "@reversible/common";
import { translate } from "@/i18n";
import * as apiService from "@/service";
import { FormValidationRule } from "./interface";

const { map } = MaybePromiseUtils;

const required = (text: string, autoTrim = true): FormValidationRule => {
  return (value: any): string => {
    if (value == null) return text;
    if (
      isString(value) &&
      ((autoTrim && !value.trim()) || (!autoTrim && !value))
    ) {
      return text;
    }
    if (Array.isArray(value)) {
      return value.length ? "" : text;
    }
    return "";
  };
};

const match = (regex: RegExp, text: string): FormValidationRule => {
  return (value: string): string => (regex.test(value) ? "" : text);
};

const passwordSyntax: FormValidationRule = (input) => {
  // length
  if (input.length < 8) {
    return translate("password_syntax_rule");
  }
  // within ascii
  // eslint-disable-next-line
  if (!/^[\x00-\x7F]+$/.test(input)) {
    return translate("password_syntax_rule");
  }
  if (
    [/[a-zA-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].some((regex) => !regex.test(input))
  ) {
    // letters, numbers symbols
    return translate("password_syntax_rule");
  }
  return "";
};

const username: FormValidationRule = (input: string) => {
  const trimmed = input.trim().toLowerCase();
  if (!trimmed) {
    return translate("please_enter_username");
  }
  if (!/^[a-zA-Z0-9_.]*$/.test(trimmed)) {
    return translate("username_syntax_alert");
  }
  if (trimmed.length > 20) {
    return translate("username_too_long");
  }
  if (/^\./.test(trimmed)) {
    return translate("username_period_start_alert");
  }
  if (/\.$/.test(trimmed)) {
    return translate("username_period_end_alert");
  }
  if (/\.\./.test(trimmed)) {
    return translate("username_period_more_alert");
  }
  return map(apiService.checkDuplication({ username: trimmed }), ({ data }) =>
    data ? "" : translate("username_already_in_use")
  );
};

const emailSyntax: FormValidationRule = (input) => {
  const isValid =
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      input
    );
  return isValid ? "" : translate("email_invalid_alert");
};

const emailDuplication: FormValidationRule = (email: string) => {
  return map(apiService.checkDuplication({ email: email.trim() }), ({ data }) =>
    data ? "" : translate("email_already_in_use")
  );
};

const passwordConfirmation: FormValidationRule = (
  passwordConfirm,
  { password }
) => {
  return password === passwordConfirm
    ? ""
    : translate("password_confirmation_alert");
};

const mobile: FormValidationRule = (mobile: string) => {
  const trimmed = mobile.trim();
  if (!trimmed) {
    return translate("please_enter_phone_number");
  }
  if (!/^[\d\s-]*$/.test(trimmed)) {
    return translate("phone_number_should_contain_numbers_only");
  }
  if (trimmed.length > 14) {
    return translate("phone_number_too_long");
  }
  return "";
};

export const FormRules = {
  required,
  match,
  username,
  mobile,
  emailSyntax,
  passwordSyntax,
  passwordConfirmation,
  emailDuplication,
} as const;
