import React from "react";
import { Route } from "react-router";
import { DevLayout } from "../component/dev-layout";
import { urls } from "../url";
import AddressView from "./address";

export const addressRoute = () => (
  <Route exact path={urls.address()}>
    <DevLayout>
      <AddressView />
    </DevLayout>
  </Route>
);
