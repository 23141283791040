import { CallNext, useFlowEffect } from "@reversible/common";
import { SettingsInfo } from "@/interface/data";
import * as apiService from "@/service";
import { GetSettingsInfo } from "@/service/interface";
import { createModel } from ".";

export const useSettingsInfo = createModel(() => {
  return useFlowEffect<SettingsInfo>(
    {
      regions: [],
      currencies: [],
      currencySymbolDict: {},
      regionCodeDict: {},
      codeRegionDict: {},
      languages: [],
      mapping: {},
      phoneCodes: [],
    },
    function* ({ call, put }) {
      const { data }: CallNext<GetSettingsInfo> = yield call(
        apiService.getSettingsInfo
      );
      yield put({
        ...data,
        currencySymbolDict: Object.fromEntries(
          data.currencies.map(({ currency, symbol }) => [currency, symbol])
        ),
        regionCodeDict: Object.fromEntries(
          data.regions.map(({ region, code }) => [region, code])
        ),
        codeRegionDict: Object.fromEntries(
          data.regions.map(({ region, code }) => [code, region])
        ),
      });
    },
    []
  );
});
