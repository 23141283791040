import {
  Effects,
  FlowIterator,
  PartialMutator,
  ValueType,
  applyPartialMutator,
  ObjectUtils,
} from "@reversible/common";
import { OfferStatus } from "@/enum";
import { UserInfo } from "@/interface/account";
import { PagiList } from "@/interface/base";
import { PostData, PostRootComment } from "@/interface/post";

export interface PostCommentsData extends PagiList<PostRootComment> {
  hasMore: boolean;
}

export interface FavoritePatch {
  favoriteId?: number;
}

export interface OfferPatch {
  offerStatus?: OfferStatus;
}

export type PatchableKeys = "favorite" | "offer";
export interface Data {
  favoritePatches: Record<number | `${number}-${number}`, FavoritePatch>;
  offerPatches: Record<number, OfferPatch>;
  posts: Record<number, PostData>;
  comments: Record<number, PostCommentsData>;
  users: Record<number, UserInfo>;
}

type UpdateAction = {
  [K in keyof Data]: Data[K] extends Record<number | string, any>
    ? ValueType<Data[K]> extends Record<any, any>
      ? {
          type: "update";
          key: K;
          id: keyof Data[K];
          mutator: PartialMutator<ValueType<Data[K]>>;
        }
      : never
    : never;
}[keyof Data];

type PatchAction = {
  [K in PatchableKeys]: {
    type: "patch";
    key: K;
    id: keyof Data[`${K}Patches`];
    patch: ValueType<Data[`${K}Patches`]>;
  };
}[PatchableKeys];

export type DataAction =
  | PatchAction
  | {
      type: "commit-posts";
      data: PostData | PostData[];
    }
  | {
      type: "commit-users";
      data: UserInfo | UserInfo[];
    }
  | {
      type: "commit-comments";
      id: number;
      data: PostCommentsData;
    }
  | {
      type: "remove";
      key: keyof Data;
      id: number | number[];
    }
  | UpdateAction;

export const initialData: Data = {
  favoritePatches: {},
  offerPatches: {},
  posts: {},
  comments: {},
  users: {},
};

function iterateMaybeArray<T>(source: T | T[], callback: (value: T) => void) {
  for (const value of Array.isArray(source) ? source : source ? [source] : []) {
    callback(value);
  }
}

const { immutableSet, immutableRemove } = ObjectUtils;

export function* dataFlow(
  { put }: Effects<Data, DataAction>,
  action: DataAction
): FlowIterator<Data, DataAction> {
  switch (action.type) {
    case "commit-posts":
      yield put((prev) => {
        const nextPosts = { ...prev.posts };
        const nextUsers = { ...prev.users };
        iterateMaybeArray(action.data, (post) => {
          nextPosts[post.id] = post;
          const { userInfo } = post;
          nextUsers[userInfo.id] = userInfo;
        });
        return {
          ...prev,
          posts: nextPosts,
          users: nextUsers,
        };
      });
      break;
    case "commit-users":
      yield put((prev) => {
        const nextUsers = { ...prev.users };
        iterateMaybeArray(action.data, (user) => {
          nextUsers[user.id] = user;
        });
        return { ...prev, users: nextUsers };
      });
      break;
    case "commit-comments":
      yield put((prev) => {
        const { id, data } = action;
        return {
          ...prev,
          comments: {
            ...prev.comments,
            [id]: data,
          },
        };
      });
      break;
    case "update":
      yield put((prev) =>
        immutableSet(prev, [action.key, action.id], (input) =>
          input != null ? applyPartialMutator(action.mutator, input) : input
        )
      );
      break;
    case "patch":
      yield put((prev) =>
        immutableSet(prev, [`${action.key}Patches`, action.id], action.patch)
      );
      break;
    case "remove":
      yield put((prev) => immutableRemove(prev, [action.key, action.id]));
      break;
    default:
      break;
  }
}
