import React, { createContext, FC, useEffect, useContext } from "react";
import { Flow, useFlow , ObjectUtils } from "@reversible/common";
import { AddressData } from "@/interface/address";
import { EmbeddedComponentProps } from "@/interface/base";
import * as apiService from "@/service";
import { Toast } from "@/ui";

export type Action =
  | {
      type: "query";
    }
  | {
      type: "set-default";
      data: AddressData;
    }
  | {
      type: "update";
      addressId: number;
      overrides: Partial<Omit<AddressData, "id">>;
    }
  | {
      type: "create";
      data: AddressData;
    }
  | {
      type: "delete";
      addressId: number;
    };

const flow: Flow<AddressData[], Action> = function* (
  { call, put, get },
  action
) {
  switch (action.type) {
    case "query": {
      const { data } = yield call(apiService.getAddressList);
      yield put(data);
      break;
    }
    case "set-default": {
      const { data } = action;
      const beforeMod = yield get();
      try {
        yield put((prev) =>
          prev.map((address) => ({
            ...address,
            isDefault: address.id === data.id,
          }))
        );
        yield call(apiService.updateAddress, {
          addressId: data.id,
          ...ObjectUtils.omit(data, ["id", "isDeletable"]),
          isDefault: true,
        });
      } catch {
        // ALERT
        yield put(beforeMod);
      }
      break;
    }
    case "update": {
      const { addressId, overrides } = action;
      yield put((prev) =>
        prev.map((item) =>
          item.id === addressId
            ? {
                ...item,
                ...overrides,
              }
            : item
        )
      );
      break;
    }
    case "create": {
      try {
        const { data } = action;
        yield put((prev) => [
          ...(data.isDefault
            ? prev.map((item) =>
                item.isDefault // clear the original default
                  ? {
                      ...item,
                      isDefault: false,
                    }
                  : item
              )
            : prev),
          data,
        ]);
      } catch {
        // FALLBACK
      }
      break;
    }
    case "delete": {
      try {
        const { addressId } = action;
        yield call(apiService.deleteAddress, { addressId });
        yield put((prev) => {
          const deleteIndex = prev.findIndex(({ id }) => id === addressId);
          if (deleteIndex === -1) {
            return prev;
          }
          const { isDefault } = prev[deleteIndex];
          const copy = prev.slice();
          copy.splice(deleteIndex, 1);
          if (isDefault && copy.length) {
            // make sure the list still has at least one
            copy[0] = {
              ...copy[0],
              isDefault: true, // set first as default
            };
          }
          return copy;
        });
      } catch (e) {
        Toast.error(e.msg);
        // FALLBACK
      }
      break;
    }
    default: {
      break;
    }
  }
};

const useAddressFlow = () => useFlow([], flow);

const addressModelContext =
  createContext<ReturnType<typeof useAddressFlow>>(null);

export const AddressModelProvider: FC<EmbeddedComponentProps> = ({
  children,
}) => {
  const addressState = useAddressFlow();

  const dispatch = addressState[1];

  useEffect(() => {
    dispatch({
      type: "query",
    });
  }, []);

  return (
    <addressModelContext.Provider value={addressState}>
      {children}
    </addressModelContext.Provider>
  );
};

export const useAddressModel = () => useContext(addressModelContext);
