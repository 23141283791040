import React, { forwardRef, memo, useMemo } from "react";
import classnames from "classnames";
import { uniqueId } from "@reversible/common";
import { StyledComponentProps } from "@/interface/base";
import styles from "./search-input.module.less";
import { useFormFieldContext } from "../form";
import { Icon } from "../icon";

interface SearchInputProps extends StyledComponentProps {
  value?: string;
  form?: string;
  onChange?(value: string): void;
  placeholder?: string;
  allowClear?: boolean;
}

export const SearchInput = memo(
  forwardRef<HTMLInputElement, SearchInputProps>(
    (
      {
        value: controlledValue,
        onChange: controlledOnChange,
        form,
        placeholder,
        className,
        allowClear = true,
        style,
      },
      ref
    ) => {
      const { value, onChange } = useFormFieldContext(
        controlledValue,
        controlledOnChange
      );

      const inputId = useMemo(() => `search-input-${uniqueId()}`, []);

      const showClose = value && allowClear;

      return (
        <div
          className={classnames(styles.search_input, className)}
          style={style}
        >
          <label
            onClick={showClose ? () => onChange("") : undefined}
            className={classnames(styles.icon, {
              [styles["icon--clickable"]]: showClose,
            })}
            htmlFor={inputId}
          >
            <Icon type={showClose ? "close" : "search"} />
          </label>
          <input
            form={form}
            id={inputId}
            ref={ref}
            className={styles.input}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value.trimStart())}
            inputMode="search"
          />
        </div>
      );
    }
  )
);
