import React, { FC, useEffect, useRef } from "react";

function makeUnselectable(node: HTMLElement) {
  if (!node) return;
  if ("onselectstart" in node) {
    if (node.nodeType === 1) {
      node.onselectstart = () => false;
      node.setAttribute("unselectable", "on");
    }
  }
  // let child = node.firstChild;
  // while (child) {
  //   makeUnselectable(child as any);
  //   child = child.nextSibling;
  // }
}

export type UnselectableProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const Unselectable: FC<UnselectableProps> = ({ children, ...props }) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    makeUnselectable(ref.current);
  }, []);

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
};
